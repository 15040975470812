import { getHTTPData } from "../../utils/getData";
import {fetchFromApi} from "../../redux/actions/service";

export const API = {
	brend: "api/v1/product-brand/",
	save:  "api/v1/product-brand",
	code:  "api/v1/dict/code",
	dictionary: "api/v1/dictionary/LANG/locale/",

	getBrend: function (str) {
		return getHTTPData(this.brend + str, 'GET');
	},
	getCode: function () {
		return fetchFromApi("api/v1/dict/code","GET",{})
			.then((res) => { return res.text() })
	},
	saveChanges: function (body) {
		return getHTTPData(this.save, 'PUT', body);
	}
}
