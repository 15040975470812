const initialState = {
  specCode: null,
  data: [],
  fetchingStatus: null,
};

function specComponentGroup(state = initialState, action) {
  switch (action.type) {
    case SET_SPEC_CODE_FOR_SCG:
      return { ...state, specCode: action.payload };
    case SET_SPEC_COMPONENT_GROUP:
      return { ...state, data: action.payload };
    case UPDATE_SPEC_COMPONENT_GROUP:
      return action.payload.actionType === "C"
        ? {
            ...state,
            data: state.data.filter((item) => item.id !== action.payload.id),
          }
        : {
            ...state,
            data: state.data.map((item) => {
              if (item.id !== action.payload.id) return item;

              return {
                ...item,
                actionType: action.payload.actionType,
              };
            }),
          };
    case SET_SPEC_COMPONENT_GROUP_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_SPEC_CODE_FOR_SCG = "SET_SPEC_CODE_FOR_SCG";
const SET_SPEC_COMPONENT_GROUP = "SET_SPEC_COMPONENT_GROUP";
const UPDATE_SPEC_COMPONENT_GROUP = "UPDATE_SPEC_COMPONENT_GROUP";
const SET_SPEC_COMPONENT_GROUP_FETCHING_STATUS =
  "SET_SPEC_COMPONENT_GROUP_FETCHING_STATUS";

export const setSpecCodeForSCG = (payload) => ({
  type: SET_SPEC_CODE_FOR_SCG,
  payload,
});

export const setSpecComponentGroup = (payload) => ({
  type: SET_SPEC_COMPONENT_GROUP,
  payload,
});

export const addSpecComponentGroup = (payload) => ({
  type: SET_SPEC_COMPONENT_GROUP,
  payload,
});

export const updateSpecComponentGroup = (payload) => ({
  type: SET_SPEC_COMPONENT_GROUP,
  payload,
});

export const setSpecComponentGroupFetchingStatus = (payload) => ({
  type: SET_SPEC_COMPONENT_GROUP_FETCHING_STATUS,
  payload,
});

export default specComponentGroup;
