import {fetchFromApi} from '../service';
import { setSelectLimitMax, setSelectSearchMax, setXlsExportMax, setXlsImportMax } from '../sync/settings';

export function getApplicationSettings() {
  return async (dispatch) => {
    const selectSearchMaxResponse = await fetchFromApi("api/v1/parameter/selectSearchMax","GET",{})
        .then((res) => res.json());
    const selectLimitMaxResponse = await fetchFromApi("api/v1/parameter/selectLimitMax","GET",{})
        .then((res) => res.json());
    const xlsExportMaxResponse = await fetchFromApi("api/v1/parameter/xlsExportMax","GET",{})
        .then((res) => res.json());
    const xlsImportMaxResponse = await fetchFromApi("api/v1/parameter/xlsImportMax","GET",{})
        .then((res) => res.json());

    dispatch(setSelectSearchMax(selectSearchMaxResponse));
    dispatch(setSelectLimitMax(selectLimitMaxResponse));
    dispatch(setXlsExportMax(xlsExportMaxResponse));
    dispatch(setXlsImportMax(xlsImportMaxResponse));
  };
}
