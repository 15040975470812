import React from "react";
import "./index.scss";

function ToggleSwitch({ id, label, checked = false, onToggle }) {
  return (
    <div className="form-toggle-switch">
      <label className="toggle-switch">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={(e) => onToggle(e)}
        />
        <span className="switch" />
      </label>
      <label htmlFor={id} className="form-toggle-switch__label">
        {label}
      </label>
    </div>
  );
}
export default ToggleSwitch;
