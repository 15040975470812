import {
  RECIVE_CATEGORIES,
  SET_DEFAULT_CATEGORY,
  UPDATE_CATEGORY,
} from '../actions/sync/category';
import { RESET_APP } from '../actions/sync/init';
import {
  RECIVE_SEARCH_BY_FILTER_RESULT,
  RECIVE_SEARCH_BY_STRING_RESULT,
} from '../actions/sync/search';

const defState = {
  current: '',
  list: [],
};

function category(state = defState, action) {
  switch (action.type) {
    case UPDATE_CATEGORY:
      return Object.assign({}, state, { current: action.id });
    case RECIVE_CATEGORIES:
      if(action.data && action.data.length === 1){
        return Object.assign({}, state, { list: action.data, current: action.data.code });
      }else{
        return Object.assign({}, state, { list: action.data });
      }
      // return Object.assign({}, state, {
      //   list: action.data,
      // });
    case RECIVE_SEARCH_BY_STRING_RESULT:
      if(action.json.modelCategory === undefined){
        return state;
      }
      return Object.assign({}, state, {
        list: action.json.modelCategory,
        current: 'def',
      });
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      if (Object.keys(action.json).length === 0) {
        return state;
        // return defState;
      }
      if (action.json.modelCategory.length === 1) {
        // return state;
        return Object.assign({}, state, { list: action.json.modelCategory, current: action.json.modelCategory[0].code });
      }
      return Object.assign({}, state, { list: action.json.modelCategory });
    case SET_DEFAULT_CATEGORY:
      if (state.list && state.list.length > 0) {
        return Object.assign({}, state, { current: state.list[0].code });
      } else {
        return state;
      }
    case RESET_APP:
      return defState;
    default:
      return state;
  }
}

export default category;
