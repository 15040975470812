import { setProductFormSelectors } from "../../reducers/productFormSelectors";
import { fetchFromApi } from "../service";

export function getProductFormSelectors() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;

    const urls = [
      "api/v1/product-brand/filter/page",
      "api/v1/product-premium/filter/page",
    ];

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await Promise.all(
        urls.map((url) => fetchFromApi(url, "POST", { body }))
      );
      const responseData = await Promise.all(
        response.map((data) => data.json())
      );

      const [productBrandList, productPremiumList] = responseData.map(
        (data) => data.data
      );

      dispatch(
        setProductFormSelectors({
          productBrandList,
          productPremiumList,
        })
      );
    } catch (error) {}
  };
}
