import {
  CHANGE_COMPONENT_MODE,
  RECIVE_PRODUCT_INFO,
} from '../actions/sync/product';

const defState = {
  by_id: {},
  all_ids: [],
};

function components(state = defState, action) {
  switch (action.type) {
    case RECIVE_PRODUCT_INFO:
      return Object.assign({}, state, getComponentsFromInfo(action.json));
    case CHANGE_COMPONENT_MODE:
      return Object.assign({}, state, {
        by_id: Object.assign({}, state.by_id, {
          [action.id]: Object.assign({}, state.by_id[action.id], {
            closed: !state.by_id[action.id].closed,
          }),
        }),
      });
    default:
      return state;
  }
}

function getComponentsFromInfo(info) {
  const res = {
    all_ids: [],
    by_id: {},
  };
  if(!info.component){
    return res;
  }
  info.component.forEach((el, i) => {
    res.all_ids.push('component_' + i);
    res.by_id['component_' + i] = {
      id: 'component_' + i,
      componentType: el.componentType,
      componentFuelType: el.componentFuelType,
      powerHp: el.powerHp,
      engineCap: el.engineCap,
      name: el.name,
      description: 'description_' + i, // el.description !== undefined ? el.description : genera/teDescription(i),
      hidden: el.hidden,
      conditions: getConditions(el.condition, i),
      closed: true,
      showDescription: false,
    };
  });
  return res;
}

function getConditions(conditions, id) {
  if (conditions === undefined || conditions === null) {
    return [];
  }
  return conditions.map((el, i) => 'condition_' + id + '_' + i);
}

export default components;
