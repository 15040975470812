import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import YearPicker from "../../pureComponents/yearPicker";
import {CategoryCard} from "../../components/categoryCard";

const SearchByFilterForm = ({
    state,
    props,
    fields,
    startSearch,
    categories,
    marks,
    models,
    fuelType,
    engineCap,
    modelClass,
    changeDropdown,
    filterSearchDisable,
    handleDateChange,
    subcategories,
    hasSearchResult
}) => {
    
    return (
        <div className={`selector-list selector-list_search-by-filter${props.fetching ? ' waiting' : ''}`}>
            <div className="selector-list__container">
                {state.search.stage === 'home' &&
                    <div className={'selector-list__under-header'}>или</div>
                }
                <div className={'selector-list__header'}>{props.phrases.header[props.lang]}</div>

                <div className="selector-list__table">
                    <CategoryCard category={fields.categoryCode} />

                    <div className="selector-list__fields">
                        <div className="selector-list__row">
                            <DropdownSelect
                                id='categoryCode'
                                label='Категория'
                                defaultValue={getNameFromList(categories, fields.categoryCode)}
                                items={categories}
                                value={fields.categoryCode}
                                onSelect={changeDropdown}
                                required={true}
                            />
                            <DropdownSelect
                                id='subcategoryCode'
                                label='Подкатегория'
                                defaultValue={getNameFromList(subcategories, fields.subcategoryCode)}
                                items={subcategories}
                                value={fields.subcategoryCode}
                                onSelect={changeDropdown}
                                isClearEnabled={true}
                                required={true}
                            />
                            <DropdownSelect
                                id='modelMarkCode'
                                label='Марка'
                                defaultValue={getNameFromList(marks, fields.modelMarkCode)}
                                items={marks}
                                value={fields.modelMarkCode}
                                onSelect={changeDropdown}
                                isClearEnabled={true}
                                required={true}
                            />
                        </div>
                        <div className="selector-list__row">
                            <DropdownSelect
                                id='modelCode'
                                label='Модель'
                                defaultValue={getNameFromList(models, fields.modelCode)}
                                items={models}
                                value={fields.modelCode}
                                onSelect={changeDropdown}
                                isClearEnabled={true}
                                required={true}
                            />
                            <YearPicker
                                id={'year'}
                                label="Год"
                                value={fields.year}
                                onChangeData={handleDateChange}
                                minDate = {props.minYearStart}
                                maxDate = {props.maxYearEnd}
                                disabled={!hasSearchResult}
                            />

                            <DropdownSelect
                                id='fuelTypeCode'
                                label='Тип топлива'
                                defaultValue={getNameFromList(fuelType, fields.fuelTypeCode)}
                                value={fields.fuelTypeCode}
                                onSelect={changeDropdown}
                                items={fuelType}
                                disabled={!hasSearchResult}
                                isClearEnabled={true}
                            />
                        </div>
                        <div className="selector-list__row">
                            <DropdownSelect
                                id='engineCapCode'
                                label='Объем двигателя'
                                defaultValue={getNameFromList(engineCap, fields.engineCapCode)}
                                items={engineCap}
                                value={fields.engineCapCode}
                                onSelect={changeDropdown}
                                disabled={!hasSearchResult}
                                isClearEnabled={true}
                            />
                            <DropdownSelect
                                id='modelClassCode'
                                label='Класс евро'
                                items={modelClass}
                                defaultValue={getNameFromList(modelClass, fields.modelClassCode)}
                                value={fields.modelClassCode}
                                onSelect={changeDropdown}
                                disabled={!hasSearchResult}
                                isClearEnabled={true}
                            />

                            <div className="dropdown-block dropdown-block__item">
                                <button
                                    className={'button button__primary'}
                                    onClick={() => startSearch('by_filter')}
                                    disabled={filterSearchDisable}>
                                    Поиск
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchByFilterForm;