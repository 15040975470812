import SideTreeMenuItem from "./sideTreeMenuItem";
import "./index.scss";

function checkIfChildrenItemIsActive(items = []) {
  return items.some(
    (item) => item.active || checkIfChildrenItemIsActive(item.children)
  );
}

const transformMenuTree = (item, padding = 0) => {
  return {
    ...item,
    padding,
    open: item.active || checkIfChildrenItemIsActive(item.children),
    children: item.children
      ? item.children.map((child) => transformMenuTree(child, padding + 25))
      : [],
  };
};

const SideTreeMenu = ({ menu, title }) => {
  const menuTree = Array.isArray(menu)
    ? menu.map((submenu) => transformMenuTree(submenu))
    : [transformMenuTree(menu)];

  return (
    <div className="side-tree-menu">
      <div className="side-tree-menu-header">
        <div className="side-tree-menu-header__caption">{title}</div>
        <div className="side-tree-menu-header__img"></div>
      </div>
      <div className="side-tree-menu-content">
        {menuTree.map((item, index) => (
          <SideTreeMenuItem {...item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default SideTreeMenu;
