const initialState = {
  selectedSpecification: [],
  selectedVisco: [],
  selectedCategory: [],
  fetchingStatus: null
};
const UPDATE_STATISTIC_COVER_REPORT_SPECIFICATION = "UPDATE_STATISTIC_COVER_REPORT_SPECIFICATION"
const RESET_STATISTIC_COVER_REPORT_FORM_STATE = "RESET_STATISTIC_COVER_REPORT_FORM_STATE";
const UPDATE_STATISTIC_COVER_REPORT_VISCO = "UPDATE_STATISTIC_COVER_REPORT_VISCO";
const UPDATE_STATISTIC_COVER_REPORT_CATEGORY = "UPDATE_STATISTIC_COVER_REPORT_CATEGORY";
const UPDATE_STATISTIC_COVER_REPORT_FORM = "UPDATE_STATISTIC_COVER_REPORT_FORM";

function statisticCoverReportForm(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_STATISTIC_COVER_REPORT_SPECIFICATION:
      return {
        ...state,
        selectedSpecification: payload ? [payload] : []    // массив равено новому значению
      };
    case UPDATE_STATISTIC_COVER_REPORT_VISCO:
      return {
        ...state,
        selectedSpecification: payload ? [payload] : []    // массив равено новому значению
      };
    case UPDATE_STATISTIC_COVER_REPORT_CATEGORY:
      return {
        ...state,
        selectedSpecification: payload ? [payload] : []    // массив равено новому значению
      };
    case UPDATE_STATISTIC_COVER_REPORT_FORM:
      return {
        ...state,
        ...payload
      };
    case RESET_STATISTIC_COVER_REPORT_FORM_STATE:            // Reload page
      return initialState;
    default:
      return state;
  }
}

export const updateStatisticCoverReportSpecification = (payload) => ({
  type: UPDATE_STATISTIC_COVER_REPORT_SPECIFICATION,
  payload
});

export const updateStatisticCoverReportVisco = (payload) => ({
  type: UPDATE_STATISTIC_COVER_REPORT_VISCO,
  payload
});

export const updateStatisticCoverReportCategory = (payload) => ({
  type: UPDATE_STATISTIC_COVER_REPORT_CATEGORY,
  payload
});

export const updateStatisticCoverReportForm = (payload) => ({
  type: UPDATE_STATISTIC_COVER_REPORT_FORM,
  payload
});

export const resetStatisticCoverReportFormState = () => ({
  type: RESET_STATISTIC_COVER_REPORT_FORM_STATE
});

export default statisticCoverReportForm;
