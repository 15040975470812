import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';

import VisibleHeader from '../../redux/containers/visibleHeader';
import {useDocumentTitle} from "../../hooks/useDocumentTitle";

export function AuthForm(props) {
  const navigate = useNavigate();

  const lang = props.lang;
  const phrases = props.phrases;

  useDocumentTitle('Авторизация');

  useEffect(() => {
    if (props.autorizationStage === 'login') {
      navigate('/search');
    } else {
      props.onNoAccess();
    }
  });

  function checkLogin(e) {
    props.setLoginAccess(false);
    const val = e.target.value;
    const dog_position = val.indexOf('@');
    if (dog_position < 1 || dog_position === val.length - 1) {
      props.setWrongLogin('absent_at');
    } else if (val.length > 512) {
      props.setWrongLogin('too_long');
    } else {
      props.setRightLogin();
    }
  }

  let loginBorderStyle = '';
  let loginImageStyle = '';
  let loginErrorCaption = '';
  if (props.loginError !== '') {
    loginBorderStyle = ' auth-form__input_wrong';
    loginImageStyle = ' auth-form__input-img_alert';
    loginErrorCaption = props.phrases.loginError[props.loginError][lang];
  } else if (props.loginRight) {
    loginBorderStyle = ' auth-form__input_right';
    loginImageStyle = ' auth-form__input-img_success';
  }

  let passwordBorderStyle = '';
  let passwordImageStyle = '';
  const passwordErrorCaption = phrases.passwordError[props.passError][lang];
  if (passwordErrorCaption !== '') {
    loginBorderStyle = ' auth-form__input_wrong';
    loginImageStyle = ' auth-form__input-img_alert';
    passwordBorderStyle = ' auth-form__input_wrong';
    passwordImageStyle = ' auth-form__input-img_alert';
  }

  const visableType = props.passwordVisable ? 'text' : 'password';

  if (props.autorizationStage === 'init') {
    return <div className="auth-page"></div>;
  }

  if (!props.access) {
    return (
      <div className="auth-page">
        <VisibleHeader />
        <div className="auth-form-wrapper">
          <div className="auth-form-wrapper__header-background auth-form-wrapper__header-background_border-bottom"></div>
          <div className="lock__wrapper">
            <div className="lock__circle-1">
              <div className="lock__circle-2">
                <div className="lock__img"></div>
              </div>
            </div>
          </div>
          <div className="error-code-caption">
            {props.authErrorCode + ' ошибка'}
          </div>
          {/* <div className="error-caption">{phrases.passwordError[props.passError][lang]}</div> */}
          <div className="error-caption">Недостаточно прав</div>
          <div className="reason-caption">для входа в систему</div>
          <div className="recomend-caption-1">
            Обратитесь к системному администратору
          </div>
          <div className="recomend-caption-2">
            или владельцу продукта для получения доступа
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-page">
      <VisibleHeader />
      <div className="auth-form-wrapper">
        <div className="auth-form-wrapper__header-background"></div>
        <div className="auth-form-wrapper__header">
          {phrases.formHeader[lang]}
        </div>

        <form
          className={'auth-form'}
          onSubmit={(e) => {
            e.preventDefault();
            if (props.loginRight && props.password.length > 0) {
              props.onLogin();
            }
          }}
        >
          <div className="auth-form__field-wrapper">
            <div className="auth-form__field-name">
              {phrases.loginName[lang]}{' '}
              <span className="auth-form__star">*</span>
            </div>
            <input
              className={'auth-form__input' + loginBorderStyle}
              readOnly={!props.loginAccess}
              placeholder={phrases.loginPlaceholder[lang]}
              value={props.login}
              onChange={(e) => props.onChangeLogin(e.target.value)}
              onBlur={(e) => checkLogin(e)}
              onFocus={() => props.setLoginAccess(true)}
            />
            <div className={'auth-form__input-img' + loginImageStyle}></div>
            <div className="auth-form__error">{loginErrorCaption}</div>
          </div>

          <div className="auth-form__field-wrapper">
            <div className="auth-form__field-name">
              {phrases.passwordName[lang]}{' '}
              <span className="auth-form__star">*</span>
            </div>
            <input
              className={'auth-form__input' + passwordBorderStyle}
              placeholder={phrases.passwordPlaceholder[lang]}
              value={props.password}
              type={visableType}
              onChange={(e) => props.onChangePassword(e.target.value)}
            />
            <div
              className={
                'auth-form__input-img auth-form__input-img_left' +
                passwordImageStyle
              }
            ></div>
            <div
              className={
                'auth-form__input-img' +
                (visableType === 'password'
                  ? ' auth-form__input-img_eye'
                  : ' auth-form__input-img_eye-crossed')
              }
              onClick={() => {
                props.switchPasswordVisable();
              }}
            ></div>
            <div className="auth-form__error">{passwordErrorCaption}</div>
          </div>

          <div className="auth-form__button-wrapper">
            <button
              className={
                'auth-form__button' +
                (props.loginRight && props.password.length > 0
                  ? ''
                  : ' auth-form__button_disabled')
              }
              type="submit"
            >
              {phrases.buttonCaption[lang]}
            </button>
          </div>
        </form>
        <button
          className="auth-form-wrapper__cancel-button"
          onClick={() => props.onCancel()}
        >
          {phrases.cancelButtonCaption[lang]}
        </button>
        <div className="auth-form-wrapper__footer">
          <span className="auth-form-wrapper__caption">
            {phrases.footerCaption_1[lang]}
          </span>
          <Link to={'../userAgreement'}>
            <span className="auth-form-wrapper__caption auth-form-wrapper__caption_underline">
              {phrases.footerCaption_2[lang]}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
