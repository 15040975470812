import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import VerticalLine from "../../components/veritcalLine";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import ChipsField from "../../pureComponents/chipsField";
import MultiSelectBlock from "../../pureComponents/multiSelectBlock";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import { Waiter } from "../../components/waiter";
import { setConditionSpecs } from "../../redux/reducers/conditionSpecs";
import { addConditionOption } from "../../redux/reducers/modelComplectMenu";
import { getSpecList } from "../../redux/actions/async/specList";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import {
  deleteConditionSpecsFromServer,
  getConditionSpecs,
  putConditionSpecs,
} from "../../redux/actions/async/conditionSpecs";
import generateModelComplectLink from "../../utils/generateModelComplectLink";
import "./index.scss";

function handleConditionSpecs(conditionSpecs, item) {
  const spec = conditionSpecs.find((spec) => spec.spec === item.code);

  if (!spec) {
    return [
      ...conditionSpecs,
      {
        id: nanoid(3),
        spec: item.code,
        name: item.name,
        actionType: "C",
      },
    ];
  }

  if (spec.actionType === "C") {
    return conditionSpecs.filter((spec) => spec.spec !== item.code);
  }

  // specs from server
  return conditionSpecs.map((spec) => {
    if (spec.spec !== item.code) return spec;

    return {
      ...spec,
      actionType: !spec.actionType ? "D" : null,
    };
  });
}

function deleteAllConditionSpecs(conditionSpecs) {
  return conditionSpecs
    .filter((spec) => spec.actionType !== "C")
    .map((spec) => ({ ...spec, actionType: "D" }));
}

const ConditionSpecPage = () => {
  const navigate = useNavigate();
  const { modelComplectCode, conditionCode } = useParams();
  const { state } = useLocation();
  const componentCode = state.componentCode;

  const searchStage = useSelector(state => state.search.stage);
  const isBackToListLinkType = searchStage !== "product"
  const backToProductLink = `/search?code=${modelComplectCode}`

  const dispatch = useDispatch();

  const menuIsLoaded = useSelector(
    (state) => state.modelComplectMenu.modelComplectCode !== ""
  );

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const [specList, isSpecListLoading] = useSelector(
    (state) => [state.specList.list, state.specList.isLoading],
    shallowEqual
  );

  const [conditionSpecs, fetchingStatus] = useSelector(
    (state) => [state.conditionSpecs.data, state.conditionSpecs.fetchingStatus],
    shallowEqual
  );
  const isDeleteButtonDisabled = conditionSpecs.every(
    (item) => item.actionType === "C"
  );

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const filteredConditionSpecs = conditionSpecs.filter(
    (spec) => spec.actionType !== "D"
  );
  const filteredSpecList = specList.map((spec) => {
    return {
      ...spec,
      checked: filteredConditionSpecs.some((item) => item.spec === spec.code),
    };
  });

  useEffect(() => {
    dispatch(getSpecList());
  }, []);

  useEffect(() => {
    if (menuIsLoaded) {
      dispatch(
        addConditionOption({
          componentCode,
          conditionCode,
          option: "spec",
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    if (!conditionCode) return;

    dispatch(getConditionSpecs(conditionCode));
  }, [conditionCode]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitSpecs));
  }, [JSON.stringify(conditionSpecs)]);

  function onSearch(value) {
    dispatch(getSpecList(value));
  }

  function onSelectSpec(item) {
    const newConditionSpecs = handleConditionSpecs(conditionSpecs, item);
    dispatch(setConditionSpecs(newConditionSpecs));

    if (formErrors.conditionSpecs) {
      setErrors({
        conditionSpecs: null,
      });
    }

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function onSelectAllSpecs() {
    const allChecked =
      filteredSpecList.length === filteredConditionSpecs.length;

    if (allChecked) {
      clearAllConditionSpecs();
      return;
    }

    const nonCheckedSpecList = filteredSpecList.filter((item) => !item.checked);
    let newConditionSpecs = conditionSpecs;

    nonCheckedSpecList.forEach((item) => {
      newConditionSpecs = handleConditionSpecs(newConditionSpecs, item);
    });

    dispatch(setConditionSpecs(newConditionSpecs));

    if (formErrors.conditionSpecs) {
      setErrors({
        conditionSpecs: null,
      });
    }

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function deleteConditionSpec(id) {
    const conditionSpec = conditionSpecs.find((spec) => spec.id === id);

    if (conditionSpec.actionType === "C") {
      dispatch(
        setConditionSpecs(conditionSpecs.filter((spec) => spec.id !== id))
      );
      return;
    }

    dispatch(
      setConditionSpecs(
        conditionSpecs.map((spec) => {
          if (spec.id !== id) return spec;

          return {
            ...spec,
            actionType: "D",
          };
        })
      )
    );

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function clearAllConditionSpecs() {
    const newConditionSpecs = deleteAllConditionSpecs(conditionSpecs);
    dispatch(setConditionSpecs(newConditionSpecs));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const handleDeleteConditionSpecs = () => {
    setDeleteModalActive(true);
  };

  const deleteConditionSpecs = () => {
    dispatch(
      deleteConditionSpecsFromServer(conditionCode, () => {
        navigate(
          generateModelComplectLink(modelComplectCode, {
            type: "condition",
            code: conditionCode,
          }),
          {
            state: {
              actionType: "edit",
            },
          }
        );
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitSpecs() {
    const isFormValid = filteredConditionSpecs.length;

    if (isFormValid) {
      return dispatch(putConditionSpecs());
    } else {
      setErrors({
        conditionSpecs: "Поле не заполнено",
      });
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    submitSpecs();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="condition-specs__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="condition-specs__header">
            <div className="main-header-block__item main-header-block__title">
              Добавление спецификаций
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteConditionSpecs}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              disabled={isDeleteButtonDisabled}
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__specs">
              <div className="chips-field-block">
                <ChipsField
                  id="conditionSpecs"
                  label="Спецификации"
                  items={filteredConditionSpecs}
                  clearField={clearAllConditionSpecs}
                  deleteChipsItem={deleteConditionSpec}
                  error={formErrors.conditionSpecs}
                  required
                />
              </div>
              <div className="multiselect-block">
                <MultiSelectBlock
                  items={filteredSpecList}
                  isLoading={isSpecListLoading}
                  onSearch={onSearch}
                  onItemSelect={onSelectSpec}
                  onSelectAll={onSelectAllSpecs}
                />
              </div>
            </div>
          </div>
          <div className="condition-specs__footer">
            {isBackToListLinkType ?
            <ModalLinkWrapper
              linkProps={{ to: "/guides", state: { pageCode: 28, withoutReset: true } }}
              className="button button__secondary button__secondary__ghost"
              >
              Вернуться к списку ТС
            </ModalLinkWrapper>
            : 
            <ModalLinkWrapper
              linkProps={{ to: backToProductLink}}
              className="button button__secondary button__secondary__ghost"
            >
              Вернуться к ТС
            </ModalLinkWrapper>}
            
            <button
              type="submit"
              className="button button__primary"
              disabled={!dataChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteConditionSpecs}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"condition_spec"}
        rowCode={conditionCode}
        listType={"hist"}
      />
    </>
  );
};

export default ConditionSpecPage;
