const initialState = {
  selectedAsType: [],
  selectedAsMark: [],
  selectedAsModel: [],
  selectedAsFuelType: [],
  enteredAsStartYear: '',
  enteredAsEndYear: '',
  enteredAsEngineCapFrom: '',
  enteredAsEngineCapTo: '',
  enteredAsPowerHpFrom: '',
  enteredAsPowerHpTo: '',
  enteredAsCntFrom: '5',
  enteredAsCntTo: '',
  fetchingStatus: ''
};
const RESET_AUTOSTAT_MODEL_REPORT_FORM_STATE = "RESET_AUTOSTAT_MODEL_REPORT_FORM_STATE";
const UPDATE_AUTOSTAT_MODEL_AS_TYPE = "UPDATE_AUTOSTAT_MODEL_AS_TYPE";
const UPDATE_AUTOSTAT_MODEL_AS_MARK = "UPDATE_AUTOSTAT_MODEL_AS_MARK";
const UPDATE_AUTOSTAT_MODEL_REPORT_FORM = "UPDATE_AUTOSTAT_MODEL_REPORT_FORM"

function autostatModelReportForm(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_AUTOSTAT_MODEL_AS_TYPE:
      return {
        ...state,
        selectedAsType: payload ? [...state.selectedAsType, payload] : []    // массив равено новому значению
      };
    case UPDATE_AUTOSTAT_MODEL_AS_MARK:
      return {
        ...state,
        selectedAsMark: payload ? [payload] : []    // массив равено новому значению
      };

    case UPDATE_AUTOSTAT_MODEL_REPORT_FORM:
      return {
        ...state,
        ...payload
      };
    case RESET_AUTOSTAT_MODEL_REPORT_FORM_STATE:            // Reload page
      return initialState;
    default:
      return state;
  }
}
export const updateAutostatModelAsType = () => ({
  type: UPDATE_AUTOSTAT_MODEL_AS_TYPE
});
export const updateAutostatModelAsMark = () => ({
  type: UPDATE_AUTOSTAT_MODEL_AS_MARK
});
export const updateAutostatModelReportForm = (payload) => ({
  type: UPDATE_AUTOSTAT_MODEL_REPORT_FORM,
  payload
});

export const resetAutostatModelReportFormState = () => ({
  type: RESET_AUTOSTAT_MODEL_REPORT_FORM_STATE
});

export default autostatModelReportForm;
