import {
  SET_TEXT_EDITOR_DATA,
  SET_TEXT_EDITOR_INPUT_HEADER,
  SET_TEXT_EDITOR_MODE,
  UPDATE_TEXT_EDITOR_OBJ_VALUE,
  UPDATE_TEXT_EDITOR_VALUE,
} from '../actions/sync/textEditor';

const defState = {
  mode: 'code',
  active: false,
  value: '',
  data: null,
  objVal: { code: '', name: '' },
  inputHeader: '',
};

function textEditor(state = defState, action) {
  switch (action.type) {
    case SET_TEXT_EDITOR_MODE:
      return Object.assign({}, state, {
        mode: action.mode,
        active: action.active,
      });
    case UPDATE_TEXT_EDITOR_VALUE:
      return Object.assign({}, state, { value: action.value, objVal: { code: '', name: '' } });
    case UPDATE_TEXT_EDITOR_OBJ_VALUE:
      return Object.assign({}, state, { objVal: action.value, value: '' });
    case SET_TEXT_EDITOR_DATA:
      return Object.assign({}, state, { data: action.data });
    case SET_TEXT_EDITOR_INPUT_HEADER:
      return Object.assign({}, state, { inputHeader: action.header });
    default:
      return state;
  }
}

export default textEditor;
