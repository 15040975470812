import React from "react";
import { createPortal } from "react-dom";
import './index.scss'

const Modal = ({ active, closeModal, children, width }) => {
  return createPortal(
    <div
      className={`modal-backdrop${active ? " active" : ""}`}
      onClick={closeModal}
    >
      <div className="modal-container" style={{width}}onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    document.getElementById("modal-container")
  );
};

export default Modal;
