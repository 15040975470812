import React from 'react';
import {useSelector} from "react-redux";
import {Waiter} from "../../components/waiter";
import FormInputField from "../../pureComponents/formInputField";
import DropdownMultiSelect from "../../pureComponents/dropdownMultiSelect";
import YearPicker from '../../pureComponents/yearPicker';

const AutostatModelReportForm = ({
        formErrors,
        onDelete,
        onSearch,
        onSelect,
        onClearField,
        onSelectAll,
        processedList,
        handleInputChange,
        handleDateChange,
        handleButtonClick,
        onSubmit
    }) => {
    const formData = useSelector((state) => state.autostatModelReportForm );
    const {asTypeList, asMarkList, asModelList, asFuelTypeList, asCntFrom, asCntTo } = useSelector((state) => state.autostatModelReportFormSelectors);

    if (formData.fetchingStatus === 'loading') {
        return <Waiter size="large" />;
    } else {
        return (
            <form onSubmit={onSubmit}>
                <fieldset
                    className="model-complect-general__form"
                    disabled={false}
                >
                    <div className="model-complect-general__header">
                        <div className="main-header-block">
                            <div className="main-header-block__item main-header-block__title">
                                Автостат - Недостающие модели (Парк РФ)
                            </div>
                        </div>
                    </div>
                    <div className="model-complect-general__form">
                        <div className="main-form-block">
                            <div className="main-form-block__column">
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedAsType"
                                        label="Тип ТС"
                                        items={asTypeList}
                                        selectedItems={formData.selectedAsType}
                                        error={formErrors.selectedAsType}
                                        onDelete={onDelete}
                                        onSelect={onSelect}
                                        onClearField={onClearField}
                                        required={true}
                                    />
                                </div>
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedAsMark"
                                        label="Марка"
                                        items={asMarkList}
                                        selectedItems={formData.selectedAsMark}
                                        onDelete={onDelete}
                                        onSearch={onSearch}
                                        onSelect={onSelect}
                                        onClearField={onClearField}
                                        onSelectAll={onSelectAll}
                                        isLoading={processedList.selectedAsMark}
                                        isSearchable={true}
                                        isSelectAllAvailable={true}
                                        required={false}
                                    />
                                    <DropdownMultiSelect
                                        id="selectedAsModel"
                                        label="Модель"
                                        items={asModelList}
                                        selectedItems={formData.selectedAsModel}
                                        onDelete={onDelete}
                                        onSearch={onSearch}
                                        onSelect={onSelect}
                                        onClearField={onClearField}
                                        onSelectAll={onSelectAll}
                                        isLoading={processedList.selectedAsModel}
                                        isSearchable={true}
                                        isSelectAllAvailable={true}
                                        required={false}
                                    />
                                </div>
                                <hr className="horizontal-line" />
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedAsFuelType"
                                        label="Тип топлива"
                                        items={asFuelTypeList}
                                        selectedItems={formData.selectedAsFuelType}
                                        onDelete={onDelete}
                                        onSelect={onSelect}
                                        onClearField={onClearField}
                                        onSelectAll={onSelectAll}
                                        isSelectAllAvailable={true}
                                        required={false}
                                    />
                                </div>
                                <div className="main-form-block__row">
                                    <div className="main-form-block__end">
                                        <FormInputField
                                            id="enteredAsEngineCapFrom"
                                            label="Объем двигателя (см3)"
                                            placeholder=""
                                            value={formData.enteredAsEngineCapFrom}
                                            error={formErrors.enteredAsEngineCapFrom}
                                            onChange={handleInputChange}
                                            required={false}
                                            inputMask="positive-integer"
                                        />
                                        <FormInputField
                                            id="enteredAsEngineCapTo"
                                            label=""
                                            placeholder=""
                                            value={formData.enteredAsEngineCapTo}
                                            error={formErrors.enteredAsEngineCapTo}
                                            onChange={handleInputChange}
                                            required={false}
                                            inputMask="positive-integer"
                                        />
                                    </div>
                                    <div className="main-form-block__end">
                                        <FormInputField
                                            id="enteredAsPowerHpFrom"
                                            label="Мощность двигателя (л.с.)"
                                            placeholder=""
                                            value={formData.enteredAsPowerHpFrom}
                                            error={formErrors.enteredAsPowerHpFrom}
                                            onChange={handleInputChange}
                                            required={false}
                                            inputMask="positive-integer"
                                        />

                                        <FormInputField
                                            id="enteredAsPowerHpTo"
                                            label=""
                                            placeholder=""
                                            value={formData.enteredAsPowerHpTo}
                                            error={formErrors.enteredAsPowerHpTo}
                                            onChange={handleInputChange}
                                            required={false}
                                            inputMask="positive-integer"
                                        />
                                    </div>
                                </div>
                                <hr className="horizontal-line" />
                                <div className="main-form-block__row">
                                    <div className="main-form-block__end">
                                        <YearPicker
                                            id="enteredAsStartYear"
                                            label="Год выпуска"
                                            value={formData.enteredAsStartYear}
                                            onChangeData={handleDateChange}
                                            maxDate={formData.enteredAsEndYear}
                                        />
                                        <YearPicker
                                            id="enteredAsEndYear"
                                            value={formData.enteredAsEndYear}
                                            onChangeData={handleDateChange}
                                            minDate={formData.enteredAsStartYear}
                                        />
                                    </div>
                                    <div className="main-form-block__end">
                                        <FormInputField
                                            id="enteredAsCntFrom"
                                            label="Количество (шт.)"
                                            placeholder=""
                                            value={formData.enteredAsCntFrom}
                                            error={formErrors.enteredAsCntFrom}
                                            onChange={handleInputChange}
                                            required={true}
                                            inputMask="positive-integer"
                                        />
                                        <FormInputField
                                            id="enteredAsCntTo"
                                            label=""
                                            placeholder=""
                                            value={formData.enteredAsCntTo}
                                            error={formErrors.enteredAsCntTo}
                                            onChange={handleInputChange}
                                            required={false}
                                            inputMask="positive-integer"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="main-form-block__column"></div>
                        </div>
                    </div>

                    <div className="model-complect-general__footer">
                        <button type="button"
                                className="button button__secondary button__secondary__ghost"
                                onClick={handleButtonClick}
                        >
                            Сбросить настройки
                        </button>
                        <button type="submit" className="button button__primary">
                            Выгрузить отчет
                        </button>
                    </div>
                </fieldset>
            </form>
        );
    }
};

export default AutostatModelReportForm;
