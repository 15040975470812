const initialState = {
  list: [],
};

function segmentList(state = initialState, action) {
  switch (action.type) {
    case SET_SEGMENT_LIST:
      return { ...state, list: action.payload };
    default:
      return state;
  }
}

const SET_SEGMENT_LIST = "SET_SEGMENT_LIST";

export const setSegmentList = (payload) => ({
  type: SET_SEGMENT_LIST,
  payload,
});

export default segmentList;