import {fetchFromApi} from '../service';
import {
  clearSearchResult,
  reciveSearchByStringResult,
  reciveSearchByFilterResult,
  addSearchByFilterResult,
  setSearchMode,
  reciveTableColumns,
  shiftSearchOffset,
  resetSearchOffset,
  waitForSearchData,
  setSearchStage,
} from '../sync/search';
import { requestCategories } from './category';

export function requestSearchResult( fields, appendResult = false ) {
  return (dispatch, getState) => {
    if (appendResult) {
      dispatch(shiftSearchOffset());
    } else {
      dispatch(resetSearchOffset());
    }
    const state = getState();
    const token = localStorage.getItem(`access_token`);
    dispatch(waitForSearchData(true));
    const body = {
      lang: state.i18.current,
      offset: state.search.offset,
      limit: state.search.limit,
      searchStrModel: state.search.type === 'technic' && state.search.mode === 'by_string' ? state.search.string : null,
      searchStrComp:  state.search.type === 'module' && state.search.mode === 'by_string' ? state.search.string : null,
      modelCategoryCode: fields.categoryCode,
      modelSubcategoryCode: fields.subcategoryCode,
      year: fields.year,
      modelMarkCode: fields.modelMarkCode,
      modelGenerationCode: fields.modelCode,
      fuelTypeCode: fields.fuelTypeCode,
      engineCap: fields.engineCapCode,
      modelClassCode: fields.modelClassCode
    };

    fetchFromApi('api/v1/selection/model-complect/filter/page',"POST",{token, body})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('wrong search');
      })
      .then((json) => {
        if (appendResult) {
          dispatch(addSearchByFilterResult(json));
        } else {
          dispatch(reciveSearchByStringResult(json));
        }
      })
      .then(() => {
        dispatch(requestTableColumn('model_complect_v', 'ref_list'));
      })
      .catch((e) => {
        console.error('error ' + e);
      })
  }
}

// TODO Refactoring
export function requestSearchByFilterResult() {
  return (dispatch, getState) => {
    dispatch(waitForSearchData(true));
    dispatch(resetSearchOffset());

    const state = getState();
    const body = {
      lang: state.i18.current,
      offset: state.search.offset,
      limit: state.search.limit,
    };

    if (state.search.mode === '') {
      dispatch(setSearchMode('by_filter'));
    }

    if (state.category.current !== 'def') {
      body.modelCategoryCode =
        state.category.current === '' ? null : state.category.current;
    }
    if (state.subcategory.current !== 'def') {
      body.modelSubcategoryCode =
        state.subcategory.current === '' ? null : state.subcategory.current;
    }
    if (state.modelMark.current !== 'def') {
      body.modelMarkCode =
        state.modelMark.current === '' ? null : state.modelMark.current;
    }
    if (state.modelGeneration.current !== 'def') {
      body.modelGenerationCode =
        state.modelGeneration.current === ''
          ? null
          : state.modelGeneration.current;
    }
    if (state.years.current !== 'def') {
      body.year = state.years.current === '' ? null : state.years.current;
    }
    if (state.fuelType.current !== 'def') {
      body.fuelTypeCode =
        state.fuelType.current === '' ? null : state.fuelType.current;
    }
    if (state.engineCap.current !== 'def') {
      body.engineCap = parseInt(state.engineCap.current);
    }
    if (state.modelClass.current !== 'def') {
      body.modelClassCode =
        state.modelClass.current === '' ? null : state.modelClass.current;
    }
    if (state.search.string !== '') {
      if (state.search.type === 'technic') {
        body.searchStrModel = state.search.string;
      } else {
        body.searchStrComp = state.search.string;
      }
    }

    return fetchFromApi("api/v1/selection/model-complect/filter/page","POST",{body})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('wrong search');
      })
      .then((json) => {
        dispatch(reciveSearchByFilterResult(json));
      })
      .then(() => {
        dispatch(requestTableColumn('model_complect_v', 'ref_list'));
      })
      .catch((e) => {
        console.log('error ' + e);
      })
  };
}

export function requestTableColumn(name, listType) {
  return (dispatch, getState) => {

    const state = getState();
    const body = {
      lang: state.i18.current,
      tableName: name, listType
    };

    return fetchFromApi("api/v1/column/list/","POST",{body})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('wrong table columns');
      })
      .then((json) => {
        dispatch(reciveTableColumns(json));
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(waitForSearchData(false));
      });
  };
}


export function resetSearch() {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(clearSearchResult());
      res();
    })
      .then(() => {
        dispatch(setSearchStage('home'));
      })
      .then(() => {
        dispatch(requestCategories());
      });
  };
}