const initialState = {
  list: [],
  isLoading: false,
};

function specList(state = initialState, action) {
  switch (action.type) {
    case SET_SPEC_LIST:
      return { ...state, list: action.payload };
    case SET_SPEC_LIST_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

const SET_SPEC_LIST = "SET_SPEC_LIST";
const SET_SPEC_LIST_LOADING = "SET_SPEC_LIST_LOADING";

export const setSpecList = (payload) => ({
  type: SET_SPEC_LIST,
  payload,
});

export const setSpecListLoading = (payload) => ({
  type: SET_SPEC_LIST_LOADING,
  payload,
});

export default specList;
