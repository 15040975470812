import React from "react";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import RadioButton from "../../pureComponents/radioButton";

import "./index.scss";
import FormInputField from "../../pureComponents/formInputField";

const ProductItem = ({
  list,
  id,
  value,
  name,
  label,
  onListSelect,
  onListSearch,
  isLoading = false,
  onExcludedChange,
  excluded,
  error,
  onDeleteItem,
  isNewItem,
  searchable = false,
}) => {
  return (
    <>
      <div className="product-item-dropdown">
        {isNewItem ? (
          <DropdownSelect
            id={id}
            label={label}
            defaultValue={getNameFromList(list, value) || name}
            items={list}
            onSelect={onListSelect}
            searchable={searchable}
            isLoading={isLoading}
            onSearch={onListSearch}
            error={error}
            required
          />
        ) : (
          <FormInputField id={id} label={label} value={name} disabled required/>
        )}
      </div>
      <RadioButton
        code={`included-${id}`}
        name="Включить в список"
        value={excluded}
        checked={!excluded}
        onChange={onExcludedChange}
      />
      <RadioButton
        code={`excluded-${id}`}
        name="Исключить из списка"
        value={excluded}
        checked={excluded}
        onChange={onExcludedChange}
      />
      <button
        type="button"
        className="button button__negative button__negative__outline"
        onClick={() => onDeleteItem(id)}
      >
        Удалить элемент
      </button>
    </>
  );
};

export default ProductItem;
