import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import VerticalLine from "../../components/veritcalLine";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import MultiSelectBlock from "../../pureComponents/multiSelectBlock";
import ChipsField from "../../pureComponents/chipsField";
import { addNewProductOption } from "../../redux/reducers/productMenu";
import { getViscoList } from "../../redux/actions/async/viscoSelectors";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import { setProductViscos } from "../../redux/reducers/productViscos";
import {
  deleteProductViscosFromServer,
  getProductViscos,
  putProductViscos,
} from "../../redux/actions/async/productViscos";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Waiter } from "../../components/waiter";

function handleProductViscos(productViscos, item) {
  const visco = productViscos.find((visco) => visco.code === item.code);

  if (!visco) {
    return [
      ...productViscos,
      {
        id: nanoid(3),
        code: item.code,
        name: item.name,
        actionType: "C",
      },
    ];
  }

  if (visco.actionType === "C") {
    return productViscos.filter((visco) => visco.code !== item.code);
  }

  // viscos from server
  return productViscos.map((visco) => {
    if (visco.code !== item.code) return visco;

    return {
      ...visco,
      actionType: !visco.actionType ? "D" : null,
    };
  });
}

function deleteAllProductViscos(productViscos) {
  return productViscos
    .filter((visco) => visco.actionType !== "C")
    .map((visco) => ({ ...visco, actionType: "D" }));
}

const ProductViscosPage = () => {
  const navigate = useNavigate();
  const { productCode } = useParams();

  const dispatch = useDispatch();

  const productMenu = useSelector((state) => state.productMenu);
  const menuIsLoaded = useSelector(
    (state) => state.productMenu.productCode !== ""
  );

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const viscoList = useSelector(
    (state) => state.viscoSelectors.viscoList,
    shallowEqual
  );

  const [productViscos, fetchingStatus] = useSelector(
    (state) => [state.productViscos.data, state.productViscos.fetchingStatus],
    shallowEqual
  );

  const isDeleteButtonDisabled = productViscos.every(
    (item) => item.actionType === "C"
  );

  const [isViscoListLoading, setViscoListLoading] = useState(false);
  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const filteredProductViscos = productViscos.filter(
    (visco) => visco.actionType !== "D"
  );
  const filteredViscoList = viscoList.map((visco) => {
    return {
      ...visco,
      checked: filteredProductViscos.some((item) => item.code === visco.code),
    };
  });

  useEffect(() => {
    dispatch(getViscoList(null, null));
  }, []);

  useEffect(() => {
    if (menuIsLoaded) {
      const option = "visco";
      const optionName = "Вязкости";

      if (productMenu.children.some((item) => item.code === option)) return;

      dispatch(
        addNewProductOption({
          option,
          optionName,
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    if (!productCode) return;

    dispatch(getProductViscos(productCode));
  }, [productCode]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitViscos));
  }, [JSON.stringify(productViscos)]);

  function onSearch(value) {
    setViscoListLoading(true);
    dispatch(getViscoList(null, null, value, () => setViscoListLoading(false)));
  }

  function onSelectVisco(item) {
    const newProductViscos = handleProductViscos(productViscos, item);
    dispatch(setProductViscos(newProductViscos));

    if (formErrors.productViscos) {
      setErrors({
        productViscos: null,
      });
    }

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function onSelectAllViscos() {
    const allChecked =
      filteredViscoList.length === filteredProductViscos.length;

    if (allChecked) {
      clearAllProductViscos();
      return;
    }

    const nonCheckedViscoList = filteredViscoList.filter(
      (item) => !item.checked
    );
    let newProductViscos = productViscos;

    nonCheckedViscoList.forEach((item) => {
      newProductViscos = handleProductViscos(newProductViscos, item);
    });

    dispatch(setProductViscos(newProductViscos));

    if (formErrors.productViscos) {
      setErrors({
        productViscos: null,
      });
    }

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function deleteProductVisco(id) {
    const productVisco = productViscos.find((visco) => visco.id === id);

    if (productVisco.actionType === "C") {
      dispatch(
        setProductViscos(productViscos.filter((visco) => visco.id !== id))
      );
      return;
    }

    dispatch(
      setProductViscos(
        productViscos.map((visco) => {
          if (visco.id !== id) return visco;

          return {
            ...visco,
            actionType: "D",
          };
        })
      )
    );

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function clearAllProductViscos() {
    const newProductViscos = deleteAllProductViscos(productViscos);
    dispatch(setProductViscos(newProductViscos));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const handleDeleteProductViscos = () => {
    setDeleteModalActive(true);
  };

  const deleteProductViscos = () => {
    dispatch(
      deleteProductViscosFromServer(productCode, () => {
        navigate(`/product/${productCode}`, {
          state: {
            actionType: "edit",
          },
        });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  function submitViscos() {
    const isFormValid = filteredProductViscos.length;

    if (isFormValid) {
      dispatch(putProductViscos());
    } else {
      setErrors({
        productViscos: "Поле не заполнено",
      });
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    submitViscos();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="content-general__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="content-general__header">
            <div className="main-header-block__item main-header-block__title">
              Добавление вязкостей
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteProductViscos}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__specs">
              <div className="chips-field-block">
                <ChipsField
                  id="productViscos"
                  label="Вязкость"
                  items={filteredProductViscos}
                  clearField={clearAllProductViscos}
                  deleteChipsItem={deleteProductVisco}
                  error={formErrors.productViscos}
                  required
                />
              </div>
              <div className="multiselect-block">
                <MultiSelectBlock
                  items={filteredViscoList}
                  isLoading={isViscoListLoading}
                  onSearch={onSearch}
                  onItemSelect={onSelectVisco}
                  onSelectAll={onSelectAllViscos}
                />
              </div>
            </div>
          </div>
          <div className="content-general__footer">
            <div className="main-footer-block__buttons">
              <ModalLinkWrapper
                linkProps={{ to: "/products", state: { pageCode: 40, withoutReset: true} }}
                className="button button__secondary button__secondary__ghost"
              >
                Вернуться к списку продуктов
              </ModalLinkWrapper>
              <button type="submit" className="button button__primary" disabled={!dataChanged}>
                Сохранить изменения
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteProductViscos}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"product_visco"}
        rowCode={productCode}
        listType={"hist"}
      />
    </>
  );
};

export default ProductViscosPage;
