import { RECEIVE_CONDITION_TYPE } from "../actions/sync/conditionType";

const initialState = {
  current: "",
  list: [],
};

function conditionType(state = initialState, action) {
  switch (action.type) {
    case SET_CONDITION_TYPE_LIST:
      return { ...state, list: action.payload };
    case RECEIVE_CONDITION_TYPE:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}

const SET_CONDITION_TYPE_LIST = "SET_CONDITION_TYPE_LIST";
export const setConditionTypeList = (payload) => ({
  type: SET_CONDITION_TYPE_LIST,
  payload,
});

export default conditionType;