import { useRef, useEffect } from "react";

//TODO: impl i18n support
export const useDocumentTitle = (title = '', prevailOnUnmount = false) => {
    // const defaultTitle = useRef(document.title ?? 'OilMap');
    const defaultTitle = useRef('OilMap');

    useEffect(() => {
        // document.title = phrases.formHeader[lang]
        document.title = `${title ? title + ' - ' : ''}OilMap`;
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }
    }, []);
}