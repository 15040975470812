import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import { setActiveProductMenuItem } from "../../reducers/productMenu";
import {
  setProductSegments,
  setProductSegmentsFetchingStatus,
} from "../../reducers/productSegments";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import { initNotificationMessage, showNotificationMessage } from "../sync/notificationMessage";
import { getProductMenu } from "./productMenu";

export function getProductSegments(productCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/product-segment";

    try {
      dispatch(setProductSegmentsFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${productCode}/locale/${lang}`,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(
        setProductSegments(
          data.map((segment) => ({
            ...segment,
            code: segment.segmentCode,
            name: segment.segmentName,
          }))
        )
      );
      dispatch(setProductSegmentsFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setProductSegmentsFetchingStatus());
    }
  };
}

export function putProductSegments() {
  return async (dispatch, getState) => {
    const state = getState();
    const productSegments = state.productSegments.data;
    const productCode = state.productMenu.productCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setProductSegmentsFetchingStatus("update"));

      const body = {
        data: productSegments
          .filter((item) => item.actionType)
          .map((item) => ({
            productCode,
            segmentCode: item.code,
            id: item.actionType === "C" ? null : item.id,
            excluded: item.excluded,
            actionType: item.actionType
          })),
      };

      const response = await fetchFromApi("api/v1/product-segment", "POST", {
        body
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getProductMenu(productCode));
      dispatch(setActiveProductMenuItem(pathname));
      dispatch(resetCurrentPageFormState());
      dispatch(getProductSegments(productCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductSegmentsFetchingStatus());

      return false;
    }
  };
}

export function deleteProductSegmentsFromServer(productCode, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const body = {
      code: productCode,
      data: state.productSegments.data.map( item => ({segment: item.code}))
    };

    try {
      dispatch(setProductSegmentsFetchingStatus("loading"));
      const response = await fetchFromApi(
        "api/v1/product-segment/all",
        "DELETE",
        {
          body
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getProductMenu(productCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductSegmentsFetchingStatus());
    }
  };
}
