import { updateYear, validateYear } from '../sync/years';
import { requestSearchByFilterResult } from './search';
import {
  initNotificationMessage,
  showNotificationMessage,
} from '../../actions/sync/notificationMessage';

export function selectYear(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateYear(id));
      res();
    }).then(() => {
      dispatch(requestSearchByFilterResult());
    });
  };
}

export function blurYear(value) {
  return (dispatch, getState) => {
    return new Promise((res) => {
      dispatch(validateYear(value));
      res();
    })
      .then(() => {
        const state = getState();
        if (state.years.errorValue) {
          dispatch(
            initNotificationMessage(
              'Введенное значение не соответствует типу или ограничениям поля ввода',
              'Тип поля, ограничения: Четырехразрядное целое больше 1900',
              'warning'
            )
          );
          return true;
        }
        return false;
      })
      .then((res) => {
        if (res) {
          dispatch(showNotificationMessage());
        }
      });
  };
}
