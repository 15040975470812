const initialState = {
  dateFrom: getDateString( -7 ),
  dateTo: getDateString(),
  selectedCatigories: [],
  selectedSubcatigories: [],
  fetchingStatus: false
};
const RESET_STATISTIC_REQUEST_REPORT_FORM_STATE = "RESET_STATISTIC_REQUEST_REPORT_FORM_STATE";
const UPDATE_STATISTIC_REQUEST_CATEGORIES = "UPDATE_STATISTIC_REQUEST_CATEGORIES";
const UPDATE_STATISTIC_REQUEST_REPORT_FORM = "UPDATE_STATISTIC_REQUEST_REPORT_FORM";

function getDateString( appendDays = 0) {
  let date = new Date();
  date.setDate(date.getDate() + appendDays);
  return date.toLocaleDateString("ru-RU");
}

function statisticRequestReportForm(state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_STATISTIC_REQUEST_CATEGORIES:
      return {
        ...state,
        selectedCatigories: payload ? [payload] : []    // массив равено новому значению
      };
    case UPDATE_STATISTIC_REQUEST_REPORT_FORM:
      return {
        ...state,
        ...payload
      };
    case RESET_STATISTIC_REQUEST_REPORT_FORM_STATE:    // Reload page
      return initialState;
    default:
      return state;
  }
}

export const resetStatisticRequestReportFormState = () => ({
  type: RESET_STATISTIC_REQUEST_REPORT_FORM_STATE
});
export const updateStatisticRequestReportForm = (payload) => ({
  type: UPDATE_STATISTIC_REQUEST_REPORT_FORM,
  payload
});
export const updateStatisticRequestReportCategories = () => ({
  type: UPDATE_STATISTIC_REQUEST_CATEGORIES
});

export default statisticRequestReportForm;
