import React from 'react';
import './index.scss';
import iconClose from '../../theme/svg/close_icon.svg';
import iconInfo from '../../theme/svg/notification_info.svg';
import iconWarning from '../../theme/svg/notification_warning.svg';
import iconSuccess from '../../theme/svg/notification_success.svg';
import iconDanger from '../../theme/svg/notification_danger.svg';

export function NotificationMessage(props) {
  const notification_mod = props.visible ? ' notification__active' : '';
  let block_mod;
  let img_src;
  switch (props.mode) {
    case 'success':
      block_mod = ' notification__block__success';
      img_src = iconSuccess;
      break;
    case 'info':
      block_mod = ' notification__block__info';
      img_src = iconInfo;
      break;
    case 'warning':
      block_mod = ' notification__block__warning';
      img_src = iconWarning;
      break;
    case 'danger':
      block_mod = ' notification__block__danger';
      img_src = iconDanger;
      break;
    default:
      block_mod = ' notification__block__success';
      img_src = iconSuccess;
  }

  // const navigate = useNavigate();

  // function goToLink(){
  //   navigate(props.linkPath);
  //   props.closeMessage();
  // }

  return (
    <div className={'notification' + notification_mod}>
      <div className={'notification__block' + block_mod}>
        <div className="notification__item">
          <div className="notification__icon notification__icon__info">
            <img src={img_src} alt="Уведомление" />
          </div>

          <div className="notification__content">
            <div className="notification__header">
              <div className="notification__header-caption">{props.header}</div>
              {/*<div className="notification__header-link" onClick={goToLink}>{props.link}</div>*/}
            </div>
            <div className="notification__message">{props.message}</div>
          </div>

          <div className="notification__close" onClick={props.closeMessage}>
            <img src={iconClose} alt="Закрыть" />
          </div>
        </div>
      </div>
    </div>
  );
}

// комментарии по верстке:

// отображение стиля окна зависит от класса на 13 строке.

// * notification__block__success - успех
// * notification__block__danger - ошибка
// * notification__block__warning - предупреждение
// * notification__block__info - информация

// на 17 строке в зав-ти от типа уведомления меняется вызов иконки, все они прописаны с 4 по 7 строки
