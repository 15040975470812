import {Waiter} from "../../components/waiter";


const SearchResultForm = ({
                              props,
                              onLoadMore,
                              activateProduct
                                 }) => {

    if (props.fetching) {
        return <Waiter size="large" />;
    }

    if (props.result.length === 0) {
        return (
            <div className="search-result-table__no-data">Данные не найдены</div>
        );
    }

    const headers = props.columns.map((el, i) => {
        return (
            <th className="search-result-table__th" key={i}>
                {el.name}
            </th>
        );
    });

    headers.push(
        <th className="search-result-table__th" key={headers.length}></th>
    );

    const rows = props.result.map((row, i) => {
        const darkStr = i % 2 === 0 ? '' : ' search-result-table__td_dark';
        const hiddenClass = row.hidden ? ' hidden' : '';
        const rowContent = props.columns.map((col, j) => {
            if (col.code === 'years') {
                return (
                    <td key={j} className={'search-result-table__td' + hiddenClass + darkStr}>
                        {(row.yearStart !== undefined ? row.yearStart : '') +
                            '-' +
                            (row.yearEnd !== undefined ? row.yearEnd : '')}
                    </td>
                );
            } else {
                return (
                    <td key={j} className={'search-result-table__td' + hiddenClass + darkStr}>
                        {row[col.code]}
                    </td>
                );
            }
        });
        rowContent.push(
            <td
                key={rowContent.length}
                className={'search-result-table__td' + hiddenClass + darkStr}
            >
                {/* <Link to={'/search_result'}> */}
                <div
                    className="search-result-table__row-button"
                    data-id={row['modelComplectCode']}
                    onClick={activateProduct}
                >
                    <div className="search-result-table__row-button-image"></div>
                </div>
                {/* </Link> */}
            </td>
        );
        return <tr key={i}>{rowContent}</tr>;
    });

    return (
        <div className="search-result">
            <div className="search-result-table-wrapper">
                <table className="search-result-table">
                    <thead>
                    <tr>{headers}</tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>

            <div className="search-result-table-controls__wrapper">
                <div className="search-result-table-controls">
                    <div className="scroll-to-top-button scroll-to-top-button_hide"></div>
                    <div className="controls__central-area">
                        { props.hasMore &&
                            <div className="more-results" onClick={onLoadMore}>
                                Показать еще результаты
                            </div>
                        }
                    </div>
                    <div
                        className="scroll-to-top-button"
                        onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    >
                        <div className="scroll-to-top-button__img"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchResultForm;