import {
  CHANGE_AUTH_STAGE,
  RECIVED_TOKENS_FROM_SERVER,
  SET_AUTH_CODE,
  SET_AUTH_ERROR,
  SET_JWT,
  SET_REFRESH,
} from '../actions/sync/auth';
import { RESET_APP } from '../actions/sync/init';
import { RECIVE_USER_DATA } from '../actions/sync/personal';

const defState = {
  stage: 'init',
  // stage: 'login',
  jwt: '',
  refresh: '',
  ttl: '',
  code: '',
  error: 'none',
  access: true,
};

function auth(state = defState, action) {
  switch (action.type) {
    case CHANGE_AUTH_STAGE:
      return Object.assign({}, state, { stage: action.stage });
    case SET_JWT:
      return Object.assign({}, state, { jwt: action.jwt });
    case SET_REFRESH:
      return Object.assign({}, state, { refresh: action.refresh });
    case SET_AUTH_CODE:
      return Object.assign({}, state, { code: action.code });
    case RECIVED_TOKENS_FROM_SERVER:
      return Object.assign({}, state, {
        jwt: action.json.access_token,
        refresh: action.json.refresh_token,
        jwt_ttl: action.json.expires_in * 1000,
        refresh_ttl: action.json.refresh_expires_in * 1000,
      });
    case SET_AUTH_ERROR:
      if (action.code === '401') {
        return Object.assign({}, state, { error: 'wrongData' });
      } else if (action.code === '500') {
        return Object.assign({}, state, { error: 'systemError' });
      }
      return state;
    case RECIVE_USER_DATA:
      const roles = action.data.authorities;
      const access = roles.filter(el=>el.authority === "ROLE_GPN_USER");
      const stage = access.length === 0 ? "logout":"login";
      return Object.assign({}, state, {access: access.length !== 0, stage: stage})
    case RESET_APP:
      return Object.assign({}, defState, {stage:'logout'});
    default:
      return state;
  }
}

export default auth;
