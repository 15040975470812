import normalizeModelComplectMenu from "../../../normalizers/modelComplectMenu";
import { setActiveMenuItem, setModelComplectMenu } from "../../reducers/modelComplectMenu";
import {fetchFromApi} from "../service";

export function getModelComplectMenu(modelComplectCode, actionType) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const lang = state.i18.current;
      const pathname = state.currentPath.path;

      const response = await fetchFromApi(`api/v1/model-complect/menu/${modelComplectCode}/locale/${lang}`,
          "GET",{});
      const data = await response.json();

      if (actionType === "copy") {
        dispatch(
          setModelComplectMenu({
            modelComplectName: data.modelComplectName,
            modelComplectCode: data.modelComplectCode,
            modelSubcategoryCode: data.modelSubcategoryCode,
            modelMarkCode: data.modelMarkCode,
          })
        );
        return;
      }
      const normalizedData = normalizeModelComplectMenu(data);
      dispatch(setModelComplectMenu(normalizedData));
      dispatch(setActiveMenuItem(pathname))
    } catch (error) {
      console.log(error);
    }
  };
}
