import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import VerticalLine from "../../components/veritcalLine";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import ChipsField from "../../pureComponents/chipsField";
import MultiSelectBlock from "../../pureComponents/multiSelectBlock";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import { Waiter } from "../../components/waiter";
import { setSpecOver } from "../../redux/reducers/specOver";
import { addNewSpecOption } from "../../redux/reducers/specMenu";
import { getSpecList } from "../../redux/actions/async/specList";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import {
  deleteSpecOverFromServer,
  getSpecOver,
  putSpecOver,
} from "../../redux/actions/async/specOver";

function handleSpecOver(specOver, item) {
  const spec = specOver.find((spec) => spec.spec === item.code);

  if (!spec) {
    return [
      ...specOver,
      {
        id: nanoid(3),
        spec: item.code,
        name: item.name,
        actionType: "C",
      },
    ];
  }

  if (spec.actionType === "C") {
    return specOver.filter((spec) => spec.spec !== item.code);
  }

  // specs from server
  return specOver.map((spec) => {
    if (spec.spec !== item.code) return spec;

    return {
      ...spec,
      actionType: !spec.actionType ? "D" : null,
    };
  });
}

function deleteAllSpecOver(specOver) {
  return specOver
    .filter((spec) => spec.actionType !== "C")
    .map((spec) => ({ ...spec, actionType: "D" }));
}

const SpecOverPage = () => {
  const navigate = useNavigate();
  const { specCode } = useParams();

  const dispatch = useDispatch();

  const menuIsLoaded = useSelector((state) => state.specMenu.specCode !== "");
  const specMenu = useSelector((state) => state.specMenu);

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const [specList, isSpecListLoading] = useSelector(
    (state) => [state.specList.list, state.specList.isLoading],
    shallowEqual
  );

  const [specOver, fetchingStatus] = useSelector(
    (state) => [state.specOver.data, state.specOver.fetchingStatus],
    shallowEqual
  );
  const isDeleteButtonDisabled = specOver.every(
    (item) => item.actionType === "C"
  );

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const filteredSpecOver = specOver.filter((spec) => spec.actionType !== "D");
  const filteredSpecList = specList.map((spec) => {
    return {
      ...spec,
      checked: filteredSpecOver.some((item) => item.spec === spec.code),
    };
  });

  useEffect(() => {
    dispatch(getSpecList());
  }, []);

  useEffect(() => {
    if (menuIsLoaded) {
      const option = "spec-over";
      const optionName = "Перекрываемые спецификации";

      if (specMenu.children.some((item) => item.code === option)) return;

      dispatch(
        addNewSpecOption({
          option,
          optionName,
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    if (!specCode) return;

    dispatch(getSpecOver(specCode));
  }, [specCode]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitSpecs));
  }, [JSON.stringify(specOver)]);

  function onSearch(value) {
    dispatch(getSpecList(value));
  }

  function onSelectSpec(item) {
    const newSpecOver = handleSpecOver(specOver, item);
    dispatch(setSpecOver(newSpecOver));

    if (formErrors.specOver) {
      setErrors({
        specOver: null,
      });
    }

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function onSelectAllSpecs() {
    const allChecked = filteredSpecList.length === filteredSpecOver.length;

    if (allChecked) {
      clearAllSpecOver();
      return;
    }

    const nonCheckedSpecList = filteredSpecList.filter((item) => !item.checked);
    let newSpecOver = specOver;

    nonCheckedSpecList.forEach((item) => {
      newSpecOver = handleSpecOver(newSpecOver, item);
    });

    dispatch(setSpecOver(newSpecOver));

    if (formErrors.specOver) {
      setErrors({
        specOver: null,
      });
    }

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function deleteSpecSpec(id) {
    const specSpec = specOver.find((spec) => spec.id === id);

    if (specSpec.actionType === "C") {
      dispatch(setSpecOver(specOver.filter((spec) => spec.id !== id)));
      return;
    }

    dispatch(
      setSpecOver(
        specOver.map((spec) => {
          if (spec.id !== id) return spec;

          return {
            ...spec,
            actionType: "D",
          };
        })
      )
    );

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function clearAllSpecOver() {
    const newSpecOver = deleteAllSpecOver(specOver);
    dispatch(setSpecOver(newSpecOver));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const handleDeleteSpecOver = () => {
    setDeleteModalActive(true);
  };

  const deleteSpecOver = () => {
    dispatch(
      deleteSpecOverFromServer(specCode, () => {
        navigate(`/spec/${specCode}`, {
          state: {
            actionType: "edit",
          },
        });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  function submitSpecs() {
    const isFormValid = filteredSpecOver.length;

    if (isFormValid) {
      return dispatch(putSpecOver());
    } else {
      setErrors({
        specOver: "Поле не заполнено",
      });
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    submitSpecs();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="content-general__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="content-general__header">
            <div className="main-header-block__item main-header-block__title">
              Перекрываемые спецификации
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteSpecOver}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__specs">
              <div className="chips-field-block">
                <ChipsField
                  id="specOver"
                  label="Перекрываемые Спецификации"
                  items={filteredSpecOver}
                  clearField={clearAllSpecOver}
                  deleteChipsItem={deleteSpecSpec}
                  error={formErrors.specOver}
                  required
                />
              </div>
              <div className="multiselect-block">
                <MultiSelectBlock
                  items={filteredSpecList}
                  isLoading={isSpecListLoading}
                  onSearch={onSearch}
                  onItemSelect={onSelectSpec}
                  onSelectAll={onSelectAllSpecs}
                />
              </div>
            </div>
          </div>
          <div className="content-general__footer">
            <div className="main-footer-block__buttons">
              <ModalLinkWrapper
                linkProps={{ to: "/specs", state: { pageCode: 50, withoutReset: true } }}
                className="button button__secondary button__secondary__ghost"
              >
                Вернуться к списку спецификаций
              </ModalLinkWrapper>
              <button
                type="submit"
                className="button button__primary"
                disabled={!dataChanged}
              >
                Сохранить изменения
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteSpecOver}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"spec_spec"}
        rowCode={specCode}
        listType={"hist"}
      />
    </>
  );
};

export default SpecOverPage;
