import React from 'react';
import {useSelector} from "react-redux";
import {Waiter} from "../../components/waiter";
import DropdownMultiSelect from "../../pureComponents/dropdownMultiSelect";
import DatePicker from '../../pureComponents/datePicker';

const StatisticRequestReportForm = ({
        formErrors,
        handleInputChange,
        onDelete,
        onSelect,
        onClearField,
        handleDateChange,
        handleButtonClick,
        onSubmit
    }) => {
    const formData = useSelector((state) => state.statisticRequestReportForm );
    const { catigoryList, subcatigoryList } = useSelector((state) => state.statisticRequestReportFormSelectors);

    if (formData.fetchingStatus === 'loading') {
        return <Waiter size="large" />;
    } else {
        return (
            <form onSubmit={onSubmit}>
                <fieldset
                    className="model-complect-general__form"
                    disabled={false}
                >
                    <div className="model-complect-general__header">
                        <div className="main-header-block">
                            <div className="main-header-block__item main-header-block__title">
                                Статистика запросов
                            </div>
                        </div>
                    </div>
                    <div className="model-complect-general__form">
                        <div className="main-form-block">
                            <div className="main-form-block__column">
                                <div className="main-form-block__row">
                                    <DatePicker
                                        id="dateFrom"
                                        label="Дата c"
                                        placeholder='От'
                                        value={formData.dateFrom}
                                        onChangeData={handleDateChange}
                                        maxDate={formData.dateTo}
                                        required={true}
                                        error={formErrors.dateFrom}
                                    />

                                    <DatePicker
                                        id="dateTo"
                                        label="Дата по"
                                        placeholder='По'
                                        value={formData.dateTo}
                                        onChangeData={handleDateChange}
                                        minDate={formData.dateFrom}
                                        required={true}
                                        error={formErrors.dateTo}
                                    />
                                </div>
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedCatigories"
                                        label="Категория ТС"
                                        items={catigoryList}
                                        selectedItems={formData.selectedCatigories}
                                        onDelete={onDelete}
                                        onSelect={onSelect}
                                        onClearField={onClearField}
                                    />
                                </div>
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedSubcatigories"
                                        label="Подкатегория ТС"
                                        items={subcatigoryList}
                                        selectedItems={formData.selectedSubcatigories}
                                        onDelete={onDelete}
                                        onSelect={onSelect}
                                        onClearField={onClearField}
                                        disabled={formData.selectedCatigories.length === 0 }
                                    />
                                </div>
                            </div>
                            <div className="main-form-block__column"></div>
                        </div>
                    </div>

                    <div className="model-complect-general__footer">
                        <button type="button"
                                className="button button__secondary button__secondary__ghost"
                                onClick={handleButtonClick}>
                            Сбросить настройки
                        </button>
                        <button type="submit" className="button button__primary">
                            Выгрузить отчет
                        </button>
                    </div>
                </fieldset>
            </form>
        );
    }
};
export default StatisticRequestReportForm;
