import { fetchFromApi } from "../service";
import {setAutostatModelReportFormSelectors} from "../../reducers/autostatModelReportFormSelectors";

export function getAsType( callback = null ) {
    return async (dispatch) => {
        const response = await fetchFromApi("api/v1/as-type", "GET", {});
        const data = await response.json();
        dispatch( setAutostatModelReportFormSelectors({asTypeList: data}) );
        if( callback ) callback();
    };
}

export function getAsMark(searchString = null, callback = null) {
    return async (dispatch) => {
        const response = await fetchFromApi("api/v1/as-mark", "POST", {body: {searchString}});
        const data = await response.json();
        dispatch( setAutostatModelReportFormSelectors({ asMarkList: data }));
        if(callback) callback();
    };
}

export function getAsModel(searchString = null, callback = null) {
    return async (dispatch) => {
        const response = await fetchFromApi("api/v1/as-model", "POST", {body: {searchString}});
        const data = await response.json();
        dispatch(setAutostatModelReportFormSelectors({asModelList: data}));
        if(callback) callback();
    };
}

export function getAsFuelType() {
    return async (dispatch) => {
        const response = await fetchFromApi("api/v1/as-fuel-type", "GET", {});
        const data = await response.json();

        dispatch(
            setAutostatModelReportFormSelectors({
                asFuelTypeList: data
            })
        );
    };
}
export function makeAutostatModelReport(callback) {
    return async (dispatch, getState) => {
        const state = getState();
        const formData = state.autostatModelReportForm;

        const response = await fetchFromApi("api/v1/xls/report/model/missed", "POST",
            { body:{
                    asType: formData.selectedAsType.map( (item) => (item.code)),
                    asMark: formData.selectedAsMark.map( (item) => (item.code)),
                    asModel: formData.selectedAsModel.map( (item) => (item.code)),
                    asFuelType: formData.selectedAsFuelType.map( (item) => (item.code)),
                    asStartYear: formData.enteredAsStartYear,
                    asEndYear: formData.enteredAsEndYear,
                    asEngineCapFrom: parseInt(formData.enteredAsEngineCapFrom),
                    asEngineCapTo: parseInt(formData.enteredAsEngineCapTo),
                    asPowerHpFrom: parseInt(formData.enteredAsPowerHpFrom),
                    asPowerHpTo: parseInt(formData.enteredAsPowerHpTo),
                    asCntFrom: parseInt(formData.enteredAsCntFrom),
                    asCntTo: parseInt(formData.enteredAsCntTo)
                } });

        const file = await response.blob();
        const name = response.headers.get('Content-Disposition').split('\'\'')[1];

        if (callback) {
            callback(file,decodeURIComponent(name));
        }
    }
}
