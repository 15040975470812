const localeList = ["en-US", "ru-RU"];

export function getDateFromTimestamp(timestamp, currentLanguage = "RU") {
  const date = new Date(timestamp * 1000);
  const locale = localeList.find((locale) =>
    locale.includes(currentLanguage, 3)
  );
  const dateFormat = new Intl.DateTimeFormat(locale);
  return dateFormat.format(date);
}

export function getValidDate(dateStr) {
  return new Date(dateStr.replace(/(\d+).(\d+).(\d+)/, "$3/$2/$1"));
}
