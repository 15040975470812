import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ComponentForm from "./componentForm";
import { useGetNewCode } from "../../hooks/useGetNewCode";
import { getComponentSelectors } from "../../redux/actions/async/componentSelectors";
import {
  resetComponent,
  updateComponentChangedFields,
  updateComponentField,
} from "../../redux/actions/sync/component";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import { getProducerList } from "../../redux/actions/async/producer";
import { getComponentTypeList } from "../../redux/actions/async/componentType";
import { getComponentGroupList } from "../../redux/actions/async/componentGroup";
import componentSchema from "../../validations/component";
import {
  deleteComponentFromServer,
  getComponent,
  putComponent,
} from "../../redux/actions/async/component";
import {
  addNewComponent,
  deleteNewComponent,
} from "../../redux/reducers/modelComplectMenu";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import generateModelComplectLink from "../../utils/generateModelComplectLink";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";

const ComponentPage = () => {
  const { state } = useLocation();
  const actionType = state ? state.actionType : "new";
  const { modelComplectCode, componentCode } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const sideMenuIsLoaded = useSelector(
    (state) => state.modelComplectMenu.modelComplectCode !== ""
  );
  const componentData = useSelector((state) => state.component);
  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const getNewCode = useGetNewCode(updateComponentField);

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const [initialLoad, setInitialLoad] = useState(true);

  const [searchLoading, setSearchLoading] = useState({
    componentTypeCode: false,
    producerCode: false,
  });

  useEffect(() => {
    dispatch(getComponentSelectors());
    dispatch(getComponentGroupList());

    return () => {
      dispatch(resetComponent());
    };
  }, []);

  useEffect(() => {
    if (!sideMenuIsLoaded) {
      return;
    }

    if (actionType !== "edit") {
      dispatch(addNewComponent(actionType));
    }

    if (actionType === "new") {
      dispatch(resetComponent());
      dispatch(getProducerList());
      dispatch(
        updateComponentField({
          changedFields: ["code", "hidden"],
        })
      );
    }

    return () => dispatch(deleteNewComponent());
  }, [actionType, sideMenuIsLoaded]);

  useEffect(() => {
    if (actionType === "new") {
      return;
    }

    if (actionType === "copy") {
      dispatch(
        updateComponentField({
          id: null,
          changedFields: Object.keys(componentData).filter(
            (key) =>
              componentData[key] !== null && !Array.isArray(componentData[key])
          ),
        })
      );
      dispatch(updateAllChg(true));
      setErrors({});
      return;
    }

    if (componentCode && actionType === "edit") {
      dispatch(getComponent(componentCode));
      setErrors({});
    }

    return () => setInitialLoad(true);
  }, [componentCode, actionType]);

  useEffect(() => {
    if (actionType !== "edit") {
      getNewCode();
    }
  }, [actionType]);

  useEffect(() => {
    if (modelComplectCode) {
      dispatch(
        updateComponentField({
          modelComplectCode,
        })
      );
    }
  }, [modelComplectCode]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitComponent));
  }, [componentData]);

  useEffect(() => {
    if (componentData.componentGroupCode && !initialLoad) {
      dispatch(
        updateComponentField({
          componentTypeCode: null,
        })
      );
      dispatch(getComponentTypeList(componentData.componentGroupCode));
    }
  }, [componentData.componentGroupCode]);

  const handleDateChange = useCallback(
    (value, id) => {
      dispatch(
        updateComponentField({
          [id]: value,
        })
      );

      dispatch(updateComponentChangedFields(id));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [actionType, dataChanged]
  );

  const handleInputChange = useCallback(
    ({ target: { id, type, value } }) => {
      if (type === "checkbox") {
        dispatch(
          updateComponentField({
            [id]: !componentData[id],
          })
        );

        dispatch(updateComponentChangedFields(id));

        if (!dataChanged) {
          dispatch(updateAllChg(true));
        }
        return;
      }

      dispatch(
        updateComponentField({
          [id]: value,
        })
      );

      dispatch(updateComponentChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [componentData, actionType, dataChanged]
  );

  const handleDropdownChange = useCallback(
    ({ code }, id) => {
      dispatch(
        updateComponentField({
          [id]: code,
        })
      );

      dispatch(updateComponentChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }

      if (id === "componentGroupCode" && initialLoad) {
        setInitialLoad(false);
      }
    },

    [actionType, dataChanged, initialLoad]
  );

  const handleDropdownSearch = useCallback(
    (id, searchValue) => {
      setSearchLoading({
        [id]: true,
      });

      if (id === "componentTypeCode") {
        dispatch(
          getComponentTypeList(
            componentData.componentGroupCode,
            componentData.componentTypeCode,
            searchValue,
            () =>
              setSearchLoading({
                [id]: false,
              })
          )
        );
      }

      if (id === "producerCode") {
        dispatch(
          getProducerList(componentData.producerCode, searchValue, () =>
            setSearchLoading({
              [id]: false,
            })
          )
        );
      }
    },
    [
      componentData.componentGroupCode,
      componentData.componentTypeCode,
      componentData.producerCode,
    ]
  );

  const handleDeleteComponent = useCallback(() => {
    setDeleteModalActive(true);
  }, []);

  const deleteComponent = () => {
    dispatch(
      deleteComponentFromServer(componentCode, () => {
        navigate(generateModelComplectLink(modelComplectCode), {
          state: {
            actionType: "edit",
          },
        });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitComponent() {
    const isFormValid = await componentSchema.isValid(componentData, {
      abortEarly: false,
    });

    if (isFormValid) {
        const data = await componentSchema.validate(componentData, {
            abortEarly: false,
        });
        return dispatch(putComponent(data));
    } else {
      if (!isFormValid) {
        componentSchema
          .validate(componentData, { abortEarly: false })
          .catch((err) => {
            const errors = err.inner.reduce((acc, error) => {
              return {
                ...acc,
                [error.path]: error.message,
              };
            }, {});

            setErrors(() => {
              return {
                ...errors,
              };
            });
          });
      }

      return false;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const isValid = await submitComponent();
    if (isValid) {
      dispatch(
        updateComponentField({
          changedFields: [],
        })
      );
      setInitialLoad(true);
      navigate(
        generateModelComplectLink(modelComplectCode, {
          type: "component",
          code: componentData.code,
        }),
        {
          state: {
            actionType: "edit",
          },
        }
      );
    }
  };

  return (
    <>
      <ComponentForm
        formErrors={formErrors}
        actionType={actionType}
        modelComplectCode={modelComplectCode}
        componentCode={componentCode}
        getNewCode={getNewCode}
        searchLoading={searchLoading}
        handleDateChange={handleDateChange}
        handleDropdownSearch={handleDropdownSearch}
        handleInputChange={handleInputChange}
        handleDropdownChange={handleDropdownChange}
        handleDeleteComponent={handleDeleteComponent}
        openHistoryChanges={openHistoryChanges}
        onSubmit={onSubmit}
      />
      <DeleteModal
        active={deleteModalActive}
        title={
          <>
            Будут удалены все данные по Узлу ТС.
            <br /> Продолжить?
          </>
        }
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteComponent}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"component"}
        rowCode={componentCode}
        listType={"hist"}
      />
    </>
  );
};

export default ComponentPage;
