import { SET_COMPONENT_SELECTORS } from "../actions/sync/componentSelectors"

const initialState = {
    componentFuelTypeList: [],
    componentGearTypeList: [],
    componentTurboTypeList: [],
    componentEngineBuildList: [],
    componentCoolTypeList: [],
}

function componentSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT_SELECTORS:
      return {...state, ...action.payload }
    default:
      return state
  }
}

export default componentSelectors;