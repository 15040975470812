import { setConditionTypeList } from "../../reducers/conditionType";
import { fetchFromApi } from "../service";
import { receiveConditionType } from "../sync/conditionType";

export function getConditionTypeList(
  modelMarkCode,
  modelSubcategoryCode,
  conditionTypeCode = null,
  searchString = null,
  callback = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/condition-type/filter";

    const body = {
      lang: locale,
      modelMarkCode,
      modelSubcategoryCode,
      conditionTypeCode,
      searchString,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(setConditionTypeList(data));

      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
}


export function requestConditionType(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveConditionType([data]))
	};
}



