import { RECIVE_PRODUCT_INFO } from '../actions/sync/product';

const defState = {
  by_id: {},
  all_ids: [],
};

function intervals(state = defState, action) {
  switch (action.type) {
    case RECIVE_PRODUCT_INFO:
      return Object.assign({}, state, getIntervalsFromInfo(action.json));
    default:
      return state;
  }
}

function getIntervalsFromInfo(info) {
  const res = {
    all_ids: [],
    by_id: {},
  };
  if( !info.component ) {
    return res;
  }
  info.component.forEach((comp, i) => {
    if (comp.condition !== undefined && comp.condition !== null) {
      comp.condition.forEach((cond, j) => {
        if (cond.interval !== undefined) {
          cond.interval.forEach((interval, h) => {
            res.all_ids.push('interval_' + i + '_' + j + '_' + h);
            res.by_id['interval_' + i + '_' + j + '_' + h] = {
              val: interval.val,
              intervalType: interval.intervalType,
              intervalAction: interval.intervalAction,
              hidden: interval.hidden,
            };
          });
        }
      });
    }
  });
  return res;
}

export default intervals;
