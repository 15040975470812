import React, { memo, useRef } from "react";

import { useSetIsEllipsisActive } from "../../hooks/useSetIsEllipsisActive.js";

import { useHover } from "usehooks-ts";

export const ListItem = memo(({ item, dropdrownName, handleOnClickItem }) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const isEllipsisActive = useSetIsEllipsisActive(hoverRef, isHover);


  const modelComplectLabelParts = [
    item.name,
    item.modelMark && `(${item.modelMark})`,
    item.model && `(${item.model})`,
    item.modelClass && `(${item.modelClass})`,
    item.startYear ? (item.startYear && !item.endYear) ? `(${item.startYear})` : `(${item.startYear}` : undefined,
    item.endYear && `- ${item.endYear})`,
    item.engineCap && `(${item.engineCap})`,
    item.code && `(${item.code})`
  ].filter((Boolean)); 


  const modelComplectLabel = modelComplectLabelParts.join(' ');
  const name = dropdrownName === 'modelComplectCode' ? modelComplectLabel : item.name


  const title = isEllipsisActive ? name : undefined;

  return (
    <div
      ref={hoverRef}
      key={item.code}
      className={`dropdown-block__option${item.hidden ? " hidden" : ""}`}
      onClick={() => handleOnClickItem(item)}
      title={title}
    >
      {name}
    </div>
  );
});
