import { useSelector } from "react-redux";

export const useAuth = () => {
  const { isLoggedIn, isLoggedOut } = useSelector((state) => ({
    isLoggedIn: state.auth.stage === "login",
    isLoggedOut: state.auth.stage === "logout",
  }));

  return { isLoggedIn, isLoggedOut };
};
