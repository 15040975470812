export const UPDATE_SUBMIT_FUNCTION = 'UPDATE_SUBMIT_FUNCTION';
export const updateSubmitFunction = (submitFunction) => ({
  type: UPDATE_SUBMIT_FUNCTION,
  submitFunction,
});

export const UPDATE_ALL_CHG = "UPDATE_ALL_CHG"
export const updateAllChg = (allChg) => ({
  type: UPDATE_ALL_CHG,
  allChg,
});

export const RESET_CURRENT_PAGE_FORM_STATE = 'RESET_CURRENT_PAGE_FORM_STATE';
export const resetCurrentPageFormState = () => ({
  type: RESET_CURRENT_PAGE_FORM_STATE,
});