import FormInputField from "../../pureComponents/formInputField";
import RadioButton from "../../pureComponents/radioButton";

const SearchByStringForm = ({
                                fields,
                                fetching,
                                changeInput,
                                startSearch,
                                stringSearchDisable,
                                stringSearchType,
                                onStringSearchTypeChange,
                                onEnter
                                 }) => {
    return ( 
        <div className={'keywords-search'}>
            <div className={`keywords-search__container${fetching ? ' waiting' : ''}`}>
                <div className="keywords-search__header">Поиск по ключевым словам</div>
                <div className="keywords-search__subheader">
                    Поиск по категории транспортных средств или по производителю
                    оборудования
                </div>
                <div className="keywords-search__form">
                    <FormInputField
                        id='searchString'
                        label='Поиск по ключевому слову'
                        value={fields.searchString}
                        onChange={changeInput}
                        onEnterDown={onEnter}
                        required={true}
                    />
                    <button
                        className={'button button__primary'}
                        onClick={() => startSearch('by_string')}
                        disabled={stringSearchDisable}>
                        Поиск
                    </button>
                </div>

                <div className="keywords-search__radio-buttons">
                    <RadioButton
                        name={"Поиск по технике"}
                        code={'technic'}
                        checked={stringSearchType === 'technic'}
                        onChange={onStringSearchTypeChange}
                    />
                    <RadioButton
                        name={"Поиск по узлу"}
                        code={'module'}
                        checked={stringSearchType === 'module'}
                        onChange={onStringSearchTypeChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default SearchByStringForm;