import { connect } from 'react-redux';
import { NotificationMessage } from '../../components/notificationMessage';
import { closeNotificationMessage } from '../actions/sync/notificationMessage';

const mapStateToProps = (state) => {
  return {
    header: state.notificationMessage.header,
    message: state.notificationMessage.message,
    link: state.notificationMessage.link,
    linkPath: state.notificationMessage.linkPath,
    mode: state.notificationMessage.mode,
    visible: state.notificationMessage.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMessage: () => dispatch(closeNotificationMessage()),
  };
};

const VisibleNotificationMessage = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationMessage);

export default VisibleNotificationMessage;
