import HttpError from "../../../utils/httpError";
import {
  setImportFileData,
  setImportFileFetchingStatus,
} from "../../reducers/importFile";
import { fetchFromApi } from "../service";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import normalizeImportData from "../../../normalizers/importData";
import { isObjectEmpty } from "../../../utils/isObjectEmpty";
import { validateCode } from "../../../validations/code";

export function getFileDataWithDiff(file, tableName, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;

    const diffBody = new FormData();
    diffBody.append("lang", lang);
    diffBody.append("xlsFile", file);

    const resource = tableName.includes("_")
      ? tableName.replace("_", "-")
      : tableName;

    const urls = [
      {
        url: "api/v1/xls/diff/" + resource,
        body: diffBody,
      },
      {
        url: "api/v1/column/list/",
        body: { lang, tableName, listType: "hist" },
      },
    ];

    try {
      dispatch(setImportFileFetchingStatus(true));

      const vocabularyResponse = await fetchFromApi(
        `api/v1/dictionary/YN/locale/${lang}`,
        "GET",
        {}
      );
      const vocabulary = await vocabularyResponse.json();

      const response = await Promise.all(
        urls.map((item) =>
          fetchFromApi(item.url, "POST", {
            body: item.body,
          })
        )
      );

      if (!response[0].ok) {
        const responseBody = await response[0].json();
        throw new HttpError(
          response[0].status,
          response[0].statusText,
          responseBody
        );
      }

      const responseData = await Promise.all(
        response.map((data) => data.json())
      );

      const [data, columns] = responseData;

      if (isObjectEmpty(data)) {
        dispatch(
          initNotificationMessage(
            "Сообщение",
            "Расхождений не обнаружено",
            "info"
          )
        );
        dispatch(showNotificationMessage());
        dispatch(setImportFileFetchingStatus(false));
        return;
      }

      const { dataFromDB, dataFromFile } = normalizeImportData(data);

      dispatch(
        setImportFileData({
          fileName: file.name,
          columns,
          dataFromDB,
          dataFromFile,
          vocabulary,
        })
      );
      dispatch(setImportFileFetchingStatus(false));
    } catch (error) {
      callback()
      if (error.status === 400) {
        const message = error.body.messages?.join("\n") || "";

        await dispatch(
          initNotificationMessage(
            "Ошибка загрузки файла",
            `Ошибка в данных файла. Проверьте, соответствует ли файл шаблону и рекомендациям в комментариях шаблона.

            Техническая информация:
            ${message}`,
            "danger"
          )
        );

        dispatch(showNotificationMessage());
        dispatch(setImportFileFetchingStatus(false));
        return;
      }

      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }

      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setImportFileFetchingStatus(false));
    }
  };
}

export function saveImportData(tableName, selectedData, callback = null) {
  return async (dispatch, getState) => {
    if (selectedData.some((data) => !validateCode(data.codeXls))) {
      await dispatch(
        initNotificationMessage(
          `Ошибка`,
          "Поле Код содержит недопустимые символы",
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      return;
    }

    const lang = getState().i18.current;
    const resource = tableName.includes("_")
      ? tableName.replace("_", "-")
      : tableName;

    const dataWithIdDb = selectedData.filter((item) => item.idDb);

    const dataWithDiffForUpdate = await checkEditedDataDiff(
      resource,
      dataWithIdDb,
      lang
    );

    if (
      dataWithDiffForUpdate.length === 0 &&
      dataWithIdDb.length === selectedData.length
    ) {
      if (callback) callback();
      return;
    }

    const data = selectedData
      .filter((item) => {
        return (
          dataWithDiffForUpdate.some((i) => i.idDb === item.idDb) || !item.idDb
        );
      })
      .map((item) => {
        const keys = Object.keys(item).filter(
          (key) => key.includes("Xls") && !key.includes("Name")
        );

        return {
          ...Object.fromEntries(
            keys.map((key) => [key.replace("Xls", ""), item[key]])
          ),
          name: item.nameMerged
            ? {
                value: item.nameMerged.value.map((el) => {
                  if (el.lang !== lang) return el;

                  return {
                    ...el,
                    name: item.nameXls,
                  };
                }),
              }
            : item.nameXls,
          id: item.idDb,
          changedFields: item.changedFields,
        };
      });

    const url = "api/v1/xls/" + resource;

    try {
      const response = await fetchFromApi(url, "PUT", {
        body: {
          data,
        },
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(
        initNotificationMessage(
          "Сообщение",
          "Данные успешно сохранены",
          "success"
        )
      );
      dispatch(showNotificationMessage());

      if (callback) callback();
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }

      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setImportFileFetchingStatus(false));
    }
  };
}

async function checkEditedDataDiff(resource, data, lang) {
  if (data.length === 0) return [];

  const body = data.map((item) => {
    const keys = Object.keys(item).filter(
      (key) => key.includes("Xls") && !key.includes("Name")
    );

    return Object.fromEntries(
      keys.map((key) => {
        const newKey = key.includes("Code")
          ? key.replace("CodeXls", "")
          : key.replace("Xls", "");

        return [newKey, item[key]];
      })
    );
  });

  try {
    const response = await fetchFromApi(
      `api/v1/xls/diff/${resource}-ui/${lang}`,
      "POST",
      {
        body,
      }
    );

    const data = await response.json();

    return isObjectEmpty(data) ? [] : data.data;
  } catch (error) {}
}
