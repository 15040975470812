import React from 'react';
import {useSelector} from "react-redux";
import {Waiter} from "../../components/waiter";
import DropdownMultiSelect from "../../pureComponents/dropdownMultiSelect";

const StatisticCoverReportForm = ({
        formErrors,
        onDelete,
        onSearch,
        onSelect,
        onClearField,
        onSelectAll,
        processedList,
        handleButtonClick,
        onSubmit
     }) => {
    const formData = useSelector((state) => state.statisticCoverReportForm);
    const { specificationList, viscoList, catigoryList } = useSelector((state) => state.statisticCoverReportFormSelectors);

    if (formData.fetchingStatus === 'loading') {
        return <Waiter size="large" />;
    } else {
        return (
            <form onSubmit={onSubmit}>
                <fieldset
                    className="model-complect-general__form"
                    disabled={false}
                >
                    <div className="model-complect-general__header">
                        <div className="main-header-block">
                            <div className="main-header-block__item main-header-block__title">
                                Статистика - покрытие спецификаций техники
                            </div>
                        </div>
                    </div>
                    <div className="model-complect-general__form">
                        <div className="main-form-block">
                            <div className="main-form-block__column">
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedSpecification"
                                        label="Спецификация"
                                        items={specificationList}
                                        selectedItems={formData.selectedSpecification}
                                        onDelete={onDelete}
                                        onSelect={onSelect}
                                        onSearch={onSearch}
                                        onSelectAll={onSelectAll}
                                        onClearField={onClearField}
                                        isLoading={processedList.selectedSpecification}
                                        isSearchable={true}
                                        isSelectAllAvailable={true}
                                        required={true}
                                        error={formErrors.selectedSpecification}
                                    />
                                </div>
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedVisco"
                                        label="Вязкости"
                                        items={viscoList}
                                        selectedItems={formData.selectedVisco}
                                        onDelete={onDelete}
                                        onSelect={onSelect}
                                        onSearch={onSearch}
                                        onSelectAll={onSelectAll}
                                        onClearField={onClearField}
                                        isLoading={processedList.selectedVisco}
                                        isSearchable={true}
                                        isSelectAllAvailable={true}
                                    />
                                </div>
                                <div className="main-form-block__row">
                                    <DropdownMultiSelect
                                        id="selectedCategory"
                                        label="Категория техники"
                                        items={catigoryList}
                                        selectedItems={formData.selectedCategory}
                                        onDelete={onDelete}
                                        onSelect={onSelect}
                                        onSelectAll={onSelectAll}
                                        onClearField={onClearField}
                                        isLoading={processedList.selectedCategory}
                                        isSelectAllAvailable={true}
                                    />
                                </div>
                            </div>
                            <div className="main-form-block__column"></div>
                        </div>
                    </div>

                    <div className="model-complect-general__footer">
                        <button type="button"
                                className="button button__secondary button__secondary__ghost"
                                onClick={handleButtonClick}>
                            Сбросить настройки
                        </button>
                        <button type="submit" className="button button__primary" >
                            Выгрузить отчет
                        </button>
                    </div>
                </fieldset>
            </form>
        );
    }
};

export default StatisticCoverReportForm;
