import normalizeConditionSpecsForServer from "../../../normalizers/conditionSpecs";
import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import {
  setConditionCodeForCS,
  setConditionSpecs,
  setConditionSpecsFetchingStatus,
} from "../../reducers/conditionSpecs";
import { setActiveMenuItem } from "../../reducers/modelComplectMenu";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getModelComplectMenu } from "./modelComplectMenu";

export function getConditionSpecs(conditionCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/condition-spec";

    try {
      dispatch(setConditionSpecsFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${conditionCode}/locale/${lang}`,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(
        setConditionSpecs(
          data.map((spec) => ({
            id: spec.id,
            spec: spec.specCode,
            name: spec.specName,
          }))
        )
      );
      dispatch(setConditionCodeForCS(conditionCode));
      dispatch(setConditionSpecsFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setConditionSpecsFetchingStatus());
    }
  };
}

export function putConditionSpecs() {
  return async (dispatch, getState) => {
    const state = getState();
    const conditionSpecs = state.conditionSpecs.data;
    const conditionCode = state.conditionSpecs.conditionCode;
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setConditionSpecsFetchingStatus("update"));

      const response = await fetchFromApi("api/v1/condition-spec", "POST", {
        body: normalizeConditionSpecsForServer(conditionSpecs, conditionCode),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getModelComplectMenu(modelComplectCode));
      dispatch(setActiveMenuItem(pathname));
      dispatch(resetCurrentPageFormState());
      dispatch(getConditionSpecs(conditionCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setConditionSpecsFetchingStatus());

      return false;
    }
  };
}

export function deleteConditionSpecsFromServer(conditionCode, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;
    const conditionSpecs = state.conditionSpecs.data;

    const body = {
      code: conditionCode,
      data: conditionSpecs
        .filter((item) => item.actionType !== "C")
        .map((item) => ({ spec: item.spec })),
    };

    try {
      dispatch(setConditionSpecsFetchingStatus("loading"));
      const response = await fetchFromApi(
        "api/v1/condition-spec/all",
        "DELETE",
        {
          body
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getModelComplectMenu(modelComplectCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setConditionSpecsFetchingStatus());
    }
  };
}
