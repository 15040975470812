import { RECEIVE_INTERVAL } from "../actions/sync/interval";

const initialState = {
	list: [],
};

function interval(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_INTERVAL:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default interval;
