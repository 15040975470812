import { receiveConditionViscoInterval } from "../sync/conditionViscoInterval";
export function requestConditionViscoInterval(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveConditionViscoInterval([data]))
	};
}
