import * as yup from "yup";

const messages = {
  required: "Поле не заполнено",
};

const guidsFormVehicleCategorySchema = yup.object().shape({
  code: yup.string()
    .required(messages.required)
    .matches(
      /^[A-Z0-9-_]+$/,
      "Допускаются заглавные латинские символы, цифры, знаки _ -"
    ),
  name: yup.string().required(messages.required),
  hidden: yup.boolean(),
});

export default guidsFormVehicleCategorySchema;