import {
  CLOSE_MARK_GUIDE_FORM,
  CLOSE_MARK_GUIDE_SAVE_FORM,
  DROP_MARK_GUIDE_FORM,
  INIT_MARK_GUIDE_FORM,
  SET_MARK_GUIDE_FORM_ID,
  SET_MARK_GUIDE_FORM_NAMES,
  SET_MARK_GUIDE_HIDDEN,
  SHOW_MARK_GUIDE_FORM,
  UPDATE_MARK_GUIDE_AS_MARK,
  UPDATE_MARK_GUIDE_CODE,
  UPDATE_MARK_GUIDE_LANGUAGES,
  UPDATE_MARK_GUIDE_NAME,
} from '../actions/sync/markGuideForm';

const defState = {
  visible: false,
  mode: 'def',
  code: 'def',
  codeError: 'Допускаются заглавные латинские символы, цифры, знаки _ -',
  visibleCodeError: false,
  name: 'def',
  asMark: 'def',
  hidden: false,
  nameChg: false,
  hiddenChg: false,
  asMarkChg: false,
  names: [],
  languages: [],
  showSaveForm: false,
};

function markGuideForm(state = defState, action) {
  switch (action.type) {
    case SHOW_MARK_GUIDE_FORM:
      return Object.assign({}, state, { visible: true });
    case CLOSE_MARK_GUIDE_FORM:
      if (state.nameChg || state.hiddenChg) {
        return Object.assign({}, state, { showSaveForm: true });
      } else {
        return Object.assign({}, state, { visible: false });
      }
    case CLOSE_MARK_GUIDE_SAVE_FORM:
      return Object.assign({}, state, { showSaveForm: false });
    case DROP_MARK_GUIDE_FORM:
      return defState;
    case INIT_MARK_GUIDE_FORM:
      switch (action.mode) {
        case 'new':
          return Object.assign({}, defState, { mode: action.mode });
        case 'copy':
          return Object.assign({}, defState, {
            mode: action.mode,
            code: action.code,
            name: action.name,
            hidden: action.hidden,
            asMark: action.asMark,
          });
        case 'edit':
          return Object.assign({}, defState, {
            mode: action.mode,
            code: action.code,
            name: action.name,
            hidden: action.hidden,
            asMark: action.asMark,
          });
        default:
          return state;
      }
    case SET_MARK_GUIDE_FORM_ID:
      return Object.assign({}, state, { id: action.id });
    case SET_MARK_GUIDE_FORM_NAMES:
      return Object.assign({}, state, { names: action.names });
    case UPDATE_MARK_GUIDE_CODE:
      const regexp = /^[A-Z0-9_-]*$/;
      if (regexp.test(action.code)) {
        return Object.assign({}, state, {
          code: action.code,
          visibleCodeError: false,
        });
      } else {
        return Object.assign({}, state, {
          code: action.code,
          visibleCodeError: true,
        });
      }
    case UPDATE_MARK_GUIDE_NAME:
      return Object.assign({}, state, {
        name: action.name,
        nameChg: action.changed,
      });
    case UPDATE_MARK_GUIDE_AS_MARK:
      return Object.assign({}, state, {
        asMark: action.asMark,
        asMarkChg: action.changed,
      });
    case UPDATE_MARK_GUIDE_LANGUAGES:
      return Object.assign({}, state, { languages: action.languages });
    case SET_MARK_GUIDE_HIDDEN:
      return Object.assign({}, state, {
        hidden: !state.hidden,
        hiddenChg: action.changed,
      });
    default:
      return state;
  }
}

export default markGuideForm;
