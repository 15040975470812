export const SET_CONDITION = 'SET_CONDITION';
export function setCondition(payload) {
    return {
        type: SET_CONDITION,
        payload
    }
}

export const UPDATE_CONDITION_FIELD = 'UPDATE_CONDITION_FIELD'
export function updateConditionField(payload) {
    return {
        type: UPDATE_CONDITION_FIELD,
        payload
    }
}

export const RESET_CONDITION_STATE = 'RESET_CONDITION_STATE'
export function resetCondition() {
    return {
        type: RESET_CONDITION_STATE,
    }
}

export const UPDATE_CONDITION_CHANGED_FIELDS = 'UPDATE_CONDITION_CHANGED_FIELDS'
export function updateConditionChangedFields(payload) {
    return {
        type: UPDATE_CONDITION_CHANGED_FIELDS,
        payload
    }
}

export const SET_CONDITION_FETCHING_STATUS = 'SET_CONDITION_FETCHING_STATUS'
export function setConditionFetchingStatus(payload = null) {
    return {
        type: SET_CONDITION_FETCHING_STATUS,
        payload
    }
}