import * as yup from "yup";

const messages = {
  required: "Поле не заполнено",
};

const specSchema = yup.object().shape({
  code: yup.string()
      .required(messages.required)
      .matches(
          /^[A-Z0-9-_]+$/,
          "Допускаются заглавные латинские символы, цифры, знаки _ -"
      ),
  name: yup.string().required(messages.required),
  producerCode: yup.string().nullable(),
  plasId: yup.string().nullable(),
  olyaId: yup.string().nullable(),
  description1: yup.string().nullable(),
  description2: yup.string().nullable(),
  hidden: yup.boolean(),
});

export default specSchema;