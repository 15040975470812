import React from "react";
import "./index.scss";
import DropdownSelect from "../../../pureComponents/dropdownSelect";
import FormInputField from "../../../pureComponents/formInputField";

const MultiLanguageItem = React.memo(
  ({
    item,
    multiLanguageFormData,
    multiLanguageFormErrors,
    updateLanguage,
    updateLanguageName,
    deleteLanguageName,
  }) => {
    return (
      <div className="multi-language-item">
        <div className="multi-language-item__selector">
          <DropdownSelect
            id={item.id + "-lang"}
            label="Язык"
            defaultValue={item.lang}
            items={item.languageList}
            onSelect={updateLanguage}
          />
        </div>

        <FormInputField
          id={item.id + "-value"}
          label="Наименование на выбранном языке"
          placeholder="Введите"
          value={item.name}
          onChange={updateLanguageName}
          error={multiLanguageFormErrors[item.id]}
        />
        <div className="multi-language-item__button">
          <button
            type="button"
            className="button button__negative button__negative__outline"
            disabled={multiLanguageFormData.length === 1}
            onClick={() => deleteLanguageName(item)}
          >
            Удалить элемент
          </button>
        </div>
      </div>
    );
  }
);

export default MultiLanguageItem;
