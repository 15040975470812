import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConditionIntervals } from "../redux/actions/async/conditionIntervals";

function useConditionIntervals(conditionCode) {
  const dispatch = useDispatch();
  const { conditionIntervals, fetchingStatus } = useSelector((state) => ({
    conditionIntervals: state.conditionIntervals.list.filter(
      (item) => item.actionType !== "D"
    ),
    fetchingStatus: state.conditionIntervals.fetchingStatus,
  }));

  useEffect(() => {
    if (!conditionCode) return;

    dispatch(getConditionIntervals(conditionCode));
  }, [conditionCode]);

  return {
    conditionIntervals,
    fetchingStatus,
  };
}

export default useConditionIntervals;
