const defState = { 
    list: ['Поиск', 'Продукт'], 
    current: 'def' 
};

function breadCrumb(state = defState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default breadCrumb;
