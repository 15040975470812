export const RECEIVE_MODEL_LIST = 'RECEIVE_MODEL_LIST';
export function receiveModelList(payload) {
  return {
    type: RECEIVE_MODEL_LIST,
    payload,
  };
}

export const CLEAR_MODEL_LIST = 'CLEAR_MODEL_LIST';
export function clearModelList() {
  return {
    type: CLEAR_MODEL_LIST,
  };
}

export const UPDATE_MODEL_LIST = 'UPDATE_MODEL_LIST';
export function updateModelList(id) {
  return {
    type: UPDATE_MODEL_LIST,
    id,
  };
}
