import React from 'react';
import './index.css';

export function CategoryCard(props) {
  let categoryImage = '';
  switch (props.category) {
    case '1':
      categoryImage = ' category-card__image_car';
      break;
    case '2':
      categoryImage = ' category-card__image_motorcicle';
      break;
    case '3':
      categoryImage = ' category-card__image_track';
      break;
    case '4':
      categoryImage = ' category-card__image_spectech';
      break;
    case '5':
      categoryImage = ' category-card__image_sudovoe';
      break;
    case '6':
      categoryImage = ' category-card__image_prom';
      break;
    default:
      break;
  }
  return (
    <div className="category-card">
      <div className="category-card__image-wrapper">
        <div className={'category-card__image' + categoryImage}></div>
      </div>
    </div>
  );
}
