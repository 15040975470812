import React from 'react';
import './index.css';

export function Waiter(props) {
  return (
    <div className="waiter-wrapper">
      <div className={"waiter waiter_" + props.size}></div>
    </div>
  );
}
