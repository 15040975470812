import React, { useRef, useEffect } from 'react';
import { Waiter } from '../../components/waiter';
import './index.scss';
import VisibleNoData from '../../redux/containers/visibleNoData';

export function Table(props) {
  const ref = useRef(null)

  const handleScrollToRow = () => {
    ref.current.scrollIntoView({block: "nearest"});
  }
 

  const columns = generateTableHeader(props.data);
  const rows = generateTableRows(props.data, props.zebra);

  useEffect(()=>{
    if(ref.current && rows){
      handleScrollToRow()
    }
  },[ref.current, rows])

  const TableWrapperMod =
    props.data.columns.length === 0 ? ' table-wrapper_blank' : '';

  function selectTableRow(e) {
    const id = e.target.getAttribute('data-row_id');
    props.selectTableRow(id);
    if( props.data.current === 28 ) { // Костыль написан для добавлкния дополнительного параметра в редактирование кроме кода объекта из строки. 28 - код меню
      const code = props.data.body.find((item) => item.code === id).modelComplectCode;
      props.setModelComplectCode( code );
    }
  }

  function isEvenRow(num, zebra) {
    if (!zebra) return true;
    return num % 2 === 0;
  }

  function isActiveRow(num, data) {
    return data.currentRow === num;
  }

  function isHiddenRow(str) {
    return str.hidden;
  }

  function generateTableRows(data, zebra = false) {
    return data.body.map((str, i) => {
      const id = str.code;
      const darkRow = isEvenRow(i, zebra) ? '' : ' table__td_dark';
      const activeRow = isActiveRow(id, data) ? ' table__td_active' : '';
      const hiddenRow = isHiddenRow(str) ? ' table__td_hidden' : '';
      const trProps = activeRow ? { ref: ref } : {};

      const tr_content = data.columns.map((col, i) => (
        <td
          className={'table__td' + darkRow + activeRow + hiddenRow}
          key={i}
          data-row_id={id}
          onClick={selectTableRow}
        >
          {getStrValue(data, str, col.code)}
        </td>
      ));
      return (
        <tr key={i} className="table__row" {...trProps}>
          {tr_content}
        </tr>
      );
    });   
  }

  function getStrValue(data, str, code){
    if(data.columns.filter(el=>el.code === code)[0].fieldType === "boolean"){
      return str[code]?"Да":"Нет";
    }else{
      return str[code];
    }
  }

  function generateTableHeader(data) {
    const cl = 'table__th';
    const res = data.columns.map((el, i) => (
      <th className={cl} key={i}>
        {el.name}
      </th>
    ));

    return res;
  }

  if (props.data.current === 'def') {
    return (
      <div className={'table-wrapper' + TableWrapperMod}>
        <VisibleNoData id={props.id} />
      </div>
    );
  }

  return (
    <>
      {props.data.fetching ? <Waiter size="large" />
      :
      <div className={'table-wrapper' + TableWrapperMod}>
          <table>
            <thead>
              <tr>{columns}</tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
      </div>}
    </>
  );
}
