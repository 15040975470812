function getNewFileName(file, files, index = 1) {
  const newFilename = `${file.name} (${index})`;

  if (files.some((item) => item.fileName === newFilename)) {
    return getNewFileName(file, files, index + 1);
  }

  return newFilename;
}

export default getNewFileName;
