import { getHTTPData } from "../../utils/getData";

export const API = {
	getCategories: function (body) {
		return getHTTPData("api/v1/model-category/filter", 'POST', body );
	},
	getSubcategories: function (body) {
		return getHTTPData("api/v1/model-subcategory/filter", 'POST', body );
	},
	getMarks: function (body) {
		return getHTTPData("api/v1/model-mark/filter", 'POST', body );
	},
	getModel: function (body) {
		return getHTTPData("api/v1/model-generation/filter ", 'POST', body );
	}
}
