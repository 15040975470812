import { fetchFromApi } from "../service";
import { receiveModelBodyList } from "../sync/modelBody";

export function requestModelBodyList() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/model-body/filter/page";

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(receiveModelBodyList(data.data));
    } catch (error) {
      console.log(error.message);
    }
  };
}