import {
  CHANGE_HIDDEN_MODE,
  CLEAR_PREW_GUIDE,
  CLEAR_SEARCH_VALUES_LIST,
  DROP_MODEL_COMPLECT_CODE,
  LOAD_SEARCH_VALUES_LIST,
  RECIVE_GUIDE_TABLE_BODY,
  RECIVE_GUIDE_TABLE_COLUMNS,
  RESET_GUIDES_PAGE,
  SET_CURRENT_GUIDE,
  SET_LIMIT,
  SET_OFFSET,
  SET_PAGE,
  SET_SEARCH_STRING,
  SET_SEARCH_VALUE,
  SWITCH_GROUP_WISABLE,
  SWITCH_MAIN_MENU_VISIBLE,
  SWITCH_SEARCH_CHECKBOX,
  WAIT_FOR_GUIDE_DATA,
} from '../actions/sync/guides';
import { SET_CURRENT_TABLE_ROW } from '../actions/sync/table';
import { RESET_APP } from '../actions/sync/init';
import { SET_EXPORT_DATA } from '../actions/sync/exportPattern';
import {RECIVE_COMPONENT_INFO, RECIVE_CONDITION_INFO} from '../actions/sync/component';

const defState = {
  current: 'def',
  currentGroup: 'def',
  exportData: null,
  list: [
    {
      id: 0,
      name: 'Транспортные средства',
      parent: null,
      collapsed: true,
      dataType: '',
      show: true,
      table: '',
    },
    {
      id: 11,
      name: 'Марка ТС',
      parent: 0,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'model_mark',
      filters: [],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 6,
      name: 'Модель ТС',
      parent: 0,
      collapsed: true,
      dataType: 'business',
      show: true,
      table: 'model',
      searchString: '',
      searchValue: 'modelMark',
      filters: [
        { id: 'category', name: 'Категория', type: 'selector', required: true },
      ],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: true,
        exportData: false,
        exportTemplate: true,
      },
    },
    {
      id: 8,
      name: 'Поколение ТС',
      parent: 0,
      collapsed: true,
      dataType: 'business',
      show: true,
      table: 'model_generation',
      filters: [
        { id: 'category', name: 'Категория', type: 'selector', required: true },
        { id: 'subcategory', name: 'Подкатегория', type: 'selector', required: false },
      ],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: true,
        exportData: false,
        exportTemplate: true,
      },
    },
    {
      id: 7,
      name: 'Модификация ТС',
      pageTitle: 'Техника',
      parent: 0,
      collapsed: true,
      dataType: 'business',
      show: true,
      deleteCascade: true,
      table: 'model_complect',
      filters: [
        { id: 'category', name: 'Категория', type: 'selector', required: true },
        { id: 'subcategory', name: 'Подкатегория', type: 'selector', required: false },
        { id: 'modelMark', name: 'Марка', type: 'selector', required: false, searchable: true },
        { id: 'model', name: 'Модель', type: 'selector', required: false, searchable: true },
        { id: 'engineCap', name: 'Объем двигателя', type: 'input', required: false },
        { id: 'years', name: 'Год', type: 'input', required: false }
      ],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: true,
        exportData: true,
        exportTemplate: true,
      },
    },
    {
      id: 9,
      name: 'Категория ТС',
      parent: 0,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'model_category',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 10,
      name: 'Класс евро',
      parent: 0,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'model_class',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 12,
      name: 'Подкатегория ТС',
      parent: 0,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'model_subcategory',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 13,
      name: 'Сегменты категории ТС',
      parent: 0,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'model_category_segment',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: false,
        hidden: false,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 14,
      name: 'Тип кузова',
      parent: 0,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'model_body',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 15,
      name: 'Тип привода',
      parent: 0,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'model_drive',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 1,
      name: 'Узлы ТС',
      parent: null,
      collapsed: true,
      dataType: '',
      show: true,
      table: '',
      service: '',
    },
    {
      id: 16,
      name: 'Узел ТС',
      pageTitle: 'Узлы',
      parent: 1,
      collapsed: true,
      dataType: 'business',
      deleteCascade: true,
      show: true,
      table: 'component',
      filters: [
        { id: 'category', name: 'Категория', type: 'selector', required: true },
        { id: 'subcategory', name: 'Подкатегория', type: 'selector', required: false },
        { id: 'modelMark', name: 'Марка', type: 'selector', required: false, searchable: true },
        { id: 'model', name: 'Модель', type: 'selector', required: false, searchable: true },
        { id: 'engineCap', name: 'Объем двигателя', type: 'input', required: false },
        { id: 'years', name: 'Год', type: 'input', required: false },
      ],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: false,
        history: true,
        hidden: true,
        importData: true,
        exportData: true,
        exportTemplate: true,
      },
    },
    {
      id: 17,
      name: 'Группа узла',
      parent: 1,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'component_group',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 19,
      name: 'Тип конструкции двигателя',
      parent: 1,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'component_engine_build',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 20,
      name: 'Тип охлаждения',
      parent: 1,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'component_cool_type',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 21,
      name: 'Тип топлива',
      parent: 1,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'component_fuel_type',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 22,
      name: 'Тип трансмиссии',
      parent: 1,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'component_gear_type',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 23,
      name: 'Тип турбины',
      parent: 1,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'component_turbo_type',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 24,
      name: 'Тип узла',
      parent: 1,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'component_type',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 2,
      name: 'Условия (рекомендации)',
      parent: null,
      collapsed: true,
      dataType: '',
      show: true,
      table: '',
      service: '',
    },
    {
      id: 28,
      name: 'Условие',
      parent: 2,
      collapsed: true,
      dataType: 'business',
      deleteCascade: true,
      show: true,
      table: 'condition',
      filters: [
        { id: 'category', name: 'Категория', type: 'selector', required: true },
        { id: 'subcategory', name: 'Подкатегория', type: 'selector', required: false },
        { id: 'modelMark', name: 'Марка', type: 'selector', required: false },
        { id: 'model', name: 'Модель', type: 'selector', required: false },
        { id: 'engineCap', name: 'Объем двигателя', type: 'input', required: false },
        { id: 'years', name: 'Год', type: 'input', required: false },
      ],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: false,
        history: true,
        hidden: true,
        importData: true,
        exportData: true,
        exportTemplate: true,
      },
    },
    {
      id: 29,
      name: 'Справочник интервалов',
      parent: 2,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'interval',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: true,
        exportTemplate: false,
      },
    },
    {
      id: 30,
      name: 'Температурные интервалы для вязкостей условия',
      parent: 2,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'condition_visco_interval',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 31,
      name: 'Тип действия для интервала использования',
      parent: 2,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'interval_action',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 32,
      name: 'Тип интервала',
      parent: 2,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'interval_type',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 33,
      name: 'Тип условия',
      parent: 2,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'condition_type',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 3,
      name: 'Продукт',
      parent: null,
      collapsed: true,
      dataType: '',
      show: true,
      table: '',
      service: '',
    },
    {
      id: 40,
      name: 'Продукт',
      pageTitle: 'Продукты',
      parent: 3,
      collapsed: true,
      dataType: 'business',
      deleteCascade: true,
      show: true,
      table: 'product',
      filters: [],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: true,
        exportData: true,
        exportTemplate: true,
      },
    },
    {
      id: 43,
      name: 'Бренд',
      parent: 3,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'product_brand',
      filters: [],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 44,
      name: 'Премиальность продукта',
      parent: 3,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'product_premium',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 45,
      name: 'Уровень соответствия спецификации продукта',
      parent: 3,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'product_spec_level',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 4,
      name: 'Вязкость/Спецификация',
      parent: null,
      collapsed: true,
      dataType: '',
      show: true,
      table: '',
      service: '',
    },
    {
      id: 46,
      name: 'Вязкость',
      parent: 4,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'visco',
      filters: [],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 48,
      name: 'Классификация вязкостей',
      parent: 4,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'visco_classification',
      filters: [],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 50,
      name: 'Спецификация',
      pageTitle: 'Спецификации',
      parent: 4,
      collapsed: true,
      dataType: '',
      deleteCascade: true,
      show: true,
      table: 'spec',
      filters: [],
      controls: {
        copy: true,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: true,
        exportData: false,
        exportTemplate: true,
      },
    },
    {
      id: 5,
      name: 'Общие справочники',
      parent: null,
      collapsed: true,
      dataType: '',
      show: true,
      table: '',
      service: '',
    },
    {
      id: 51,
      name: 'Производитель',
      parent: 5,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'producer',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 52,
      name: 'Рынок',
      parent: 5,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'market',
      filters: [],
      controls: {
        copy: false,
        edit: true,
        delete: true,
        add: true,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
    {
      id: 53,
      name: 'Сегмент',
      parent: 5,
      collapsed: true,
      dataType: '',
      show: true,
      table: 'segment',
      filters: [],
      controls: {
        copy: false,
        edit: false,
        delete: false,
        add: false,
        history: true,
        hidden: true,
        importData: false,
        exportData: false,
        exportTemplate: false,
      },
    },
  ],
  caption: 'Все справочники',
  columns: [],
  body: [],
  limitList: [25, 50, 75],
  limit: 25,
  offset: 0,
  rows: 0,
  activePage: 1,
  searchValueList: [],
  currentSearchValue: 'name',
  searchString: '',
  showMainMenu: false,
  currentRow: 'def',
  fetching: false,
  searchCheckbox: false,
  hidden: true,
  modelComplectCode: null,
  componentCode: null,
};

function guides(state = defState, action) {
  switch (action.type) {
    case SET_CURRENT_GUIDE:
      return Object.assign({}, state, {
        current: action.id,
        currentGroup: action.parent,
      });
    case CLEAR_PREW_GUIDE:
      return defState;
    case SWITCH_GROUP_WISABLE:
      return Object.assign({}, state, {
        list: state.list.map((el) => {
          if (action.id === el.id) {
            return Object.assign({}, el, { collapsed: !el.collapsed });
          }
          if (action.id === el.parent) {
            return Object.assign({}, el, { show: !el.show });
          }
          return el;
        }),
      });
    case RECIVE_GUIDE_TABLE_COLUMNS:
      return Object.assign({}, state, { columns: action.json });
    case RECIVE_GUIDE_TABLE_BODY:
      return Object.assign({}, state, {
        body: action.json.data,
        rows: action.json.rowCount,
      });
    case RECIVE_COMPONENT_INFO:
      return Object.assign({}, state, {
        modelComplectCode: action.json.modelComplectCode,
      });
    case RECIVE_CONDITION_INFO:
      return Object.assign({}, state, {
        componentCode: action.json.componentCode,
      });
    case DROP_MODEL_COMPLECT_CODE:
      return Object.assign({}, state, {
        modelComplectCode: null,
        componentCode: null,
      });
    case SET_LIMIT:
      return Object.assign({}, state, { limit: action.limit });
    case SET_PAGE:
      return Object.assign({}, state, { activePage: action.page });
    case SET_OFFSET:
      return Object.assign({}, state, {
        offset: state.limit * (state.activePage - 1),
      });
    case SET_SEARCH_VALUE:
      return Object.assign({}, state, { currentSearchValue: action.value ?? 'def' });
    case SET_SEARCH_STRING:
      return Object.assign({}, state, { searchString: action.value });
    case SWITCH_SEARCH_CHECKBOX:
      return Object.assign({}, state, { searchCheckbox: action.checked });
    case SWITCH_MAIN_MENU_VISIBLE:
      return Object.assign({}, state, { showMainMenu: action.mode });
    case SET_CURRENT_TABLE_ROW:
      if (action.table === 'guides') {
        return Object.assign({}, state, { currentRow: action.id });
      } else {
        return state;
      }
    case WAIT_FOR_GUIDE_DATA:
      return Object.assign({}, state, { fetching: action.mode });
    case LOAD_SEARCH_VALUES_LIST:
      return Object.assign({}, state, {
        searchValueList: state.columns.filter((el) => el.inFilter),
      });
    case CLEAR_SEARCH_VALUES_LIST:
      return Object.assign({}, state, { searchValueList: [] });
    case SET_EXPORT_DATA:
      return Object.assign({}, state, { exportData: action.data });
    case CHANGE_HIDDEN_MODE:
      return Object.assign({}, state, { hidden: !state.hidden });
    case RESET_GUIDES_PAGE:
      return defState;
    case RESET_APP:
      return defState;
    default:
      return state;
  }
}

export default guides;
