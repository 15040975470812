import { getHTTPData } from "../../utils/getData";
import {fetchFromApi} from "../../redux/actions/service";

export const API = {
	conditionType: "api/v1/condition-type/",
	save:  "api/v1/condition-type",
	code:  "api/v1/dict/code",
	dictionary: "api/v1/dictionary/LANG/locale/",

	getConditionType: function (str) {
		return getHTTPData(this.conditionType + str, 'GET');
	},
	getSubCategory: function (body) {
		return getHTTPData("api/v1/model-subcategory/filter", 'POST', body);
	},
	getMark: function (body) {
		return getHTTPData("api/v1/model-mark/wide-filter/page", 'POST', body);
	},
	getComponentType: function (body) {
		return getHTTPData("api/v1/component-type/wide-filter", 'POST', body);
	},
	getCode: function () {
		return fetchFromApi("api/v1/dict/code","GET",{})
			.then((res) => { return res.text() })
	},
	saveChanges: function (body) {
		return getHTTPData(this.save, 'PUT', body);
	}
}
