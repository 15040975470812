import React from "react";

const DatePickerIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="#777B92"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0.5C11.7761 0.5 12 0.723858 12 1V2.5H13.5C14.3284 2.5 15 3.17157 15 4V7V14C15 14.8284 14.3284 15.5 13.5 15.5H3.5C2.67157 15.5 2 14.8284 2 14V7V4C2 3.17157 2.67157 2.5 3.5 2.5H5V1C5 0.723858 5.22386 0.5 5.5 0.5C5.77614 0.5 6 0.723858 6 1V2.5H11V1C11 0.723858 11.2239 0.5 11.5 0.5ZM5 3.5V4C5 4.27614 5.22386 4.5 5.5 4.5C5.77614 4.5 6 4.27614 6 4V3.5H11V4C11 4.27614 11.2239 4.5 11.5 4.5C11.7761 4.5 12 4.27614 12 4V3.5H13.5C13.7761 3.5 14 3.72386 14 4V6.5H3V4C3 3.72386 3.22386 3.5 3.5 3.5H5ZM3 7.5H14V14C14 14.2761 13.7761 14.5 13.5 14.5H3.5C3.22386 14.5 3 14.2761 3 14V7.5ZM5.5 12C6.05228 12 6.5 11.5523 6.5 11C6.5 10.4477 6.05228 10 5.5 10C4.94772 10 4.5 10.4477 4.5 11C4.5 11.5523 4.94772 12 5.5 12ZM8 11C8 10.7239 8.22386 10.5 8.5 10.5H11.5C11.7761 10.5 12 10.7239 12 11C12 11.2761 11.7761 11.5 11.5 11.5H8.5C8.22386 11.5 8 11.2761 8 11Z"
      />
    </svg>
  );
};

export default DatePickerIcon;
