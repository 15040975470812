const initialState = {
  productCode: null,
  data: [],
  fetchingStatus: null,
};

function productModelSubcategories(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_MODEL_SUBCATEGORIES:
      return { ...state, data: action.payload };
    case SET_PRODUCT_MODEL_SUBCATEGORIES_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_PRODUCT_MODEL_SUBCATEGORIES = "SET_PRODUCT_MODEL_SUBCATEGORIES";
const SET_PRODUCT_MODEL_SUBCATEGORIES_FETCHING_STATUS =
  "SET_PRODUCT_MODEL_SUBCATEGORIES_FETCHING_STATUS";

export const setProductModelSubcategories = (payload) => ({
  type: SET_PRODUCT_MODEL_SUBCATEGORIES,
  payload,
});

export const setProductModelSubcategoriesFetchingStatus = (payload) => ({
  type: SET_PRODUCT_MODEL_SUBCATEGORIES_FETCHING_STATUS,
  payload,
});

export default productModelSubcategories;
