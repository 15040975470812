import React from 'react';
import VisibleConditionTableBlock from '../../redux/containers/visibleConditionTableBlock';
import './index.css';

export function ConditionTable(props) {
  if (props.conditions.length === 0) {
    return null;
  }

  const blocks = props.conditions.map((el, i) => (
    <VisibleConditionTableBlock id={el} key={i} />
  ));

  return (
    <div className="condition-table">
      <div className="condition-table-header">
        <div className="condition-table-header__item condition-table-header__item_left">
          Спецификация
        </div>
        <div className="condition-table-header__item">
          Вязкость
        </div>
        <div className="condition-table-header__item">
          Интервал использования
        </div>
        <div className="condition-table-header__item condition-table-header__item_right">
          Продукт
        </div>
      </div>
      {blocks}
    </div>
  );
}
