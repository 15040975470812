import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import {
  resetSpecState,
  setSpec,
  setSpecFetchingStatus,
  updateSpecField,
} from "../../reducers/spec";
import { setActiveSpecMenuItem } from "../../reducers/specMenu";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getProducerList } from "./producer";
import { getSpecMenu } from "./specMenu";

export function getSpec(code, actionType, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const spec = state.spec;
    try {
      dispatch(resetSpecState());
      dispatch(setSpecFetchingStatus("loading"));
      const response = await fetchFromApi("api/v1/spec", "GET", {
        id: code,
      });

      if (!response.ok) {
        throw new HttpError(response.status, response.statusText);
      }

      const data = await response.json();
      const multiLanguageArray = data.name.value;
      const name = multiLanguageArray.find((item) => item.lang === "DEF").name;

      dispatch(
        setSpec({
          ...data,
          name,
          names: multiLanguageArray.filter((item) => item.lang !== "DEF"),
          changedFields:
            actionType === "copy"
              ? Object.keys(data).filter(
                  (key) => data[key] !== null && !Array.isArray(data[key])
                )
              : [],
          code: actionType === "edit" ? data.code : spec.code,
        })
      );

      dispatch(getProducerList(data.producerCode));
      if (callback) callback();

      dispatch(setSpecFetchingStatus());
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }

      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setSpecFetchingStatus());
    }
  };
}

export function putSpec(namesList, actionType) {
  return async (dispatch, getState) => {
    const state = getState();
    const spec = state.spec;
    const pathname = state.currentPath.path;

    try {
      dispatch(setSpecFetchingStatus("update"));
      const response = await fetchFromApi("api/v1/spec", "PUT", {
        body: {
          ...spec,
          name: namesList,
          id: actionType === "edit" ? spec.id : null,
        },
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getSpecMenu(spec.code));
      dispatch(setActiveSpecMenuItem(pathname));
      dispatch(setSpecFetchingStatus());
      dispatch(resetCurrentPageFormState());

      //reset changedFields
      dispatch(
        updateSpecField({
          changedFields: [],
        })
      );

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setSpecFetchingStatus());

      return false;
    }
  };
}

export function deleteSpecFromServer(id, callback = null) {
  return async (dispatch, getState) => {
    const body = {
      code: id,
      tableName: "spec",
    };

    try {
      dispatch(setSpecFetchingStatus("loading"));
      const response = await fetchFromApi("api/v1/dict/record", "DELETE", {
        body,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setSpecFetchingStatus());
    }
  };
}
