import { receiveModelTC } from "../sync/modelTC";
export function requestModelTC(active, type, id) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.id = id;
		dispatch(receiveModelTC([data]))
	};
}
