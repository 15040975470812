import React from "react";
import { useSelector } from 'react-redux'
import VerticalLine from "../../../../components/veritcalLine";
import FileUploader from "../../../../components/fileUploader";
import MultiLanguageForm from "../../../../components/multiLanguageForm";
import { getDateFromTimestamp } from "../../../../utils/getDate";
import { Waiter } from "../../../../components/waiter";
import FormInputField from "../../../../pureComponents/formInputField";
import DropdownSelect from "../../../../pureComponents/dropdownSelect";
import ToggleSwitch from "../../../../pureComponents/toggleSwitch";
import CodeInputField from "../../../../pureComponents/codeInputField";
import ModalLinkWrapper from "../../../../components/modalLinkWrapper";
import { TextAreaDescription } from "../../../../components/textAreaDescription";
import generateModelComplectLink from "../../../../utils/generateModelComplectLink";
import getNameFromList from "../../../../utils/getNameFromList";
import "./index.scss";
import YearPicker from "../../../../pureComponents/yearPicker";

const ModelComplectGeneralForm = ({
  currentLanguage,
  multiLanguageFormData,
  multiLanguageProps,
  selectorsData,
  modelComplectData,
  formErrors,
  searchLoading,
  handleDropdownSearch,
  getNewCode,
  handleDateChange,
  handleInputChange,
  handleDropdownChange,
  handleFileUpload,
  handleFileRemove,
  handleDownloadFile,
  handleDeleteModelComplect,
  openHistoryChanges,
  onSubmit,
  dataChanged
}) => {
  const searchStage = useSelector(state => state.search.stage);
  const isBackToListLinkType = searchStage !== "product"
  const backToProductLink = `/search?code=${modelComplectData.code}`


  if (modelComplectData.fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }
  return (
    <form onSubmit={onSubmit}>
      <fieldset
        className="model-complect-general__form"
        disabled={
          modelComplectData.isDocsFetching ||
          modelComplectData.fetchingStatus === "update"
        }
      >
        <div className="model-complect-general__header">
          <div className="main-header-block">
            <div className="main-header-block__item main-header-block__title">
              Данные о технике
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={modelComplectData.actionType !== "edit"}
              onClick={handleDeleteModelComplect}
            >
              <div
                className={`control__img control__img_trash${
                  modelComplectData.actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
              disabled={modelComplectData.actionType !== "edit"}
            >
              <div
                className={`control__img control__img_history${
                  modelComplectData.actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <div className="main-header-block__item main-header-block__code input-block input-block--flex">
              <CodeInputField
                id="code"
                label="Код"
                placeholder="Введите"
                value={modelComplectData.code}
                error={formErrors.code}
                onChange={handleInputChange}
                disabled={modelComplectData.actionType === "edit"}
                required={true}
              />
              <button
                type="button"
                className="button button__small button__primary"
                onClick={() => getNewCode()}
                disabled={modelComplectData.actionType === "edit"}
              >
                Создать код
              </button>
            </div>
            <VerticalLine size="24px" />
          </div>
          <div className="additional-header-block">
            <ModalLinkWrapper
              linkProps={{
                to: generateModelComplectLink(modelComplectData.code, {
                  type: "component",
                  code: "new",
                }),
                state: { actionType: "new" },
              }}
              className="main-header-block__item button button__small button__primary"
              disabled={modelComplectData.actionType !== "edit"}
            >
              Добавить узел
            </ModalLinkWrapper>
          </div>
        </div>
        <div className="main-form-block">
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <FormInputField
                id="name"
                label="Название"
                placeholder="Введите"
                value={modelComplectData.name || ""}
                error={formErrors.name}
                onChange={handleInputChange}
                required={true}
              />
              <DropdownSelect
                id="modelCategoryCode"
                label="Категория"
                error={formErrors.modelCategoryCode}
                defaultValue={getNameFromList(
                  selectorsData.categoryList,
                  modelComplectData.modelCategoryCode
                )}
                items={selectorsData.categoryList}
                onSelect={handleDropdownChange}
                required={true}
              />
              <DropdownSelect
                id="modelSubcategoryCode"
                label="Подкатегория"
                error={formErrors.modelSubcategoryCode}
                defaultValue={getNameFromList(
                  selectorsData.subcategoryList,
                  modelComplectData.modelSubcategoryCode
                )}
                items={selectorsData.subcategoryList}
                onSelect={handleDropdownChange}
                disabled={!modelComplectData.modelCategoryCode}
                required={true}
              />
              <DropdownSelect
                id="modelMarkCode"
                label="Марка"
                defaultValue={getNameFromList(
                  selectorsData.markList,
                  modelComplectData.modelMarkCode
                )}
                error={formErrors.modelMarkCode}
                items={selectorsData.markList}
                onSelect={handleDropdownChange}
                required={true}
                searchable={true}
                onSearch={handleDropdownSearch}
                isLoading={searchLoading.modelMarkCode}
                disabled={!modelComplectData.modelSubcategoryCode}
              />
              <DropdownSelect
                id="modelCode"
                label="Модель"
                defaultValue={getNameFromList(
                  selectorsData.modelList,
                  modelComplectData.modelCode
                )}
                error={formErrors.modelCode}
                items={selectorsData.modelList}
                onSelect={handleDropdownChange}
                required={true}
                searchable={true}
                onSearch={handleDropdownSearch}
                isLoading={searchLoading.modelCode}
                disabled={
                  !modelComplectData.modelSubcategoryCode ||
                  !modelComplectData.modelMarkCode
                }
              />
              <DropdownSelect
                id="modelGenerationCode"
                label="Поколение"
                defaultValue={getNameFromList(
                  selectorsData.generationList,
                  modelComplectData.modelGenerationCode
                )}
                error={formErrors.modelGenerationCode}
                items={selectorsData.generationList}
                onSelect={handleDropdownChange}
                required={true}
                disabled={!modelComplectData.modelCode}
              />
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <div className="main-form-block__row">
                <DropdownSelect
                  id="modelClassCode"
                  label="Класс экологичности"
                  defaultValue={getNameFromList(
                    selectorsData.classList,
                    modelComplectData.modelClassCode
                  )}
                  items={selectorsData.classList}
                  onSelect={handleDropdownChange}
                />
                <DropdownSelect
                  id="modelDriveCode"
                  label="Тип привода"
                  defaultValue={getNameFromList(
                    selectorsData.driveList,
                    modelComplectData.modelDriveCode
                  )}
                  items={selectorsData.driveList}
                  onSelect={handleDropdownChange}
                />
              </div>
              <DropdownSelect
                id="modelBodyCode"
                label="Тип кузова"
                defaultValue={getNameFromList(
                  selectorsData.bodyList,
                  modelComplectData.modelBodyCode
                )}
                items={selectorsData.bodyList}
                onSelect={handleDropdownChange}
              />
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <div className="main-form-block__row">
                <YearPicker
                  id="startYear"
                  label="Год (начало)"
                  placeholder="Выберите"
                  value={modelComplectData.startYear}
                  onChangeData={handleDateChange}
                  maxDate={modelComplectData.endYear}
                />
                <YearPicker
                  id="endYear"
                  label="Год (окончание)"
                  placeholder="Выберите"
                  value={modelComplectData.endYear}
                  onChangeData={handleDateChange}
                  minDate={modelComplectData.startYear}
                />
              </div>
              <TextAreaDescription
                id="description"
                label="Описание"
                value={modelComplectData.description || ""}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <div className="main-form-block__file-uploader-wrapper">
                {modelComplectData.isDocsFetching ? (
                  <Waiter size="large" />
                ) : (
                  <>
                    <FileUploader
                      onFileUpload={handleFileUpload}
                      disabled={modelComplectData.actionType !== "edit"}
                      isLoading={modelComplectData.isDocsFetching}
                    />
                    <ul className="file-uploader-list">
                      {modelComplectData.files.map((file) => {
                        return (
                          <li
                            key={file.id}
                            className="file-uploader-list__file-item"
                          >
                            <span className="file-icon"></span>
                            <button
                              type="button"
                              onClick={() =>
                                handleDownloadFile(file.id, file.fileName)
                              }
                            >
                              <span>
                                {file.fileName} от{" "}
                                {getDateFromTimestamp(
                                  file.changeDate,
                                  currentLanguage
                                )}
                              </span>
                            </button>
                            <button
                              type="button"
                              onClick={() => handleFileRemove(file.id)}
                              className="cross-icon"
                            ></button>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <MultiLanguageForm
                multiLanguageFormData={multiLanguageFormData}
                {...multiLanguageProps}
              />
            </div>
          </div>
        </div>
        <div className="model-complect-general__footer">
          <div className="main-footer-block__toggles">
            <ToggleSwitch
              checked={modelComplectData.hidden}
              id="hidden"
              label="Скрыть"
              onToggle={handleInputChange}
            />
            <ToggleSwitch
              checked={modelComplectData.verified}
              id="verified"
              label="Проверенный"
              onToggle={handleInputChange}
            />
          </div>
          <div className="main-footer-block__buttons">
          {isBackToListLinkType ?
          <ModalLinkWrapper
              linkProps={{ to: "/techniks", state: { pageCode: 7, withoutReset: true } }}
              className="button button__secondary button__secondary__ghost"
            >
             Вернуться к списку ТС
            </ModalLinkWrapper>
            : 
            <ModalLinkWrapper
              linkProps={{ to: backToProductLink}}
              className="button button__secondary button__secondary__ghost"
            >
              Вернуться к ТС
            </ModalLinkWrapper>}
            <button
              type="submit"
              className="button button__primary"
              disabled={!dataChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default ModelComplectGeneralForm;
