import { getModelMarks } from "../../../redux/actions/async/modelMark";
import {
  deleteProductModelMarkFromServer,
  getProductModelMark,
  putProductModelMark,
} from "../../../redux/actions/async/productModelMark";
import { setProductModelMark } from "../../../redux/reducers/productModelMark";

const modelMark = {
  headerTitle: "Добавление Марок ТС",
  list: "modelMark",
  productList: "productModelMark",
  option: "model-mark",
  optionName: "Марка ТС",
  historyTableName: "product_model_mark",
  searchable: true,
  getList: (searchStr = null, callback = null) => getModelMarks(searchStr, callback),
  getData: (productCode) => getProductModelMark(productCode),
  setData: (productModelMarks) =>
    setProductModelMark(productModelMarks),
  deleteData: (productCode, callback) =>
    deleteProductModelMarkFromServer(productCode, callback),
  putData: () => putProductModelMark(),
};

export default modelMark;