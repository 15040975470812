import { getHTTPData } from "../../utils/getData";
import {fetchFromApi} from "../../redux/actions/service";

export const API = {
	getModel: function (str) {
		return getHTTPData("api/v1/model/" + str, 'GET');
	},
	getCategory: function (body) {
		return getHTTPData("api/v1/model-category/filter/page", 'POST', body);
	},
	getSubCategory: function (body) {
		return getHTTPData("api/v1/model-subcategory/filter", 'POST', body);
	},
	getMark: function (body) {
		return getHTTPData("api/v1/model-mark/wide-filter/page", 'POST', body);
	},
	getCode: function () {
		const token = localStorage.getItem(`access_token`);
		return fetchFromApi("api/v1/dict/code","GET",{token})
			.then((res) => { return res.text() })
	},
	saveChanges: function (body) {
		return getHTTPData("api/v1/model", 'PUT', body);
	}
}
