import normalizeHistoryData from "../../../normalizers/historyData";
import { fetchFromApi } from "../service";
import { receiveHistoryChanges, setHistoryDataLoading } from "../sync/historyChanges";
import { tableNames } from '../../../consts'
export function requestHistoryChanges(tableName, rowCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const urls = [
      { url: "api/v1/dict/history", body: { lang, tableName, rowCode } },
      {
        url: "api/v1/column/list/",
        body: { lang, tableName, listType: "hist" },
      },
    ];

    try {
      dispatch(setHistoryDataLoading(true));
      const vocabularyResponse = await fetchFromApi(
        `api/v1/dictionary/YN/locale/${lang}`,
        "GET",
        {}
      );
      const vocabulary = await vocabularyResponse.json();

      const response = await Promise.all(
        urls.map((item) =>
          fetchFromApi(item.url, "POST", { body: item.body })
        )
      );  

      const responseData = await Promise.all(
        response.map((data) => data.json())
      );

      const [data, columns] = responseData;

      const decouplingTableNames = Array.from(new Set(data.history.map(table => {
        if(table.tableName !== tableName){
          return table.tableName
        }
      }).filter(column => column)))


      const decouplingTableColumsResponse = await Promise.all(
        decouplingTableNames.map((tableName) =>
          fetchFromApi('api/v1/column/list/', "POST", { body: { lang, tableName: tableName, listType: "hist" } })
        )
      );  
      
      const decouplingTableColumsData = await Promise.all(
        decouplingTableColumsResponse.map((data) => data.json())
      );


      const decouplingTableColumsDataWithParentTable = decouplingTableColumsData.map((table, i)=>table.map(column=>{
        const decouplingTableName = tableNames[decouplingTableNames[i]] || decouplingTableNames[i]
        return {
        ...column,
        decouplingTable: decouplingTableName,
        colspan: table.length
        }}
      ))
      

      const normalizedHistoryData = normalizeHistoryData(data, lang, vocabulary)

      dispatch(
        receiveHistoryChanges({
          columns: !decouplingTableColumsDataWithParentTable.length ? columns : [...columns,  ...decouplingTableColumsDataWithParentTable.flat()],
          list: normalizedHistoryData,
        } 
      )
      );
      dispatch(setHistoryDataLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
}
