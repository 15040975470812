import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ViscosItem from "./viscosItem";
import VerticalLine from "../../components/veritcalLine";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import { Waiter } from "../../components/waiter";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import useConditionViscos from "../../hooks/useConditionViscos";
import generateModelComplectLink from "../../utils/generateModelComplectLink";
import parseInteger from "../../utils/parseInteger";
import conditionViscosSchema from "../../validations/conditionViscos";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import { addConditionOption } from "../../redux/reducers/modelComplectMenu";
import {
  deleteConditionViscosFromServer,
  putConditionViscos,
} from "../../redux/actions/async/conditionViscos";
import { addNewConditionVisco } from "../../redux/reducers/conditionViscos";
import { getConditionViscoIntervalSelector } from "../../redux/actions/async/viscoSelectors";
import "./index.scss";

const ConditionViscosPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modelComplectCode, conditionCode } = useParams();
  const { state } = useLocation();
  const componentCode = state.componentCode;

  const searchStage = useSelector(state => state.search.stage);
  const isBackToListLinkType = searchStage !== "product"
  const backToProductLink = `/search?code=${modelComplectCode}`

  const menuIsLoaded = useSelector(
    (state) => state.modelComplectMenu.modelComplectCode !== ""
  );
  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const { conditionViscos, fetchingStatus } = useConditionViscos(conditionCode);
  const isDeleteButtonDisabled = conditionViscos.every(
    (item) => item.actionType === "C"
  );

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  useEffect(() => {
    dispatch(getConditionViscoIntervalSelector());
  }, []);

  useEffect(() => {
    if (menuIsLoaded) {
      dispatch(
        addConditionOption({
          componentCode,
          conditionCode,
          option: "visco",
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitViscos));
  }, [JSON.stringify(conditionViscos)]);

  const handleDeleteConditionViscos = useCallback(() => {
    setDeleteModalActive(true);
  }, []);

  const deleteConditionViscos = () => {
    dispatch(
      deleteConditionViscosFromServer(conditionCode, () => {
        navigate(
          generateModelComplectLink(modelComplectCode, {
            type: "condition",
            code: conditionCode,
          }),
          {
            state: {
              actionType: "edit",
            },
          }
        );
      })
    );
    setDeleteModalActive(false);
  };

  function addNewVisco() {
    dispatch(addNewConditionVisco());

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitViscos() {
    if (conditionViscos.length === 0) {
      navigate(
        generateModelComplectLink(modelComplectCode, {
          type: "condition",
          code: conditionCode,
        }),
        {
          state: {
            actionType: "edit",
          },
        }
      );
    }

    if (conditionViscos.every((visco) => visco.actionType === "D")) {
      dispatch(
        deleteConditionViscosFromServer(conditionCode, () => {
          navigate(
            generateModelComplectLink(modelComplectCode, {
              type: "condition",
              code: conditionCode,
            }),
            {
              state: {
                actionType: "edit",
              },
            }
          );
        })
      );
    }
    const isFormValid = await conditionViscosSchema.isValid(conditionViscos, {
      abortEarly: false,
    });
    if (isFormValid) {
      return dispatch(putConditionViscos());
    } else {
      if (!isFormValid) {
        conditionViscosSchema
          .validate(conditionViscos, { abortEarly: false })
          .catch((err) => {
            const errors = err.inner.reduce((acc, error) => {
              const [index, field] = error.path.split(".");
              const id = conditionViscos[parseInteger(index)].id;
              return {
                ...acc,
                [id]: {
                  ...acc[id],
                  [field]: error.message,
                },
              };
            }, {});

            setErrors(() => {
              return {
                ...errors,
              };
            });
          });
      }
      return false;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    await submitViscos();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="condition-viscos__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="condition-viscos__header">
            <div className="main-header-block__item main-header-block__title">
              Добавление вязкости
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteConditionViscos}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              disabled={isDeleteButtonDisabled}
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__column">
              <div className="main-form-block__column__block">
                {conditionViscos.map((item) => {
                  return (
                    <div className="main-form-block__row" key={item.id}>
                      <ViscosItem
                        {...item}
                        error={formErrors[item.id]}
                        setErrors={setErrors}
                      />
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="button button__secondary button__secondary__ghost"
                  onClick={addNewVisco}
                >
                  + Добавить вязкость
                </button>
              </div>
            </div>
          </div>
          <div className="condition-viscos__footer">
          {isBackToListLinkType ?
            <ModalLinkWrapper
            linkProps={{ to: "/guides", state: { pageCode: 28, withoutReset: true } }}
                className="button button__secondary button__secondary__ghost"
              >
              Вернуться к списку ТС
              </ModalLinkWrapper>
              : 
              <ModalLinkWrapper
                linkProps={{ to: backToProductLink}}
                className="button button__secondary button__secondary__ghost"
              >
               Вернуться к ТС
              </ModalLinkWrapper>}

            <button
              type="submit"
              className="button button__primary"
              disabled={!dataChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteConditionViscos}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"condition_visco"}
        rowCode={conditionCode}
        listType={"hist"}
      />
    </>
  );
};

export default ConditionViscosPage;
