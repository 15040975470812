import React from 'react';
import { SideElement } from '../sideElement';
import './index.scss';

export function Side(props) {
  const sideContent = props.data.list.map((el, i) => (
    <SideElement
      key={i}
      data={el}
      current={props.data.current}
      currentGroup={props.data.currentGroup}
      setCurrentGuide={props.setCurrentGuide}
      switchGroupVisable={props.switchGroupVisable}
    />
  ));
  return (
    <div className="side">
      <div className="side-header">
        <div className="side-header__caption">{props.data.caption}</div>
        <div className="side-header__img"></div>
      </div>
      <div className="side-content">{sideContent}</div>
      <div className={'side__cover' + (props.access?'':'_active')}></div>
    </div>
  );
}
