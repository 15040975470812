import { RESET_APP } from "../actions/sync/init";
import {
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  CLEAR_SEARCH_RESULT,
} from "../actions/sync/search";
import {
  CLEAR_SUBCATEGORY,
  CLEAR_SUBCATEGORY_LIST,
  RECIVE_SUBCATEGORIES,
  RECIVE_SUBCATEGORY_LIST,
  SET_DEFAULT_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
} from "../actions/sync/subcategory";

const defState = { current: "def", list: [], modelComplectPageList: [] };

function subcategory(state = defState, action) {
  switch (action.type) {
    case RECIVE_SUBCATEGORIES:
      if (action.data.length === 1) {
        return Object.assign({}, state, {
          list: action.data,
          current: action.data.code,
        });
      } else {
        return Object.assign({}, state, {
          list: action.data,
        });
      }
    case RECIVE_SEARCH_BY_STRING_RESULT:
      if (Object.keys(action.json).length === 0) {
        return state;
      }
      return Object.assign({}, state, {
        list: action.json.modelSubcategory,
        current: "def",
      });
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      if (Object.keys(action.json).length === 0) {
        return state;
      }
      if (action.json.modelSubcategory.length === 1) {
        return Object.assign({}, state, {
          list: action.json.modelSubcategory,
          current: action.json.modelSubcategory[0].code,
        });
      }
      return Object.assign({}, state, { list: action.json.modelSubcategory });
    case UPDATE_SUBCATEGORY:
      return Object.assign({}, state, { current: action.id });
    case SET_DEFAULT_SUBCATEGORY:
      return Object.assign({}, state, { current: state.list[0].code });
    case RECIVE_SUBCATEGORY_LIST:
      return Object.assign({}, state, {
        modelComplectPageList: action.payload,
      });
    case CLEAR_SUBCATEGORY_LIST:
      return Object.assign({}, state, { modelComplectPageList: [] });
    case CLEAR_SEARCH_RESULT:
      return defState;
    case RESET_APP:
      return defState;
    case CLEAR_SUBCATEGORY:
      return defState;
    default:
      return state;
  }
}

export default subcategory;
