export const RECIVE_MODEL_MARK = "RECIVE_MODEL_MARK";
export function receiveModelMark(json){
    return{
        type: RECIVE_MODEL_MARK,
        json
    }
}

export const RECIVE_MODEL_MARK_LIST = "RECIVE_MODEL_MARK_LIST";
export function receiveModelMarkList(payload){
    return{
        type: RECIVE_MODEL_MARK_LIST,
        payload
    }
}

export const CLEAR_MODEL_MARK = "CLEAR_MODEL_MARK";
export function clearModelMark(){
    return {
        type:CLEAR_MODEL_MARK
    }
}

export const CLEAR_MODEL_MARK_LIST = "CLEAR_MODEL_MARK_LIST";
export function clearModelMarkList(){
    return {
        type:CLEAR_MODEL_MARK_LIST
    }
}

export const UPDATE_MODEL_MARK = "UPDATE_MODEL_MARK";
export function updateModelMark(id){
    return{
        type: UPDATE_MODEL_MARK,
        id
    }
}