import React from "react";
import { Outlet } from "react-router-dom";
import VisibleHeader from "../../redux/containers/visibleHeader";
import "./index.scss";
import VisibleNotificationMessage from "../../redux/containers/visibleNotificationMessage";

const Layout = () => {
  return (
    <div className="layout">
      <VisibleHeader />
      <div className="layout__content">
        <Outlet />
      </div>
      <VisibleNotificationMessage />
    </div>
  );
};

export default Layout;
