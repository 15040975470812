import {
  SET_MODEL_COMPLECT,
  UPDATE_MODEL_COMPLECT_FIELD,
  UPDATE_CHANGED_FIELDS,
  RESET_MODEL_COMPLECT_STATE,
  SET_DOCS_FETCHING,
  SET_MODEL_COMPLECT_FETCHING_STATUS,
} from "../actions/sync/modelComplect";

const initialState = {
  code: null,
  name: null,
  names: [],
  modelCategoryCode: null,
  modelSubcategoryCode: null,
  modelMarkCode: null,
  modelMark: null,
  modelCode: null,
  model: null,
  modelGenerationCode: null,
  modelGeneration: null,
  modelClassCode: null,
  modelClass: null,
  modelDriveCode: null,
  modelDrive: null,
  modelBodyCode: null,
  modelBody: null,
  startYear: null,
  endYear: null,
  description: null,
  hidden: false,
  verified: false,
  actionType: "new",
  changedFields: [],
  files: [],
  fetchingStatus: "",
  isDocsFetching: false
};

const fieldsForChange = [
  "name",
  "modelGenerationCode",
  "modelClassCode",
  "modelDriveCode",
  "modelBodyCode",
  "startYear",
  "endYear",
  "description",
  "hidden",
  "verified",
];

function modelComplect(state = initialState, action) {
  switch (action.type) {
    case SET_MODEL_COMPLECT:
      return Object.assign({}, state, action.payload);
    case UPDATE_MODEL_COMPLECT_FIELD:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CHANGED_FIELDS:
      const isFieldForUpdate = fieldsForChange.includes(action.payload);
      if (isFieldForUpdate) {
        const isFieldAlreadyMarkedForUpdate = state.changedFields.includes(action.payload);

        return {
          ...state,
          changedFields: isFieldAlreadyMarkedForUpdate
            ? [...state.changedFields]
            : [...state.changedFields, action.payload],
        };
      }
      return state;
    case SET_MODEL_COMPLECT_FETCHING_STATUS:
      return {
        ...state,
        fetchingStatus: action.payload,
      };
    case SET_DOCS_FETCHING:
      return {
        ...state,
        isDocsFetching: action.payload
      }
    case RESET_MODEL_COMPLECT_STATE:
      return initialState;
    default:
      return state;
  }
}

export default modelComplect;
