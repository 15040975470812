import { connect } from 'react-redux';
import { Description } from '../../components/description';
import { switchFullDescription } from '../actions/sync/description';

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.descriptions.by_id[ownProps.id].description,
    hidden: state.descriptions.by_id[ownProps.id].hidden,
    mode: state.descriptions.by_id[ownProps.id].visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchFullDescription: (id) => dispatch(switchFullDescription(id)),
  };
};

const VisibleDescription = connect(
  mapStateToProps,
  mapDispatchToProps
)(Description);

export default VisibleDescription;
