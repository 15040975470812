import { setComponentFuelTypeList } from "../../reducers/componentFuelType";
import { fetchFromApi } from "../service";
import { setComponentSelectors } from "../sync/componentSelectors";

export function getComponentSelectors() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const urls = [
      "api/v1/component-fuel-type/filter/page",
      "api/v1/component-gear-type/filter/page",
      "api/v1/component-turbo-type/filter/page",
      "api/v1/component-engine-build/filter/page",
      "api/v1/component-cool-type/filter/page",
    ];

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await Promise.all(
        urls.map((url) => fetchFromApi(url, "POST", { body }))
      );
      const responseData = await Promise.all(
        response.map((data) => data.json())
      );

      const [
        componentFuelTypeList,
        componentGearTypeList,
        componentTurboTypeList,
        componentEngineBuildList,
        componentCoolTypeList,
      ] = responseData.map((data) => data.data);

      dispatch(
        setComponentSelectors({
          componentFuelTypeList,
          componentGearTypeList,
          componentTurboTypeList,
          componentEngineBuildList,
          componentCoolTypeList,
        })
      );
    } catch (error) {}
  };
}

export function getComponentFuelTypeList() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/component-fuel-type/filter/page";

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });

      const responseData = await response.json();

      const componentFuelTypeList = responseData.data;

      dispatch(setComponentFuelTypeList(componentFuelTypeList));
    } catch (error) {}
  };
}
