import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import IntervalsItem from "./intervalsItem";
import VerticalLine from "../../components/veritcalLine";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import { Waiter } from "../../components/waiter";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import useConditionIntervals from "../../hooks/useConditionIntervals";
import conditionIntervalsSchema from "../../validations/conditionIntervals";
import parseInteger from "../../utils/parseInteger";
import generateModelComplectLink from "../../utils/generateModelComplectLink";
import { addConditionOption } from "../../redux/reducers/modelComplectMenu";
import { getIntervalSelectors } from "../../redux/actions/async/intervalSelectors";
import {
  deleteConditionIntervalsFromServer,
  putConditionIntervals,
} from "../../redux/actions/async/conditionIntervals";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import { addNewConditionInterval } from "../../redux/reducers/conditionIntervals";
import "./index.scss";

const ConditionIntervalsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modelComplectCode, conditionCode } = useParams();
  const { state } = useLocation();
  const componentCode = state.componentCode;
  const menuIsLoaded = useSelector(
    (state) => state.modelComplectMenu.modelComplectCode !== ""
  );
  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const searchStage = useSelector(state => state.search.stage);
  const isBackToListLinkType = searchStage !== "product"
  const backToProductLink = `/search?code=${modelComplectCode}`

  const { conditionIntervals, fetchingStatus } =
    useConditionIntervals(conditionCode);
  const isDeleteButtonDisabled = conditionIntervals.every(
    (item) => item.actionType === "C"
  );

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  useEffect(() => {
    dispatch(getIntervalSelectors());
  }, []);

  useEffect(() => {
    if (menuIsLoaded) {
      dispatch(
        addConditionOption({
          componentCode,
          conditionCode,
          option: "interval",
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitIntervals));
  }, [JSON.stringify(conditionIntervals)]);

  const handleDeleteConditionIntervals = useCallback(() => {
    setDeleteModalActive(true);
  }, []);

  const deleteConditionIntervals = () => {
    dispatch(
      deleteConditionIntervalsFromServer(conditionCode, () => {
        navigate(
          generateModelComplectLink(modelComplectCode, {
            type: "condition",
            code: conditionCode,
          }),
          {
            state: {
              actionType: "edit",
            },
          }
        );
      })
    );
    setDeleteModalActive(false);
  };

  function addNewInterval() {
    dispatch(addNewConditionInterval());

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitIntervals() {
    if (conditionIntervals.length === 0) {
      navigate(
        generateModelComplectLink(modelComplectCode, {
          type: "condition",
          code: conditionCode,
        }),
        {
          state: {
            actionType: "edit",
          },
        }
      );
    }

    if (conditionIntervals.every((interval) => interval.actionType === "D")) {
      dispatch(
        deleteConditionIntervalsFromServer(conditionCode, () => {
          navigate(
            generateModelComplectLink(modelComplectCode, {
              type: "condition",
              code: conditionCode,
            }),
            {
              state: {
                actionType: "edit",
              },
            }
          );
        })
      );
    }
    const isFormValid = await conditionIntervalsSchema.isValid(
      conditionIntervals,
      {
        abortEarly: false,
      }
    );
    if (isFormValid) {
      return dispatch(putConditionIntervals());
    } else {
      if (!isFormValid) {
        conditionIntervalsSchema
          .validate(conditionIntervals, { abortEarly: false })
          .catch((err) => {
            const errors = err.inner.reduce((acc, error) => {
              const [index, field] = error.path.split(".");
              const id = conditionIntervals[parseInteger(index)].id;
              return {
                ...acc,
                [id]: {
                  ...acc[id],
                  [field]: error.message,
                },
              };
            }, {});

            setErrors(() => {
              return {
                ...errors,
              };
            });
          });
      }
      return false;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    await submitIntervals();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="condition-intervals__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="condition-intervals__header">
            <div className="main-header-block__item main-header-block__title">
              Добавление интервалов
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteConditionIntervals}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              disabled={isDeleteButtonDisabled}
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__column">
              <div className="main-form-block__column__block">
                {conditionIntervals.map((item) => {
                  return (
                    <div className="main-form-block__row" key={item.id}>
                      <IntervalsItem
                        {...item}
                        error={formErrors[item.id]}
                        setErrors={setErrors}
                      />
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="button button__secondary button__secondary__ghost"
                  onClick={addNewInterval}
                >
                  + Добавить интервал
                </button>
              </div>
            </div>
          </div>
          <div className="condition-intervals__footer">
          {isBackToListLinkType ?
            <ModalLinkWrapper
            linkProps={{ to: "/guides", state: { pageCode: 28, withoutReset: true } }}
            className="button button__secondary button__secondary__ghost"
              >
              Вернуться к списку ТС
              </ModalLinkWrapper>
              : 
              <ModalLinkWrapper
                linkProps={{ to: backToProductLink}}
                className="button button__secondary button__secondary__ghost"
              >
               Вернуться к ТС
              </ModalLinkWrapper>}

            <button
              type="submit"
              className="button button__primary"
              disabled={!dataChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteConditionIntervals}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"condition_interval"}
        rowCode={conditionCode}
        listType={"hist"}
      />
    </>
  );
};

export default ConditionIntervalsPage;
