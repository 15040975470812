import { connect } from 'react-redux';
import { ConditionTableBlock } from '../../components/conditionTableBlock';

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.conditions.by_id[ownProps.id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const VisibleConditionTableBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionTableBlock);

export default VisibleConditionTableBlock;
