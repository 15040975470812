import { useSelector } from "react-redux";
import getNameFromList from "../../utils/getNameFromList";
import { Waiter } from "../../components/waiter";
import VerticalLine from "../../components/veritcalLine";
import CodeInputField from "../../pureComponents/codeInputField";
import FormInputField from "../../pureComponents/formInputField";
import MultiLanguageForm from "../../components/multiLanguageForm";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import { TextAreaDescription } from "../../components/textAreaDescription";
import DropdownSelect from "../../pureComponents/dropdownSelect";

const SpecPageForm = ({
  formErrors,
  searchLoading,
  actionType,
  specCode,
  getNewCode,
  multiLanguageFormData,
  multiLanguageProps,
  handleInputChange,
  handleDropdownChange,
  handleDropdownSearch,
  handleDeleteSpec,
  openHistoryChanges,
  onSubmit,
}) => {
  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);
  const specData = useSelector((state) => state.spec);
  const producerList = useSelector((state) => state.producer.list);

  if (specData.fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <form onSubmit={onSubmit}>
      <fieldset
        className="content-general__form"
        disabled={specData.fetchingStatus === "update"}
      >
        <div className="content-general__header">
          <div className="main-header-block">
            <div className="main-header-block__item main-header-block__title">
              Данные о спецификации
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={actionType !== "edit"}
              onClick={handleDeleteSpec}
            >
              <div
                className={`control__img control__img_trash${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
              disabled={actionType !== "edit"}
            >
              <div
                className={`control__img control__img_history${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <div className="main-header-block__item main-header-block__code input-block input-block--flex">
              <CodeInputField
                id="code"
                label="Код"
                placeholder="Введите"
                value={specData.code ?? ""}
                error={formErrors.code}
                onChange={handleInputChange}
                required={true}
                disabled={actionType === "edit"}
              />
              <button
                type="button"
                className="button button__small button__primary"
                onClick={() => getNewCode()}
                disabled={actionType === "edit"}
              >
                Создать код
              </button>
            </div>
            <VerticalLine size="24px" />
          </div>
          <div className="additional-header-block">
            <ModalLinkWrapper
              linkProps={{
                to: `/spec/${specCode}/spec-over`,
              }}
              className="main-header-block__item button button__small button__primary"
              disabled={actionType !== "edit"}
            >
              Добавить ПС
            </ModalLinkWrapper>
            <ModalLinkWrapper
              linkProps={{
                to: `/spec/${specCode}/spec-component-group`,
              }}
              className="main-header-block__item button button__small button__primary"
              disabled={actionType !== "edit"}
            >
              Добавить группу узла
            </ModalLinkWrapper>
          </div>
        </div>
        <div className="main-form-block">
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <FormInputField
                id="name"
                label="Название"
                placeholder="Введите"
                value={specData.name || ""}
                error={formErrors.name}
                onChange={handleInputChange}
                required={true}
              />
              <DropdownSelect
                label="Производитель"
                id="producerCode"
                items={producerList}
                defaultValue={getNameFromList(producerList, specData.producerCode)}
                onSelect={handleDropdownChange}
                onSearch={handleDropdownSearch}
                isLoading={searchLoading.producerCode}
                searchable
              />
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <div className="main-form-block__row">
                <FormInputField
                  label="Идентификатор в PLAS"
                  id="plasId"
                  value={specData.plasId || ""}
                  onChange={handleInputChange}
                />
                <FormInputField
                  label="Идентификатор в OLYA"
                  id="olyaId"
                  value={specData.olyaId || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <TextAreaDescription
                id="description1"
                label={"Описание 1"}
                value={specData.description1}
                onChange={handleInputChange}
              />
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <TextAreaDescription
                id={"description2"}
                label={"Описание 2"}
                value={specData.description2}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <MultiLanguageForm
                multiLanguageFormData={multiLanguageFormData}
                {...multiLanguageProps}
              />
            </div>
          </div>
        </div>
        <div className="content-general__footer">
          <div className="main-footer-block__toggles">
            <ToggleSwitch
              label="Скрыть"
              id="hidden"
              checked={specData.hidden}
              onToggle={handleInputChange}
            />
          </div>
          <div className="main-footer-block__buttons">
            <ModalLinkWrapper
              linkProps={{ to: "/specs", state: { pageCode: 50, withoutReset: true } }}
              className="button button__secondary button__secondary__ghost"
            >
              Вернуться к списку спецификаций
            </ModalLinkWrapper>
            <button
              type="submit"
              className="button button__primary"
              disabled={!dataChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default SpecPageForm;
