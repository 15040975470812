import React from 'react';
import './index.css';

export function BreadCrumbs(props) {

  function goHome() {
    props.resetSearch();
    props.setSearchStage('home')
  }

  function goSearch() {
    props.setSearchStage('search')
  }

  switch (props.searchStage) {
    case 'home':
      return <></>;
    case 'search':
      return (
        <div className="bread-crumbs">
          <div
            className="bread-crumbs__point bread-crumbs__point_home"
            onClick={goHome}
          ></div>
          <div className="bread-crumbs__separator"></div>
          <div className="bread-crumbs__point bread-crumbs__point_active">
            Поиск
          </div>
        </div>
      );
    case 'product':
      return (
        <div className="bread-crumbs">
          <div
            className="bread-crumbs__point bread-crumbs__point_home"
            onClick={goHome}
          ></div>
          <div className="bread-crumbs__separator"></div>
          <div className="bread-crumbs__point" onClick={goSearch}>
            Поиск
          </div>
          <div className="bread-crumbs__separator"></div>
          <div className="bread-crumbs__point bread-crumbs__point_active">
            Продукт
          </div>
        </div>
      );
    default:
      return <></>;
  }
}
