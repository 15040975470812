export const INIT_MARK_GUIDE_FORM = 'INIT_MARK_GUIDE_FORM';
export function initMarkGuideForm(mode, code, name, hidden, asMark) {
  return {
    type: INIT_MARK_GUIDE_FORM,
    mode,
    code,
    name,
    hidden,
    asMark,
  };
}

export const SET_MARK_GUIDE_FORM_ID = 'SET_MARK_GUIDE_FORM_ID';
export function setMarkGuideFormId(id) {
  return {
    type: SET_MARK_GUIDE_FORM_ID,
    id,
  };
}

export const SET_MARK_GUIDE_FORM_NAMES = 'SET_MARK_GUIDE_FORM_NAMES';
export function setMarkGuideFormNames(names) {
  return {
    type: SET_MARK_GUIDE_FORM_NAMES,
    names,
  };
}

export const UPDATE_MARK_GUIDE_CODE = 'UPDATE_MARK_GUIDE_CODE';
export function updateMarkGuideCode(code) {
  return {
    type: UPDATE_MARK_GUIDE_CODE,
    code,
  };
}

export const UPDATE_MARK_GUIDE_NAME = 'UPDATE_MARK_GUIDE_NAME';
export function updateMarkGuideName(name, changed) {
  return {
    type: UPDATE_MARK_GUIDE_NAME,
    name,
    changed,
  };
}

export const UPDATE_MARK_GUIDE_AS_MARK = 'UPDATE_MARK_GUIDE_AS_MARK';
export function updateMarkGuideAsMark(asMark, changed) {
  return {
    type: UPDATE_MARK_GUIDE_AS_MARK,
    asMark,
    changed,
  };
}

export const SET_MARK_GUIDE_HIDDEN = 'SET_MARK_GUIDE_HIDDEN';
export function setMarkGuideHidden(changed) {
  return {
    type: SET_MARK_GUIDE_HIDDEN,
    changed,
  };
}

export const UPDATE_MARK_GUIDE_LANGUAGES = 'UPDATE_MARK_GUIDE_LANGUAGES';
export function updateMarkGuideLanguages(languages) {
  return {
    type: UPDATE_MARK_GUIDE_LANGUAGES,
    languages,
  };
}

export const SHOW_MARK_GUIDE_FORM = 'SHOW_MARK_GUIDE_FORM';
export function showMarkGuideForm() {
  return {
    type: SHOW_MARK_GUIDE_FORM,
  };
}

export const CLOSE_MARK_GUIDE_FORM = 'CLOSE_MARK_GUIDE_FORM';
export function closeMarkGuideForm() {
  return {
    type: CLOSE_MARK_GUIDE_FORM,
  };
}

export const CLOSE_MARK_GUIDE_SAVE_FORM = 'CLOSE_MARK_GUIDE_SAVE_FORM';
export function closeMarkGuideSaveForm() {
  return {
    type: CLOSE_MARK_GUIDE_SAVE_FORM,
  };
}

export const DROP_MARK_GUIDE_FORM = 'DROP_MARK_GUIDE_FORM';
export function dropMarkGuideForm() {
  return {
    type: DROP_MARK_GUIDE_FORM,
  };
}
