import { deleteProductSegmentsFromServer, getProductSegments, putProductSegments } from "../../../redux/actions/async/productSegments";
import { getSegmentList } from "../../../redux/actions/async/segmentList";
import { setProductSegments } from "../../../redux/reducers/productSegments";


const segment = {
  headerTitle: "Добавление сегментов",
  list: "segmentList",
  productList: "productSegments",
  option: "segment",
  optionName: "Сегмент",
  historyTableName: "product_segment",
  searchable: false,
  getList: () => getSegmentList(),
  getData: (productCode) => getProductSegments(productCode),
  setData: (productSegments) => setProductSegments(productSegments),
  deleteData: (productCode, callback) =>
    deleteProductSegmentsFromServer(productCode, callback),
  putData: () => putProductSegments(),
};

export default segment;
