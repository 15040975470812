import { RECIVE_PRODUCT_INFO } from '../actions/sync/product';
import { SHOW_PRODUCT_LIST } from '../actions/sync/svProduct';

const defState = {
  by_id: {},
  all_ids: [],
  active: null,
};

function specVisco(state = defState, action) {
  switch (action.type) {
    case RECIVE_PRODUCT_INFO:
      return Object.assign({}, state, getSpecViscoFromInfo(action.json));
    case SHOW_PRODUCT_LIST:
      return Object.assign({}, state, {active: action.id});
    default:
      return state;
  }
}

function getSpecViscoFromInfo(info) {
  const res = {
    all_ids: [],
    by_id: {},
  };
  if( !info.component ) {
    return res;
  }
  info.component.forEach((comp, i) => {
    if (comp.condition !== undefined && comp.condition !== null) {
      comp.condition.forEach((cond, j) => {
        if (cond.specVisco !== undefined) {
          cond.specVisco.forEach((spec, h) => {
            res.all_ids.push('specVisco_' + i + '_' + j + '_' + h);
            res.by_id['specVisco_' + i + '_' + j + '_' + h] = {
              id: 'specVisco_' + i + '_' + j + '_' + h,
              spec: spec.spec,
              description: 'description_' + i + '_' + j + '_' + h,
              specHidden: spec.specHidden,
              visco: spec.visco,
              viscoHidden: spec.viscoHidden,
              interval: spec.interval,
              intervalHidden: spec.intervalHidden,
              products: getIds(spec.product, 'svproduct', i, j, h),
              showProducts: false,
            };
          });
        }
      });
    }
  });
  return res;
}

function getIds(data, caption, comp, cond, spec) {
  if (data === undefined) {
    return [];
  }
  return data.map(
    (el, i) => caption + '_' + comp + '_' + cond + '_' + spec + '_' + i
  );
}

export default specVisco;
