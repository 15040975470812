const initialState = {
  productCode: null,
  data: [],
  fetchingStatus: null,
};

function productModel(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_MODEL:
      return { ...state, data: action.payload };
    case SET_PRODUCT_MODEL_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_PRODUCT_MODEL = "SET_PRODUCT_MODEL";
const SET_PRODUCT_MODEL_FETCHING_STATUS =
  "SET_PRODUCT_MODEL_FETCHING_STATUS";

export const setProductModel = (payload) => ({
  type: SET_PRODUCT_MODEL,
  payload,
});

export const setProductModelFetchingStatus = (payload) => ({
  type: SET_PRODUCT_MODEL_FETCHING_STATUS,
  payload,
});

export default productModel;
