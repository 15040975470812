import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import SideTreeMenu from "../../../components/sideTreeMenu";
import { setCurrentPath } from "../../../redux/reducers/currentPath";
import { getProductMenu } from "../../../redux/actions/async/productMenu";
import {
  resetProductMenu,
  setActiveProductMenuItem,
} from "../../../redux/reducers/productMenu";
import {useDocumentTitle} from "../../../hooks/useDocumentTitle";

const ProductPageLayout = () => {
  const dispatch = useDispatch();
  const productMenu = useSelector((state) => state.productMenu);
  const { productCode } = useParams();
  const { pathname, state } = useLocation();
  const actionType = state?.actionType ? state.actionType : "new";

  useDocumentTitle('Продукты')

  const isProductPage =
    pathname === `/product/${productCode}` || pathname === `/product/`;

  useEffect(() => {
    dispatch(setCurrentPath(pathname));
  }, [pathname]);

  useEffect(() => {
    if (productCode && actionType !== "copy") {
      dispatch(getProductMenu(productCode));
    }

    return () => {
      dispatch(resetProductMenu());
    };
  }, [productCode]);

  useEffect(() => {
    if (pathname && productMenu.productCode !== "") {
      dispatch(setActiveProductMenuItem(pathname));
    }
  }, [pathname, productMenu.productCode]);

  return (
    <>
      <SideTreeMenu
        menu={productMenu}
        title={
          actionType === "edit" || !isProductPage
            ? `Редактирование продукта`
            : "Создание продукта"
        }
      />
      <section className="layout-wrapper">
        <Outlet />
      </section>
    </>
  );
};

export default ProductPageLayout;
