import { receiveViscoClass } from "../sync/viscoClass";
export function requestViscoClass(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveViscoClass([data]))
	};
}

