import {
  CLOSE_NOTIFICATION_MESSAGE,
  INIT_NOTIFICATION_MESSAGE,
  SHOW_NOTIFICATION_MESSAGE,
} from '../actions/sync/notificationMessage';

const defState = {
  visible: false,
  header: '',
  message: '',
  link: '',
  linkPath: '',
  mode: '',
};

function notificationMessage(state = defState, action) {
  switch (action.type) {
    case INIT_NOTIFICATION_MESSAGE:
      return Object.assign({}, state, {
        header: action.header,
        message: action.message,
        link: action.link,
        linkPath: action.linkPath,  
        mode: action.mode,
      });
    case SHOW_NOTIFICATION_MESSAGE:
      return Object.assign({}, state, { visible: true });
    case CLOSE_NOTIFICATION_MESSAGE:
      return Object.assign({}, state, { visible: false });
    default:
      return state;
  }
}

export default notificationMessage;
