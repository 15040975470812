import React from 'react';
import { useDispatch } from "react-redux";

import './index.css';
import {
  initNotificationMessage, closeNotificationMessage, showNotificationMessage,
} from "../../redux/actions/sync/notificationMessage";

export function SvProductListItemAlwaysVisible(props) {
  const dispatch = useDispatch();

  function copyData() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(props.data.name);
      dispatch(initNotificationMessage('Название продукта скопировано'));
      dispatch(showNotificationMessage());
      setTimeout(() => dispatch(closeNotificationMessage())
      , 2000);
    }
  }


  return (
    <div className={'product-list__item_inRow' + (props.data.hidden ? ' hidden' : '')} onClick={copyData}>
      <div className={'pl-item__caption pl-item__caption_inRow' + props.data.id}>
        {props.data.name}
      </div>
    </div>
  );
}
