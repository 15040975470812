import { connect } from 'react-redux';
import { Report } from '../../components/report';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const VisibleReport = connect(mapStateToProps, mapDispatchToProps)(Report);

export default VisibleReport;
