const initialState = {
  path: "",
};

function currentPath(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PATH:
      return { ...state, path: action.payload };
    default:
      return state;
  }
}

const SET_CURRENT_PATH = "SET_CURRENT_PATH";
export const setCurrentPath = (payload) => ({
  type: SET_CURRENT_PATH,
  payload,
});

export default currentPath
