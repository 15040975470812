const defState = {
  fileName: "",
  fetching: false,
  columns: [],
  dataFromDB: [],
  dataFromFile: [],
};

function importFile(state = defState, action) {
  switch (action.type) {
    case SET_IMPORT_FILE_DATA:
      return { ...state, ...action.payload };
    case UPDATE_DATA_FROM_FILE:
      return { ...state, dataFromFile: action.payload };
    case SET_IMPORT_FILE_FETCHING_STATUS:
      return { ...state, fetching: action.payload };
    case RESET_IMPORT_FILE_DATA:
      return defState;
    default:
      return state;
  }
}

const SET_IMPORT_FILE_DATA = "SET_IMPORT_FILE_DATA";
export const setImportFileData = (payload) => ({
  type: SET_IMPORT_FILE_DATA,
  payload,
});

const UPDATE_DATA_FROM_FILE = "UPDATE_DATA_FROM_FILE";
export const updateDataFromFile = (payload) => ({
  type: UPDATE_DATA_FROM_FILE,
  payload,
});

const SET_IMPORT_FILE_FETCHING_STATUS = "SET_IMPORT_FILE_DATA_FETCHING_STATUS";
export const setImportFileFetchingStatus = (payload) => ({
  type: SET_IMPORT_FILE_FETCHING_STATUS,
  payload,
});

const RESET_IMPORT_FILE_DATA = "RESET_IMPORT_FILE_DATA";
export const resetImportFileData = () => ({
  type: RESET_IMPORT_FILE_DATA,
});

export default importFile;
