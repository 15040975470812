import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import { setActiveMenuItem } from "../../reducers/modelComplectMenu";
import { fetchFromApi } from "../service";
import {
  resetCondition,
  setCondition,
  setConditionFetchingStatus,
} from "../sync/condition";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getModelComplectMenu } from "./modelComplectMenu";

export function getCondition(code) {
  return async (dispatch, getState) => {
    try {
      dispatch(resetCondition());
      dispatch(setConditionFetchingStatus("loading"));
      const response = await fetchFromApi("api/v1/condition", "GET", {
        id: code,
      });

      if (!response.ok) {
        throw new HttpError(response.status, response.statusText);
      }

      const data = await response.json();

      dispatch(setCondition({ ...data, changedFields: [] }));
      dispatch(setConditionFetchingStatus());
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }

      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setConditionFetchingStatus());
    }
  };
}

export function putCondition() {
  return async (dispatch, getState) => {
    const state = getState();
    const condition = state.condition;
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setConditionFetchingStatus("update"));
      const response = await fetchFromApi("api/v1/condition", "PUT", {
        body: condition,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getModelComplectMenu(modelComplectCode));
      dispatch(setActiveMenuItem(pathname));
      dispatch(setConditionFetchingStatus());
      dispatch(resetCurrentPageFormState());

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setConditionFetchingStatus());

      return false;
    }
  };
}

export function deleteConditionFromServer(id, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;

    const body = {
      code: id,
      tableName: "condition",
    };

    try {
      dispatch(setConditionFetchingStatus("loading"));
      const response = await fetchFromApi("api/v1/dict/record", "DELETE", {
        body,
      });
      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getModelComplectMenu(modelComplectCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setConditionFetchingStatus());
    }
  };
}
