import { connect } from "react-redux";
import { GuidesPageControls } from "../../components/guidesPageControls";
import {
  changeModeGuidesRow,
  startSearch,
  newCurrentGuideElement,
  updateGuidesHiddenMode,
  deleteCurrentRow,
} from "../actions/async/guides";
import {
  dropModelComplectCode,
  setSearchString,
  setSearchValue,
  switchMainMenuVisible,
  switchSearchCheckbox,
} from "../actions/sync/guides";
import { getComponentInfo, getConditionInfo } from "../actions/async/component";
import { exportData, exportTemplate } from "../actions/async/exportFile";
import { setCurrentTableRow } from '../actions/sync/table';

const mapStateToProps = (state) => {
  const currGuideMass = state.guides.list.filter(
    (el) => el.id === state.guides.current
  );
  let currGuideName = "def";
  let currGuideFilters = [];
  let currGuideControls = {};
  let currTableName = null;
  let currGuideDeleteCascade = false;
  let currentPageId = null;

  if (currGuideMass.length > 0) {
    currGuideName = currGuideMass[0].name;
    currGuideFilters =
      currGuideMass[0].filters !== undefined ? currGuideMass[0].filters : [];
    currGuideControls = currGuideMass[0].controls;
    currTableName = currGuideMass[0].table;
    currGuideDeleteCascade = !!currGuideMass[0].deleteCascade;
    currentPageId = currGuideMass[0].id;
  }

  const currRowMass = state.guides.body.filter(
    (el) => +el.code === state.guides.currentRow
  );
  const currentRowMode = currRowMass.length === 0 ? "" : currRowMass[0].hidden;

  const allFiltersFilled =
    currGuideFilters
      .map((el) => {
        if (!el.required) {
          return true;
        }
        const guide = state[el.id];
        if (guide.value === undefined) {
          return !(guide.current === "def");
        } else {
          return !(guide.value === "") && guide.verified;
        }
      })
      .indexOf(false) === -1;

  const currentSearchValueEl = state.guides.searchValueList.filter(
    (el) => el.code === state.guides.currentSearchValue
  )[0];

  return {
    currentPageId,
    currentGuide: currGuideName,
    currentTable: currTableName,
    dataLoaded: state.guides.body.length > 0,
    currentRow: state.guides.currentRow,
    currentGuideFilters: currGuideFilters,
    currentGuideControls: currGuideControls,
    currentRowMode,
    allFiltersFilled,
    currentSearchValue: state.guides.currentSearchValue,
    searchValueList: state.guides.searchValueList,
    currentSearchValueType:
      currentSearchValueEl !== undefined ? currentSearchValueEl.fieldType : "",
    searchString: state.guides.searchString,
    searchCheckbox: state.guides.searchCheckbox,
    showMainMenu: state.guides.showMainMenu,
    category: state.category,
    subcategory: state.subcategory,
    hiddenModeCaption:
      state.i18.phrases.guides.controlPanel.hiddenModeCaption[
        state.guides.hidden
      ][state.i18.current],
    hiddenMode: state.guides.hidden,
    modelComplectCode: state.guides.modelComplectCode,
    componentCode: state.guides.componentCode,
    deleteCascade: currGuideDeleteCascade,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchValue: (value) => dispatch(setSearchValue(value)),
    setSearchString: (value) => dispatch(setSearchString(value)),
    switchSearchCheckbox: (checked) => dispatch(switchSearchCheckbox(checked)),
    startSearch: () => dispatch(startSearch()),
    startTemplateExport: (callback) => dispatch(exportTemplate(callback)),
    startDataExport: (callback) => dispatch(exportData(callback)),
    switchMainMenuVisible: (mode) => dispatch(switchMainMenuVisible(mode)),
    changeRowMode: () => dispatch(changeModeGuidesRow()),
    newGuideElement: (mode) => dispatch(newCurrentGuideElement(mode)),
    changeHiddenMode: (needReload) =>
      dispatch(updateGuidesHiddenMode(needReload)),
    delCurrentRow: () => dispatch(deleteCurrentRow()),
    getComponentInfo: (code) => dispatch(getComponentInfo(code)),
    getConditionInfo: (code) => dispatch(getConditionInfo(code)),
    dropModelComplectCode: () => dispatch(dropModelComplectCode()),
    setCurrentRow: (code) => dispatch(setCurrentTableRow(code, 'guides')),
  };
};

const VisableGuidesPageControls = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuidesPageControls);

export default VisableGuidesPageControls;
