import { connect } from 'react-redux';
import { AuthController } from '../../components/authController';
import { requestCategories } from '../actions/async/category';
import { initSystem } from '../actions/async/init';

const mapStateToProps = (state) => {
  return {
    authStage: state.auth.stage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initSystem: () => dispatch(initSystem()),
    loadCategories: () => dispatch(requestCategories()),
  };
};

const VisableAuthController = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthController);

export default VisableAuthController;
