import { nanoid } from "nanoid";

const initialState = {
  menu: [
    {
      active: false,
      link: "/reports/autostat/missing-models",
      rootName: "Автостат",
      root: true,
      code: nanoid(),
      children: [
        {
          code: nanoid(),
          active: false,
          root: true,
          link: "/reports/autostat/missing-models",
          rootName: "Недостающие модели (Парк РФ)",
        },
      ],
    },
    {
      code: nanoid(),
      active: false,
      root: true,
      link: "/reports/statistics/specification-coverage",
      rootName: "Статистика",
      children: [
        {
          code: nanoid(),
          active: false,
          root: true,
          link: "/reports/statistics/specification-coverage",
          rootName: "Покрытие спецификацией техники",
        },
        {
          code: nanoid(),
          active: false,
          root: true,
          link: "/reports/statistics/requests",
          rootName: "Статистика запросов",
        },
      ],
    },
  ],
};

function reportMenu(state = initialState, {type, payload}) {
  switch (type) {
    case SET_ACTIVE_REPORT_MENU_ITEM:
      return {
        ...state,
        menu: state.menu.map((item) => ({
          ...item,
          children: item.children.map((child) => ({
            ...child,
            active: child.link === payload,
          })),
        })),
      };
    default:
      return state;
  }
}

const SET_ACTIVE_REPORT_MENU_ITEM = "SET_ACTIVE_REPORT_MENU_ITEM";
export const setActiveReportMenuItem = (payload) => ({
  type: SET_ACTIVE_REPORT_MENU_ITEM,
  payload,
});

export default reportMenu;
