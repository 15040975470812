import { useState, memo, useRef, useCallback } from "react";
import ChipsItem from "../chipsField/chipsItem";
import MultiSelectBlock from "../multiSelectBlock";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ClearFieldIcon from "../../icons/ClearFieldIcon";
import "./index.scss";

const DropdownMultiSelect = memo(
  ({
    id,
    label,
    items = [],
    selectedItems = [],
    isSearchable = false,
    isSelectAllAvailable = false,
    onSearch = () => {},
    onDelete = () => {},
    onClearField = () => {},
    onSelect = () => {},
    onSelectAll = () => {},
    isLoading = false,
    required = false,
    error = null,
  }) => {
    const blockRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const filteredItems = items.map((item) => ({
      ...item,
      checked: selectedItems.some(
        (selectedItem) => selectedItem.code === item.code
      ),
    }));

    const onClickOutside = useCallback(() => {
      setIsOpen(false);
    }, []);

    useOnClickOutside(blockRef, onClickOutside);
    function handleOnClickItem(item) {
      onSelect(item, id);
    }

    function handleOnDelete(code) {
      onDelete(code, id);
    }

    function handleClearField() {
      onClearField(id);
    }

    function handleSelectAll() {
      onSelectAll(items, id);
    }

    function handleOnSearch(value) {
      onSearch(value, id);
    }

    return (
      <>
        <div className="dropdown-multiselect" ref={blockRef}>
          <label className="dropdown-multiselect__label" htmlFor={id}>
            <span>{label}</span>
            {required && (
              <>
                &nbsp;
                <span className="required">*</span>
              </>
            )}
          </label>
          <div className="dropdown-multiselect__container">
            <div
              id={id}
              className={`dropdown-multiselect__field ${
                error ? " dropdown-multiselect__field--error" : ""
              }`}
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            >
              <div className="dropdown-multiselect__list">
                {selectedItems.map((item) => {
                  return (
                    <ChipsItem
                      key={item.code}
                      id={item.code}
                      {...item}
                      onDelete={handleOnDelete}
                    />
                  );
                })}
              </div>
              <div className="dropdown-multiselect__icons">
                {selectedItems.length > 0 && (
                  <>
                    <button
                      type="button"
                      className="dropdown-multiselect__icon"
                      onClick={handleClearField}
                    >
                      <ClearFieldIcon />
                    </button>
                  </>
                )}
                <button
                  type="button"
                  className="dropdown-multiselect__icon"
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsOpen((prev) => !prev)
                  }}
                >
                  <div className="dropdown-multiselect__chevron-down"></div>
                </button>
              </div>
            </div>
            {isOpen && (
              <div className={`dropdown-multiselect__options`}>
                <MultiSelectBlock
                  items={filteredItems}
                  isLoading={isLoading}
                  onItemSelect={handleOnClickItem}
                  onSearch={handleOnSearch}
                  isSearchable={isSearchable}
                  isSelectAllAvailable={isSelectAllAvailable}
                  onSelectAll={handleSelectAll}
                />
              </div>
            )}
          </div>
          <div className="dropdown-multiselect__error">{error}</div>
        </div>
      </>
    );
  }
);

export default DropdownMultiSelect;
