const initialState = {
  id: null,
  code: null,
  name: null,
  names: [],
  productBrandCode: null,
  productPremiumCode: null,
  priority: null,
  startYear: null,
  endYear: null,
  hidden: false,
  fetchingStatus: null,
  changedFields: []
};

function productForm(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PRODUCT_FORM:
      return Object.assign({}, state, payload);
    case UPDATE_PRODUCT_FORM_FIELD:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_PRODUCT_FORM_CHANGED_FIELDS:
      const isFieldAlreadyMarkedForUpdate =
        state.changedFields.includes(payload);

      return {
        ...state,
        changedFields: isFieldAlreadyMarkedForUpdate
          ? [...state.changedFields]
          : [...state.changedFields, payload],
      };
    case SET_PRODUCT_FORM_FETCHING_STATUS:
      return {
        ...state,
        fetchingStatus: payload,
      };
    case RESET_PRODUCT_FORM_STATE:
      return initialState;
    default:
      return state;
  }
}

const SET_PRODUCT_FORM = "SET_PRODUCT_FORM";
const UPDATE_PRODUCT_FORM_FIELD = "UPDATE_PRODUCT_FIELD";
const UPDATE_PRODUCT_FORM_CHANGED_FIELDS = "UPDATE_PRODUCT_CHANGED_FIELDS";
const SET_PRODUCT_FORM_FETCHING_STATUS = "SET_PRODUCT_FORM_FETCHING_STATUS";
const RESET_PRODUCT_FORM_STATE = "RESET_PRODUCT_FORM_STATE";

export const setProductForm = (payload) => ({
  type: SET_PRODUCT_FORM,
  payload,
});

export const updateProductFormField = (payload) => ({
  type: UPDATE_PRODUCT_FORM_FIELD,
  payload,
});

export const updateProductFormChangedFields = (payload) => ({
  type: UPDATE_PRODUCT_FORM_CHANGED_FIELDS,
  payload,
});

export const setProductFormFetchingStatus = (payload) => ({
  type: SET_PRODUCT_FORM_FETCHING_STATUS,
  payload,
});

export const resetProductFormState = () => ({
  type: RESET_PRODUCT_FORM_STATE,
});

export default productForm;
