import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import ImportDataCellEditorModal from "./importDataCellEditorModal";
import { validateCode } from "../../validations/code";
import {
  nonEditableField,
  requiredField,
} from "./importDataCellEditorModal/fieldsMap";
import { updateDataFromFile } from "../../redux/reducers/importFile";

const XlsTable = ({
  data,
  columns,
  vocabulary,
  selectAllRows,
  allRowsSelected,
  selectedRows,
  selectRow,
}) => {
  const dispatch = useDispatch();

  const [isModalOpen, setModalOpen] = useState(false);
  const [editingField, setEditingField] = useState({});

  function editCell(idXls, idDb, column, value) {
    if (nonEditableField.includes(column.code)) {
      return;
    }

    if (column.code === "code" && idDb) {
      return;
    }

    setEditingField({
      id: idXls,
      code: column.code,
      name: column.name,
      value,
    });
    setModalOpen(true);
  }

  function updateCell(id, key, value) {
    const updatedFields = value.name
      ? {
          [key + "NameXls"]: value.name,
          [key + "CodeXls"]: value.code,
        }
      : {
          [key + "Xls"]: value.code,
        };
    const changedFieldName = value.name ? key + "Code" : key;

    const newImportData = data.map((item) => {
      if (item.idXls !== id) {
        return item;
      }

      const changedFields = item.changedFields.includes(changedFieldName)
        ? item.changedFields
        : [...item.changedFields, changedFieldName];

      return {
        ...item,
        ...updatedFields,
        changedFields,
      };
    });

    dispatch(updateDataFromFile(newImportData));
    setModalOpen(false);
  }

  return (
    <>
      <table className="guides-table">
        <thead>
          <tr>
            {columns.map((el, i) => (
              <th className="guides-table__th" key={i}>
                {el.name}
              </th>
            ))}
            <th className="guides-table__th" key={columns.length + 1}>
              <label className="checkbox__block">
                <input
                  type="checkbox"
                  onChange={selectAllRows}
                  checked={allRowsSelected}
                />
                <span>{"Выбрать все"}</span>
              </label>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            const tr_content = columns.map((column, index) => {
              let key = column.code + "Xls";
              let isDiff =
                item[column.code + "Xls"] === item[column.code + "Db"];
              let name = item[key];
              let value = item[key];
              let codeError = null;
              let isNoMatch = false;

              if (column.fieldType === "ref") {
                key = column.code + "NameXls";
                isDiff =
                  item[column.code + "CodeXls"] ===
                  item[column.code + "CodeDb"];

                name =
                  item[column.code + "CodeXls"] && !item[key]
                    ? "нет соответствия"
                    : item[key];
                isNoMatch = item[column.code + "CodeXls"] && !item[key];

                if (requiredField.includes(column.code)) {
                  isNoMatch = !item[column.code + "CodeXls"] || !item[key];
                  name =
                    item[column.code + "CodeXls"] && item[key]
                      ? item[key]
                      : "нет соответствия";
                }

                value = item[column.code + "CodeXls"];
              }

              if (column.fieldType === "boolean") {
                name = item[key] ? vocabulary[1].name : vocabulary[0].name;
              }

              if (!item.idDb) {
                if (column.code === "code" && !validateCode(value)) {
                  codeError =
                    "Допускаются заглавные латинские буквы, цифры, символы - _";
                }

                return (
                  <td
                    key={index}
                    className={`guides-table__td${
                      codeError ? " guides-table__error" : ""
                    }${isNoMatch ? " red-text" : ""}`}
                    onDoubleClick={() =>
                      editCell(item.idXls, item.idDb, column, value)
                    }
                  >
                    <span>{name}</span>
                    {codeError && (
                      <>
                        <div
                          className="error-icon"
                          data-tooltip-id="code-error"
                          data-tooltip-place="bottom"
                          data-tooltip-content={codeError}
                        >
                          <div className="alert-icon"></div>
                        </div>
                        <div className="code-input__tooltip">
                          <Tooltip
                            id="code-error"
                            style={{
                              zIndex: 1,
                              maxHeight: "385px",
                              maxWidth: "300px",
                            }}
                            clickable
                          />
                        </div>
                      </>
                    )}
                  </td>
                );
              }

              return (
                <td
                  key={index}
                  className={`guides-table__td ${
                    isDiff ? "" : " guides-table__td_marked"
                  }${isNoMatch ? " red-text" : ""}`}
                  onDoubleClick={() =>
                    editCell(item.idXls, item.idDb, column, value)
                  }
                >
                  {name}
                </td>
              );
            });

            const selectColumn = (
              <td className="guides-table__td" key={columns.length + 1}>
                <label className="checkbox__block">
                  <input
                    type="checkbox"
                    onChange={() => selectRow(item.idXls)}
                    checked={selectedRows.includes(item.idXls)}
                  />
                  <span></span>
                </label>
              </td>
            );

            return <tr key={item.idXls}>{[...tr_content, selectColumn]}</tr>;
          })}
        </tbody>
      </table>
      <ImportDataCellEditorModal
        active={isModalOpen}
        close={() => setModalOpen(false)}
        field={editingField}
        updateCell={updateCell}
      />
    </>
  );
};

export default XlsTable;
