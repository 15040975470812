import React from "react";
import Modal from "../index";
import { Waiter } from '../../../components/waiter';

const DeleteModal = ({
  active,
  title,
  subtitle = '',
  closeModal,
  onDelete,
  waiting = false,
}) => {
  return (
    <Modal active={active} closeModal={closeModal}>
      <div className="modal__header">
        <h4>{title}</h4>
        <div>{subtitle}</div>
      </div>
      <hr className="horizontal-line" />
      <div className="modal__buttons">
        <button
          className="modal-button button button__primary"
          onClick={onDelete}
          disabled={waiting}
        >
          <div className="modal-button-content">{waiting && (<Waiter size="small"/>)}Удалить</div>
        </button>
        <button
          className="modal-button button button__negative button__negative__outline"
          onClick={closeModal}
        >
          Отмена
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
