import { updateEngineCap, validateEngineCap } from '../sync/engineCap';
import {
  initNotificationMessage,
  showNotificationMessage,
} from '../sync/notificationMessage';
import { requestSearchByFilterResult } from './search';

export function selectEngineCap(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateEngineCap(id));
      res();
    }).then(() => {
      dispatch(requestSearchByFilterResult());
    });
  };
}

export function blurEngineCap(value) {
  return (dispatch, getState) => {
    return new Promise((res) => {
      dispatch(validateEngineCap(value));
      res();
    })
      .then(() => {
        const state = getState();
        if (state.engineCap.errorValue) {
          dispatch(
            initNotificationMessage(
              'Введенное значение не соответствует типу или ограничениям поля ввода',
              'Тип поля, ограничения:Положительное целое',
              'warning'
            )
          );
          return true;
        }
        return false;
      })
      .then((res) => {
        if (res) {
          dispatch(showNotificationMessage());
        }
      });
  };
}
