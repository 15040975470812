function normalizeSpecComponentGroupForServer(specs, specCode) {
  return {
    data: specs
      .filter((item) => item.actionType)
      .map((item) => ({
        specCode,
        componentGroupCode: item.spec,
        id: item.actionType === "C" ? null : item.id,
        actionType: item.actionType,
      })),
  };
}

export default normalizeSpecComponentGroupForServer;
