import { connect } from 'react-redux';
import { SvProductList } from '../../components/svProductList';
import { showProductList } from '../actions/sync/svProduct';

const mapStateToProps = (state, ownProps) => {
  return {
    mode: state.specVisco.active === ownProps.id,
    data: ownProps.products.map(el=>state.svProduct.by_id[el]),
    expandAllProducts: state.expandAllProducts.expandAllProducts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showProductList: (id) => dispatch(showProductList(id)),
  };
};

const VisibleSvProductList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SvProductList);

export default VisibleSvProductList;
