import { useCallback } from "react";
import { fetchFromApi } from "../redux/actions/service";
import { useDispatch } from "react-redux";

export const useGetNewCode = (updateField) => {
  const dispatch = useDispatch();

  const getNewCode = useCallback(async () => {
    const newCode = await fetchFromApi("api/v1/dict/code", "GET", {}).then((res) => res.text());
    dispatch(updateField({ code: newCode }));
  }, []);
  return getNewCode;
};
