function normalizeConditionViscosForServer(viscos, condition) {
  return {
    data: viscos
      .filter((item) => item.actionType)
      .map((visco) => ({
        condition,
        visco: visco.viscoCode,
        conditionViscoInterval: visco.conditionViscoIntervalCode,
        id: visco.actionType === "C" ? null : visco.id,
        actionType: visco.actionType,
      })),
  };
}

export default normalizeConditionViscosForServer;
