export const SWITCH_FULL_DESCRIPTION = 'SWITCH_FULL_DESCRIPTION';
export function switchFullDescription(id) {
  return {
    type: SWITCH_FULL_DESCRIPTION,
    id,
  };
}

export const SWITCH_DOUBLE_DESCRIPTION = 'SWITCH_DOUBLE_DESCRIPTION';
export function switchDoubleDescription(id) {
  return {
    type: SWITCH_DOUBLE_DESCRIPTION,
    id,
  };
}

export const SET_ACTIVE_DESCRIPTION = 'SET_ACTIVE_DESCRIPTION';
export function setActiveDescription(id, active) {
  return {
    type: SET_ACTIVE_DESCRIPTION,
    id,
    active,
  };
}
