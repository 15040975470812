import { RESET_APP } from "../actions/sync/init";
import { SET_ACTIVE_PAGE } from "../actions/sync/pages";

const defState = {
  current: "product",
  list: [
    { name: "product", url: "/search" },
    { name: "model-complect", url: "/techniks", pageCode: 7 },
    { name: "guides", url: "/guides" },
    { name: "components", url: "/components", pageCode: 16 },
    { name: "products", url: "/products", pageCode: 40 },
    { name: "specs", url: "/specs", pageCode: 50 },
    { name: "reports", url: "/reports" },
  ],
};

function pages(state = defState, action) {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return Object.assign({}, state, {
        current: () => {
          const nameNode = state.list.find((page) => page.url === action.page);
          if (nameNode.hasOwnProperty(`name`))
            return nameNode.name;
        }
      });
    case RESET_APP:
      return defState;
    default:
      return state;
  }
}

export default pages;
