export const SET_SELECT_SEARCH_MAX = "SET_SELECT_SEARCH_MAX";
export function setSelectSearchMax(val){
    return{
        type: SET_SELECT_SEARCH_MAX,
        val,
    }
}

export const SET_SELECT_LIMIT_MAX = "SET_SELECT_LIMIT_MAX";
export function setSelectLimitMax(val){
    return{
        type: SET_SELECT_LIMIT_MAX,
        val,
    }
}

export const SET_XLS_EXPORT_MAX = "SET_XLS_EXPORT_MAX";
export function setXlsExportMax(val){
    return{
        type: SET_XLS_EXPORT_MAX,
        val,
    }
}

export const SET_XLS_IMPORT_MAX = "SET_XLS_IMPORT_MAX";
export function setXlsImportMax(val){
    return{
        type: SET_XLS_IMPORT_MAX,
        val,
    }
}