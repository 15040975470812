const initialState = {
  productCode: null,
  data: [],
  fetchingStatus: null,
};

function productComponentGroup(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_COMPONENT_GROUP:
      return { ...state, data: action.payload };
    case SET_PRODUCT_COMPONENT_GROUP_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_PRODUCT_COMPONENT_GROUP = "SET_PRODUCT_COMPONENT_GROUP";
const SET_PRODUCT_COMPONENT_GROUP_FETCHING_STATUS =
  "SET_PRODUCT_COMPONENT_GROUP_FETCHING_STATUS";

export const setProductComponentGroup = (payload) => ({
  type: SET_PRODUCT_COMPONENT_GROUP,
  payload,
});

export const setProductComponentGroupFetchingStatus = (payload) => ({
  type: SET_PRODUCT_COMPONENT_GROUP_FETCHING_STATUS,
  payload,
});

export default productComponentGroup;
