import {fetchFromApi} from '../service';
import { initNotificationMessage, showNotificationMessage } from "../sync/notificationMessage";

import {
  reciveGuideTableColumns,
  setCurrentGuide,
  reciveGuideTableBody,
  setLimit,
  setPage,
  setOffset,
  clearPrewGuide,
  waitForGuideData,
  loadSearchValuesList,
  setSearchValue,
  setSearchString,
  changeHiddenMode,
} from '../sync/guides';
import { requestCategories } from './category';
import { callMarkGuideForm } from './markGuideForm';

export function selectGuide(id, parent) {
  return (dispatch, getState) => {
    return new Promise((res) => {
      dispatch(clearPrewGuide());
      res();
    })
      .then(() => {
        dispatch(requestCategories());
      })
      .then(() => {
        dispatch(setCurrentGuide(id, parent));
      })
      .then(() => {
        dispatch(requestActiveGuideTableColumns());
      })
      .then(() => {
        const filters = getState().guides.list
            .filter((el) => el.id === id)[0].filters ?? [];
        if (
          // auto request data on dictionaries without required filters (ex. category with default value)
          // state.guides.list.filter((el) => el.id === id)[0].dataType !== 'business'
          filters.filter((el) => el.required && el.id !== 'category').length === 0
        ) {
          dispatch(requestGuideTableBody());
        } else {
          dispatch(waitForGuideData(false));
        }
      });
  };
}

export function startSearch() {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(requestActiveGuideTableColumns());
      res();
    })
    .then(() => {
      dispatch(setPage(1));
    })
    .then(() => {
      dispatch(setOffset());
    })
    .then(() => {
      dispatch(requestGuideTableBody());
    });
  };
}

function requestActiveGuideTableColumns() {
  return (dispatch, getState) => {
    dispatch(waitForGuideData(true));

    const state = getState();
    const table = () => {
        const tableNode = state.guides.list.filter((el) => el.id === state.guides.current)[0];
        return tableNode && tableNode.hasOwnProperty(`table`)
                ? tableNode.table
                : false;
      };

    if (!table()) return false;

    const body = {
      lang: state.i18.current,
      tableName: table(),
      listType: 'ref_list',
    };

    return fetchFromApi("api/v1/column/list","POST",{body})
      .then((res) => {
        if (res && res.status === 200) {
          return res.json();
        }
        throw new Error('wrong table columns');
      })
      .then((json) => {
        dispatch(reciveGuideTableColumns(json));
      })
      .then(() => {
        dispatch(loadSearchValuesList());
      })
      .catch((e) => {
        console.log(e);
      })
  };
}

export function requestGuideTableBody() {
  return (dispatch, getState) => {
    const state = getState();
    const currentGuide = state.guides.list.filter((el) => el.id === state.guides.current)[0];
    const url = currentGuide && currentGuide.hasOwnProperty(`table`)
                  ? 'api/v1/' + currentGuide.table.split('_').join('-') + '/filter/page'
                  : false;

    if (!url) return false;

    let body = {
      lang: state.i18.current,
      offset: state.guides.offset,
      limit: state.guides.limit,
      hidden: state.guides.hidden,
      searchString: getSearchString(state),
      colListCode:
        state.guides.currentSearchValue === 'def'
          ? null
          : state.guides.currentSearchValue,
    };
    if (currentGuide && currentGuide.filters.length > 0) {
      if (state.category.current !== 'def') {
        body.modelCategoryCode = state.category.current;
      }
      if (state.subcategory.current !== 'def') {
        body.modelSubcategoryCode = state.subcategory.current;
      }
      if (state.modelMark.current !== 'def') {
        body.modelMarkCode = state.modelMark.current;
      }
      if (state.modelGeneration.current !== 'def') {
        body.modelCode = state.modelGeneration.current;
      }
      if (state.model.current !== 'def') {
        body.modelCode = state.model.current;
      }
      if (state.engineCap.value !== '') {
        body.engineCap = state.engineCap.value;
      }
      if (state.years.value !== '') {
        body.year = state.years.value;
      }
    }

    return fetchFromApi(url,"POST",{body})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error('wrong table body');
      })
      .then((json) => {
        dispatch(reciveGuideTableBody(json));
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(waitForGuideData(false));
      });
  };
}

export function selectLimit(limit) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(waitForGuideData(true));
      dispatch(setLimit(limit));
      res();
    })
    .then(() => {
      dispatch(setPage(1));
    })
    .then(() => {
      dispatch(setOffset());
    })
    .then(() => {
      dispatch(requestGuideTableBody());
    })
    .catch((e) => console.error(e))
    .finally(() => dispatch(waitForGuideData(true)));
  };
}

export function selectPage(page) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(waitForGuideData(true));
      dispatch(setPage(page));
      res();
    })
    .then(() => {
      dispatch(setOffset());
    })
    .then(() => {
      dispatch(requestGuideTableBody());
    })
    .catch((e) => console.error(e))
    .finally(() => dispatch(waitForGuideData(true)));
  };
}

export function changeModeGuidesRow(id) {
  return (dispatch, getState) => {
    // dispatch(nextStep('changeModeGuidesRow'));
    dispatch(waitForGuideData(true));

    const state = getState();
    const table = state.guides.list.filter(
      (el) => el.id === state.guides.current
    )[0].table;
    const hidden = state.guides.body.filter(
      (el) => el.code === state.guides.currentRow
    )[0].hidden;
    let body = {
      code: String(state.guides.currentRow),
      tableName: table,
      hidden: !hidden,
    };

    return fetchFromApi('api/v1/dict/hidden',"POST",{body})
      .then((res) => {
        if (res.status === 200) {
          dispatch(requestGuideTableBody());
        } else {
          throw new Error('wrong row change mode');
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        dispatch(waitForGuideData(false));
      });
  };
}

export function newCurrentGuideElement(mode) {
  return (dispatch, getState) => {
    const state = getState();
    const guideName = state.guides.list.filter(
      (el) => el.id === state.guides.current
    )[0].name;

    switch (guideName) {
      case 'Марка ТС':
        dispatch(callMarkGuideForm(mode));
        break;

      default:
        break;
    }
  };
}

export function updateGuidesSearchValue(value) {
  return (dispatch, getState) => {
    return new Promise((res) => {
      dispatch(setSearchValue(value));
      res();
    }).then(() => {
      dispatch(setSearchString(''));

      if (!value) {
        const state = getState();
        const currentGuide = state.guides.current;
        const isNoFilters = 
          state.guides.list.find(guide => guide.id === currentGuide)
            .filters.length === 0

        if (isNoFilters) {
          dispatch(startSearch())
        }
      }
    });
  };
}

export function updateGuidesHiddenMode(needReload) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(changeHiddenMode());
      res();
    })
    .then(() => {
      if (needReload) dispatch(waitForGuideData(true));
    })
    .then(() => {
      if (needReload) dispatch(requestGuideTableBody());
    });
  };
}

export function getSearchString(state) {
  if (state.guides.currentSearchValue === 'def') {
    return null;
  }
  
  if (state.guides.currentSearchValue === 'name' && state.guides.searchString === '') {
    return null;
  }

 
  const currSearchValue = state.guides.columns.filter(
    (el) => el.code === state.guides.currentSearchValue
  )[0];
  if (currSearchValue.fieldType !== undefined) {
    if (currSearchValue.fieldType === 'boolean') {
      return String(state.guides.searchCheckbox);
    }
    if (state.guides.searchString === '') {
      return null;
    }
    return state.guides.searchString;
  }
}

export function deleteCurrentRow() {
  return async (dispatch, getState) => {
    const state = getState();
    const tableName = state.guides.list.filter(
      (el) => el.id === state.guides.current
    )[0].table;
    const code = state.guides.currentRow;
    const body = {
      tableName,
      code,
    };

    return fetchFromApi('api/v1/dict/record',"DELETE",{body})
      .then((res) => {
        if (res.ok) {
            dispatch(initNotificationMessage('Удаление выполнено успешно.'));
            dispatch(showNotificationMessage());
            dispatch(waitForGuideData(true));
            dispatch(requestGuideTableBody());
        } else {
            if (res.status === 409) {
                res.json()
                .then((resData) => {
                  dispatch(
                    initNotificationMessage(
                        'Удаление не выполнено.',
                        'Status code: ' + (resData.msgCode ?? 'unknown'),
                        'danger'
                    )
                  );
                  dispatch(showNotificationMessage());
                });
            } else {
             dispatch(
                initNotificationMessage(
                    'Удаление не выполнено.',
                    `${res.status} ${res.statusText ?? ''}`,
                    'danger'
                )
              );
              dispatch(showNotificationMessage());
            }
        }
      })
  };
}
