const initialState = {
  id: null,
  code: null,
  name: null,
  producerCode: null,
  plasId: null,
  olyaId: null,
  hidden: false,
  description1: null,
  description2: null,
  changedFields: [],
  fetchingStatus: null,
  names: [],
};

function spec(state = initialState, action) {
  switch (action.type) {
    case SET_SPEC:
      return Object.assign({}, state, action.payload);
    case UPDATE_SPEC_FIELD:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_SPEC_CHANGED_FIELDS:
      const isFieldAlreadyMarkedForUpdate = state.changedFields.includes(
        action.payload
      );

      return {
        ...state,
        changedFields: isFieldAlreadyMarkedForUpdate
          ? [...state.changedFields]
          : [...state.changedFields, action.payload],
      };
    case SET_SPEC_FETCHING_STATUS:
      return {
        ...state,
        fetchingStatus: action.payload,
      };
    case RESET_SPEC_STATE:
      return initialState;
    default:
      return state;
  }
}

const SET_SPEC = "SET_SPEC";
const UPDATE_SPEC_FIELD = "UPDATE_SPEC_FIELD";
const UPDATE_SPEC_CHANGED_FIELDS = "UPDATE_SPEC_CHANGED_FIELDS";
const SET_SPEC_FETCHING_STATUS = "SET_SPEC_FETCHING_STATUS";
const RESET_SPEC_STATE = "RESET_SPEC_STATE";

export function setSpec(payload) {
  return {
    type: SET_SPEC,
    payload,
  };
}

export function updateSpecField(payload) {
  return {
    type: UPDATE_SPEC_FIELD,
    payload,
  };
}

export function resetSpecState() {
  return {
    type: RESET_SPEC_STATE,
  };
}

export function updateSpecChangedFields(payload) {
  return {
    type: UPDATE_SPEC_CHANGED_FIELDS,
    payload,
  };
}

export function setSpecFetchingStatus(payload = null) {
  return {
    type: SET_SPEC_FETCHING_STATUS,
    payload,
  };
}

export default spec;
