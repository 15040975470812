import { combineReducers } from "redux";

import personal from "./personal";
import category from "./category";
import subcategory from "./subcategory";
import modelMark from "./modelMark";
import modelGeneration from "./modelGeneration";
import i18 from "./i18";
import auth from "./auth";
import step from "./step";
import pages from "./pages";
import search from "./search";
import years from "./years";
import fuelType from "./fuelType";
import engineCap from "./engineCap";
import modelClass from "./modelClass";
import guides from "./guides";
import breadCrumb from "./breadcramb";
import product from "./product";
import components from "./components";
import conditions from "./conditions";
import intervals from "./intervals";
import specVisco from "./specVisco";
import svProduct from "./svProduct";
import descriptions from "./descriptions";
import importFile from "./importFile";
import textEditor from "./textEditor";
import notificationMessage from "./notificationMessage";
import markGuideForm from "./markGuideForm";
import modelDrive from "./modelDrive";
import modelBody from "./modelBody";
import model from "./model";
import modelComplect from "./modelComplect";
import modelComplectMenu from "./modelComplectMenu";
import currentPageFormState from "./currentPageFormState";
import component from "./component";
import componentSelectors from "./componentSelectors";
import componentGroup from "./componentGroup";
import componentType from "./componentType";
import producer from "./producer";
import condition from "./condition";
import marketList from "./marketList";
import conditionType from "./conditionType";
import conditionAnalog from "./conditionAnalog";
import currentPath from "./currentPath";
import intervalSelectors from "./intervalSelectors";
import conditionIntervals from "./conditionIntervals";
import historyChanges from "./historyChanges";
import conditionViscos from "./conditionViscos";
import viscoSelectors from "./viscoSelectors";
import specList from "./specList";
import conditionSpecs from "./conditionSpecs";
import modelTC from "./modelTC";
import brend from "./brend";
import market from "./market";
import interval from "./interval";
import intervalType from "./intervalType";
import conditionViscoInterval from "./conditionViscoInterval";
import vehicleCategory from "./vehicleCategory";
import vehicleSubCategory from "./vehicleSubCategory";
import vehicleBodyType from "./vehicleBodyType";
import visco from "./visco";
import viscoClass from "./viscoClass";
import productForm from "./productForm";
import productMenu from "./productMenu";
import productFormSelectors from "./productFormSelectors";
import productSpecs from "./productSpecs";
import productSpecLevel from "./productSpecLevel";
import productViscos from "./productViscos";
import segmentList from "./segmentList";
import productSegments from "./productSegments";
import productModelSubcategories from "./productModelSubcategory";
import productModelMark from "./productModelMark";
import productModel from "./productModel";
import productComponentFuelType from "./productComponentFuelType";
import componentFuelType from "./componentFuelType";
import productComponentGroup from "./productComponentGroup";
import generation from "./generation";
import autostatModelReportForm from "./autostatModelReportForm";
import autostatModelReportFormSelectors from "./autostatModelReportFormSelectors";
import statisticRequestReportForm from "./statisticRequestReportForm";
import statisticRequestReportFormSelectors from "./statisticRequestFormSelectors";
import statisticCoverReportForm from "./statisticCoverReportForm";
import statisticCoverReportFormSelectors from "./statisticCoverReportFormSelectors";
import reportMenu from "./reportMenu";
import spec from "./spec";
import specMenu from "./specMenu";
import specOver from "./specOver";
import specComponentGroup from "./specComponentGroup";
import settings from "./settings";
import expandAllProducts from "./expandAllProducts";


const rootReducer = combineReducers({
  auth,
  breadCrumb,
  category,
  engineCap,
  fuelType,
  guides,
  i18,
  model,
  modelClass,
  modelDrive,
  modelBody,
  modelGeneration,
  modelMark,
  modelComplect,
  modelComplectMenu,
  expandAllProducts,
  pages,
  personal,
  search,
  step,
  subcategory,
  years,
  product,
  components,
  conditions,
  intervals,
  specVisco,
  svProduct,
  descriptions,
  importFile,
  textEditor,
  notificationMessage,
  markGuideForm,
  component,
  componentSelectors,
  componentGroup,
  componentType,
  producer,
  condition,
  marketList,
  conditionType,
  conditionAnalog,
  currentPath,
  intervalSelectors,
  conditionIntervals,
  currentPageFormState,
  historyChanges,
  conditionViscos,
  specList,
  conditionSpecs,
  viscoSelectors,
  modelTC,
  brend,
  vehicleCategory,
  vehicleSubCategory,
  vehicleBodyType,
  interval,
  intervalType,
  conditionViscoInterval,
  market,
  visco,
  generation,
  viscoClass,
  productForm,
  productMenu,
  productFormSelectors,
  productSpecs,
  productSpecLevel,
  productViscos,
  segmentList,
  productSegments,
  productModelSubcategories,
  productModelMark,
  productModel,
  productComponentFuelType,
  componentFuelType,
  productComponentGroup,
  autostatModelReportForm,
  autostatModelReportFormSelectors,
  statisticCoverReportForm,
  statisticCoverReportFormSelectors,
  statisticRequestReportForm,
  statisticRequestReportFormSelectors,
  reportMenu,
  spec,
  specMenu,
  specOver,
  specComponentGroup,
  settings,
});

export default rootReducer;
