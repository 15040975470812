const initialState = {
  productCode: null,
  data: [],
  fetchingStatus: null,
};

function productComponentFuelType(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_COMPONENT_FUEL_TYPE:
      return { ...state, data: action.payload };
    case SET_PRODUCT_COMPONENT_FUEL_TYPE_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_PRODUCT_COMPONENT_FUEL_TYPE = "SET_PRODUCT_COMPONENT_FUEL_TYPE";
const SET_PRODUCT_COMPONENT_FUEL_TYPE_FETCHING_STATUS =
  "SET_PRODUCT_COMPONENT_FUEL_TYPE_FETCHING_STATUS";

export const setProductComponentFuelType = (payload) => ({
  type: SET_PRODUCT_COMPONENT_FUEL_TYPE,
  payload,
});

export const setProductComponentFuelTypeFetchingStatus = (payload) => ({
  type: SET_PRODUCT_COMPONENT_FUEL_TYPE_FETCHING_STATUS,
  payload,
});

export default productComponentFuelType;
