import { useEffect, useState } from "react";
import ModalLinkWrapper from "../../modalLinkWrapper";
import "./index.scss";

const SideTreeMenuItem = ({
  name,
  children = [],
  active,
  open,
  link,
  actionType = null,
  root = false,
  rootName = null,
  padding,
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <>
      <div
        className={`side-menu-item${active ? " side-menu-item--active" : ""}`}
      >
        <ModalLinkWrapper
          linkProps={{
            to: link,
            state: { actionType },
          }}
          disabled={!link}
          className="side-menu-item__link-wrapper"
        >
          <div
            className="side-menu-item__link"
            style={{ padding: `10px ${padding}px 10px` }}
          >
            <div className="side-menu-item__img"></div>
            <div className="side-menu-item__caption">
              {root ? rootName : name}
            </div>
          </div>
        </ModalLinkWrapper>
        <button
          className="side-menu-item__collapse-button"
          onClick={() => setOpen((prev) => !prev)}
        >
          {children.length > 0 && (
            <div
              className={`collapse-button__img collapse-button__img_${
                isOpen ? "open" : "close"
              }`}
            ></div>
          )}
        </button>
      </div>
      {(isOpen) &&
        children.map((node) => <SideTreeMenuItem {...node} key={node.code} />)}
    </>
  );
};

export default SideTreeMenuItem;
