export const SET_COMPONENT = 'SET_COMPONENT';
export function setComponent(payload) {
  return {
    type: SET_COMPONENT,
    payload,
  };
}

export const UPDATE_COMPONENT_FIELD = 'UPDATE_COMPONENT_FIELD';
export function updateComponentField(payload) {
  return {
    type: UPDATE_COMPONENT_FIELD,
    payload,
  };
}

export const RESET_COMPONENT_STATE = 'RESET_COMPONENT_STATE';
export function resetComponent() {
  return {
    type: RESET_COMPONENT_STATE,
  };
}

export const UPDATE_COMPONENT_CHANGED_FIELDS =
  'UPDATE_COMPONENT_CHANGED_FIELDS';
export function updateComponentChangedFields(payload) {
  return {
    type: UPDATE_COMPONENT_CHANGED_FIELDS,
    payload,
  };
}

export const SET_COMPONENT_STATUS = 'SET_COMPONENT_STATUS';
export function setComponentStatus(payload = null) {
  return {
    type: SET_COMPONENT_STATUS,
    payload,
  };
}

export const RECIVE_COMPONENT_INFO = 'RECIVE_COMPONENT_INFO';
export function reciveComponentInfo(json) {
  return {
    type: RECIVE_COMPONENT_INFO,
    json,
  };
}

export const RECIVE_CONDITION_INFO = 'RECIVE_CONDITION_INFO';
export function reciveConditionInfo(json) {
  return {
    type: RECIVE_CONDITION_INFO,
    json,
  };
}

