import * as yup from "yup";

const messages = {
  required: "Поле не заполнено",
};

const guidsFormIntervalSchema = yup.object().shape({
  code: yup.string()
    .required(messages.required)
    .matches(
      /^[A-Z0-9-_]+$/,
      "Допускаются заглавные латинские символы, цифры, знаки _ -"
    ),
  intervalTypeCode: yup.string().required(messages.required),
  val: yup.number().required(messages.required),
  hidden: yup.boolean(),
});

export default guidsFormIntervalSchema;