
export const tableNames = {
"autostat": "Данные Автостата",
"component": "Узел ТС",
"component_cool_type": "Тип охлаждения",
"component_engine_build": "Конструкция двигателя",
"component_fuel_type": "Тип топлива",
"component_gear_type": "Тип трансмиссии",
"component_turbo_type": "Тип турбины",
"component_group": "Группа узла",
"component_tmpl": "Справочник узлов",
"component_type": "Тип узла",
"condition": "Условие",
"condition_interval": "Интервалы использования для условия",
"condition_spec": "Спецификации Условия",
"condition_type": "Тип условия",
"condition_visco": "Вязкости условия",
"condition_visco_interval": "Температурный интервал для вязкости условия",
"dict_field": "Справочник полей таблиц модели данных",
"dict_lov": "Локальные справочники",
"hist": "История изменений",
"interval": "Справочник интервалов",
"interval_action": "Тип действия для интервала условия",
"interval_type": "Тип интервала",
"market": "Рынок",
"model": "Модель ТС",
"model_body": "Кузов ТС",
"model_category": "Категория ТС",
"model_category_segment": "Сегменты категории ТС",
"model_class": "класс экологичности ТС",
"model_complect": "Комплектация ТС",
"model_doc": "Документация на ТС",
"model_drive": "Привод ТС",
"model_generation": "Поколение модели ТС",
"model_mark": "Марка ТС",
"model_subcategory": "Подкатегория ТС",
"mv_dict": "состояние мат.представлений",
"producer": "Производитель",
"product": "Продукт",
"product_brand": "Бренд",
"product_component_group": "Группы узлов для продукта",
"product_component_fuel_type": "Тип топлива для продукта",
"product_model": "Модель ТС для продукта",
"product_model_mark": "Марка ТС для продукта",
"product_model_subcategory": "Подкатегории ТС для продукта",
"product_premium": "Премиальность продукта",
"product_segment": "Сегменты для продукта",
"product_spec": "Спецификации продукта",
"product_spec_level": "Уровень соответствия спецификации продукта",
"product_visco": "Вязкость продукта",
"segment": "Сегмент рынка",
"spec": "Спецификация",
"spec_component_group": "Группы узла для спецификации",
"spec_spec": "Перекрываемые спецификации",
"stat": "Статистика запросов с внешних сайтов",
"template_doc": "Шаблон на загрузку справочника",
"visco": "Вязкость",
"visco_classification": "Классификация вязкостей"
}

export const tableUrls = [
    { name: "model_complect", url: "/techniks", pageCode: 7 },
    { name: "component", url: "/components", pageCode: 16 },
    { name: "product", url: "/products", pageCode: 40 },
    { name: "spec", url: "/specs", pageCode: 50 }]
    