import React, { useRef } from 'react';
import './index.css';

export function Description(props) {
  const ref = useRef(null);
  // const fullDescription = props.displayFullDescription === props.id;
  const fullDescription = props.mode;

  React.useEffect(() => {
    if (props.data !== '') {
      ref.current.focus();
    }
  });

  const preview = props.data
    // props.data !== null ? props.data.substring(0, 300) + '...' : '...';
  const classMod = props.mode === undefined ? '' : ' description_' + props.mode;

  if (props.data === '') {
    return (
      <div className={'description' + classMod}>
        <div className="description__preview"></div>
      </div>
    );
  }

  function selectAll(e) {
    if (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();

      let range = new Range();

      range.setStart(
        document.querySelector('.description-modal__span_' + props.id),
        0
      );
      range.setEnd(
        document.querySelector('.description-modal__span_' + props.id),
        1
      );

      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
      // setSelect(true);
      // props.selectFullDescription(true);
      // document.querySelector("textarea").select();
    }
  }

  function rollUp() {
    document.querySelector('.description-modal__text-content').scrollTo(0, 0);
  }

  return (
    <>
    <div
      className={'description' + classMod}
      onDoubleClick={() => {
        document.getSelection().removeAllRanges();
        props.switchFullDescription(props.id);
      }}
    >
      <div className={'description__preview'+ (props.hidden ? ' hidden' : '')}>
        {preview}
      </div>
    </div>
    <div
      className={
        'description-modal__wrapper' +
        (fullDescription ? ' description-modal__wrapper_visable' : '')
      }
      ref={ref}
      onKeyDown={selectAll}
      tabIndex={0}>
      <div className="description-modal">
        <div className="description-modal__header">
          <div className="description-modal__header-caption">Описание</div>
          <div
            className="description-modal__header-button"
            onClick={() => {
              document.getSelection().removeAllRanges();
              props.switchFullDescription(props.id);
            }}>
            <div className="description-modal__header-button-img"/>
          </div>
        </div>
        <div className="description-modal__content">
          <div className="description-modal__text-content">
            <span className={'description-modal__span description-modal__span_' + props.id}>
              {props.data}
            </span>
          </div>
          <div className="description-modal__content-button" onClick={rollUp}>
            <div className="description-modal__content-button-img"/>
          </div>
        </div>
        <div className="description-modal__footer">
          <div
            className="description-modal__footer-button"
            onClick={() => props.switchFullDescription(props.id)}
          >
            Закрыть
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
