// function to concat default name and language names for services with multiple names
function createNameObject(defaultName, namesArray) {
  const nameObjectsList = namesArray.map((item) => ({
    lang: item.lang,
    name: item.name,
  }));

  const defaultNameObject = {
    lang: "DEF",
    name: defaultName,
  };

  return {
    value: [...nameObjectsList, defaultNameObject],
  };
}

export default createNameObject;
