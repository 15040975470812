import { refreshTokens } from "./async/auth";

export function getRootUrl() {
  let url = document.location.origin;
  if (url[url.length - 1] !== "/") {
    url += "/";
  }
  return url;
}

export function encodeFormData(data) {
  const formBody = [];
  for (let property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
}

export function isCashedLocale(part, locale) {
  if (typeof part === "object") {
    if (locale in part) {
      return true;
    }
    return isCashedLocale(part[Object.keys(part)[0]], locale);
  }
  return false;
}

export function fetchFromApiAuth(url, method, { body = {} }) {
  const options = {
    method,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: encodeFormData(body),
  };
  return fetch(getRootUrl() + `${url}`, options);
}

export async function fetchFromApi(url, method, { id = "", body = {} }) {
  const token = localStorage.getItem(`access_token`);
  const options = {
    method,
    headers: {},
  };

  if (token) {
    options.headers["Authorization"] = "Bearer " + token;
  }

  if (!(body instanceof FormData)) {
    options.headers["Content-Type"] = "application/json";
  }

  if (method !== "GET") {
    options.body = body instanceof FormData ? body : JSON.stringify(body);
  }

  return fetch(getRootUrl() + `${url}${id ? "/" + id : ""}`, options)
    .then((response) => {
      if (!response.ok && response.status === 401) {
        const isTokenExpired = response.headers
          .get("WWW-Authenticate")
          .includes("expired");
        if (isTokenExpired) {
          const refreshTokensParams = [url, method, { id, body }];
          return refreshTokens(refreshTokensParams);
        }

        throw new Error(`${response.status} ${response.statusText}`);
      } else {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
