export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export function updateCategory(id){
    return {
        type: UPDATE_CATEGORY,
        id
    }
}

export const RECIVE_CATEGORIES = "RECIVE_CATEGORIES";
export function reciveCategories(data, locale){
    return {
        type: RECIVE_CATEGORIES,
        data,
        locale
    }
}

export const SET_DEFAULT_CATEGORY = "SET_DEFAULT_CATEGORY";
export function setDefaultCategory(){
    return{
        type: SET_DEFAULT_CATEGORY
    }
}