import React, { useEffect, useState } from "react";

export const useSetIsEllipsisActive = (hoverRef, isHovered) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  useEffect(() => {
    setIsEllipsisActive(
      hoverRef?.current?.offsetWidth < hoverRef?.current?.scrollWidth
    );
  }, [isHovered]);
  return isEllipsisActive;
};
