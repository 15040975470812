import modelMark from "./restrictions/modelMark";
import modelSubcategory from "./restrictions/modelSubcategory";
import segment from "./restrictions/segment";
import model from "./restrictions/model";
import componentFuelType from "./restrictions/componentFuelType";
import componentGroup from "./restrictions/componentGroup";

const productRestrictionMap = {
  segment,
  "model-subcategory": modelSubcategory,
  "model-mark": modelMark,
  model,
  "component-fuel-type": componentFuelType,
  "component-group": componentGroup,
};

export default productRestrictionMap;
