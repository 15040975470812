import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import { resetModelComplectMenu } from "../../reducers/modelComplectMenu";
import { fetchFromApi } from "../service";

import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  setDocsFetching,
  setModelComplect,
  setModelComplectFetchingStatus,
  updateModelComplectField,
} from "../sync/modelComplect";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { requestModelList } from "./model";
import { requestModelGenerationV2 } from "./modelGeneration";
import { requestModelMarkList } from "./modelMark";
import { getSubcategories } from "./subcategory";

export function getModelComplect(id, actionType, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const modelMarkCode = state.modelComplectMenu.modelMarkCode;
    const modelSubcategoryCode = state.modelComplectMenu.modelSubcategoryCode;

    try {
      if (actionType === "copy") {
        dispatch(resetModelComplectMenu());
      }

      const response = await fetchFromApi("api/v1/model-complect", "GET", {
        id,
      });

      if (!response.ok) {
        throw new HttpError(response.status, response.statusText);
      }

      const data = await response.json();
      const multiLanguageArray = data.name.value;
      const name = multiLanguageArray.find((item) => item.lang === "DEF").name;

      const modelGenerationResponse = await fetchFromApi(
        "api/v1/model-generation",
        "GET",
        {
          id: data.modelGenerationCode,
        }
      );

      const modelGenerationData = await modelGenerationResponse.json();
      const { modelCode, modelCategoryCode } = modelGenerationData;

      await dispatch(getSubcategories(modelCategoryCode));
      await dispatch(requestModelMarkList(modelSubcategoryCode));
      await dispatch(
        requestModelList({
          modelSubcategoryCode,
          modelMarkCode,
          modelCode,
          isModelComplectPage: true,
        })
      );
      await dispatch(requestModelGenerationV2(modelCode, modelCategoryCode));

      dispatch(
        setModelComplect({
          ...data,
          modelCategoryCode,
          modelSubcategoryCode,
          modelMarkCode,
          modelCode,
          name,
          names: multiLanguageArray.filter((item) => item.lang !== "DEF"),
          changedFields:
            actionType === "copy"
              ? Object.keys(data).filter((key) => data[key] !== null)
              : [],
          actionType,
        })
      );
      dispatch(setModelComplectFetchingStatus());

      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }

      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setModelComplectFetchingStatus());
    }
  };
}

export function putModelComplect(modelComplectData) {
  return async (dispatch, getState) => {
    try {
      dispatch(setModelComplectFetchingStatus("update"));
      const response = await fetchFromApi("api/v1/model-complect", "PUT", {
        body: modelComplectData,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }
      dispatch(resetCurrentPageFormState());
      dispatch(setModelComplectFetchingStatus());

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setModelComplectFetchingStatus());

      return false;
    }
  };
}

export function deleteModelComplectFromServer(id, callback = null) {
  return async (dispatch, getState) => {
    const body = {
      code: id,
      tableName: "model_complect",
    };
    dispatch(setModelComplectFetchingStatus("loading"));

    try {
      const response = await fetchFromApi("api/v1/dict/record", "DELETE", {
        body,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setModelComplectFetchingStatus());
    }
  };
}

export function createUpdateModelComplectDoc({
  file,
  fileName = null,
  fileId = null,
}) {
  return async (dispatch, getState) => {
    dispatch(setDocsFetching(true));
    const state = getState();
    const modelComplect = state.modelComplect;

    const body = new FormData();
    body.append("fileStream", file);
    body.append("modelComplectCode", modelComplect.code);

    if (fileName) {
      body.append("fileName", fileName);
    }

    if (fileId) {
      body.append("id", fileId);
    }
    try {
      const response = await fetchFromApi("api/v1/doc/model-complect", "PUT", {
        body,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getModelComplectDoc());
      dispatch(setDocsFetching(false));
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body && error.body.msgCode) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setDocsFetching(false));
    }
  };
}

export function getModelComplectDoc() {
  return async (dispatch, getState) => {
    const state = getState();
    const modelComplectCode = state.modelComplect.code;

    try {
      const response = await fetchFromApi(`api/v1/doc/model-complect`, "GET", {
        id: modelComplectCode,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      const data = await response.json();

      dispatch(
        updateModelComplectField({
          files: data,
        })
      );
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
    }
  };
}

export const deleteModelComplectDoc = (fileId) => {
  return async (dispatch, getState) => {
    dispatch(setDocsFetching(true));
    try {
      const response = await fetchFromApi(
        `api/v1/doc/model-complect/${fileId}`,
        "DELETE",
        {}
      );
      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getModelComplectDoc());
      dispatch(setDocsFetching(false));
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setDocsFetching(false));
    }
  };
};

export function downloadModelComplectDoc(id, callback = null) {
  return async (dispatch, getState) => {
    try {
      const response = await fetchFromApi("api/v1/doc/file", "GET", {
        id,
      });

      const file = await response.blob();

      if (callback) {
        callback(file);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
}


export async function copyModelComplect(originalCode) {
    try {
      const response = await fetchFromApi("api/v1/model-complect/copy", "POST", {
        body:{originalCode},
      });
      const data = await response.json();
      return  data?.code
    } catch (error) {
      console.log(error.message);
    }
  };
  
  export function getModelComplects(props) {
    return async (dispatch, getState) => {
      const state = getState();  
        const body = {
          lang: state.i18.current,
          offset: state.guides.offset,
          limit: props?.limit || state.guides.limit,
          hidden: state.guides.hidden,
          searchString: props?.searchString || "",
          colListCode:
          props?.colListCode ? props?.colListCode : state.guides.currentSearchValue === 'def'
            ? null
            : state.guides.currentSearchValue,
          };

          
        try {
          const response = await fetchFromApi("api/v1/model-complect/filter/page", "POST", {
            body,
          });
          const data = await response.json();
          return  data.data
        } catch (error) {
          console.log(error.message);
        }
      };
    }


