import { getHTTPData } from "../../../utils/getData";

const tableURLMap = {
  productBrand: "product-brand/filter/page",
  productPremium: "product-premium/filter/page",
  conditionType: "condition-type/filter",
  market: "market/filter/page",
  componentType: "component-type/filter",
  componentTypeWideFilter: "component-type/wide-filter",
  componentFuelType: "component-fuel-type/filter/page",
  componentGearType: "component-gear-type/filter/page",
  componentTurboType: "component-turbo-type/filter/page",
  componentEngineBuild: "component-engine-build/filter/page",
  componentCoolType: "component-cool-type/filter/page",
  producer: "producer/filter/page",
  modelSubcategory: "model-subcategory/filter/page",
  modelMark: "model-mark/filter",
  modelMarkWideFilter: "model-mark/wide-filter/page",
  modelDrive: "model-drive/filter/page",
  modelBody: "model-body/filter/page",
  modelClass: "model-class/filter/page",
};

export const API = {
  getList: function (tableName, body) {
    return getHTTPData(`api/v1/${tableURLMap[tableName]}`, "POST", body);
  },
};
