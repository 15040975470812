import { RECEIVE_HISTORY_CHANGES, SET_HISTORY_DATA_LOADING } from "../actions/sync/historyChanges";

const initialState = {
  columns: [],
  list: [],
  isLoading: false,
};

function historyChanges(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_HISTORY_CHANGES:
      return Object.assign({}, state, action.payload);
    case SET_HISTORY_DATA_LOADING:
      return Object.assign({}, state, { isLoading: action.payload });
    default:
      return state;
  }
}

export default historyChanges;
