import { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideTreeMenu from "../../components/sideTreeMenu";
import { getModelComplectMenu } from "../../redux/actions/async/modelComplectMenu";
import {
  resetModelComplectMenu,
  setActiveMenuItem,
} from "../../redux/reducers/modelComplectMenu";
import "./index.scss";
import { setCurrentPath } from "../../redux/reducers/currentPath";
import {useDocumentTitle} from "../../hooks/useDocumentTitle";

const ModelComplectPage = () => {
  const dispatch = useDispatch();
  const modelComplectMenu = useSelector((state) => state.modelComplectMenu);
  const { modelComplectCode, componentCode, conditionCode } = useParams();
  const { pathname, state } = useLocation();

  useDocumentTitle('Техника')

  useEffect(() => {
    return () => dispatch(resetModelComplectMenu());
  }, [])

  useEffect(() => {
    if (pathname) {
      dispatch(setCurrentPath(pathname));
    }
  }, [pathname]);

  useEffect(() => {
    if (modelComplectCode) {
      dispatch(getModelComplectMenu(modelComplectCode));
    }
  }, [modelComplectCode]);

  useEffect(() => {
    if (pathname && modelComplectMenu.modelComplectCode !== "") {
      dispatch(setActiveMenuItem(pathname));
    }
  }, [pathname, modelComplectMenu.modelComplectCode]);

  // ${modelComplectMenu.modelComplectName} - пока решили не выводить название так как в аналогичных страницах в сервисе меню нет имени

  return (
    <>
      <SideTreeMenu
        menu={modelComplectMenu}
        title={
          componentCode || conditionCode || state.actionType === "edit"
            ? `Редактирование ТС`
            : `Создание ТС`
        }
      />
      <section className="layout-wrapper">
        <Outlet />
      </section>
    </>
  );
};

export default ModelComplectPage;
