// заполняет в мультиязычной форме пустое наименование названием
function updateDefaultLangName(multiLanguageFormData, defaultName) {
  if(defaultName) {
    multiLanguageFormData.forEach((langItem) => {
      if(!langItem.name) {
        langItem.name = defaultName
    }})
  }
  return multiLanguageFormData
}
export default updateDefaultLangName
