import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import SideTreeMenu from "../../components/sideTreeMenu";
import { setActiveReportMenuItem } from "../../redux/reducers/reportMenu";
import {useDocumentTitle} from "../../hooks/useDocumentTitle";

const ReportPageLayout = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const reportMenu = useSelector((state) => state.reportMenu.menu);

  useDocumentTitle('Отчеты');

  useEffect(() => {
    dispatch(setActiveReportMenuItem(pathname));
  }, [pathname]);

  return (
    <>
      <SideTreeMenu menu={reportMenu} title={"Отчеты"} />
      <section className="layout-wrapper">
        <Outlet />
      </section>
    </>
  );
};

export default ReportPageLayout;
