const childrensMap = {
  specOverFlg: {
    name: "Перекрываемые спецификации",
    linkName: "spec-over",
  },
  specComponentGrFlg: {
    name: "Группа узла",
    linkName: "spec-component-group",
  },
};

function normalizeSpecMenu(menu, specCode) {
  const specLink = `/spec/${specCode}`;
  const activeChildrens = Object.keys(menu).filter((child) => menu[child]);

  return {
    specCode,
    active: false,
    link: specLink,
    actionType: "edit",
    children: activeChildrens
      ? activeChildrens.map((child) => {
          return {
            link: `${specLink}/${childrensMap[child].linkName}`,
            active: false,
            name: childrensMap[child].name,
            code: childrensMap[child].linkName,
          };
        })
      : [],
  };
}

export default normalizeSpecMenu;
