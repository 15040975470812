import { RESET_APP } from '../actions/sync/init';
import {
  RECIVE_PRODUCT_INFO,
  SET_PRODUCT_CODE,
  WAIT_FOR_PRODUCT,
} from '../actions/sync/product';

const defState = {
  code: '',
  fetching: false,
  modelMark: '',
  model: '',
  modelGeneration: '',
  modelComplect: '',
  startYear: 0,
  endYear: 0,
  modelDrive: '',
  modelBody: '',
  modelClass: '',
  hidden: false,
};

function product(state = defState, action) {
  switch (action.type) {
    case WAIT_FOR_PRODUCT:
      return Object.assign({}, state, { fetching: action.mode });
    case RECIVE_PRODUCT_INFO:
      return Object.assign({}, state, {
        modelMark: action.json.modelMark,
        model: action.json.model,
        modelGeneration: action.json.modelGeneration,
        modelComplect: action.json.modelComplect,
        startYear: action.json.startYear,
        endYear: action.json.endYear,
        modelDrive: action.json.modelDrive,
        modelBody: action.json.modelBody,
        modelClass: action.json.modelClass,
        hidden: action.json.hidden,
      });
    case SET_PRODUCT_CODE:
      return Object.assign({}, state, { code: action.code });
    case RESET_APP:
      return defState;
    default:
      return state;
  }
}

export default product;
