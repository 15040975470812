import { useState } from "react";
import Calendar from "react-calendar";
import { getValidDate } from "../../utils/getDate";
import DatePickerContainer from "../datePickerContainer";

import "./index.scss";

const YearPicker = ({
  label,
  id,
  onChangeData,
  placeholder = "Выберите",
  minDate = null,
  maxDate = null,
  required = false,
  error = null,
  value = null,
  disabled = false,
}) => {
  const [isOpen, setOpen] = useState(false);

  function handleOnChangeDate(value, event) {
    onChangeData(value.getFullYear(), id);
    setOpen(false);
  }

  return (
    <DatePickerContainer
      label={label}
      id={id}
      placeholder={placeholder}
      isOpen={isOpen}
      setOpen={setOpen}
      required={required}
      error={error}
      value={value}
      onChangeData={onChangeData}
      disabled={disabled}
    >
      <Calendar
        value={value ? getValidDate(value.toString()) : ""}
        onClickYear={handleOnChangeDate}
        minDetail="decade"
        maxDetail="decade"
        className="calendar year-calendar"
        locale="ru-RU" // TODO locale refactoring
        minDate={minDate ? getValidDate(minDate.toString()) : new Date("1900")}
        maxDate={maxDate ? getValidDate(maxDate.toString()) : new Date("2099")}
        defaultActiveStartDate={maxDate ? getValidDate(maxDate.toString()) : new Date()}
      />
    </DatePickerContainer>
  );
};

export default YearPicker;
