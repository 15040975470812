import {
  SET_SELECT_LIMIT_MAX,
  SET_SELECT_SEARCH_MAX,
  SET_XLS_EXPORT_MAX,
  SET_XLS_IMPORT_MAX,
} from '../actions/sync/settings';

const defState = {
  selectSearchMax: null,
  selectLimitMax: null,
  xlsExportMax: null,
  xlsImportMax: null,
};

function settings(state = defState, action) {
  switch (action.type) {
    case SET_SELECT_SEARCH_MAX:
      return Object.assign({}, state, { selectSearchMax: action.val });
    case SET_SELECT_LIMIT_MAX:
      return Object.assign({}, state, { selectLimitMax: action.val });
    case SET_XLS_EXPORT_MAX:
      return Object.assign({}, state, { xlsExportMax: action.val });
    case SET_XLS_IMPORT_MAX:
      return Object.assign({}, state, { xlsImportMax: action.val });
    default:
      return state;
  }
}

export default settings;
