export const UPDATE_MODEL_CLASS = "UPDATE_MODEL_CLASS";
export function updateModelClass(id){
    return{
        type: UPDATE_MODEL_CLASS,
        id
    }
}

export const CLEAR_MODEL_CLASS = "CLEAR_MODEL_CLASS";
export function clearModelClass(id){
    return{
        type: CLEAR_MODEL_CLASS,
    }
}

export const RECEIVE_MODEL_CLASSES = 'RECEIVE_MODEL_CLASSES'
export function receiveModelClasses(payload) {
    return {
        type: RECEIVE_MODEL_CLASSES,
        payload
    }
}