export const WAIT_FOR_PRODUCT = 'WAIT_FOR_PRODUCT';
export function waitForProduct(mode) {
  return {
    type: WAIT_FOR_PRODUCT,
    mode,
  };
}

export const RECIVE_PRODUCT_INFO = 'RECIVE_PRODUCT_INFO';
export function reciveProductInfo(json) {
  return {
    type: RECIVE_PRODUCT_INFO,
    json,
  };
}

export const SET_PRODUCT_CODE = 'SET_PRODUCT_CODE';
export function setProductCode(code) {
  return {
    type: SET_PRODUCT_CODE,
    code,
  };
}

export const CHANGE_COMPONENT_MODE = 'CHANGE_COMPONENT_MODE';
export function changeComponentMode(id) {
  return {
    type: CHANGE_COMPONENT_MODE,
    id,
  };
}
