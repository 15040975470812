import { connect } from "react-redux";
import { GuidesPage } from "../../pages/guides";
import { setCurrentGuide } from "../actions/sync/guides";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentGuide: (pageCode) => dispatch(setCurrentGuide(pageCode)),
  };
};

const VisibleGuidesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuidesPage);

export default VisibleGuidesPage;
