import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../pureComponents/modal/confirmModal";
import { resetCurrentPageFormState } from "../../redux/actions/sync/currentPageFormState";

const ModalLinkWrapper = (
  { linkProps, className, children, disabled = false },
  ref
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allChg, submitFunction } = useSelector(
    (state) => state.currentPageFormState
  );
  const [showModal, setShowModal] = useState(false);

  const handleSave = async () => {
    const isValid = await submitFunction(); // вызов функции submit формы на текущей странице
    if (isValid) {
      const options = {};

      if (linkProps.state) {
        options.state = linkProps.state;
      }

      navigate(linkProps.to, options);
    }
    setShowModal(false);
    dispatch(resetCurrentPageFormState());
  };

  const handleWithoutSave = () => {
    const options = {};

    if (linkProps.state) {
      options.state = linkProps.state;
    }

    navigate(linkProps.to, options);
    setShowModal(false);
    dispatch(resetCurrentPageFormState());
  };

  return (
    <>
      {disabled ? (
        <button className={className} disabled>
          {children}
        </button>
      ) : allChg ? (
        <div className={className} onClick={() => setShowModal(true)}>
          {children}
        </div>
      ) : (
        <Link {...linkProps} className={className}>
          {children}
        </Link>
      )}
      <ConfirmModal
        active={showModal}
        closeModal={() => setShowModal(false)}
        onSave={handleSave}
        onContinueWithoutSave={handleWithoutSave}
      />
    </>
  );
};

export default ModalLinkWrapper;
