import { getHTTPData } from "../../utils/getData";
import {fetchFromApi} from "../../redux/actions/service";

export const API = {
	getViscoClass: function (str) {
		return getHTTPData("api/v1/visco-classification/" + str, 'GET');
	},
	getCode: function () {
		const token = localStorage.getItem(`access_token`);;
		return fetchFromApi("api/v1/dict/code","GET",{token})
			.then((res) => { return res.text() })	},
	saveChanges: function (body) {
		return getHTTPData("api/v1/visco-classification", 'PUT', body);
	}
}
