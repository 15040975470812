import { connect } from 'react-redux';
import {
  changeLogin,
  changePassword,
  clearAuthForm,
  setLoginAccess,
  setLoginError,
  setRightLogin,
  switchPasswordVisable,
} from '../actions/sync/personal';
import { AuthForm } from '../../pages/login';
import { removeTokens, requestTokensFromServer } from '../actions/async/auth';

const mapStateToProps = (state) => {
  return {
    autorizationStage: state.auth.stage,
    access: state.auth.access,
    authErrorCode: state.auth.code,
    passError: state.auth.error,

    login: state.personal.login,
    loginAccess: state.personal.loginAccess,
    password: state.personal.password,
    passwordVisable: state.personal.passwordVisable,
    loginError: state.personal.loginError,
    loginRight: state.personal.loginRight,

    phrases: state.i18.phrases.authForm,
    lang: state.i18.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeLogin: (login) => {
      dispatch(changeLogin(login));
    },
    onChangePassword: (password) => {
      dispatch(changePassword(password));
    },
    onLogin: () => dispatch(requestTokensFromServer()),
    onCancel: () => dispatch(clearAuthForm()),
    setLoginAccess: (access) => dispatch(setLoginAccess(access)),
    setRightLogin: () => dispatch(setRightLogin()),
    setWrongLogin: (error) => dispatch(setLoginError(error)),
    switchPasswordVisable: () => dispatch(switchPasswordVisable()),
    onNoAccess: ()=> dispatch(removeTokens()),
  };
};

const VisibleAuthForm = connect(mapStateToProps, mapDispatchToProps)(AuthForm);

export default VisibleAuthForm;
