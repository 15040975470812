import { RECIVE_PRODUCT_INFO } from '../actions/sync/product';

const defState = {
  by_id: {},
  all_ids: [],
};

function conditions(state = defState, action) {
  switch (action.type) {
    case RECIVE_PRODUCT_INFO:
      return Object.assign({}, state, getConditionsFromInfo(action.json));
    default:
      return state;
  }
}

function getConditionsFromInfo(info) {
  const res = {
    all_ids: [],
    by_id: {},
  };
  if(!info.component) {
    return res; // Костыль
  }

  info.component.forEach((comp, i) => {
    if (comp.condition !== undefined && comp.condition !== null) {
      comp.condition.forEach((cond, j) => {
        res.all_ids.push('condition_' + i + '_' + j);
        res.by_id['condition_' + i + '_' + j] = {
          conditionType: cond.conditionType,
          market: cond.market,
          description: 'description_' + i + '_' + j,
          hidden: cond.hidden,
          intervals: getIds(cond.interval, 'interval', i, j),
          specVisco: getIds(cond.specVisco, 'specVisco', i, j),
        };
      });
    }
  });
  return res;
}

function getIds(data, caption, comp, cond) {
  if (data === undefined) {
    return [];
  }
  return data.map((el, i) => caption + '_' + comp + '_' + cond + '_' + i);
}

export default conditions;
