import { RECEIVE_VISCO_CLASS } from "../actions/sync/viscoClass";

const initialState = {
	list: [],
};

function viscoClass(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_VISCO_CLASS:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default viscoClass;
