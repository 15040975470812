import { RECEIVE_VISCO } from "../actions/sync/visco";

const initialState = {
	list: [],
};

function visco(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_VISCO:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default visco;
