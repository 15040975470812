import { connect } from 'react-redux';
import { Table } from '../../pureComponents/table';
import { setCurrentTableRow } from '../actions/sync/table';
import {reciveComponentInfo} from "../actions/sync/component";

const mapStateToProps = (state, ownProps) => {
  return {
    data: state[ownProps.id],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectTableRow: (id) => dispatch(setCurrentTableRow(id, ownProps.id)),
    setModelComplectCode: (code) => dispatch(reciveComponentInfo({ modelComplectCode: code })),
  };
};

const VisibleTable = connect(mapStateToProps, mapDispatchToProps)(Table);

export default VisibleTable;
