import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductForm from "./productForm";
import { useMultiLanguageList } from "../../hooks/useMultiLanguageList";
import productFormSchema from "../../validations/productForm";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import {
  resetCurrentPageFormState,
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import {
  resetProductFormState,
  updateProductFormChangedFields,
  updateProductFormField,
} from "../../redux/reducers/productForm";
import {
  deleteProductFromServer,
  getProduct,
  putProduct,
} from "../../redux/actions/async/product";
import { getProductFormSelectors } from "../../redux/actions/async/productFormSelectors";
import createNameObject from "../../utils/createNameObject";
import updateDefaultLangName from "../../utils/updateDefaultLangName";

const ProductPage = () => {
  const navigate = useNavigate();
  const { productCode } = useParams();
  const { state } = useLocation();
  const actionType = state ? state.actionType : "new";

  const dispatch = useDispatch();
  const productData = useSelector((state) => state.productForm);
  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const {
    multiLanguageFormData,
    multiLanguageFormDataChanged,
    checkMultiLanguageFormIsValid,
    validateMultiLanguageForm,
    setMultiLanguageFormDataChanged,
    ...multiLanguageProps
  } = useMultiLanguageList(productData.names);

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  useEffect(() => {
    dispatch(getProductFormSelectors());

    return () => {
      dispatch(resetProductFormState());
      dispatch(resetCurrentPageFormState());
    };
  }, []);

  useEffect(() => {
    if (productCode && actionType !== "new") {
      dispatch(
        getProduct(productCode, actionType)
      );

      return;
    }
  }, [productCode, actionType]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitProduct));
  }, [productData, multiLanguageFormData]);

  useEffect(() => {
    if (multiLanguageFormDataChanged) {
      dispatch(updateProductFormChangedFields("name"));
      dispatch(updateAllChg(true));
    }
  }, [multiLanguageFormDataChanged]);

  const handleDateChange = useCallback(
    (value, id) => {
      dispatch(
        updateProductFormField({
          [id]: value,
        })
      );

      dispatch(updateProductFormChangedFields(id));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [actionType, dataChanged]
  );

  const handleInputChange = useCallback(
    ({ target: { id, type, value } }) => {
      if (type === "checkbox") {
        dispatch(
          updateProductFormField({
            [id]: !productData[id],
          })
        );

        dispatch(updateProductFormChangedFields(id));

        if (!dataChanged) {
          dispatch(updateAllChg(true));
        }
        return;
      }

      dispatch(
        updateProductFormField({
          [id]: value,
        })
      );

      dispatch(updateProductFormChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [productData, actionType, dataChanged]
  );

  const handleDropdownChange = useCallback(
    ({ code }, id) => {
      dispatch(
        updateProductFormField({
          [id]: code,
        })
      );

      dispatch(updateProductFormChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },

    [actionType, dataChanged]
  );

  const handleDeleteProduct = useCallback(() => {
    setDeleteModalActive(true);
  }, []);

  const deleteProduct = () => {
    dispatch(
      deleteProductFromServer(productCode, () => {
        navigate("/products", { state: { pageCode: 40, withoutReset: true} });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitProduct() {
    const isFormValid = await productFormSchema.isValid(productData, {
      abortEarly: false,
    });

    const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();
    if (isFormValid && isMultiLanguageFormValid) {
      updateDefaultLangName(multiLanguageFormData, productData.name)
      const namesList = createNameObject(
        productData.name,
        multiLanguageFormData
      );
      return dispatch(putProduct(namesList, actionType));
    } else {
      if (!isFormValid) {
        productFormSchema
          .validate(productData, { abortEarly: false })
          .catch((err) => {
            const errors = err.inner.reduce((acc, error) => {
              return {
                ...acc,
                [error.path]: error.message,
              };
            }, {});
            setErrors(() => {
              return {
                ...errors,
              };
            });
          });
      }

      if (!isMultiLanguageFormValid) {
        validateMultiLanguageForm();
      }
      return false;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const isValid = await submitProduct();
    if (isValid) {
      setMultiLanguageFormDataChanged(false);
      navigate(`/product/${productData.code}`, {
        state: {
          actionType: "edit",
        },
      });
    }
  };

  return (
    <>
      <ProductForm
        formErrors={formErrors}
        actionType={actionType}
        productCode={productCode}
        multiLanguageFormData={multiLanguageFormData}
        multiLanguageProps={multiLanguageProps}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        handleDropdownChange={handleDropdownChange}
        handleDeleteProduct={handleDeleteProduct}
        openHistoryChanges={openHistoryChanges}
        onSubmit={onSubmit}
      />
      <DeleteModal
        active={deleteModalActive}
        title={
          <>
            Будут удалены все данные по продукту.
            <br /> Продолжить?
          </>
        }
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteProduct}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"product"}
        rowCode={productCode}
        listType={"hist"}
      />
    </>
  );
};

export default ProductPage;
