import isJSONString from "../utils/isJSONString";

// TODO move to i18n service
const actionTypeNames = {
  C: "Создание",
  U: "Изменение",
  D: "Удаление",
}

function normalizeHistoryData(data, lang, vocabulary) {
  const normalizedData = data.history.map((item) => {
    const parsedData = JSON.parse(item.data);

    for (const prop in parsedData) {
      if (
        parsedData[prop] &&
        typeof parsedData[prop] === "object" &&
        parsedData[prop].hasOwnProperty("code")
      ) {
        const parsedPropNames = isJSONString(parsedData[prop].refColName)
          ? JSON.parse(parsedData[prop].refColName).value
          : parsedData[prop].refColName;

        parsedData[prop] = Array.isArray(parsedPropNames)
          ? parsedPropNames.find((item) => item.lang === lang || item.lang === "DEF").name
          : parsedPropNames;
      }

      if (typeof parsedData[prop] === "boolean") {
        parsedData[prop] = parsedData[prop]
          ? vocabulary[1].name
          : vocabulary[0].name;
      }      
      
      if (
        parsedData[prop]?.value && parsedData[prop]?.type  === "jsonb" &&
        typeof parsedData[prop] === "object" && isJSONString(parsedData[prop].value)
      ) {
      
      const values = JSON.parse(parsedData[prop]?.value).value
      parsedData[prop].value = Array.isArray(values)
        ? values.find((item) => item.lang === lang || item.lang === "DEF").name
        : values;
      }
    }

    if (parsedData.name && typeof parsedData.name === "object") {
      parsedData.name = parsedData.name.value.find(
        (item) => item.lang === lang || item.lang === "DEF"
      ).name;
    }

    return {
      ...item,
      ...parsedData,
      actionType: actionTypeNames[item.actionType]
    };
  });

  // normalized data view
  return normalizedData.reduce((acc, item, index) => {
    if (index === 0) {
      return [item];
    }

    return [...acc, { ...acc[index - 1], ...item }];
  }, []);
}

export default normalizeHistoryData