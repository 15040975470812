const initialState = {
  productCode: null,
  data: [],
  fetchingStatus: null,
};

function productViscos(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_CODE_FOR_PV:
      return { ...state, productCode: action.payload };
    case SET_PRODUCT_VISCOS:
      return { ...state, data: action.payload };
    case UPDATE_PRODUCT_VISCO:
      return action.payload.actionType === "C"
        ? {
            ...state,
            data: state.data.filter((item) => item.id !== action.payload.id),
          }
        : {
            ...state,
            data: state.data.map((item) => {
              if (item.id !== action.payload.id) return item;

              return {
                ...item,
                actionType: action.payload.actionType,
              };
            }),
          };
    case SET_PRODUCT_VISCOS_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_PRODUCT_CODE_FOR_PV = "SET_PRODUCT_CODE_FOR_PV";
const SET_PRODUCT_VISCOS = "SET_PRODUCT_VISCOS";
const UPDATE_PRODUCT_VISCO = "UPDATE_PRODUCT_VISCO";
const SET_PRODUCT_VISCOS_FETCHING_STATUS =
  "SET_PRODUCT_VISCOS_FETCHING_STATUS";

export const setProductCodeForPV = (payload) => ({
  type: SET_PRODUCT_CODE_FOR_PV,
  payload,
});

export const setProductViscos = (payload) => ({
  type: SET_PRODUCT_VISCOS,
  payload,
});

export const addProductVisco = (payload) => ({
  type: SET_PRODUCT_VISCOS,
  payload,
});

export const updateProductVisco = (payload) => ({
  type: SET_PRODUCT_VISCOS,
  payload,
});

export const setProductViscosFetchingStatus = (payload) => ({
  type: SET_PRODUCT_VISCOS_FETCHING_STATUS,
  payload,
});

export default productViscos;

