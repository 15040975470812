import { RESET_APP } from '../actions/sync/init';
import { NEXT_STEP } from '../actions/sync/step';

const defState = '';

function step(state = defState, action) {
  switch (action.type) {
    case NEXT_STEP:
      return action.step;
    case RESET_APP:
        return defState;
    default:
      return state;
  }
}

export default step;
