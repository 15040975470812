import React from "react";

const PlusIcon = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2263_61648)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 1C8.5 0.723858 8.27614 0.5 8 0.5C7.72386 0.5 7.5 0.723858 7.5 1V7.5H1C0.723858 7.5 0.5 7.72386 0.5 8C0.5 8.27614 0.723858 8.5 1 8.5H7.5V15C7.5 15.2761 7.72386 15.5 8 15.5C8.27614 15.5 8.5 15.2761 8.5 15V8.5H15C15.2761 8.5 15.5 8.27614 15.5 8C15.5 7.72386 15.2761 7.5 15 7.5H8.5V1Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2263_61648">
          <rect width="16" height="16" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusIcon;
