import {
  RECIVE_USER_DATA,
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CLEAR_AUTH_FORM,
  SWITCH_USER_INFO,
  SET_LOGIN_ERROR,
  SET_RIGHT_LOGIN,
  SWITCH_PASSWORD_VISABLE,
  SET_LOGIN_ACCESS,
} from '../actions/sync/personal';
import { FETCHING_DATA } from '../actions/sync/fetchingData';
import { RESET_APP } from '../actions/sync/init';

const defState = {
  login: '',
  loginAccess: false,
  loginError: '',
  loginRight: false,
  password: '',
  passwordVisable: false,
  userData: {},
  username: '',
  fio: '',
  position: '',
  fetching: false,
  showUserInfo: false,
  passwordType: 'password',
};

function personal(state = defState, action) {
  switch (action.type) {
    case CHANGE_LOGIN:
      return Object.assign({}, state, { login: action.login });
    case SET_LOGIN_ACCESS:
      return Object.assign({}, state, { loginAccess: action.access });
    case SET_LOGIN_ERROR:
      return Object.assign({}, state, {
        loginError: action.error,
        loginRight: false,
      });
    case SET_RIGHT_LOGIN:
      return Object.assign({}, state, { loginError: '', loginRight: true });
    case CHANGE_PASSWORD:
      return Object.assign({}, state, { password: action.password });
    case FETCHING_DATA:
      if (action.id === 'personal') {
        return Object.assign({}, state, { fetching: true });
      }
      return state;
    case RECIVE_USER_DATA:
      return Object.assign({}, state, {
        userData: action.data,
        username: action.data.username,
        fio: action.data.fullName,
        position:
          action.data.authorities.filter(
            (el) => el.authority === 'ROLE_GPN_EXPERT'
          ).length > 0
            ? 'expert'
            : 'user',
        fetching: false,
      });
    case SWITCH_USER_INFO:
      return Object.assign({}, state, { showUserInfo: !state.showUserInfo });
    case SWITCH_PASSWORD_VISABLE:
      return Object.assign({}, state, {
        passwordVisable: !state.passwordVisable,
      });
    case CLEAR_AUTH_FORM:
      return defState;
    case RESET_APP:
      return defState;
    default:
      return state;
  }
}

export default personal;
