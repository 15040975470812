import React from 'react';
import { Link } from 'react-router-dom';
import VisibleHeader from '../../redux/containers/visibleHeader';
import './index.css';

export function UserAgreement(props) {
  return (
    <div className="user-agreement">
      <VisibleHeader />
      <div className="user-agreement__contant">
        <div className="user-agreement__header">
          Политика конфиденциальности
        </div>

        <div className="user-agreement__text-block">
          Поскольку мы стремимся предоставить вам высококачественный
          персонализированный опыт, мы понимаем решающее значение
          конфиденциальности. Настоящая Политика конфиденциальности описывает,
          как ваша личная информация собирается, используется и передается,
          когда вы посещаете и взаимодействуете с
        </div>

        <div className="user-agreement__subheader">
          Сбор и использование информации
        </div>
        <div className="user-agreement__text-block">
          собирает и использует ваши данные для предоставления вам услуг и
          улучшения Веб-сайта. Посещая, регистрируясь и взаимодействуя с
          Веб-сайтом, вы соглашаетесь на сбор и использование вашей информации в
          соответствии с этой политикой.
        </div>

        <div className="user-agreement__subheader">Безопасность данных</div>
        <div className="user-agreement__text-block">
          Доступ к ней предоставляется только сотрудникам, которым нужна ваша
          личная информация для выполнения определенной работы (например,
          представителю службы поддержки клиентов). Принимает все необходимые
          меры для обеспечения того, чтобы ваши данные обрабатывались безопасно
          и в соответствии с настоящей Политикой конфиденциальности.
        </div>

        <div className="user-agreement__subheader">Передача данных</div>
        <div className="user-agreement__text-block">
          Ваша информация, включая Персональные данные, может передаваться и
          храниться на компьютерах, расположенных за пределами вашего штата,
          провинции и страны, где законы о защите данных отличаются. Никакая
          передача ваших Персональных данных компании или стране не будет
          осуществляться, если не будет обеспечена безопасность ваших данных и
          другой информации.
        </div>

        <Link className='user-agreement__button-wrapper' to={'/login'}>
          <div className="user-agreement__agree-button">Принимаю</div>
        </Link>
      </div>
    </div>
  );
}
