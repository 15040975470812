import React from "react";
import './index.scss';

export function NoData(props){
    return(
        <div className="no-data">
          <div className="no-data__header">Нет данных для отображения</div>
          <div className="no-data__caption">Для продолжения работы выберите {props.data[props.lang]} в левом меню</div>
        </div>
    )
}