import { connect } from 'react-redux';
import { Filter } from '../../components/filter';
import { selectCategory } from '../actions/async/category';
import { blurEngineCap } from '../actions/async/engineCap';
import { dropModelGeneration, selectModelGeneration } from '../actions/async/modelGeneration';
import { dropModelMark, selectModelMark } from '../actions/async/modelMark';
import {
  dropSubcategory,
  selectSubcategory,
} from '../actions/async/subcategory';
import { blurYear } from '../actions/async/years';
import {
  hendleUpdateEngineCap,
} from '../actions/sync/engineCap';
import { hendleUpdateYear } from '../actions/sync/years';
import { updateGuidesSearchValue } from '../actions/async/guides';
import { selectModelList } from '../actions/async/model';
import { clearModelList } from '../actions/sync/model';

const mapStateToProps = (state, ownProps) => {
  switch (ownProps.filter.id) {
    case 'engineCap':
    case 'years':
      return {
        value: state[ownProps.filter.id].value,
        err: state[ownProps.filter.id].errorValue,
        name: ownProps.filter.name,
        type: ownProps.filter.type,
        blocked: ownProps.blocked,
        required: ownProps.filter.required,
      };
    case 'category':
      return {
        data: state[ownProps.filter.id].list,
        current: state[ownProps.filter.id].current,
        name: ownProps.filter.name,
        type: ownProps.filter.type,
        priority: 'primary',
        value: state[ownProps.filter.id].current,
        blocked: ownProps.blocked,
        required: ownProps.filter.required,
      };
    case 'guides':
      return {
        data: state[ownProps.filter.id].searchValueList,
        current: state[ownProps.filter.id].currentSearchValue,
        name: 'Заголовок',
        type: 'selector',
        value: state[ownProps.filter.id].currentSearchValue,
        mode: 'filter',
        blocked: ownProps.blocked,
        required: ownProps.filter.required,
      };
    default:
      return {
        data: state[ownProps.filter.id].list,
        current: state[ownProps.filter.id].current,
        id: ownProps.filter.id,
        name: ownProps.filter.name,
        type: ownProps.filter.type,
        value: state[ownProps.filter.id].current,
        mode: 'selector',
        blocked: ownProps.blocked,
        required: ownProps.filter.required,
        searchable: !!ownProps.filter?.searchable,
      };
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.filter.id) {
    case 'category':
      return {
        onChange: (id) => dispatch(selectCategory(id)),
      };
    case 'subcategory':
      return {
        onChange: (id) => dispatch(selectSubcategory(id)),
        onDrop: () => dispatch(dropSubcategory()),
      };
    case 'modelMark':
      return {
        onChange: (id) => dispatch(selectModelMark(id)),
        onDrop: () => dispatch(dropModelMark()),
      };
    case 'modelGeneration':
      return {
        onChange: (id) => dispatch(selectModelGeneration(id)),
        onDrop: () => dispatch(dropModelGeneration()),
      };
    case 'model':
      return {
        onChange: (id) => dispatch(selectModelList(id)),
        onDrop: () => dispatch(clearModelList()),
      };
    case 'engineCap':
      return {
        onChange: (val) => dispatch(hendleUpdateEngineCap(val)),
        onBlur: (val) => dispatch(blurEngineCap(val)),
      };
    case 'years':
      return {
        onChange: (val) => dispatch(hendleUpdateYear(val)),
        onBlur: (val) => dispatch(blurYear(val)),
      };
    case 'guides':
      return {
        onChange: (val) => dispatch(updateGuidesSearchValue(val)),
        onDrop: () => dispatch(updateGuidesSearchValue()),
      };
    default:
      return {};
  }
};

const VisibleFilter = connect(mapStateToProps, mapDispatchToProps)(Filter);

export default VisibleFilter;
