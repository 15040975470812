function generateModelComplectLink(
  modelComplectCode,
  subLink = {
    type: null,
    code: null,
  },
  option = null
) {
  let link = `/model-complect/${modelComplectCode}`;

  if (subLink.type) {
    link = link + `/${subLink.type}/${subLink.code}`;
  }

  if (option) {
    link = link + `/${option}`;
  }

  return link;
}

export default generateModelComplectLink;
