import normalizeSpecOverForServer from "../../../normalizers/specOver";
import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import { setActiveSpecMenuItem } from "../../reducers/specMenu";
import {
  setSpecCodeForSO,
  setSpecOver,
  setSpecOverFetchingStatus,
} from "../../reducers/specOver";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getSpecMenu } from "./specMenu";

export function getSpecOver(specCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/spec-spec";

    try {
      dispatch(setSpecOverFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${specCode}/locale/${lang}`
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(
        setSpecOver(
          data.map((spec) => ({
            id: spec.id,
            spec: spec.specOverCode,
            name: spec.specOverName,
          }))
        )
      );
      dispatch(setSpecCodeForSO(specCode));
      dispatch(setSpecOverFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setSpecOverFetchingStatus());
    }
  };
}

export function putSpecOver() {
  return async (dispatch, getState) => {
    const state = getState();
    const specOver = state.specOver.data;
    const specCode = state.specOver.specCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setSpecOverFetchingStatus("update"));

      const response = await fetchFromApi("api/v1/spec-spec", "POST", {
        body: normalizeSpecOverForServer(specOver, specCode)
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getSpecMenu(specCode));
      dispatch(setActiveSpecMenuItem(pathname));
      dispatch(resetCurrentPageFormState());
      dispatch(getSpecOver(specCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setSpecOverFetchingStatus());

      return false;
    }
  };
}

export function deleteSpecOverFromServer(specCode, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const specCode = state.specMenu.specCode;
    const data = state.specOver.data.filter((spec) => spec.actionType !== "C");

    const body = {
      code: specCode,
      data,
    };

    try {
      dispatch(setSpecOverFetchingStatus("loading"));
        const response = await fetchFromApi("api/v1/spec-spec/all", "DELETE", {
          body
        });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getSpecMenu(specCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setSpecOverFetchingStatus());
    }
  };
}
