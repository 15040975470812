import React from 'react';
import VisibleInterval from '../../redux/containers/visibleInterval';
import './index.scss';
import VisibleDescription from '../../redux/containers/visibleDescription';
import VisibleReport from '../../redux/containers/visibleReport';

export function ConditionTableBlock(props) {

  return (
    <div className="condition-table-block">
      <div className={'condition-section ' + (props.data.hidden? 'hidden-bg':'') }>
        <div className="condition-section__attributes">
          <div className="condition-section__row">
            <div className={'condition-section__parameter ' + (props.data.hidden? 'hidden':'') }>
						{/* <div className="condition-section__parameter"> */}
              {props.data.conditionType}
            </div>
            <div className={'condition-section__value'}>
              {<VisibleInterval id={props.data.intervals[0]} />}
            </div>
          </div>
          <div className="condition-section__row">
            <div className={'condition-section__parameter ' + (props.data.hidden? 'hidden':'') }>
						{/* <div className="condition-section__parameter"> */}
              {props.data.market}
            </div>
            <div className="condition-section__value">
              {<VisibleInterval id={props.data.intervals[1]} />}
            </div>
          </div>
        </div>
        <div className="condition-section__description">
          <VisibleDescription id={props.data.description} />
        </div>
      </div>
      <div className="report-section">{<VisibleReport specVisco={props.data.specVisco} />}</div>
    </div>
  );
}
