import React from "react";
import ChipsItem from "./chipsItem";

import "./index.scss";

const ChipsField = ({
  id = null,
  label = null,
  required = false,
  items = [],
  clearField = () => {},
  deleteChipsItem = () => {},
  error = null,
}) => {
  function onClearField() {
    clearField();
  }

  function onDeleteChipsItem(id) {
    deleteChipsItem(id);
  }

  return (
    <div className="chips-field">
      <label htmlFor={id} className="chips-field__label">
        <span>{label}</span>
        {required && (
          <>
            &nbsp;
            <span className="required">*</span>
          </>
        )}
      </label>
      <div
        id={id}
        className={`chips-field__block ${
          error ? " chips-field__block--error" : ""
        }`}
      >
        <div className="chips-field__list">
          {items.map((item) => {
            return (
              <ChipsItem key={item.id} {...item} onDelete={onDeleteChipsItem} />
            );
          })}
        </div>
        {items.length > 0 && (
          <div className="chips-field__icons">
            <button type="button" onClick={onClearField}>
              <div className="clear-field-icon"></div>
            </button>
          </div>
        )}
      </div>
      <div className="chips-field__error">{error}</div>
    </div>
  );
};

export default ChipsField;
