import { connect } from "react-redux";
import { NoData } from "../../pureComponents/noData";

const mapStateToProps = (state, ownProps) => {
    return{
        data: state.i18.phrases.noData[ownProps.id],
        lang: state.i18.current,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{}
}

const VisibleNoData = connect(mapStateToProps, mapDispatchToProps)(NoData)

export default VisibleNoData;