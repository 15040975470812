export const RECEIVE_HISTORY_CHANGES = 'RECEIVE_HISTORY_CHANGES'
export function receiveHistoryChanges(payload) {
	return {
		type: RECEIVE_HISTORY_CHANGES,
		payload
	}
}

export const SET_HISTORY_DATA_LOADING = "SET_HISTORY_DATA_LOADING"
export function setHistoryDataLoading(payload) {
	return {
		type: SET_HISTORY_DATA_LOADING,
		payload
	}
}
