import { useState } from "react";
import "./index.scss";

const MenuComponent = ({
  buttonName,
  className,
  disabled = false,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);

  // function handleOnBlur() {
  //   setTimeout(() => setOpen(false), 200);
  // }

  return (
    <div className="menu">
      <button
        type="button"
        className={className}
        onClick={() => setOpen((prev) => !prev)}
        disabled={disabled}
      >
        {buttonName}
      </button>
      {isOpen && <div className="menu__items">{children}</div>}
    </div>
  );
};

export default MenuComponent;
