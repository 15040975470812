const initialState = {
  list: [],
};

function productSpecLevel(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_SPEC_LEVEL_LIST:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}

const SET_PRODUCT_SPEC_LEVEL_LIST = "SET_PRODUCT_SPEC_LEVEL_LIST";
export const setProductSpecLevelList = (payload) => ({
  type: SET_PRODUCT_SPEC_LEVEL_LIST,
  payload,
});

export default productSpecLevel;
