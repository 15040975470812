import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Waiter } from '../../components/waiter';
import './index.css';

export function HomePage(props) {
  const navigate = useNavigate();

  React.useEffect(() => {
    switch (props.autorizationStage) {
      case 'login':
        navigate('/search');
        break;
      case 'logout':
        navigate('/login');
        break;
      default:
        break;
    }
  }, [props.autorizationStage]);

  return (
    <div className="home">
      <Waiter size="large" />
    </div>
  );
}
