import { nanoid } from "nanoid";

function normalizeImportData(data) {
  // sorting by existing idDb value
  data.data.sort((a, b) => {
    if (a.idDb === null) return 1;
    if (b.idDb === null) return -1;

    return 0;
  });

  const dataWithIdDB = data.data.filter((item) => item.idDb);

  const dataFromDB = dataWithIdDB.map((item) => {
    const keys = Object.keys(item).filter((key) => key.includes("Db"));
    return Object.fromEntries(keys.map((key) => [key, item[key]]));
  });

  const dataFromFile = data.data.map((item) => ({
    ...item,
    idXls: nanoid(3),
  }));

  return {
    dataFromDB,
    dataFromFile,
  };
}

export default normalizeImportData;
