import * as yup from "yup";

const messages = {
  required: "Поле не заполнено",
};

const modelComplectGeneralSchema = yup.object().shape({
  code: yup.string()
    .required(messages.required)
    .matches(
      /^[A-Z0-9-_]+$/,
      "Допускаются заглавные латинские символы, цифры, знаки _ -"
    ),
  name: yup.string().required(messages.required),
  modelCategoryCode: yup.string().nullable().required(messages.required),
  modelSubcategoryCode: yup.string().nullable().required(messages.required),
  modelMarkCode: yup.string().nullable().required(messages.required),
  modelCode: yup.string().nullable().required(messages.required),
  modelGenerationCode: yup.string().nullable().required(messages.required),
  modelDriveCode: yup.string().nullable(),
  modelClassCode: yup.string().nullable(),
  modelBodyCode: yup.string().nullable(),
  startYear: yup.string().nullable(),
  endYear: yup.string().nullable(),
  description: yup.string().nullable(),
  hidden: yup.boolean(),
  verified: yup.boolean(),
});

export default modelComplectGeneralSchema;
