import { receiveBrend } from "../sync/brend";
export function requestBrend(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveBrend([data]))
	};
}
