const initialState = {
  specCode: null,
  data: [],
  fetchingStatus: null,
};

function specOver(state = initialState, action) {
  switch (action.type) {
    case SET_SPEC_CODE_FOR_SO:
      return { ...state, specCode: action.payload };
    case SET_SPEC_OVER:
      return { ...state, data: action.payload };
    case UPDATE_SPEC_OVER:
      return action.payload.actionType === "C"
        ? {
            ...state,
            data: state.data.filter((item) => item.id !== action.payload.id),
          }
        : {
            ...state,
            data: state.data.map((item) => {
              if (item.id !== action.payload.id) return item;

              return {
                ...item,
                actionType: action.payload.actionType,
              };
            }),
          };
    case SET_SPEC_OVER_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_SPEC_CODE_FOR_SO = "SET_SPEC_CODE_FOR_SO";
const SET_SPEC_OVER = "SET_SPEC_OVER";
const UPDATE_SPEC_OVER = "UPDATE_SPEC_OVER";
const SET_SPEC_OVER_FETCHING_STATUS =
  "SET_SPEC_OVER_FETCHING_STATUS";

export const setSpecCodeForSO = (payload) => ({
  type: SET_SPEC_CODE_FOR_SO,
  payload,
});

export const setSpecOver = (payload) => ({
  type: SET_SPEC_OVER,
  payload,
});

export const addSpecOver = (payload) => ({
  type: SET_SPEC_OVER,
  payload,
});

export const updateSpecOver = (payload) => ({
  type: SET_SPEC_OVER,
  payload,
});

export const setSpecOverFetchingStatus = (payload) => ({
  type: SET_SPEC_OVER_FETCHING_STATUS,
  payload,
});

export default specOver;
