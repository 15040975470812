export const SET_COMPONENT_GROUP_LIST = "SET_COMPONENT_GROUP_LIST";
export const setComponentGroupList = (payload) => {
  return {
    type: SET_COMPONENT_GROUP_LIST,
    payload,
  };
};

export const RECEIVE_COMPONENT_GROUP = 'RECEIVE_COMPONENT_GROUP'
export function receiveComponentGroup(payload) {
	return {
		type: RECEIVE_COMPONENT_GROUP,
		payload
	}
}