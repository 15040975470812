import * as yup from "yup";

const messages = {
  required: "Поле не заполнено",
};

const conditionSchema = yup.object().shape({
  code: yup.string()
    .required(messages.required)
    .matches(
      /^[A-Z0-9-_]+$/,
      "Допускаются заглавные латинские символы, цифры, знаки _ -"
    ),
  conditionTypeCode: yup.string().nullable().required(messages.required),
  marketCode: yup.string().nullable().required(messages.required),
  componentCode: yup.string().nullable(),
  description: yup.string().nullable(),
  hidden: yup.boolean(),
});

export default conditionSchema;