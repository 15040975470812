import { RECIVE_PRODUCT_INFO } from '../actions/sync/product';

const defState = {
  by_id: {},
  all_ids: [],
};

function svProduct(state = defState, action) {
  switch (action.type) {
    case RECIVE_PRODUCT_INFO:
      return Object.assign({}, state, getSVProductFromInfo(action.json));
    default:
      return state;
  }
}

function getSVProductFromInfo(info) {
  const res = {
    all_ids: [],
    by_id: {},
  };
  if( !info.component ) {
    return res;
  }
  info.component.forEach((comp, i) => {
    if (comp.condition !== undefined && comp.condition !== null) {
      comp.condition.forEach((cond, j) => {
        if (cond.specVisco !== undefined) {
          cond.specVisco.forEach((spec, h) => {
            if (spec.product !== undefined) {
              spec.product.forEach((prod, k) => {
                res.all_ids.push(
                  'svproduct_' + i + '_' + j + '_' + h + '_' + k
                );
                res.by_id['svproduct_' + i + '_' + j + '_' + h + '_' + k] = {
                  id: 'svproduct_' + i + '_' + j + '_' + h + '_' + k,
                  name: prod.name,
                  hidden: prod.hidden,
                };
              });
            }
          });
        }
      });
    }
  });
  return res;
}

export default svProduct;
