// Looks not good
export const dropdownSelectFields = [
  "productBrand",
  "productPremium",
  "conditionType",
  "market",
  "componentType",
  "componentFuelType",
  "componentGearType",
  "componentTurboType",
  "componentEngineBuild",
  "componentCoolType",
  "producer",
  "modelSubcategory",
  "modelMark",
  "modelDrive",
  "modelBody",
  "modelClass",
];

export const booleanFields = ["hidden", "verified"];

export const fieldsWithSearch = [
  "conditionType",
  "componentType",
  "producer",
  "modelMark",
];

export const yearPickerFields = ["startYear", "endYear"];

export const inputFields = [
  "code",
  "name",
  "priority",
  "engineCap",
  "fillVolume",
  "serviceVolume",
  "powerHp",
  "powerRpm",
  "valveCnt",
  "gearFwCnt",
  "gearRevCnt",
  "cilinderCap",
  "cilinderCnt",
  "stroke",
  "plasId",
  "olyaId",
  "description1",
  "description2",
];

export const textAreaFields = ["description"];

export const nonEditableField = [
  "component",
  "modelComplect",
  "model",
  "modelGeneration",
];

export const requiredField = [
  "component",
  "modelSubcategory",
  "modelComplect",
  "model",
  "modelGeneration",
  "modelMark",
  "componentType",
  "conditionType",
  "market",
  "productBrand",
  "productPremium",
  "name",
  "hidden",
  "verified",
  "priority"
];
