import { receiveMarket } from "../sync/market";
export function requestMarket(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveMarket([data]))
	};
}
