import { fetchFromApi } from "../service";
import {
  clearModelMark,
  receiveModelMark,
  receiveModelMarkList,
  updateModelMark,
} from "../sync/modelMark";
import { requestModelGeneration } from "./modelGeneration";
import { clearModelGeneration } from "../sync/modelGeneration";
import { requestSearchByFilterResult } from "./search";
import { clearYear } from "../sync/years";
import { clearFuelType } from "../sync/fuelType";
import { clearEngineCap } from "../sync/engineCap";
import { clearModelClass } from "../sync/modelClass";
import { dropSearchResult } from "../sync/search";
import { clearModelList } from "../sync/model";
import { requestModelList } from "./model";

export function requestModelMark(active, type, code) {
  return async (dispatch) => {
    let data = {
      active,
      type,
      code,
    };
    dispatch(receiveModelMark([data]));
  };
}

export function requestModelMark_o() {
  //TODO fix it
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      lang: state.i18.current,
      modelSubcategoryCode: state.subcategory.current,
    };

    return fetchFromApi("api/v1/model-mark/filter", "POST", { body })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(receiveModelMark(res));
        return res;
      })
      .then((res) => {
        if (res.length === 1) {
          dispatch(selectModelMark(res[0].code));
        }
      })
      .catch((e) => {
        console.log("error " + e);
      });
  };
}

export function getModelMarks(searchString = null, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = "api/v1/model-mark/filter/page";
    const body = {
      lang: state.i18.current,
      limit: 100,
      offset: 0,
      colListCode: "name",
      searchString,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });
      const data = await response.json();

      if (callback) {
        callback(data.data);
        return;
      }

      dispatch(receiveModelMark(data.data));
    } catch (error) {}
  };
}

// TODO: refactoring
export function requestModelMarkList(
  modelSubcategoryCode = null,
  modelMarkCode = null,
  searchString = null,
  callback = null
) {
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      lang: state.i18.current,
      modelSubcategoryCode,
      searchString,
      modelMarkCode,
    };

    return fetchFromApi("api/v1/model-mark/filter", "POST", { body })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(receiveModelMarkList(res));

        if (callback) {
          callback();
        }

        return res;
      })
      .catch((e) => {
        console.log("error " + e);
      });
  };
}

export function selectModelMark(id) {
  return (dispatch, getState) => {
    return new Promise((res) => {
      dispatch(updateModelMark(id));
      res();
    })
      .then(() => {
        dispatch(clearModelList());
        dispatch(clearModelGeneration());
      })
      .then(() => {
        const state = getState();
        const modelMarkCode = id === "def" ? null : id;
        const modelSubcategoryCode =
          state.subcategory.current === "def"
            ? null
            : state.subcategory.current;
        dispatch(requestModelList({ modelSubcategoryCode, modelMarkCode }));
        dispatch(requestModelGeneration());
      });
  };
}

export function filterByModelMark(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateModelMark(id));
      res();
    }).then(() => {
      dispatch(requestSearchByFilterResult());
    });
  };
}

export function dropModelMark() {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(clearModelMark());
      res();
    })
      .then(() => {
        dispatch(requestModelMark_o());
      })
      .then(() => {
        dispatch(clearModelGeneration());
      })
      .then(() => {
        dispatch(clearModelList());
      })
      .then(() => {
        // dispatch(requestModelGeneration());
      })
      .then(() => {
        dispatch(clearYear());
      })
      .then(() => {
        dispatch(clearFuelType());
      })
      .then(() => {
        dispatch(clearEngineCap());
      })
      .then(() => {
        dispatch(clearModelClass());
      })
      .then(() => {
        dispatch(dropSearchResult());
      });
  };
}
