export const UPDATE_ENGINE_CAP = 'UPDATE_ENGINE_CAP';
export function updateEngineCap(id) {
  return {
    type: UPDATE_ENGINE_CAP,
    id,
  };
}

export const CLEAR_ENGINE_CAP = 'CLEAR_ENGINE_CAP';
export function clearEngineCap() {
  return {
    type: CLEAR_ENGINE_CAP,
  };
}

export const HENDLE_UPDATE_ENGINE_CAP = 'HENDLE_UPDATE_ENGINE_CAP';
export function hendleUpdateEngineCap(value) {
  return {
    type: HENDLE_UPDATE_ENGINE_CAP,
    value,
  };
}

export const VALIDATE_ENGINE_CAP = 'VALIDATE_ENGINE_CAP';
export function validateEngineCap(value) {
  return {
    type: VALIDATE_ENGINE_CAP,
    value,
  };
}
