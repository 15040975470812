import { nanoid } from "nanoid";

const initialState = {
  conditionCode: null,
  list: [],
  fetchingStatus: "",
};

function conditionIntervals(state = initialState, action) {
  switch (action.type) {
    case SET_CONDITION_INTERVALS:
      return { ...state, list: [...action.payload] };
    case UPDATE_CONDITION_INTERVAL:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id.toString() !== action.payload.id) {
            return item;
          }

          return {
            ...item,
            [action.payload.field]: action.payload.value,
            actionType:
              item.actionType !== "C" && action.payload.field !== "intervalList"
                ? "U"
                : item.actionType,
          };
        }),
      };
    case ADD_NEW_CONDITION_INTERVAL:
      return {
        ...state,
        list: [
          ...state.list,
          {
            id: nanoid(3),
            actionType: "C",
            intervalTypeCode: null,
            intervalCode: null,
            intervalActionCode: null,
            intervalList: [],
            isNewInterval: true,
          },
        ],
      };
    case DELETE_CONDITION_INTERVAL:
      const item = state.list.find((item) => item.id === action.payload);
      if (item.actionType === "C") {
        return {
          ...state,
          list: state.list.filter((item) => item.id !== action.payload),
        };
      }
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id !== action.payload) return item;
          return {
            ...item,
            actionType: "D",
          };
        }),
      };
    case SET_CI_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    case SET_CONDITION_CODE:
      return { ...state, conditionCode: action.payload };
    case RESET_CONDITION_INTERVALS:
      return initialState;
    default:
      return state;
  }
}

const SET_CONDITION_INTERVALS = "SET_CONDITION_INTERVALS";
const UPDATE_CONDITION_INTERVAL = "UPDATE_CONDITION_INTERVAL";
const ADD_NEW_CONDITION_INTERVAL = "ADD_NEW_CONDITION_INTERVAL";
const DELETE_CONDITION_INTERVAL = "DELETE_CONDITION_INTERVAL";
const SET_CI_FETCHING_STATUS = "SET_CI_FETCHING_STATUS";
const RESET_CONDITION_INTERVALS = "RESET_CONDITION_INTERVALS";
const SET_CONDITION_CODE = "SET_CONDITION_CODE";

export const setConditionIntervals = (payload) => ({
  type: SET_CONDITION_INTERVALS,
  payload,
});

export const updateConditionInterval = (payload) => ({
  type: UPDATE_CONDITION_INTERVAL,
  payload,
});

export const addNewConditionInterval = (payload) => ({
  type: ADD_NEW_CONDITION_INTERVAL,
  payload,
});

export const deleteConditionInterval = (payload) => ({
  type: DELETE_CONDITION_INTERVAL,
  payload,
});

export const setCIFetchingStatus = (payload) => ({
  type: SET_CI_FETCHING_STATUS,
  payload,
});

export const resetConditionIntervals = () => ({
  type: RESET_CONDITION_INTERVALS,
});

export const setConditionCode = (payload) => ({
  type: SET_CONDITION_CODE,
  payload,
});

export default conditionIntervals;
