import { RECEIVE_MODEL_DRIVE_LIST } from "../actions/sync/modelDrive";

const initialState = {
  list: [],
};

function modelDrive(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_MODEL_DRIVE_LIST:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}

export default modelDrive;
