import { getHTTPData } from "../../utils/getData";
import {fetchFromApi} from "../../redux/actions/service";

export const API = {
	getMark: function (code) {
		return getHTTPData("api/v1/model-mark/" + code, 'GET');
	},
	getCode: function () {
		const token = localStorage.getItem(`access_token`);
		return fetchFromApi("api/v1/dict/code","GET",{token})
			.then((res) => { return res.text() })
	}
}
