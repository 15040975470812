export const SET_TEXT_EDITOR_MODE = 'SET_TEXT_EDITOR_MODE';
export function setTextEditorMode(mode, active) {
  return {
    type: SET_TEXT_EDITOR_MODE,
    mode,
    active,
  };
}

export const UPDATE_TEXT_EDITOR_VALUE = 'UPDATE_TEXT_EDITOR_VALUE';
export function updateTextEditorValue(value) {
  return {
    type: UPDATE_TEXT_EDITOR_VALUE,
    value,
  };
}

export const UPDATE_TEXT_EDITOR_OBJ_VALUE = 'UPDATE_TEXT_EDITOR_OBJ_VALUE';
export function updateTextEditorObjValue(value) {
  return {
    type: UPDATE_TEXT_EDITOR_OBJ_VALUE,
    value,
  };
}

export const SET_TEXT_EDITOR_DATA = 'SET_TEXT_EDITOR_DATA';
export function setTextEditorData(data) {
  return {
    type: SET_TEXT_EDITOR_DATA,
    data,
  };
}

export const SET_TEXT_EDITOR_INPUT_HEADER = "SET_TEXT_EDITOR_INPUT_HEADER";
export function setTextEditorInputHeader(header) {
  return {
    type: SET_TEXT_EDITOR_INPUT_HEADER,
    header,
  };
}
