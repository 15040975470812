const initialState = {
  asTypeList: [],
  asMarkList: [],
  asModelList: [],
  asFuelTypeList: []
};

const SET_AUTOSTAT_MODEL_REPORT_FORM_SELECTORS = "SET_AUTOSTAT_MODEL_REPORT_FORM_SELECTORS";

function autostatModelReportFormSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_AUTOSTAT_MODEL_REPORT_FORM_SELECTORS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const setAutostatModelReportFormSelectors = (payload) => ({
  type: SET_AUTOSTAT_MODEL_REPORT_FORM_SELECTORS,
  payload,
});

export default autostatModelReportFormSelectors;
