export const RECIVED_TOKENS_FROM_SERVER = "RECIVED_TOKENS_FROM_SERVER";
export function recivedAuthResponse(json){
  return{
    type: RECIVED_TOKENS_FROM_SERVER,
    json
  }
}

export const CHANGE_AUTH_STAGE = "CHANGE_AUTH_STAGE";
export function changeAuthStage(stage){
  return {
    type: CHANGE_AUTH_STAGE,
    stage
  }
}

export const SET_JWT = "SET_JWT";
export function setJWT(jwt){
  return {
    type: SET_JWT,
    jwt
  }
}

export const SET_REFRESH = "SET_REFRESH";
export function setRefresh(refresh){
  return {
    type: SET_REFRESH,
    refresh
  }
}

export const SET_AUTH_CODE = "SET_AUTH_CODE";
export function setAuthCode(code){
  return{
    type: SET_AUTH_CODE,
    code
  }
}

export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export function setAuthError(code){
  return{
    type: SET_AUTH_ERROR,
    code
  }
}