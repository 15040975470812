import React, {useCallback, useEffect, useState} from 'react';
import './index.scss';
import {useDispatch, useSelector} from 'react-redux'
import {useMultiLanguageList} from "../../hooks/useMultiLanguageList";
import iconClose from "../../theme/svg/close_icon.svg";
import MultiLanguageForm from "../../components/multiLanguageForm";
import {fetchFromApi} from "../../redux/actions/service";
import {initNotificationMessage, showNotificationMessage} from "../../redux/actions/sync/notificationMessage";
import {API} from "../generation/api";
import createNameObject from "../../utils/createNameObject";
import updateDefaultLangName from "../../utils/updateDefaultLangName";
import {requestGeneration} from "../../redux/actions/async/generation";
import {TextAreaDescription} from "../../components/textAreaDescription";
import CodeInputField from "../../pureComponents/codeInputField";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import FormInputField from "../../pureComponents/formInputField";
import guidsFormGeneration from "../../validations/guidsFormGeneration";
import YearPicker from "../../pureComponents/yearPicker";
import ConfirmModal from "../../pureComponents/modal/confirmModal";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import {requestGuideTableBody} from "../../redux/actions/async/guides";
import {waitForGuideData} from "../../redux/actions/sync/guides";

const initialState = {
    id: null,
    code: null,
    name: null,
    modelCategoryCode: null,
    modelSubcategoryCode: null,
    modelCode: null,
    startYear: null,
    endYear: null,
    description: null,
    hidden: false
}
const avaliableFields = {
    code: 'code',
    name: 'name',
    modelCode: 'modelCode',
    startYear: 'startYear',
    endYear: 'endYear',
    hidden: 'hidden',
    description: 'description'
}

export function GenerationGuideForm(props) {
    const state = useSelector(state => state );
    const store = useSelector(state => state.generation.list[0] );
    const dispatch = useDispatch();

    const [active_modal, setActive_modal] = useState(null);
    const [fields, setFields] = useState(initialState);
    const [names, setNames] = useState([]);
    const [changes, setChanges] = useState(false);
    const [errors, setErrors] = useState([]);
    const [changedFields, setChangedFields] = useState([]);
    const [modelSearchLoading,setModelSearchLoading] = useState(false);
    const [modelCategory, setModelCategory] = useState([]);
    const [modelSubcategory, setModelSubcategory] = useState([]);
    const [model, setModel] = useState([]);
    const [isInitState, setIsInitState] = useState(true);

    const {
        multiLanguageFormData,
        multiLanguageFormErrors,
        multiLanguageFormDataChanged,
        updateLanguage,
        updateLanguageName,
        addNewLanguageName,
        deleteLanguageName,
        checkMultiLanguageFormIsValid,
        validateMultiLanguageForm
    } = useMultiLanguageList(names);

    useEffect(() => {
        store.type === 'edit' && startEditCopy();
        store.type === 'copy' && startEditCopy();
        store.type === 'new' && startNew();
    },[]);

    useEffect(() => {
        if(multiLanguageFormDataChanged && !changes) {
            setChanges(true);
            updateChangedFields('name');
        }
    },[multiLanguageFormDataChanged, changes]);

    useEffect(() => {
        if(isInitState) return;
        setFields({ ...fields, modelSubcategoryCode: null, modelCode: null });
        if( !fields.modelCategoryCode) {
            return;
        }
        API.getSubcategory({lang: state.i18.current, modelCategoryCode: fields.modelCategoryCode, hidden: true})
            .then((response) => {
                setModelSubcategory(response);
        });
    },[fields.modelCategoryCode]);

    useEffect(() => {
        if(isInitState) return;
        setFields({ ...fields, modelCode: null });
        if( !fields.modelSubcategoryCode ) {
            setModel([]);
            return;
        }
        API.getModel({lang: state.i18.current, modelSubcategoryCode: fields.modelSubcategoryCode, hidden: true})
            .then((response) => {
                setModel(response);
            });
    },[fields.modelSubcategoryCode]);

    // Редактирование
    function startEditCopy() {
        Promise.all( [
            API.getGeneration(props.data.currentRow),
            API.getCategory({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, colListCode: 'name'})
        ]).then((responseGen) => {
            setNames(responseGen[0].name.value.filter(item => item.lang !== 'DEF'));
            setModelCategory(responseGen[1].data);
            setFields({ ...fields,
                id: store.type === 'copy' ? null : responseGen[0]?.id,
                code: responseGen[0]?.code ,
                name: responseGen[0]?.name.value.filter(item => item.lang === 'DEF')[0]?.name,
                modelCategoryCode:  responseGen[0]?.modelCategoryCode,
                modelSubcategoryCode: responseGen[0]?.modelSubcategoryCode,
                startYear: responseGen[0]?.startYear,
                endYear: responseGen[0]?.endYear,
                modelCode: responseGen[0]?.modelCode,
                description: responseGen[0]?.description,
                hidden: responseGen[0]?.hidden || false
            });
            if(responseGen[0]?.modelCategoryCode) {
                API.getSubcategory(
                    {lang: state.i18.current, modelCategoryCode: responseGen[0]?.modelCategoryCode, hidden: true})
                    .then((response) => {
                        setModelSubcategory(response);
                    });
            }
            if(responseGen[0]?.modelSubcategoryCode) {
                API.getModel(
                    {
                        lang: state.i18.current,
                        modelSubcategoryCode: responseGen[0]?.modelSubcategoryCode,
                        modelCode: responseGen[0]?.modelCode,
                        hidden: true})
                    .then((response) => {
                        setModel(response);
                    });
            }
            if(store.type === 'copy') {
                createCode();
                setChanges(true);
                setChangedFields(Object.values(avaliableFields));
            }
        });
    }
    // Новое
    function startNew() {
        Promise.all([
                API.getCode(),
                API.getCategory({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, colListCode: 'name'})
            ]
        ).then(response => {
            setModelCategory(response[1].data);
            setFields({ ...fields, code: response[0] });
            updateChangedFields('code');
        });
    }

    function updateChangedFields(id) {
        if (avaliableFields[id] && !changedFields.includes(avaliableFields[id])) {
            setChangedFields((prev) => [...prev, avaliableFields[id]]);
        }
    }

    function createCode() {
        API.getCode().then((response) => {
            setFields(prev => ({...prev, ['code']: response }));
        });
    }

    function changeInput(event) {
        setChanges(true);
        updateChangedFields(event.target.id);
        if(event.target.id === 'hidden') {
            setFields({ ...fields, [event.target.id]: !fields.hidden });
        } else {
            setFields({ ...fields, [event.target.id]: event.target.value });
            setErrors(prev => ({...prev, [event.target.id]:null }));
        }
    }

    const handleDateChange = useCallback((value, id) => {
            setFields(prev => ({...prev, [id]:value }));
            updateChangedFields(id);
            setChanges(true);
        },[]
    );

    function changeDropdown(item, id) {
        setFields(prev => ({...prev, [id]:item.code }));
        if(isInitState) setIsInitState(false);
        setChanges(true);
        updateChangedFields(id);
    }

    const handleDropdownSearch = useCallback((id, searchString) => {
            let reqBody = {
                lang: state.i18.current,
                modelSubcategoryCode: fields.modelSubcategoryCode,
                searchStr: searchString,
                hidden: true};
            if( fields.modelCode ) {
                reqBody.modelCode = fields.modelCode;
            }
            setModelSearchLoading(true);
            API.getModel(reqBody).then((response) =>
            {
                setModel(response);
                setModelSearchLoading(false);
            });
        },[fields.modelSubcategoryCode,fields.modelCode]
    );



    async function save() {
        const isFormValid = await guidsFormGeneration.isValid( fields,{abortEarly: false} );
        const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();
        if( isFormValid && isMultiLanguageFormValid ) {
            updateDefaultLangName(multiLanguageFormData, fields.name)
            saveChanges({
                id: fields.id,
                code: fields.code,
                name: createNameObject(fields.name, multiLanguageFormData),
                modelCode: fields.modelCode,
                startYear: fields.startYear,
                endYear: fields.endYear,
                description: fields.description === "" ? null : fields.description,
                hidden: fields.hidden,
                changedFields: changedFields
            }).then();
        } else {
            if (!isFormValid) {
                guidsFormGeneration
                    .validate(fields, { abortEarly: false })
                    .catch((err) => {
                        const errors = err.inner.reduce((acc, error) => {
                            return {
                                ...acc,
                                [error.path]: error.message,
                            };
                        }, {});
                        setErrors(() => {
                            return {
                                ...errors,
                            };
                        });
                    });
            }
            if (!isMultiLanguageFormValid) {
                validateMultiLanguageForm();
            }
        }
    }

    async function saveChanges(body) {
        await fetchFromApi("api/v1/model-generation","PUT",{body})
        .then((res) => {
            if (res.status === 200) {
                dispatch(waitForGuideData(true));
                dispatch(requestGeneration(false, null, res.id));
                dispatch(requestGuideTableBody());
            } else {
                dispatch(
                    initNotificationMessage(
                        'Ошибка',
                        'Произошла системная ошибка. Обратитесь к администратору системы',
                        'danger'
                    )
                )
                dispatch(showNotificationMessage());
            }
        })
        .catch((e) => {
            console.log(e);
            dispatch(
                initNotificationMessage(
                    'Ошибка',
                    'Произошла системная ошибка. Обратитесь к администратору системы',
                    'danger'
                )
            );
            dispatch(showNotificationMessage());
        });
    }

    function close() {
        if(!changes) {
            onContinueWithoutSave(null);
        } else {
            setActive_modal('modalTC__wrapper-active');
        }
    }

    function closeApprowal(value) {
        setActive_modal(null);
    }

    function onContinueWithoutSave(value) {
        setFields(initialState);
        setChangedFields([]);
        setActive_modal(null);
        dispatch(requestGeneration(false, null, null));
    }


    return (
        <div className={"model-guide__wrapper model-guide__wrapper_active"}>
            <div className="model-guide-block">
                {/* Header */}
                <div className="model-guide-block__header">
                    <div className="model-guide-block__header__name">{props.data.currentGuide}</div>

                    <div className="model-guide-block__control model-guide-block__control_elastic">
                        <CodeInputField
                            id={'code'}
                            label={'Код'}
                            disabled={store.type === 'edit'}
                            error={errors.code}
                            value={fields.code}
                            onChange={changeInput}
                            required={true}
                        />
                    </div>
                    <button
                        className={'button button__primary button__small'}
                        disabled={store?.type === 'edit'}
                        onClick={() => createCode()}>
                        Создать код
                    </button>
                    {/* X button */}
                    <div className="model-guide-block__close" onClick={() => close()}>
                        <img src={iconClose} alt="Закрыть" />
                    </div>
                </div>

                {/* Body */}
                <div className="model-guide-block__content">
                    <div className="model-guide-block__content__item">
                        <div className="model-guide-block__control-column">
                            {/* Название */}
                            <FormInputField
                                id='name'
                                label='Название'
                                value={fields.name}
                                error={errors.name}
                                onChange={changeInput}
                                required={true}
                            />
                            {/* Категория */}
                            <DropdownSelect
                                id={'modelCategoryCode'}
                                label={'Категория'}
                                defaultValue={getNameFromList(modelCategory, fields.modelCategoryCode)}
                                items={modelCategory}
                                required={true}
                                error={errors.modelCategoryCode}
                                onSelect={changeDropdown}
                            />
                            {/* Подкатегория */}
                            <DropdownSelect
                                id={'modelSubcategoryCode'}
                                label={'Подкатегория'}
                                defaultValue={getNameFromList(modelSubcategory, fields.modelSubcategoryCode)}
                                items={modelSubcategory}
                                onSelect={changeDropdown}
                                error={errors.modelSubcategoryCode}
                                required={true}
                            />
                            {/* Модель ТС */}
                            <DropdownSelect
                                id={'modelCode'}
                                label={'Модель ТС'}
                                defaultValue={getNameFromList(model, fields.modelCode)}
                                items={model}
                                onSelect={changeDropdown}
                                isLoading={modelSearchLoading}
                                onSearch={handleDropdownSearch}
                                error={errors.modelCode}
                                searchable={true}
                                required={true}
                            />
                        </div>
                    </div>
                    <hr className="horizontal-line" />
                    <div className="model-guide-block__content__item">
                        <YearPicker
                            id={'startYear'}
                            label="Год (начало выпуска)"
                            value={fields.startYear}
                            onChangeData={handleDateChange}
                            maxDate={fields.endYear}
                        />
                        <YearPicker
                            id={'endYear'}
                            label="Год (окончание выпуска)"
                            value={fields.endYear}
                            onChangeData={handleDateChange}
                            minDate={fields.startYear}
                        />
                    </div>
                    {/*  Описание */}
                    <div className="model-guide-block__content__item">
                        <div className="model-guide-block__control-column">
                            <TextAreaDescription
                                id={'description'}
                                value={fields.description}
                                onChange={changeInput}/>
                        </div>
                    </div>
                    <hr className="horizontal-line" />
                    <div className="model-guide-block__content__item">
                        <div className="model-guide-block__control-column">
                            <MultiLanguageForm
                                multiLanguageFormData={multiLanguageFormData}
                                multiLanguageFormDataChanged={multiLanguageFormDataChanged}
                                multiLanguageFormErrors={multiLanguageFormErrors}
                                updateLanguage={updateLanguage}
                                updateLanguageName={updateLanguageName}
                                addNewLanguageName={addNewLanguageName}
                                deleteLanguageName={deleteLanguageName}
                            />
                        </div>
                    </div>
                </div>

                <div className="model-guide-block__bottom">
                    <ToggleSwitch
                        id={'hidden'}
                        label={'Скрыть'}
                        onToggle={changeInput}
                        checked={fields.hidden}
                    />
                    <div className="buttons__group">
                        <div
                            className="button button__secondary button__secondary__ghost"
                            onClick={() => onContinueWithoutSave()}
                        >
                            Не сохранять
                        </div>

                        <button
                            disabled={!changes}
                            className={'button button__primary '}
                            onClick={() => save()}
                        >
                            Сохранить изменения
                        </button>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <ConfirmModal
                active={active_modal}
                onSave={save}
                onContinueWithoutSave={onContinueWithoutSave}
                closeModal={closeApprowal}
            />
        </div>
    );
}