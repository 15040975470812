import normalizeSpecMenu from "../../../normalizers/specMenu";
import { setSpecMenu } from "../../reducers/specMenu";
import {fetchFromApi} from "../service";

export function getSpecMenu(specCode, actionType) {
  return async (dispatch, getState) => {
    try {
      const response = await fetchFromApi(`api/v1/menu/spec/${specCode}`,"GET",{})
      const data = await response.json();

      if (actionType === "copy") {
        dispatch(
          setSpecMenu({
            specCode: specCode,
          })
        );
        return;
      }
      const normalizedData = normalizeSpecMenu(data, specCode);
      dispatch(setSpecMenu(normalizedData));
    } catch (error) {
      console.log(error);
    }
  };
}
