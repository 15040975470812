import {
  RESET_CURRENT_PAGE_FORM_STATE,
  UPDATE_ALL_CHG,
  UPDATE_SUBMIT_FUNCTION,
} from "../actions/sync/currentPageFormState";

const initialState = {
  allChg: false,
  submitFunction: () => {},
};

const currentPageFormState = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUBMIT_FUNCTION:
      return {
        ...state,
        submitFunction: action.submitFunction,
      };
    case UPDATE_ALL_CHG:
      return {
        ...state,
        allChg: action.allChg,
      };
    case RESET_CURRENT_PAGE_FORM_STATE:
      return initialState;
    default:
      return state;
  }
};

export default currentPageFormState;
