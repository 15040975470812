import { getComponentFuelTypeList } from "../../../redux/actions/async/componentSelectors";
import {
  deleteProductComponentFuelTypeFromServer,
  getProductComponentFuelType,
  putProductComponentFuelType,
} from "../../../redux/actions/async/productComponentFuelType";
import { setProductComponentFuelType } from "../../../redux/reducers/productComponentFuelType";

const componentFuelType = {
  headerTitle: "Добавление Типов топлива",
  list: "componentFuelType",
  productList: "productComponentFuelType",
  option: "component-fuel-type",
  optionName: "Тип топлива",
  historyTableName: "product_component_fuel_type",
  searchable: false,
  getList: () => getComponentFuelTypeList(),
  getData: (productCode) => getProductComponentFuelType(productCode),
  setData: (productComponentFuelType) =>
    setProductComponentFuelType(productComponentFuelType),
  deleteData: (productCode, callback) =>
    deleteProductComponentFuelTypeFromServer(productCode, callback),
  putData: () => putProductComponentFuelType(),
};

export default componentFuelType;
