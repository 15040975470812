import { RECIVE_PRODUCT_INFO } from '../actions/sync/product';
import { SET_ACTIVE_DESCRIPTION, SWITCH_FULL_DESCRIPTION } from '../actions/sync/description';

const defState = {
  by_id: {},
  all_ids: {},
};

function descriptions(state = defState, action) {
  switch (action.type) {
    case RECIVE_PRODUCT_INFO:
      return Object.assign({}, state, getDescriptionsFromInfo(action.json));
    case SWITCH_FULL_DESCRIPTION:
      return Object.assign({}, state, {
        by_id: Object.assign({}, state.by_id, {
          [action.id]: Object.assign({}, state.by_id[action.id], {
            visible: !state.by_id[action.id].visible,
          }),
        }),
      });
    case SET_ACTIVE_DESCRIPTION:
      return Object.assign({}, state, {
        by_id: Object.assign({}, state.by_id, {
          [action.id]: Object.assign({}, state.by_id[action.id], {
            active: action.active,
          }),
        }),
      });
    default:
      return state;
  }
}

function getDescriptionsFromInfo(info) {
  let id = '';
  const res = {
    all_ids: [],
    by_id: {},
  };
  if( !info.component ) {
    return res; // Костыль
  }
  info.component.forEach((comp, i) => {
    // if (comp.description !== undefined) {
    id = 'description_' + i;
    res.all_ids.push(id);
    res.by_id[id] = {
      id,
      description: comp.description !== undefined ? comp.description : '',
      visible: false,
      hidden: comp.hidden
    };
    // }
    if (comp.condition !== undefined && comp.condition !== null) {
      comp.condition.forEach((cond, j) => {
        // if (cond.description !== undefined) {
        id = 'description_' + i + '_' + j;
        res.all_ids.push(id);
        res.by_id[id] = {
          id,
          description:
            cond.conditionDescription !== undefined
              ? cond.conditionDescription
              : '',
          visible: false,
          hidden: cond.hidden
        };
        // }
        if (cond.specVisco !== undefined) {
          cond.specVisco.forEach((sv, h) => {
            // if (sv.description1 !== undefined) {
            id = 'description_' + i + '_' + j + '_' + h;
            res.all_ids.push(id);
            res.by_id[id] = {
              id,
              description1:
                sv.description1 !== undefined ? sv.description1 : id + '_1',
              description2:
                sv.description2 !== undefined ? sv.description2 : id + '_2',
              visible: false,
              active: null,
              hidden: cond.hidden
            };
          });
        }
      });
    }
  });
  return res;
}

export default descriptions;