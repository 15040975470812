import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModelComplectGeneralForm from "../modelComplectGeneralForm";
import { useGetNewCode } from "../../../../hooks/useGetNewCode";
import { useLanguage } from "../../../../hooks/useLanguage";
import useDownloadFile from "../../../../hooks/useDownloadFile";
import { useMultiLanguageList } from "../../../../hooks/useMultiLanguageList";
import getNewFileName from "../../../../utils/getNewFileName";
import modelComplectGeneralSchema from "../../../../validations/modelComplectGeneral";
import DeleteModal from "../../../../pureComponents/modal/deleteModal";
import ReplaceModelComplectFileModal from "../../../../pureComponents/modal/fileReplaceModal";
import { ModalHistoryChanges } from "../../../../pureComponents/modal/modalHistoryChanges";
import generateModelComplectLink from "../../../../utils/generateModelComplectLink";
import { requestCategoriesV2 } from "../../../../redux/actions/async/category";
import { requestModelClasses } from "../../../../redux/actions/async/modelClass";
import { requestModelDriveList } from "../../../../redux/actions/async/modelDrive";
import { requestModelBodyList } from "../../../../redux/actions/async/modelBody";
import { getSubcategories } from "../../../../redux/actions/async/subcategory";
import { requestModelMarkList } from "../../../../redux/actions/async/modelMark";
import { requestModelList } from "../../../../redux/actions/async/model";
import { requestModelGenerationV2 } from "../../../../redux/actions/async/modelGeneration";
import updateDefaultLangName from "../../../../utils/updateDefaultLangName";

import {
  createUpdateModelComplectDoc,
  deleteModelComplectDoc,
  getModelComplect,
  getModelComplectDoc,
  putModelComplect,
  deleteModelComplectFromServer,
  downloadModelComplectDoc,
} from "../../../../redux/actions/async/modelComplect";
import {
  resetModelComplect,
  setModelComplectFetchingStatus,
  updateChangedFields,
  updateModelComplectField,
} from "../../../../redux/actions/sync/modelComplect";
import {
  resetCurrentPageFormState,
  updateAllChg,
  updateSubmitFunction,
} from "../../../../redux/actions/sync/currentPageFormState";
import createNameObject from "../../../../utils/createNameObject";

const selectorsIds = [
  "modelCategoryCode",
  "modelSubcategoryCode",
  "modelMarkCode",
  "modelCode",
];

function ModelComplectGeneral(props) {
  const {
    selectorsData,
    modelComplectData,
    dataChanged,
    dispatch,
    sideMenuIsLoaded,
  } = props;

  const { state } = useLocation();
  const { modelComplectCode } = useParams();
  const navigate = useNavigate();

  const actionType = state?.actionType ? state.actionType : "new";

  const currentLanguage = useLanguage();

  const {
    multiLanguageFormData,
    multiLanguageFormDataChanged,
    checkMultiLanguageFormIsValid,
    validateMultiLanguageForm,
    ...multiLanguageProps
  } = useMultiLanguageList(modelComplectData.names);

  const getNewCode = useGetNewCode(updateModelComplectField);
  const downloadFile = useDownloadFile();

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [fileDraft, setFileDraft] = useState(null);
  const [replaceFileModalActive, setReplaceFileModalActive] = useState(false);

  const [initialLoad, setInitialLoad] = useState(true);

  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const [searchLoading, setSearchLoading] = useState({
    modelMarkCode: false,
    modelCode: false,
  });

  useEffect(() => {
    dispatch(requestCategoriesV2());
    dispatch(requestModelClasses());
    dispatch(requestModelDriveList());
    dispatch(requestModelBodyList());

    return () => {
      dispatch(resetModelComplect());
      dispatch(resetCurrentPageFormState());
    };
  }, []);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitModelComplect));
  }, [modelComplectData, multiLanguageFormData]);

  useEffect(() => {
    if (multiLanguageFormDataChanged) {
      dispatch(updateAllChg(true));
      dispatch(updateChangedFields("name"));
    }
  }, [multiLanguageFormDataChanged]);

  useEffect(() => {
    dispatch(setModelComplectFetchingStatus("loading"));
    if (modelComplectCode && sideMenuIsLoaded) {
      dispatch(
        getModelComplect(modelComplectCode, actionType, () => {
          if (actionType === "copy") {
            getNewCode();
          }
        })
      );
    }
  }, [modelComplectCode, actionType, sideMenuIsLoaded]);

  useEffect(() => {
    if (actionType === "new") {
      dispatch(resetModelComplect());
      getNewCode();
      dispatch(
        updateModelComplectField({
          changedFields: ["code", "hidden", "verified"],
        })
      );
    }

    if (actionType === "new" && modelComplectCode) {
      navigate("/model-complect");
    }
  }, [actionType, getNewCode, modelComplectCode]);

  useEffect(() => {
    if (modelComplectData.actionType === "edit") {
      dispatch(getModelComplectDoc());
    }
  }, [modelComplectData.actionType]);

  useEffect(() => {
    if (modelComplectData.modelCategoryCode && !initialLoad) {
      dispatch(
        updateModelComplectField({
          modelSubcategoryCode: null,
          modelMarkCode: null,
          modelCode: null,
          modelGenerationCode: null,
        })
      );
      dispatch(getSubcategories(modelComplectData.modelCategoryCode));
    }
  }, [modelComplectData.modelCategoryCode]);

  useEffect(() => {
    if (modelComplectData.modelSubcategoryCode && !initialLoad) {
      dispatch(
        updateModelComplectField({
          modelMarkCode: null,
          modelCode: null,
          modelGenerationCode: null,
        })
      );
      dispatch(requestModelMarkList(modelComplectData.modelSubcategoryCode));
    }
  }, [modelComplectData.modelSubcategoryCode]);

  useEffect(() => {
    if (
      modelComplectData.modelSubcategoryCode &&
      modelComplectData.modelMarkCode &&
      !initialLoad
    ) {
      dispatch(
        updateModelComplectField({
          modelCode: null,
          modelGenerationCode: null,
        })
      );
      dispatch(
        requestModelList({
          modelSubcategoryCode: modelComplectData.modelSubcategoryCode,
          modelMarkCode: modelComplectData.modelMarkCode,
          isModelComplectPage: true,
        })
      );
    }
  }, [modelComplectData.modelSubcategoryCode, modelComplectData.modelMarkCode]);

  useEffect(() => {
    if (
      modelComplectData.modelCode &&
      modelComplectData.modelCategoryCode &&
      !initialLoad
    ) {
      dispatch(
        updateModelComplectField({
          modelGenerationCode: null,
        })
      );
      dispatch(
        requestModelGenerationV2(
          modelComplectData.modelCode,
          modelComplectData.modelCategoryCode
        )
      );
    }
  }, [modelComplectData.modelCategoryCode, modelComplectData.modelCode]);

  const handleDateChange = useCallback(
    (value, id) => {
      dispatch(
        updateModelComplectField({
          [id]: value,
        })
      );

      dispatch(updateChangedFields(id));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [actionType, dataChanged]
  );

  const handleInputChange = useCallback(
    ({ target: { id, type, value } }) => {
      if (type === "checkbox") {
        dispatch(
          updateModelComplectField({
            [id]: !modelComplectData[id],
          })
        );

        dispatch(updateChangedFields(id));

        if (!dataChanged) {
          dispatch(updateAllChg(true));
        }
        return;
      }

      dispatch(
        updateModelComplectField({
          [id]: value,
        })
      );

      dispatch(updateChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [modelComplectData, actionType, dataChanged]
  );

  const handleDropdownChange = useCallback(
    ({ code }, id) => {
      dispatch(
        updateModelComplectField({
          [id]: code,
        })
      );

      dispatch(updateChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }

      if (selectorsIds.includes(id) && initialLoad) {
        setInitialLoad(false);
      }
    },
    [actionType, dataChanged]
  );

  const handleDropdownSearch = useCallback(
    (id, searchValue) => {
      setSearchLoading({
        [id]: true,
      });

      if (id === "modelMarkCode") {
        dispatch(
          requestModelMarkList(
            modelComplectData.modelSubcategoryCode,
            modelComplectData.modelMarkCode,
            searchValue,
            () =>
              setSearchLoading({
                [id]: false,
              })
          )
        );
        return;
      }

      if (id === "modelCode") {
        dispatch(
          requestModelList(
            {
              modelSubcategoryCode: modelComplectData.modelSubcategoryCode,
              modelMarkCode: modelComplectData.modelMarkCode,
              modelCode: modelComplectData.modelCode,
              searchStr: searchValue,
              isModelComplectPage: true,
            },
            () =>
              setSearchLoading({
                [id]: false,
              })
          )
        );
      }
    },
    [
      modelComplectData.modelSubcategoryCode,
      modelComplectData.modelMarkCode,
      modelComplectData.modelCode,
    ]
  );

  const handleFileUpload = useCallback(
    (file) => {
      if (
        modelComplectData.files.some((item) => item.fileName === file[0].name)
      ) {
        setFileDraft(file[0]);
        setReplaceFileModalActive(true);

        return;
      }

      dispatch(createUpdateModelComplectDoc({ file: file[0] }));
    },
    [modelComplectData]
  );

  const replaceModelComplectFile = () => {
    const fileId = modelComplectData.files.find(
      (item) => item.fileName === fileDraft.name
    ).id;
    dispatch(createUpdateModelComplectDoc({ file: fileDraft, fileId }));
    setFileDraft(null);
    setReplaceFileModalActive(false);
  };

  const createNewModelComplectFile = () => {
    const newFilename = getNewFileName(fileDraft, modelComplectData.files);
    dispatch(
      createUpdateModelComplectDoc({ file: fileDraft, fileName: newFilename })
    );
    setFileDraft(null);
    setReplaceFileModalActive(false);
  };

  const handleFileRemove = useCallback((id) => {
    dispatch(deleteModelComplectDoc(id));
  }, []);

  const handleDownloadFile = useCallback(
    (id, name) => {
      dispatch(
        downloadModelComplectDoc(id, (fileBlob) => downloadFile(fileBlob, name))
      );
    },
    [downloadFile]
  );

  const handleDeleteModelComplect = useCallback(() => {
    setDeleteModalActive(true);
  }, []);

  const deleteModelComplect = async () => {
    dispatch(
      deleteModelComplectFromServer(modelComplectCode, () => {
        navigate("/techniks", { state: { pageCode: 7, withoutReset: true } });
      })
    );
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitModelComplect() {
    const isFormValid = await modelComplectGeneralSchema.isValid(
      modelComplectData,
      {
        abortEarly: false, // Prevent aborting validation after first error
      }
    );

    const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();

    if (isFormValid && isMultiLanguageFormValid) {
      
      updateDefaultLangName(multiLanguageFormData, modelComplectData.name)

      const namesList = createNameObject(
        modelComplectData.name,
        multiLanguageFormData
      );



      return dispatch(
        putModelComplect({
          id: actionType === "edit" ? modelComplectData.id : null,
          code: modelComplectData.code,
          modelGenerationCode: modelComplectData.modelGenerationCode,
          modelClassCode: modelComplectData.modelClassCode,
          modelDriveCode: modelComplectData.modelDriveCode,
          modelBodyCode: modelComplectData.modelBodyCode,
          startYear: modelComplectData.startYear,
          endYear: modelComplectData.endYear,
          description:
            modelComplectData.description === ""
              ? null
              : modelComplectData.description,
          hidden: modelComplectData.hidden,
          verified: modelComplectData.verified,
          changedFields: modelComplectData.changedFields,
          actionType: "edit",
          name: namesList,
        })
      );
    } else {
      if (!isFormValid) {
        modelComplectGeneralSchema
          .validate(modelComplectData, { abortEarly: false })
          .catch((err) => {
            const errors = err.inner.reduce((acc, error) => {
              return {
                ...acc,
                [error.path]: error.message,
              };
            }, {});

            setErrors(() => {
              return {
                ...errors,
              };
            });
          });
      }

      if (!isMultiLanguageFormValid) {
        validateMultiLanguageForm();
      }

      return false;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const isValid = await submitModelComplect();
    if (isValid) {
      dispatch(
        updateModelComplectField({
          changedFields: [],
        })
      );
      navigate(generateModelComplectLink(modelComplectData.code), {
        state: {
          actionType: "edit",
        },
      });
    }
  };

  return (
    <>
      <ModelComplectGeneralForm
        currentLanguage={currentLanguage}
        selectorsData={selectorsData}
        modelComplectData={modelComplectData}
        formErrors={formErrors}
        multiLanguageFormData={multiLanguageFormData}
        multiLanguageProps={multiLanguageProps}
        searchLoading={searchLoading}
        handleDropdownSearch={handleDropdownSearch}
        getNewCode={getNewCode}
        handleDateChange={handleDateChange}
        handleInputChange={handleInputChange}
        handleDropdownChange={handleDropdownChange}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove}
        handleDownloadFile={handleDownloadFile}
        handleDeleteModelComplect={handleDeleteModelComplect}
        openHistoryChanges={openHistoryChanges}
        onSubmit={onSubmit}
        dataChanged={dataChanged}
      />
      <DeleteModal
        active={deleteModalActive}
        title={
          <>
            Будут удалены все данные по ТС.
            <br /> Продолжить?
          </>
        }
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteModelComplect}
      />
      <ReplaceModelComplectFileModal
        active={replaceFileModalActive}
        closeModal={() => setReplaceFileModalActive(false)}
        onReplace={replaceModelComplectFile}
        onCreateNew={createNewModelComplectFile}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"model_complect"}
        rowCode={modelComplectCode}
        listType={"hist"}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    selectorsData: {
      categoryList: state.category.list,
      subcategoryList: state.subcategory.modelComplectPageList,
      markList: state.modelMark.modelComplectPageList,
      modelList: state.model.modelComplectPageList,
      generationList: state.modelGeneration.list,
      classList: state.modelClass.list,
      driveList: state.modelDrive.list,
      bodyList: state.modelBody.list,
    },
    sideMenuIsLoaded: state.modelComplectMenu.modelComplectCode !== "",
    modelComplectData: state.modelComplect,
    dataChanged: state.currentPageFormState.allChg,
  };
};

export default connect(mapStateToProps)(ModelComplectGeneral);
