import React, { useState, useEffect, memo, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { autoUpdate, flip, offset, useFloating } from "@floating-ui/react-dom";
import { Waiter } from "../../components/waiter";
import useDebounce from "../../hooks/useDebounce";
import InputSearchField from "../inputSearchField";
import ClearFieldIcon from "../../icons/ClearFieldIcon";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { ListItem } from "./listItem";

import "./index.scss";

const DropdownSelect = memo(
  ({
    id,
    label,
    onSelect,
    items = [],
    searchable = false,
    onSearch = () => {},
    isLoading = false,
    required = false,
    defaultValue = null,
    disabled = false,
    isClearEnabled = false,
    isErrorsEnabled = true,
    error = null,
    isFlipping = true
  }) => {
    const { currentLanguage, phrases } = useSelector((state) => ({
      currentLanguage: state.i18.current,
      phrases: state.i18.phrases,
    }));

    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef(null);
    const selectedValueRef = useRef(null);
    const selectedValueTextRef = useRef(null);

    const { refs, floatingStyles } = useFloating({
      transform: false,
      placement: "bottom",
      whileElementsMounted: autoUpdate,
      middleware: [isFlipping && flip(), offset(4)],
    });

    const [showSelectedTooltip, setShowSelectedTooltip] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const debouncedSearchValue = useDebounce(searchValue, 500);
    const [isInit, setInit] = useState(true);

    const onClickOutside = useCallback(() => {
      setSearchValue("");
      setIsOpen(false);
    }, []);

    useOnClickOutside(containerRef, onClickOutside);

    function handleOnClickItem(item) {
      setSearchValue("");
      onSelect(item, id);
      setIsOpen(false);
    }

    useEffect(() => {
      // to prevent API call when mounting
      if (isInit) {
        setInit(false);
        return;
      }

      const search = async () => {
        await onSearch(id, debouncedSearchValue);
      };

      search();
    }, [debouncedSearchValue]);

    useEffect(() => {
      if (selectedValueTextRef.current && selectedValueRef.current) {
        const selectedValueBlockWidth =
          selectedValueRef.current.getBoundingClientRect().width;
        const selectedValueTextWidth =
          selectedValueTextRef.current.getBoundingClientRect().width;

        setShowSelectedTooltip(
          selectedValueTextWidth > selectedValueBlockWidth
        );
      }
    }, [defaultValue, selectedValueRef, selectedValueTextRef]);

    return (
      <>
        <div className="dropdown-block dropdown-block__item" ref={containerRef}>
          <label
            className={`dropdown-block__label${label ? "" : " sr-only"}`}
            htmlFor={id}
          >
            <span>
              {label}&nbsp;
              {required && label && <span className="required">*</span>}
            </span>
          </label>
          <div className="dropdown-block__container" ref={refs.setReference}>
            <button
              id={id}
              type="button"
              className={`dropdown-block__field${
                error ? " dropdown-block__field--error" : ""
              }`}
              onClick={() => setIsOpen((prev) => !prev)}
              disabled={disabled}
              data-tooltip={defaultValue}
              data-show-tooltip={showSelectedTooltip}
            >
              <div
                className="dropdown-block__selected-value"
                ref={selectedValueRef}
              >
                <span ref={selectedValueTextRef}>
                  {defaultValue?.toString() ||
                    phrases.dropdown.placeholder[currentLanguage]}
                </span>
              </div>
              <div className="dropdown-block__icons">
                {isClearEnabled && defaultValue && (
                  <>
                    <div
                      type="button"
                      className="dropdown-block__icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnClickItem({ code: null, name: "" });
                      }}
                    >
                      <ClearFieldIcon />
                    </div>
                  </>
                )}
                <div className="dropdown-block__icon">
                  <div className="dropdown-block__chevron-down"></div>
                </div>
              </div>
            </button>
            {isOpen && (
              <div
                className={`dropdown-block__options`}
                ref={refs.setFloating}
                style={floatingStyles}
              >
                {!!searchable && (
                  <div className="dropdown-block__search">
                    <InputSearchField
                      id="search"
                      value={searchValue}
                      onChange={(id, value) => setSearchValue(value)}
                    />
                  </div>
                )}
                {isLoading ? (
                  <div className="dropdown-block__waiter">
                    <Waiter size="large" />
                  </div>
                ) : (
                  <>
                    {!required && (
                      <div
                        className="dropdown-block__option"
                        onClick={() =>
                          handleOnClickItem({ code: null, name: "" })
                        }
                      >
                        {phrases.dropdown.placeholder[currentLanguage]}
                      </div>
                    )}
                    {items.length === 0 ? (
                      <div className="dropdown-block__no-items">
                        {phrases.dropdown.noItems[currentLanguage]}
                      </div>
                    ) : (
                      items.map((item, index) => {
                        return (
                          <ListItem
                            key={item?.code || index}
                            item={item}
                            handleOnClickItem={handleOnClickItem}
                            dropdrownName={id}
                          />
                        );
                      })
                    )}
                  </>
                )}
              </div>
            )}
          </div>              
          {isErrorsEnabled && (
            <div className="dropdown-block__error">{error}</div>
          )}
        </div>
      </>
    );
  }
);

export default DropdownSelect;
