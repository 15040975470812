import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addNewProductOption } from "../../redux/reducers/productMenu";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import { nanoid } from "nanoid";
import VerticalLine from "../../components/veritcalLine";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import { Waiter } from "../../components/waiter";
import ProductItem from "../../components/productItem";
import productRestrictionMap from "./productRestrictionMap";

const ProductRestrictionPage = ({ page }) => {
  const pageData = productRestrictionMap[page];
  const navigate = useNavigate();
  const { productCode } = useParams();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const productMenu = useSelector((state) => state.productMenu);
  const menuIsLoaded = useSelector(
    (state) => state.productMenu.productCode !== ""
  );

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const itemsList = useSelector((state) => state[pageData.list].list);

  const [productItems, fetchingStatus] = useSelector(
    (state) => [
      state[pageData.productList].data,
      state[pageData.productList].fetchingStatus,
    ],
    shallowEqual
  );

  const isDeleteButtonDisabled = productItems.every(
    (item) => item.actionType === "C"
  );

  const [listIsLoading, setListLoading] = useState(false);
  const [error, setError] = useState([]);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const filteredProductItems = productItems.filter(
    (item) => item.actionType !== "D"
  );

  useEffect(() => {
    dispatch(pageData.getList());
  }, [pathname]);

  useEffect(() => {
    if (menuIsLoaded) {
      const option = pageData.option;
      const optionName = pageData.optionName;

      if (productMenu.children.some((item) => item.code === option)) return;

      dispatch(
        addNewProductOption({
          option,
          optionName,
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    if (!productCode) return;

    dispatch(pageData.getData(productCode));
  }, [productCode, pathname]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitItems));
  }, [JSON.stringify(productItems)]);

  function onListSearch(id, value) {
    setListLoading(true);
    dispatch(
      pageData.getList(value, (itemsList) => {
        const newProductItems = productItems.map((productItem) => {
          if (productItem.id !== id) return productItem;

          return {
            ...productItem,
            itemsList,
            actionType: !productItem.actionType ? "U" : productItem.actionType,
          };
        });

        dispatch(pageData.setData(newProductItems));
        setListLoading(false);
      })
    );
  }

  function onListSelect(item, id) {
    const newProductItems = productItems.map((productItem) => {
      if (productItem.id !== id) return productItem;

      return {
        ...productItem,
        name: item.name,
        code: item.code,
        itemsList,
        actionType: !productItem.actionType ? "U" : productItem.actionType,
      };
    });

    dispatch(pageData.setData(newProductItems));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }

    setError((errors) => ({
      ...errors,
      [id]: null,
    }));
  }

  function onExcludedChanged(e) {
    const [buttonId, itemId] = e.target.id.split("-");

    const newProductItems = productItems.map((productItem) => {
      if (productItem.id.toString() !== itemId) return productItem;
      return {
        ...productItem,
        excluded: buttonId === "excluded",
        actionType: !productItem.actionType ? "U" : productItem.actionType,
      };
    });

    dispatch(pageData.setData(newProductItems));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function deleteProductItem(id) {
    const productItem = productItems.find((item) => item.id === id);
    if (productItem.actionType === "C") {
      dispatch(pageData.setData(productItems.filter((item) => item.id !== id)));
      return;
    }
    dispatch(
      pageData.setData(
        productItems.map((item) => {
          if (item.id !== id) return item;
          return {
            ...item,
            actionType: "D",
          };
        })
      )
    );
    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function addNewProductItem() {
    const newProductItems = [
      ...productItems,
      {
        id: nanoid(3),
        code: null,
        name: null,
        excluded: true,
        itemsList,
        actionType: "C",
      },
    ];
    dispatch(pageData.setData(newProductItems));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const handleDeleteProductItems = () => {
    setDeleteModalActive(true);
  };

  const deleteProductItems = () => {
    dispatch(
      pageData.deleteData(productCode, () => {
        navigate(`/product/${productCode}`, {
          state: {
            actionType: "edit",
          },
        });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitItems() {
    if (productItems.length === 0) {
      navigate(`/product/${productCode}`, {
        state: {
          actionType: "edit",
        },
      });
      return;
    }

    if (productItems.every((spec) => spec.actionType === "D")) {
      dispatch(
        pageData.deleteData(productCode, () => {
          navigate(`/product/${productCode}`, {
            state: {
              actionType: "edit",
            },
          });
        })
      );
      return;
    }

    const isFormValid = productItems.every((item) => item.code);

    if (isFormValid) {
      dispatch(pageData.putData());
    } else {
      const errorsList = {};
      productItems
        .filter((item) => !item.code)
        .forEach((item) => {
          errorsList[item.id] = "Поле не заполнено";
        });

      setError(errorsList);
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    submitItems();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="content-general__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="content-general__header">
            <div className="main-header-block__item main-header-block__title">
              {pageData.headerTitle}
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteProductItems}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__column">
              <div className="main-form-block__column__block">
                {filteredProductItems.map((item) => {
                  return (
                    <div className="main-form-block__row" key={item.id}>
                      <ProductItem
                        list={item.itemsList}
                        id={item.id}
                        value={item.code}
                        name={item.name}
                        label={pageData.optionName}
                        onListSelect={onListSelect}
                        onListSearch={onListSearch}
                        searchable={pageData.searchable}
                        isLoading={listIsLoading}
                        onExcludedChange={onExcludedChanged}
                        excluded={item.excluded}
                        error={error[item.id]}
                        onDeleteItem={deleteProductItem}
                        isNewItem={item.actionType === "C"}
                      />
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="button button__secondary button__secondary__ghost"
                  onClick={addNewProductItem}
                >
                  + Добавить
                </button>
              </div>
            </div>
          </div>
          <div className="content-general__footer">
            <div className="main-footer-block__buttons">
              <ModalLinkWrapper
                linkProps={{ to: "/products", state: { pageCode: 40, withoutReset: true} }}
                className="button button__secondary button__secondary__ghost"
              >
                Вернуться к списку продуктов
              </ModalLinkWrapper>
              <button
                type="submit"
                className="button button__primary"
                disabled={!dataChanged}
              >
                Сохранить изменения
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteProductItems}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={pageData.historyTableName}
        rowCode={productCode}
        listType={"hist"}
      />
    </>
  );
};

export default ProductRestrictionPage;
