import React, {useEffect, useState} from 'react';
import './index.scss';
import iconClose from '../../theme/svg/close_icon.svg';
import { useMultiLanguageList } from '../../hooks/useMultiLanguageList';
import MultiLanguageForm from '../../components/multiLanguageForm';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmModal from '../../pureComponents/modal/confirmModal';
import CodeInputField from "../../pureComponents/codeInputField";
import FormInputField from "../../pureComponents/formInputField";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import {API} from "./api";
import createNameObject from "../../utils/createNameObject";
import updateDefaultLangName from "../../utils/updateDefaultLangName";
import {fetchFromApi} from "../../redux/actions/service";
import {initNotificationMessage, showNotificationMessage} from "../../redux/actions/sync/notificationMessage";
import {requestModelMark} from "../../redux/actions/async/modelMark";
import guidsFormMarkSchema from "../../validations/guidsFormMark";
import {waitForGuideData} from "../../redux/actions/sync/guides";
import {requestGuideTableBody} from "../../redux/actions/async/guides";

const initialState = {
  id: null,
  code: null,
  name: null,
  asMark: null,
  hidden: false
}
const avaliableFields = {
  code: 'code',
  name: 'name',
  asMark: 'asMark',
  hidden: 'hidden'
}

export function MarkGuideForm(props) {
  const store = useSelector(state => state.modelMark.list[0] );
  const dispatch = useDispatch();

  const [active_modal, setActive_modal] = useState(null);
  const [fields, setFields] = useState(initialState);
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState([]);
  const [changes, setChanges] = useState(false);
  const [changedFields, setChangedFields] = useState([]);

  const {
    multiLanguageFormData,
    multiLanguageFormDataChanged,
    multiLanguageFormErrors,
    updateLanguage,
    updateLanguageName,
    addNewLanguageName,
    deleteLanguageName,
    checkMultiLanguageFormIsValid,
    validateMultiLanguageForm,
  } = useMultiLanguageList(names);

  useEffect(() => {
    store.type === 'edit' && startEditCopy();
    store.type === 'copy' && startEditCopy();
    store.type === 'new' && startNew();
  },[]);

  useEffect(() => {
    if(multiLanguageFormDataChanged && !changes) {
      setChanges(true);
      updateChangedFields('name');
    }
  },[multiLanguageFormDataChanged, changes]);

  function startEditCopy() {
    API.getMark(props.data.currentRow).then((response) => {
      setNames(response.name.value.filter(item => item.lang !== 'DEF'));
      setFields((prev) => ({ ...prev,
        id: store.type === 'copy' ? null : response?.id,
        code: response?.code ,
        name: response?.name.value.filter(item => item.lang === 'DEF')[0].name,
        asMark: response?.asMark,
        hidden: response?.hidden || false
      }))
      if(store.type === 'copy') {
        API.getCode().then((rsCode) => {
          setFields((prev) => ({ ...prev, code: rsCode }))
          setChanges(true);
          setChangedFields(Object.values(avaliableFields));
        });
      }
    })
  }

  function startNew() {
    createCode();
  }

  function createCode() {
    API.getCode().then((response) => {
      setFields({ ...fields, code: response })
    });
  }

  function changeInput(event) {
    setChanges(true);
    updateChangedFields(event.target.id);
    if(event.target.id === 'hidden') {
      setFields({ ...fields, [event.target.id]: !fields.hidden });
    } else {
      setFields({ ...fields, [event.target.id]: event.target.value });
      setErrors(prev => ({...prev, [event.target.id]:null }));
    }
  }

  function updateChangedFields(id) {
    if (avaliableFields[id] && !changedFields.includes(avaliableFields[id])) {
      setChangedFields((prev) => [...prev, avaliableFields[id]]);
    }
  }

  async function save() {
    const isFormValid = await guidsFormMarkSchema.isValid( fields,{abortEarly: false} );
    const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();
    if( isFormValid && isMultiLanguageFormValid ) {
      updateDefaultLangName(multiLanguageFormData, fields.name)
      saveChanges({
        id: fields.id,
        code: fields.code,
        name: createNameObject(fields.name, multiLanguageFormData),
        asMark: fields.asMark,
        hidden: fields.hidden,
        changedFields: changedFields
      }).then();
    } else {
      if (!isFormValid) {
        guidsFormMarkSchema
            .validate(fields, { abortEarly: false })
            .catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: error.message,
                };
              }, {});
              setErrors(() => {
                return {
                  ...errors,
                };
              });
            });
      }
      if (!isMultiLanguageFormValid) {
        validateMultiLanguageForm();
      }
    }
  }

  async function saveChanges(body) {
    await fetchFromApi("api/v1/model-mark","PUT",{body})
        .then((res) => {
          switch (res.status) {
            case 200:
              dispatch(waitForGuideData(true));
              dispatch( requestModelMark(false, null, res.id));
              dispatch(requestGuideTableBody());
              break;
            case 409:
              dispatch(
                  initNotificationMessage(
                      'Ошибка',
                      'Введенный код уже существует в системе',
                      'danger'
                  ));
              dispatch(showNotificationMessage());
              break;
            default:
              dispatch(
                  initNotificationMessage(
                      'Ошибка',
                      'Произошла системная ошибка. Обратитесь к администратору системы',
                      'danger'
                  ));
              dispatch(showNotificationMessage());
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(
              initNotificationMessage(
                  'Ошибка',
                  'Произошла системная ошибка. Обратитесь к администратору системы',
                  'danger'
              )
          );
          dispatch(showNotificationMessage());
        });
  }

  function close() {
    if(!changes) {
      setFields(initialState);
      dispatch( requestModelMark(false, null, null));
    } else {
      setActive_modal('modalTC__wrapper-active');
    }
  }

  function closeApprowal(value) {
    setActive_modal(null);
  }

  function onContinueWithoutSave(value) {
    setFields(initialState);
    setActive_modal(null);
    dispatch(requestModelMark(false, null, null));
  }

  return (
    <div className={"model-guide__wrapper model-guide__wrapper_active"}>
      <div className="guide-block">
        <div className="model-guide-block__header">
          <div className="model-guide-block__header__name">{props.data.currentGuide}</div>
          <div className="model-guide-block__control model-guide-block__control_elastic">
            <CodeInputField
                id={'code'}
                label={'Код'}
                disabled={store.type === 'edit'}
                error={errors.code}
                value={fields.code}
                onChange={changeInput}
                required={true}
            />
          </div>
          <button
              className={'button button__primary button__small'}
              disabled={store?.type === 'edit'}
              onClick={() => createCode()}>
            Создать код
          </button>
          {/* X button */}
          <div className="model-guide-block__close" onClick={() => close()}>
            <img src={iconClose} alt="Закрыть" />
          </div>
        </div>

        <div className="guide-block__content">
          <FormInputField
              id='name'
              label='Название'
              value={fields.name}
              error={errors.name}
              onChange={changeInput}
              required={true}
          />
          <FormInputField
              id='asMark'
              label='Модель автостата'
              value={fields.asMark}
              error={errors.asMark}
              onChange={changeInput}
              // required={true}
          />
          <div className="guide-block__content__divider" />
          <MultiLanguageForm
            multiLanguageFormData={multiLanguageFormData}
            multiLanguageFormErrors={multiLanguageFormErrors}
            updateLanguage={updateLanguage}
            updateLanguageName={updateLanguageName}
            addNewLanguageName={addNewLanguageName}
            deleteLanguageName={deleteLanguageName}
          />
        </div>

        <div className="guide-block__bottom">
          <ToggleSwitch
              id={'hidden'}
              label={'Скрыть'}
              onToggle={changeInput}
              checked={fields.hidden}
          />
          <div className="buttons__group">
            <div
                className="button button__secondary button__secondary__ghost"
                onClick={() => onContinueWithoutSave()}
            >
              Не сохранять
            </div>

            <button
                disabled={!changes}
                className={'button button__primary ' }
                onClick={() => save()}
            >
              Сохранить изменения
            </button>
          </div>
        </div>
      </div>
      <ConfirmModal
          active={active_modal}
          onSave={save}
          onContinueWithoutSave={onContinueWithoutSave}
          closeModal={closeApprowal}
      />
    </div>
  );
}
