export const SET_CURRENT_GUIDE = 'SET_CURRENT_GUIDE';
export function setCurrentGuide(id, parent) {
  return {
    type: SET_CURRENT_GUIDE,
    id,
    parent,
  };
}

export const CLEAR_PREW_GUIDE = 'CLEAR_PREW_GUIDE';
export function clearPrewGuide() {
  return {
    type: CLEAR_PREW_GUIDE,
  };
}

export const SWITCH_GROUP_WISABLE = 'SWITCH_GROUP_WISABLE';
export function switchGroupVisable(id) {
  return {
    type: SWITCH_GROUP_WISABLE,
    id,
  };
}

export const RECIVE_GUIDE_TABLE_COLUMNS = 'RECIVE_GUIDE_TABLE_COLUMNS';
export function reciveGuideTableColumns(json) {
  return {
    type: RECIVE_GUIDE_TABLE_COLUMNS,
    json,
  };
}

export const RECIVE_GUIDE_TABLE_BODY = 'RECIVE_GUIDE_TABLE_BODY';
export function reciveGuideTableBody(json) {
  return {
    type: RECIVE_GUIDE_TABLE_BODY,
    json,
  };
}

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export function setSearchValue(value) {
  return {
    type: SET_SEARCH_VALUE,
    value,
  };
}

export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export function setSearchString(value) {
  return {
    type: SET_SEARCH_STRING,
    value,
  };
}

export const SET_LIMIT = 'SET_LIMIT';
export function setLimit(limit) {
  return {
    type: SET_LIMIT,
    limit,
  };
}

export const SET_PAGE = 'SET_PAGE';
export function setPage(page) {
  return {
    type: SET_PAGE,
    page,
  };
}

export const SET_OFFSET = 'SET_OFFSET';
export function setOffset(page) {
  return {
    type: SET_OFFSET,
    page,
  };
}

export const RESET_GUIDES_PAGE = 'RESET_GUIDES_PAGE';
export function resetGuidesPage() {
  return {
    type: RESET_GUIDES_PAGE,
  };
}

export const SWITCH_MAIN_MENU_VISIBLE = 'SWITCH_MAIN_MENU_VISIBLE';
export function switchMainMenuVisible(mode) {
  return {
    type: SWITCH_MAIN_MENU_VISIBLE,
    mode,
  };
}

export const SET_CURRENT_GUIDE_TABLE_ROW = 'SET_CURRENT_TABLE_ROW';
export function setCurrentGuideTableRow(id) {
  return {
    type: SET_CURRENT_GUIDE_TABLE_ROW,
    id,
  };
}

export const WAIT_FOR_GUIDE_DATA = 'WAIT_FOR_GUIDE_DATA';
export function waitForGuideData(mode) {
  return {
    type: WAIT_FOR_GUIDE_DATA,
    mode,
  };
}

export const LOAD_SEARCH_VALUES_LIST = 'LOAD_SEARCH_VALUES_LIST';
export function loadSearchValuesList() {
  return {
    type: LOAD_SEARCH_VALUES_LIST,
  };
}

export const CLEAR_SEARCH_VALUES_LIST = 'CLEAR_SEARCH_VALUES_LIST';
export function clearSearchValuesList() {
  return {
    type: CLEAR_SEARCH_VALUES_LIST,
  };
}

export const SWITCH_SEARCH_CHECKBOX = 'SWITCH_SEARCH_CHECKBOX';
export function switchSearchCheckbox(checked) {
  return {
    type: SWITCH_SEARCH_CHECKBOX,
    checked,
  };
}

export const CHANGE_HIDDEN_MODE = 'CHANGE_HIDDEN_MODE';
export function changeHiddenMode() {
  return {
    type: CHANGE_HIDDEN_MODE,
  };
}

export const DROP_MODEL_COMPLECT_CODE = 'DROP_MODEL_COMPLECT_CODE';
export function dropModelComplectCode() {
  return {
    type: DROP_MODEL_COMPLECT_CODE
  };
}
