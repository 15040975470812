import { connect } from 'react-redux';
import { GuidesPageSubcontrols } from '../../components/guidesPageSubcontrols';
import { selectLimit, selectPage } from '../actions/async/guides';

const mapStateToProps = (state) => {
  return {
    limit: state.guides.limit,
    limitList: state.guides.limitList,
    rows: state.guides.rows,
    offset: state.guides.offset,
    page: state.guides.activePage,
    currGuide: state.guides.current,
    dataLoaded: state.guides.body.length > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectLimit: (val) => dispatch(selectLimit(val)),
    selectPage: (page) => dispatch(selectPage(page)),
  };
};

const VisableGuidesPageSubcontrols = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuidesPageSubcontrols);

export default VisableGuidesPageSubcontrols;
