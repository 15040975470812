import { fetchFromApi } from "../service";
import {setStatisticRequestReportFormSelectors} from "../../reducers/statisticRequestFormSelectors";

export function getModelCategory() {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        const response = await fetchFromApi("api/v1/model-category/filter/page", "POST",{
                body: {
                    lang: locale,
                    hidden: true,
                    offset: "0",
                    limit: 100}
            });
        const data = await response.json();
        dispatch(
            setStatisticRequestReportFormSelectors({
                catigoryList: data.data
            })
        );
    };
}

export function getModelSubcategory(categoryList = null) {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        const catigories = state.statisticRequestReportForm.selectedCatigories ;
        try {
            const response = await fetchFromApi("api/v1/model-subcategory/list/filter", "POST",
                { body:{
                        lang: locale,
                        modelCategory: catigories.map( (item) => (item.code))
                    } });
            const data = await response.json();
            dispatch(
                setStatisticRequestReportFormSelectors({
                    subcatigoryList: data
                })
            );
        } catch (error) {}
    };
}

export function makeStatisticRequestReport(callback) {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        try {
            const response = await fetchFromApi("api/v1/xls/report/stat/request", "POST",
                { body:{
                        lang: locale,
                        modelCategory: state.statisticRequestReportForm.selectedCatigories.map( (item) => (item.code)),
                        modelSubcategory: state.statisticRequestReportForm.selectedSubcatigories.map( (item) => (item.code)),
                        dateFrom: state.statisticRequestReportForm.dateFrom,
                        dateTo: state.statisticRequestReportForm.dateTo
                    } });

            const file = await response.blob();
            const name = response.headers.get('Content-Disposition').split('\'\'')[1];
            if (callback) {
                callback(file, decodeURIComponent(name));
            }
        } catch (error) {
        }
    }
}
