import { fetchFromApi } from "../service";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getSearchString } from "./guides";

const filtersMap = {
  category: "modelCategoryCode",
  subcategory: "modelSubcategoryCode",
  markCode: "modelMarkCode",
  model: "modelCode",
  engineCap: "engineCap",
  years: "year",
};

function checkRequiredFilters(state, filters) {
  return filters.every((filter) => state[filter.id].current !== "def");
}

export function exportTemplate(callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const tableName = state.guides.list.filter(
      (el) => el.id === state.guides.current
    )[0].table;
    const token = state.auth.jwt;

    try {
      const responce = await fetchFromApi(
        "api/v1/dictionary/export/template/" + tableName,
        "GET",
        { token }
      );
      const file = await responce.blob();

      callback(file);
    } catch (error) {
      console.error(error.message);
    }
  };
}

export function exportData(callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const token = state.auth.jwt;

    const guide = state.guides.list.find(
      (el) => el.id === state.guides.current
    );

    const requiredFilters = guide.filters.filter((filter) => filter.required);
    if (!checkRequiredFilters(state, requiredFilters)) {
      dispatch(
        initNotificationMessage(
          "Ошибка",
          `Не заполнены обязательные фильтры!`,
          "danger"
          )
          );
          dispatch(showNotificationMessage());
          return;
    }

    const body = {
      lang,
      hidden: state.guides.hidden,
      dictName: guide.name,
    };

    const filters = guide.filters.map((filter) => filter.id);
    filters.forEach((filter) => {
      if (state[filter].current !== "def") {
        body[filtersMap[filter]] = state[filter].current;
      }
    });

    if (
      state.guides.currentSearchValue !== "def" &&
      state.guides.searchString
    ) {
      body.colListCode = state.guides.currentSearchValue;
      body.searchString = getSearchString(state);
    }

    const resource = guide.table.includes("_")
      ? guide.table.replace("_", "-")
      : guide.table;

    try {
      const response = await fetchFromApi(
        "api/v1/xls/export/" + resource,
        "POST",
        { token, body }
      );
      const file = await response.blob();
      const fileName = response.headers.get('Content-Disposition').split('\'\'')[1];

      callback(file, decodeURIComponent(fileName));
    } catch (error) {
      console.error(error.message);
    }
  };
}
