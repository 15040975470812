export const UPDATE_YEAR = 'UPDATE_YEAR';
export function updateYear(year) {
  return {
    type: UPDATE_YEAR,
    year,
  };
}

export const CLEAR_YEAR = 'CLEAR_YEAR';
export function clearYear() {
  return {
    type: CLEAR_YEAR,
  };
}

export const HENDLE_UPDATE_YEAR = "HENDLE_UPDATE_YEAR";
export function hendleUpdateYear(value){
    return{
        type:HENDLE_UPDATE_YEAR,
        value
    }
}

export const VALIDATE_YEAR = "VALIDATE_YEAR";
export function validateYear(value){
  return{
    type: VALIDATE_YEAR,
    value,
  }
}