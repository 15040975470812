import React from 'react';
import './index.scss';
import DropdownSelect from "../../pureComponents/dropdownSelect";

export function GuidesPageSubcontrols(props) {
  if(props.currGuide === 'def'){
    return(<></>)
  }
  const limitList = props.limitList.map((el, i) => ({ code:i, name:el }));

  let pages;
  const tail = props.rows % props.limit;
  let pageCount = (props.rows - tail) / props.limit;
  pageCount = tail === 0 ? pageCount : pageCount + 1;
  if (pageCount <= 7) {
    pages = createPages(1, pageCount);
  } else {
    if ((props.page > 2) && (props.page < pageCount - 5)) {
      pages = [
        ...createPages(props.page - 1, props.page + 1),
        <div className="paginator__page-shim" key={pageCount + 1}>
          {'...'}
        </div>,
        ...createPages(pageCount - 2, pageCount),
      ];
    } else if (props.page > pageCount - 6) {
      pages = createPages(pageCount - 6, pageCount);
    } else {
      pages = [
        ...createPages(1, 3),
        <div className="paginator__page-shim" key={pageCount + 1}>
          {'...'}
        </div>,
        ...createPages(pageCount - 2, pageCount),
      ];
    }
  }

  function createPages(offset, pageCount) {
    let pages = [];
    for (let i = offset; i <= pageCount; i++) {
      if (i === props.page) {
        pages.push(
          <div
            className="paginator__page-button paginator__page-button_active"
            key={i}
          >
            {i}
          </div>
        );
      } else {
        pages.push(
          <div
            className="paginator__page-button"
            onClick={() => props.selectPage(i)}
            key={i}
          >
            {i}
          </div>
        );
      }
    }
    return pages;
  }

  let paginatorMod = '';
  let limitMod = false;
  if (props.rows === 0) {
    paginatorMod = ' paginator_hide';
    limitMod=true;
  }

  function lastPage() {
    if (props.page < pageCount) {
      props.selectPage(pageCount);
    }
  }

  function firstPage() {
    if (props.page > 1) {
      props.selectPage(1);
    }
  }

  function changeLimit(item, id) {
    props.selectLimit(item.name)
  }

  return (
    <div className="guides-page-subcontrols">
      <div className="table-limit-control">
        <div className="table-limit-control__name">Строк на странице&nbsp;</div>
        <div className="table-limit-control__name">
          <DropdownSelect
              id={'limit'}
              disabled={limitMod}
              defaultValue={props.limit}
              items={limitList}
              value={props.limit}
              required={true}
              onSelect={changeLimit}
              isErrorsEnabled={false}
          />
        </div>
      </div>
      <div className={'paginator' + paginatorMod}>
        <div className="paginator__info">
          {'Показано ' +
            (props.offset + 1) +
            '-' +
            Math.min((props.offset + 1) + (props.limit - 1), props.rows) +
            ' строк из ' +
            props.rows}
        </div>
        <div
          className={
            'paginator__button' +
            (props.page === 1 ? '' : ' paginator__button_active')
          }
          onClick={firstPage}
        >
          {'< В начало'}
        </div>
        <div className="paginator__pages">{pages}</div>
        <div
          className={
            'paginator__button' +
            (props.page >= pageCount ? '' : ' paginator__button_active')
          }
          onClick={lastPage}
        >
          {'В конец >'}
        </div>
      </div>
    </div>
  );
}
