import { object, string, array } from "yup";

const messages = {
  required: "Поле не заполнено",
};

const conditionIntervalsSchema = array().of(
  object().shape({
    id: string(),
    actionType: string().nullable(),
    intervalTypeCode: string().required(messages.required),
    intervalCode: string().required(messages.required),
    intervalActionCode: string().required(messages.required),
  })
);

export default conditionIntervalsSchema;
