import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {
    getModelCategory, getModelSubcategory
} from "../../redux/actions/async/statisticRequestReportFormSelectors";
import {
    resetStatisticRequestReportFormState, updateStatisticRequestReportForm
} from "../../redux/reducers/statisticRequestReportForm";
import useDownloadFile from "../../hooks/useDownloadFile";
import StatisticRequestReportForm from "./statisticRequestReportForm";
import {makeStatisticRequestReport} from "../../redux/actions/async/statisticRequestReportFormSelectors";
import {useDocumentTitle} from "../../hooks/useDocumentTitle";


const StatisticRequestReportPage = () => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.statisticRequestReportForm );
    const [formErrors, setErrors] = useState({});

    useDocumentTitle('Отчеты - Статистика запросов');

    useEffect(() => {
        dispatch(getModelCategory());
        return () => {
            dispatch(resetStatisticRequestReportFormState());
        };
    }, []);

    useEffect(() => {
        dispatch(getModelSubcategory(formData.selectedCatigories));
    }, [formData.selectedCatigories]);

    const downloadFile = useDownloadFile();
    const onSubmit = (event) => {
        event.preventDefault();
        if(formData['dateFrom'] && formData['dateTo']) {
            dispatch(makeStatisticRequestReport((file, name) => downloadFile(file, name)));
        } else {
            if( !formData.dateFrom ) setErrors(prev => ( {...prev, dateFrom: 'Error!'}) );
        }
    };

    const handleInputChange = useCallback(({ target: { id, type, value } }) => {
    }, []);

    const handleButtonClick = useCallback(
        ( items, id ) => {
            dispatch(resetStatisticRequestReportFormState());
        },[]
    );

    const handleDateChange = useCallback((value, id) => {
        dispatch(updateStatisticRequestReportForm({[id]: value?.toLocaleDateString("ru-RU")}))
        setErrors(prev => ( {...prev, [id]: null}) );
    }, [])

    const onDelete = (code,id) => {
        const newSelectedItems = formData[id].filter( (selectedItem) => selectedItem.code !== code );
        dispatch(updateStatisticRequestReportForm({[id]:newSelectedItems}));
    };

    const onSelect = (item, id) => {
        const isExist = formData[id].some((i) => i.code === item.code);
        if (!isExist) {
            const newSelectedItems = [...formData[id], item];
            dispatch(updateStatisticRequestReportForm({[id]:newSelectedItems}));
        } else {
            onDelete(item.code, id);
        }
    };

    const onClearField = (id) => {
        dispatch(updateStatisticRequestReportForm({[id]:[]}));
    };

    return (
        <>
            <StatisticRequestReportForm
                formErrors={formErrors}
                handleInputChange={handleInputChange}
                handleDateChange={handleDateChange}
                onSubmit={onSubmit}
                onDelete={onDelete}
                onSelect={onSelect}
                onClearField={onClearField}
                handleButtonClick={handleButtonClick}
            />
        </>
    );
};

export default StatisticRequestReportPage;
