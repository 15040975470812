import { connect } from 'react-redux';
import { SvProductListItem } from '../../components/svProductListItem';

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.svProduct.by_id[ownProps.id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const visibleSvProductListItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SvProductListItem);

export default visibleSvProductListItem;
