import { RECEIVE_MARKET } from "../actions/sync/market";

const initialState = {
	list: [],
};

function market(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_MARKET:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default market;
