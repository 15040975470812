import { RESET_APP } from "../actions/sync/init";
import {
  CLEAR_MODEL_CLASS,
  RECEIVE_MODEL_CLASSES,
  UPDATE_MODEL_CLASS,
} from "../actions/sync/modelClass";
import {
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  CLEAR_SEARCH_RESULT,
} from "../actions/sync/search";

const defState = {
  current: "def",
  list: [],
};

function modelClass(state = defState, action) {
  switch (action.type) {
    case RECEIVE_MODEL_CLASSES:
      return Object.assign({}, state, { list: action.payload });
    case RECIVE_SEARCH_BY_STRING_RESULT:
      return Object.assign({}, state, { list: action.json.modelClass });
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      if (Object.keys(action.json).length === 0) {
        return defState;
      }
      if (action.json.modelClass.length === 1) {
        // return state;
        return Object.assign({}, state, {
          list: action.json.modelClass,
          current: action.json.modelClass[0].code,
        });
      }
      return Object.assign({}, state, { list: action.json.modelClass });
    case UPDATE_MODEL_CLASS:
      return Object.assign({}, state, { current: action.id });
    case CLEAR_MODEL_CLASS:
      return defState;
    case RESET_APP:
      return defState;
    case CLEAR_SEARCH_RESULT:
      return defState;
    default:
      return state;
  }
}

export default modelClass;
