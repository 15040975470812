import { RECEIVE_CONDITION_VISCO_INTERVAL } from "../actions/sync/conditionViscoInterval";

const initialState = {
	list: [],
};

function conditionViscoInterval(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_CONDITION_VISCO_INTERVAL:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default conditionViscoInterval;
