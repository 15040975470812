const restrictionsMap = {
  specFlg: {
    name: "Спецификации",
    linkName: "spec",
  },
  viscoFlg: { name: "Вязкости", linkName: "visco" },
  segmentFlg: { name: "Сегмент", linkName: "segment" },
  modelSubcategoryFlg: {
    name: "Подкатегория ТС",
    linkName: "model-subcategory",
  },
  modelMarkFlg: { name: "Марка ТС", linkName: "model-mark" },
  modelFlg: { name: "Модель ТС", linkName: "model" },
  componentFuelTypeFlg: {
    name: "Тип топлива",
    linkName: "component-fuel-type",
  },
  componentGroupFlg: { name: "Группа узла", linkName: "component-group" },
};

function normalizeProductMenu(menu, productCode) {
  const productLink = `/product/${productCode}`;
  const activeRestrictions = Object.keys(menu).filter(
    (restriction) => menu[restriction]
  );

  return {
    productCode,
    active: false,
    link: productLink,
    actionType: "edit",
    children: activeRestrictions
      ? activeRestrictions.map((restriction) => {
          return {
            link: `${productLink}/${restrictionsMap[restriction].linkName}`,
            active: false,
            name: restrictionsMap[restriction].name,
            code: restrictionsMap[restriction].linkName,
          };
        })
      : [],
  };
}

export default normalizeProductMenu;
