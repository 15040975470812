import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteConditionVisco,
  updateConditionVisco,
} from "../../../redux/reducers/conditionViscos";
import { updateAllChg } from "../../../redux/actions/sync/currentPageFormState";
import DropdownSelect from "../../../pureComponents/dropdownSelect";
import getNameFromList from "../../../utils/getNameFromList";
import { getViscoList } from "../../../redux/actions/async/viscoSelectors";

const ViscosItem = ({
  id,
  viscoCode,
  viscoList,
  conditionViscoIntervalCode,
  isNewVisco,
  error,
  setErrors,
}) => {
  const dispatch = useDispatch();
  const { conditionViscoIntervalList } = useSelector(
    (state) => state.viscoSelectors
  );

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const [searchLoading, setSearchLoading] = useState({
    [id]: false,
  });

  useEffect(() => {
    dispatch(getViscoList(viscoCode, id));
  }, [viscoCode]);

  const handleDropdownChange = useCallback(({ code }, id) => {
    const [fieldName, viscoId] = id.split("-");

    dispatch(
      updateConditionVisco({
        id: viscoId,
        field: fieldName,
        value: code,
      })
    );

    setErrors((prevErorrs) => ({
      ...prevErorrs,
      [viscoId]: {
        ...prevErorrs[viscoId],
        [fieldName]: "",
      },
    }));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }, []);

  const handleDropdownSearch = useCallback((id, searchValue) => {
    const viscoId = id.split("-")[1];
    setSearchLoading({
      [viscoId]: true,
    });

    dispatch(
      getViscoList(viscoCode, viscoId, searchValue, () =>
        setSearchLoading({
          [viscoId]: false,
        })
      )
    );
  }, [viscoCode]);

  const handleDeleteItem = () => {
    dispatch(deleteConditionVisco(id));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  };

  return (
    <>
      <DropdownSelect
        id={`viscoCode-${id}`}
        label="Вязкость"
        defaultValue={getNameFromList(viscoList, viscoCode)}
        items={viscoList}
        onSelect={handleDropdownChange}
        searchable={true}
        onSearch={handleDropdownSearch}
        isLoading={searchLoading[id]}
        error={error?.viscoCode}
        disabled={!isNewVisco}
        required
      />
      <DropdownSelect
        id={`conditionViscoIntervalCode-${id}`}
        label="Температурный интервал"
        defaultValue={getNameFromList(
          conditionViscoIntervalList,
          conditionViscoIntervalCode
        )}
        items={conditionViscoIntervalList}
        onSelect={handleDropdownChange}
        error={error?.conditionViscoIntervalCode}
        required
      />
      <button
        type="button"
        className="button button__negative button__negative__ghost"
        onClick={() => handleDeleteItem()}
      >
        Удалить элемент
      </button>
    </>
  );
};

export default ViscosItem;
