import React from "react";
import { useSelector } from "react-redux";
import MultiLanguageItem from "./multiLanguageItem";

const MultiLanguageForm = React.memo(
  ({ multiLanguageFormData, addNewLanguageName, ...multiLanguageItemProps }) => {
    const languageListLength = useSelector((state) => state.i18.list.length);

    return (
      <div className="multi-language-form">
        {multiLanguageFormData.map((item) => {
          return (
            <MultiLanguageItem
              key={item.id}
              item={item}
              multiLanguageFormData={multiLanguageFormData}
              {...multiLanguageItemProps}
            />
          );
        })}
        <button
          type="button"
          className="button button__secondary button__secondary__ghost"
          disabled={multiLanguageFormData.length === languageListLength}
          onClick={addNewLanguageName}
        >
          + Добавить Язык
        </button>
      </div>
    );
  }
);

export default MultiLanguageForm;
