import { connect } from 'react-redux';
import { Component } from '../../components/component';
import { changeComponentMode } from '../actions/sync/product';
import { switchFullDescription } from '../actions/sync/description';

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.components.by_id[ownProps.id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeComponentMode: (id) => dispatch(changeComponentMode(id)),
    switchFullDescription: (id) => dispatch(switchFullDescription(id))
  };
};

const VisibleComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default VisibleComponent;
