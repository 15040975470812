import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useDateFormat(options = null) {
  const locale = useSelector((state) => state.i18.current);
  const [localeDate, setLocaleDate] = useState(
    () => new Intl.DateTimeFormat(locale)
  );

  useEffect(() => {
    if (options) {
      setLocaleDate(new Intl.DateTimeFormat(options));
    }
  }, [options]);

  return localeDate;
}

export default useDateFormat;
