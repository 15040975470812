import normalizeIntervalList from "../../../normalizers/intervalList";
import { updateConditionInterval } from "../../reducers/conditionIntervals";
import { setIntervalSelectors } from "../../reducers/intervalSelectors";
import { fetchFromApi } from "../service";

export function getIntervalSelectors() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;

    const urls = [
      "api/v1/interval-type/filter/page",
      "api/v1/interval-action/filter/page",
    ];

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await Promise.all(
        urls.map((url) => fetchFromApi(url, "POST", { body }))
      );
      const responseData = await Promise.all(
        response.map((data) => data.json())
      );

      const [intervalTypeList, intervalActionList] = responseData.map(
        (data) => data.data
      );

      dispatch(
        setIntervalSelectors({
          intervalTypeList,
          intervalActionList,
        })
      );
    } catch (error) {}
  };
}

export function getFitleredIntervalsList(
  intervalTypeCode,
  intervalRowId,
  searchStr = null,
  callback = null
) {
  return async (dispatch, getState) => {
    const url = "api/v1/interval/filter";
    const body = {
      val: searchStr,
      intervalTypeCode,
    };
    try {
      const response = await fetchFromApi(url, "POST", { body });
      const data = await response.json();
      const intervalList = normalizeIntervalList(data);

      dispatch(
        updateConditionInterval({
          id: intervalRowId.toString(),
          field: "intervalList",
          value: intervalList,
        })
      );

      if (callback) {
        callback();
      }
    } catch (error) {}
  };
}
