const initialState = {
  current: "",
  list: [],
};

function conditionAnalog(state = initialState, action) {
  switch (action.type) {
    case SET_CONDITION_ANALOG_LIST:
      return { ...state, list: action.payload };
    default:
      return state;
  }
}

const SET_CONDITION_ANALOG_LIST = "SET_CONDITION_ANALOG_LIST";
export const setConditionAnalogList = (payload) => ({
  type: SET_CONDITION_ANALOG_LIST,
  payload,
});

export default conditionAnalog;
