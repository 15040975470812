import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { API } from "./api";
import SearchByStringForm from "./searchByStringForm";
import SearchByFilterForm from "./searchByFilterForm";
import SearchResultForm from "./searchResultForm";
import SearchViewProductForm from "./searchViewProductForm";
import {
  setSearchMode,
  setSearchStage,
  setSearchType,
  updateSearchString,
} from "../../redux/actions/sync/search";
import { requestSearchResult } from "../../redux/actions/async/search";
import FilterResultForm from "./filterResultForm";
import { fetchProductInfo } from "../../redux/actions/async/product";
import { setProductCode } from "../../redux/actions/sync/product";
import { useSearchParams } from "react-router-dom";

const initialState = {
  categoryCode: null,
  subcategoryCode: null,
  modelMarkCode: null,
  modelCode: null,
  year: null,
  fuelTypeCode: null,
  engineCapCode: null,
  modelClassCode: null,
};

export function SelectorList(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();

  const [fields, setFields] = useState(initialState);
  const [filterFields, setFilterFields] = useState(initialState);

  const [stringSearchDisable, setStringSearchDisable] = useState(true);
  const [filterSearchDisable, setFilterSearchDisable] = useState(true);
  const [hasSearchResult, setHasSearchResult] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [marks, setMarks] = useState([]);
  const [models, setModels] = useState([]);

  const [stringSearchType, setStringSearchType] = useState("technic");
  const [searchPart, setSearchPart] = useState("");

  useEffect(() => {
    const currentProductCode = searchParams.get('code')
    if(!currentProductCode){
    dispatch(setSearchStage("home"));
    API.getCategories({ lang: state.i18.current, hidden: true }).then(
      (response) => {
        setCategories(response);
        setFields({ ...fields, categoryCode: "1" });
      }
    );
  }
  }, [searchParams]);

  // Change categoryCode
  useEffect(() => {
    setSubcategories([]);
    setFields((prev) => ({ ...prev, subcategoryCode: null }));
    setFilterSearchDisable(true);
    if (fields.categoryCode) {
      API.getSubcategories({
        lang: state.i18.current,
        modelCategoryCode: fields.categoryCode,
        hidden: true,
      }).then((response) => {
        setSubcategories(response);
      });
    }
  }, [fields.categoryCode]);

  // Change subcategoryCode
  useEffect(() => {
    setMarks([]);
    setFields({ ...fields, modelMarkCode: null });
    setFilterSearchDisable(true);
    if (fields.subcategoryCode) {
      API.getMarks({
        lang: state.i18.current,
        modelSubcategoryCode: fields.subcategoryCode,
        hidden: true,
      }).then((response) => {
        setMarks(response);
      });
    }
  }, [fields.subcategoryCode]);

  // Change modelMarkCode
  useEffect(() => {
    setModels([]);
    setFields({ ...fields, modelCode: null });
    setFilterSearchDisable(true);
    if (fields.modelMarkCode) {
      API.getModel({
        lang: state.i18.current,
        modelSubcategoryCode: fields.subcategoryCode,
        modelMarkCode: fields.modelMarkCode,
        hidden: true,
      }).then((response) => {
        setModels(response);
      });
    }
  }, [fields.modelMarkCode]);

  // Change modelCode
  useEffect(() => {
    setFields({
      ...fields,
      year: null,
      fuelTypeCode: null,
      engineCapCode: null,
      modelClassCode: null,
    });
    if (fields.modelCode) {
      setFilterSearchDisable(false);
    } else {
      setFilterSearchDisable(true);
    }
  }, [fields.modelCode]);

  useEffect(() => {
    if (searchPart === "by_string") {
      doSearchRequest();
    }
  }, [searchPart, filterFields]);

  function doSearchRequest(appendResult = false) {
    if (searchPart === "by_string") {
      dispatch(requestSearchResult(filterFields, appendResult));
    } else {
      dispatch(requestSearchResult(fields, appendResult));
    }
  }

  function changeDropdown(item, id) {
    setFields((prev) => ({ ...prev, [id]: item.code }));
  }

  function changeFilterDropdown(item, id) {
    setFilterFields((prev) => ({ ...prev, [id]: item.code }));
  }

  function changeInput(event) {
    setFields({ ...fields, [event.target.id]: event.target.value });
    setStringSearchDisable(event.target.value?.length < 2);
  }

  function onEnter(event) {
    if (event.target.id === "searchString" && !stringSearchDisable) {
      startSearch("by_string");
    }
  }

  function onStringSearchTypeChange(event) {
    if (stringSearchType === "technic") {
      setStringSearchType("module");
    } else {
      setStringSearchType("technic");
    }
  }
  const handleDateChange = useCallback((value, id) => {
    setFields((prev) => ({ ...prev, [id]: value }));
  }, []);

  const handleFilterDateChange = useCallback((value, id) => {
    setFilterFields((prev) => ({ ...prev, [id]: value }));
  }, []);

  function startSearch(type) {
    setSearchPart(type);
    dispatch(updateSearchString(fields.searchString));
    dispatch(setSearchMode(type));
    dispatch(setSearchType(stringSearchType));

    if (type === "by_string") {
      setFilterFields(initialState);
      dispatch(requestSearchResult(filterFields));
    } else {
      dispatch(requestSearchResult(fields));
    }
    dispatch(setSearchStage("search"));
    setHasSearchResult(true);
  }

  function activateProduct(event) {
    const code = event.currentTarget.getAttribute("data-id");
    setSearchParams(`?code=${code}`)
    dispatch(setProductCode(code));
    dispatch(fetchProductInfo());
    dispatch(setSearchStage("product"));
  }

  return (
    <>
      {(state.search.stage === "home" ||
        (state.search.stage === "search" && searchPart === "by_string")) && (
        <SearchByStringForm
          props={props}
          stringSearchType={stringSearchType}
          onStringSearchTypeChange={onStringSearchTypeChange}
          startSearch={startSearch}
          changeInput={changeInput}
          stringSearchDisable={stringSearchDisable}
          fields={fields}
          fetching={props.fetching}
          onEnter={onEnter}
          searchPart={searchPart}
        />
      )}
      {(state.search.stage === "home" ||
        (state.search.stage === "search" && searchPart === "by_filter")) && (
        <SearchByFilterForm
          state={state}
          props={props}
          startSearch={startSearch}
          fields={fields}
          filterSearchDisable={filterSearchDisable}
          categories={categories}
          changeDropdown={changeDropdown}
          handleDateChange={handleDateChange}
          marks={marks}
          models={models}
          subcategories={subcategories}
          fuelType={props.fuelType}
          engineCap={props.engineCap}
          modelClass={props.modelClass}
          searchPart={searchPart}
          hasSearchResult={hasSearchResult}
        />
      )}
      {state.search.stage === "search" &&
        searchPart === "by_string" && ( // (searchPart === 'by_string' && hasSearchResult ) &&
          <FilterResultForm
            props={props}
            startSearch={startSearch}
            fields={filterFields}
            categories={props.categories}
            marks={props.modelMark}
            models={props.modelGeneration}
            subcategories={props.subcategories}
            fuelType={props.fuelType}
            engineCap={props.engineCap}
            modelClass={props.modelClass}
            changeDropdown={changeDropdown}
            handleFilterDateChange={handleFilterDateChange}
            searchPart={searchPart}
            changeFilterDropdown={changeFilterDropdown}
          />
        )}
      {state.search.stage === "search" && (
        <SearchResultForm
          props={props}
          onLoadMore={doSearchRequest}
          activateProduct={activateProduct}
        />
      )}
      {state.search.stage === "product" && (
        <SearchViewProductForm props={props} state={state.product} />
      )}
    </>
  );
}
