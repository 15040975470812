import React from 'react';
import VisibleReportRow from '../../redux/containers/visibleReportRow';
import './index.css';

export function Report(props) {
  const rows = props.specVisco.map((el, i, mass) => (
    <VisibleReportRow data={el} key={i} last={mass.length-1 === i} />
  ));
  return <div className="report">{rows}</div>;
}
