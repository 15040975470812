import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import { setActiveProductMenuItem } from "../../reducers/productMenu";
import {
  setProductComponentGroup,
  setProductComponentGroupFetchingStatus,
} from "../../reducers/productComponentGroup";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getProductMenu } from "./productMenu";

export function getProductComponentGroup(productCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/product-component-group";

    try {
      dispatch(setProductComponentGroupFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${productCode}/locale/${lang}`,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(
        setProductComponentGroup(
          data.map((item) => ({
            ...item,
            code: item.componentGroupCode,
            name: item.componentGroupName,
          }))
        )
      );
      dispatch(setProductComponentGroupFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setProductComponentGroupFetchingStatus());
    }
  };
}

export function putProductComponentGroup() {
  return async (dispatch, getState) => {
    const state = getState();
    const productComponentGroup = state.productComponentGroup.data;
    const productCode = state.productMenu.productCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setProductComponentGroupFetchingStatus("update"));

      const body = {
        data: productComponentGroup
          .filter((item) => item.actionType)
          .map((item) => ({
            productCode,
            componentGroupCode: item.code,
            id: item.actionType === "C" ? null : item.id,
            excluded: item.excluded,
            actionType: item.actionType,
          })),
      };

      const response = await fetchFromApi(
        "api/v1/product-component-group",
        "POST",
        {
          body,
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getProductMenu(productCode));
      dispatch(setActiveProductMenuItem(pathname));
      dispatch(resetCurrentPageFormState());
      dispatch(getProductComponentGroup(productCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductComponentGroupFetchingStatus());

      return false;
    }
  };
}

export function deleteProductComponentGroupFromServer(
  productCode,
  callback = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    const body = {
      code: productCode,
      data: state.productComponentGroup.data.map( item => ({componentGroup: item.code}))
    };

    try {
      dispatch(setProductComponentGroupFetchingStatus("loading"));
      const response = await fetchFromApi(
        "api/v1/product-component-group/all",
        "DELETE",
        {
          body
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getProductMenu(productCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductComponentGroupFetchingStatus());
    }
  };
}
