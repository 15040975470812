const initialState = {
  specCode: null,
  active: false,
  root: true,
  rootName: "Данные по спецификации",
  children: [],
};

function specMenu(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SPEC_MENU:
      return { ...state, ...payload };
    case SET_ACTIVE_SPEC_MENU_ITEM:
      return {
        ...state,
        active: state.link === payload,
        children: state.children.map((item) => ({
          ...item,
          active: item.link === payload,
        })),
      };
    case ADD_NEW_SPEC_OPTION:
      return {
        ...state,
        children: [
          ...state.children,
          {
            active: true,
            link: `/spec/${state.specCode}/${payload.option}`,
            code: payload.option,
            name: payload.optionName,
          },
        ],
      };
    case RESET_SPEC_MENU:
      return initialState;
    default:
      return state;
  }
}

const SET_SPEC_MENU = "SET_SPEC_MENU";
const RESET_SPEC_MENU = "RESET_SPEC_MENU";
const SET_ACTIVE_SPEC_MENU_ITEM = "SET_ACTIVE_SPEC_MENU_ITEM";
const ADD_NEW_SPEC_OPTION = "ADD_NEW_SPEC_OPTION";

export const setSpecMenu = (payload) => ({
  type: SET_SPEC_MENU,
  payload,
});

export const resetSpecMenu = () => ({
  type: RESET_SPEC_MENU,
});

export const setActiveSpecMenuItem = (payload) => ({
  type: SET_ACTIVE_SPEC_MENU_ITEM,
  payload,
});

export const addNewSpecOption = (payload) => ({
  type: ADD_NEW_SPEC_OPTION,
  payload,
});

export default specMenu;
