import React from "react";
import { Tooltip } from "react-tooltip";

const CodeInputField = React.memo(
  ({
    label,
    id,
    value = "",
    type = "text",
    placeholder = "Введите значение",
    onChange,
    required = false,
    disabled = false,
    error = null,
  }) => {
    const handleOnClear = () => {
      onChange({ target: { id, type, value: "" } });
    };

    const handleOnChange = (e) => {
      onChange(e);
    };

    return (
      <>
        <div className="form-input code-input">
          <label htmlFor={id} className="form-input__label">
            <span>{label}</span>
            {required && (
              <>
                &nbsp;
                <span className="required">*</span>
              </>
            )}
          </label>
          <input
            value={value}
            id={id}
            type={type}
            placeholder={placeholder}
            className={`form-input__field${
              error ? " form-input__field--error" : ""
            }`}
            onChange={(e) => handleOnChange(e)}
            disabled={disabled}
          />
          {disabled || (
            <div className="form-input__icons">
              {value && value.length > 0 && (
                <button
                  type="button"
                  className="button button__secondary button__secondary__ghost form-input__icon"
                  tabIndex={0}
                  onClick={() => handleOnClear()}
                >
                  <div className="clear-field-icon"></div>
                </button>
              )}
              {error && (
                <>
                  <div
                    className="form-input__icon"
                    data-tooltip-id="code-error"
                    data-tooltip-place="bottom"
                    data-tooltip-content={error}
                  >
                    <div className="alert-icon"></div>
                  </div>
                  <div className="code-input__tooltip">
                    <Tooltip id="code-error" style={{zIndex: 1, maxHeight: '385px', maxWidth: '300px'}} clickable />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
);

export default CodeInputField;
