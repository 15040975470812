import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import YearPicker from "../../pureComponents/yearPicker";


const FilterResultForm = ({
                            props,
                            fields,
                            categories,
                            marks,
                            models,
                            fuelType,
                            engineCap,
                            modelClass,
                            changeFilterDropdown,
                            handleFilterDateChange,
                            subcategories
     }) => {

    return (
        <div className={`selector-list selector-list_search-by-filter${props.fetching ? ' waiting' : ''}`}>
            <div className="selector-list__contant">
                <div className={'selector-list__under-header'}>Отфильтровать список результатов</div>

                <div className="selector-list__table">
                    <div className="selector-list__fields">
                        <div className="selector-list__row">
                            <DropdownSelect
                                id='categoryCode'
                                label='Категория'
                                defaultValue={getNameFromList(categories, fields.categoryCode)}
                                items={categories}
                                value={fields.categoryCode}
                                onSelect={changeFilterDropdown}
                                isClearEnabled={true}
                            />
                            <DropdownSelect
                                id='subcategoryCode'
                                label='Подкатегория'
                                defaultValue={getNameFromList(subcategories, fields.subcategoryCode)}
                                items={subcategories}
                                value={fields.subcategoryCode}
                                onSelect={changeFilterDropdown}
                                isClearEnabled={true}
                            />
                            <DropdownSelect
                                id='modelMarkCode'
                                label='Марка'
                                defaultValue={getNameFromList(marks, fields.modelMarkCode)}
                                items={marks}
                                value={fields.modelMarkCode}
                                onSelect={changeFilterDropdown}
                                isClearEnabled={true}
                            />
                            <DropdownSelect
                                id='modelCode'
                                label='Модель'
                                defaultValue={getNameFromList(models, fields.modelCode)}
                                items={models}
                                value={fields.modelCode}
                                onSelect={changeFilterDropdown}
                                isClearEnabled={true}
                            />
                        </div>
                        <div className="selector-list__row">
                            <YearPicker
                                id={'year'}
                                label="Год"
                                value={fields.year}
                                onChangeData={handleFilterDateChange}
                                minDate = {props.minYearStart}
                                maxDate = {props.maxYearEnd}
                            />
                            <DropdownSelect
                                id='fuelTypeCode'
                                label='Тип топлива'
                                defaultValue={getNameFromList(fuelType, fields.fuelTypeCode)}
                                items={fuelType}
                                value={fields.fuelTypeCode}
                                onSelect={changeFilterDropdown}
                                isClearEnabled={true}
                            />
                            <DropdownSelect
                                id='engineCapCode'
                                label='Объем двигателя'
                                defaultValue={getNameFromList(engineCap, fields.engineCapCode)}
                                items={engineCap}
                                value={fields.engineCapCode}
                                onSelect={changeFilterDropdown}
                                isClearEnabled={true}
                            />
                            <DropdownSelect
                                id='modelClassCode'
                                label='Класс евро'
                                items={modelClass}
                                defaultValue={getNameFromList(modelClass, fields.modelClassCode)}
                                value={fields.modelClassCode}
                                onSelect={changeFilterDropdown}
                                isClearEnabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilterResultForm;