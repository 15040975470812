export const SET_MODEL_COMPLECT = 'SET_MODEL_COMPLECT'
export function setModelComplect(payload) {
    return {
        type: SET_MODEL_COMPLECT,
        payload
    }
}

export const UPDATE_MODEL_COMPLECT_FIELD = 'UPDATE_MODEL_COMPLECT_FIELD'
export function updateModelComplectField(payload) {
    return {
        type: UPDATE_MODEL_COMPLECT_FIELD,
        payload
    }
}

export const RESET_MODEL_COMPLECT_STATE = 'RESET_MODEL_COMPLECT_STATE'
export function resetModelComplect() {
    return {
        type: RESET_MODEL_COMPLECT_STATE,
    }
}

export const UPDATE_CHANGED_FIELDS = 'UPDATE_CHANGED_FIELDS'
export function updateChangedFields(payload) {
    return {
        type: UPDATE_CHANGED_FIELDS,
        payload
    }
}

export const SET_MODEL_COMPLECT_FETCHING_STATUS = 'SET_MODEL_COMPLECT_FETCHING_STATUS'
export function setModelComplectFetchingStatus(payload) {
    return {
        type: SET_MODEL_COMPLECT_FETCHING_STATUS,
        payload
    }
}

export const SET_DOCS_FETCHING = 'SET_DOCS_FETCHING'
export function setDocsFetching(payload) {
    return {
        type: SET_DOCS_FETCHING,
        payload
    }
}