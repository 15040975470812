import * as yup from "yup";
const messages = {
  required: "Поле не заполнено",
};

const guidsFormComponentTypeSchema = yup.object().shape({
  code: yup.string()
    .required(messages.required)
    .matches(
      /^[A-Z0-9-_]+$/,
      "Допускаются заглавные латинские символы, цифры, знаки _ -"
    ),
  name: yup.string().required(messages.required),
  componentGroupCode: yup.string().required(messages.required),
  priority: yup.number()
  .transform((value) => Number.isNaN(value) ? null : value )
  .nullable(),
  hidden: yup.boolean(),
});

export default guidsFormComponentTypeSchema;