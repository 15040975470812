export const RECIVE_SUBCATEGORIES = "RECIVE_SUBCATEGORIES";
export function reciveSubcategories(data){
    return{
        type: RECIVE_SUBCATEGORIES,
        data
    }
}

export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export function updateSubcategory(id){
    return{
        type: UPDATE_SUBCATEGORY,
        id
    }
}

export const SET_DEFAULT_SUBCATEGORY = 'SET_DEFAULT_SUBCATEGORY';
export function setDefaultSubcategory(){
    return {
        type: SET_DEFAULT_SUBCATEGORY
    }
}

export const CLEAR_SUBCATEGORY = "CLEAR_SUBCATEGORY";
export function clearSubcategory(){
    return{
        type: CLEAR_SUBCATEGORY
    }
}

export const RECIVE_SUBCATEGORY_LIST = "RECIVE_SUBCATEGORY_LIST";
export function receiveSubcategoryList(payload){
    return{
        type: RECIVE_SUBCATEGORY_LIST,
        payload
    }
}

export const CLEAR_SUBCATEGORY_LIST = "CLEAR_SUBCATEGORY_LIST";
export function clearSubcategoryList(){
    return{
        type: CLEAR_SUBCATEGORY_LIST
    }
}