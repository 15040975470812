import React, {useCallback, useEffect, useState} from 'react';
import './index.scss';
import iconClose from '../../theme/svg/close_icon.svg';
import {useDispatch, useSelector} from 'react-redux'
import {TextAreaDescription} from "../../components/textAreaDescription";
import {requestModelTC} from "../../redux/actions/async/modelTC";
import {API} from "./api";
import {useMultiLanguageList} from "../../hooks/useMultiLanguageList";
import MultiLanguageForm from "../../components/multiLanguageForm";
import {fetchFromApi} from "../../redux/actions/service";
import {initNotificationMessage, showNotificationMessage} from "../../redux/actions/sync/notificationMessage";
import createNameObject from "../../utils/createNameObject";
import updateDefaultLangName from "../../utils/updateDefaultLangName";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import FormInputField from "../../pureComponents/formInputField";
import YearPicker from "../../pureComponents/yearPicker";
import guidsFormModelTSSchema from "../../validations/guidsFormModelTS";
import CodeInputField from "../../pureComponents/codeInputField";
import ConfirmModal from "../../pureComponents/modal/confirmModal";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import {requestGuideTableBody} from "../../redux/actions/async/guides";
import {waitForGuideData} from "../../redux/actions/sync/guides";

const initialState = {
	id: null,
	code: null,
	name: null,
	category: null,
	modelSubcategoryCode: null,
	modelMarkCode: null,
	model: null,
	startYear: null,
	endYear: null,
	description: null,
	hidden: false
}
// formField, DB_Field
const avaliableFields = {
	code: 'code',
	name: 'name',
	modelSubcategoryCode: 'modelSubcategoryCode',
	modelMarkCode: 'modelMarkCode',
	startYear: 'startYear',
	endYear: 'endYear',
	description: 'description',
	model: 'asModel',
	hidden: 'hidden'
}

export function ModelGuideForm(props) {

	const state = useSelector(state => state );
	const store = useSelector(state => state.modelTC.list[0] );
	const dispatch = useDispatch();
	const [active_modal, setActive_modal] = useState(null);
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState([]);
	const [changes, setChanges] = useState(false);
	const [fields, setFields] = useState(initialState);
	const [categories, setCategories] = useState([]);
	const [sub, setSub] = useState([]);
	const [mark, setMark] = useState([]);
	const [isInitState, setIsInitState] = useState(true);
	const [markSearchLoading,setMarkSearchLoading] = useState(false);
	const [changedFields, setChangedFields] = useState([]);

	const {
		multiLanguageFormData,
		multiLanguageFormErrors,
		updateLanguage,
		updateLanguageName,
		addNewLanguageName,
		deleteLanguageName,
		checkMultiLanguageFormIsValid,
		validateMultiLanguageForm,
		multiLanguageFormDataChanged
	} = useMultiLanguageList(names);

	useEffect(() => {
			store.type === 'edit' && startEditCopy();
			store.type === 'copy' && startEditCopy();
			store.type === 'new' && startNew();
		},[]);

	useEffect(() => {
		if(isInitState) return;
		setFields({ ...fields, modelSubcategoryCode: null});
		if( !fields.category) {
			return;
		}
		API.getSubCategory(
			{ lang: state.i18.current, modelCategoryCode: fields.category, hidden: true, offset: 0, limit: 100 })
			.then((response) => {
				setSub(response);
			});
	},[fields.category]);

	useEffect(() => {
		if(multiLanguageFormDataChanged && !changes) {
			setChanges(true);
			updateChangedFields('name');
		}
	},[multiLanguageFormDataChanged, changes]);

	function changeDropdown(item, id) {
		setFields(prev => ({...prev, [id]:item.code }));
		setErrors(prev => ({...prev, [id]:null }));
		if(isInitState) setIsInitState(false);
		setChanges(true);
		updateChangedFields(id);
	}

	function updateChangedFields(id) {
		if (avaliableFields[id] && !changedFields.includes(avaliableFields[id])) {
			setChangedFields((prev) => [...prev, avaliableFields[id]]);
		}
	}

	const handleDropdownSearch = useCallback((id, searchString) => {
		if( id === 'modelMarkCode' ) {
			setMarkSearchLoading(true);
			API.getMark({
				lang: state.i18.current,
				modelMarkCode: fields.modelMarkCode,
				hidden: true,
				offset: 0, 
				limit: 20,
				searchString, 		
			})
			.then((response) =>
			{
				setMark(response.data);
				setMarkSearchLoading(false);
			});
		}
	},[fields.modelMarkCode]);

	const handleDateChange = useCallback((value, id) => {
		setFields(prev => ({...prev, [id]:value }));
		updateChangedFields(id);
		setChanges(true);
		},[]
	);

	function startEditCopy() {
		Promise.all([
				API.getModel(props.data.currentRow),
				API.getCategory({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
			 	API.getCode(),
			]
		).then((response) => {
			setNames(response[0].name.value.filter(item => item.lang !== 'DEF'));
			setCategories(response[1].data);

			setFields({ ...fields,
				id: store.type === 'copy' ? null : response[0]?.id,
				code: store.type === 'copy' ? response[2] : response[0]?.code ,
				name: response[0]?.name.value.filter(item => item.lang === 'DEF')[0]?.name,
				category: response[0]?.modelCategoryCode,
				modelSubcategoryCode: response[0]?.modelSubcategoryCode,
				modelMarkCode: response[0]?.modelMarkCode,
				model: response[0]?.asModel,
				startYear: response[0]?.startYear,
				endYear: response[0]?.endYear,
				description: response[0]?.description,
				hidden: response[0]?.hidden || false
			});
			if (store.type === 'copy') {
				setChangedFields(Object.values(avaliableFields));
				setChanges(true);
			}
			if(response[0]?.modelSubcategoryCode) {
				API.getSubCategory(
					{ lang: state.i18.current, modelCategoryCode: response[0]?.modelCategoryCode, hidden: true, offset: 0, limit: 100 })
					.then((rs) => {
						setSub(rs);
					});
			}
			API.getMark({
				lang: state.i18.current,
				modelMarkCode: response[0]?.modelMarkCode ? response[0].modelMarkCode : null,
				limit: 100,
				hidden: true })
			.then((rs) => {
				setMark(rs.data);
			});
		})
	}

	function startNew() {
		Promise.all([
				API.getCode(),
				API.getCategory({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
				API.getMark({ lang: state.i18.current, hidden: true, offset: 0, limit: 20 })
			]
		).then(response => {
			setFields({ ...fields, code: response[0] })
			setCategories(response[1].data);
			setMark(response[2].data);
			setChangedFields(['code','hidden']);
		});
	}

	function changeInput(event) {
		setChanges(true);
		updateChangedFields(event.target.id);
		if(event.target.id === 'hidden') {
			setFields({ ...fields, [event.target.id]: !fields.hidden });
		} else {
			setFields({ ...fields, [event.target.id]: event.target.value });
			setErrors(prev => ({...prev, [event.target.id]:null }));
		}
	}

	function createCode() {
		let code = API.getCode();
		code.then((response) => {
			setFields({ ...fields,
				code: response
			})
		});
	}
	async function save() {
		const isFormValid = await guidsFormModelTSSchema.isValid( fields,{abortEarly: false} );
		const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();

    	if( isFormValid && isMultiLanguageFormValid ) {
			updateDefaultLangName(multiLanguageFormData, fields.name)
			saveChanges({
				id: fields.id,
				code: fields.code,
				name: createNameObject(fields.name, multiLanguageFormData),
				modelSubcategoryCode: fields.modelSubcategoryCode,
				modelMarkCode: fields.modelMarkCode,
				startYear: fields.startYear,
				endYear: fields.endYear,
				hidden: fields.hidden,
				description: fields.description === "" ? null : fields.description,
				asModel: fields.model,
				changedFields: changedFields
			}).then();
		} else {
			if (!isFormValid) {
				guidsFormModelTSSchema
					.validate(fields, { abortEarly: false })
					.catch((err) => {
						const errors = err.inner.reduce((acc, error) => {
							return {
								...acc,
								[error.path]: error.message,
							};
						}, {});
						setErrors(() => {
							return {
								...errors,
							};
						});
					});
			}
			if (!isMultiLanguageFormValid) {
				validateMultiLanguageForm();
			}
		}
	}
	async function saveChanges(body) {
		await fetchFromApi("api/v1/model","PUT",{body})
			.then((res) => {
				if (res.status === 200) {
					dispatch(waitForGuideData(true));
					dispatch( requestModelTC(false, null, res.id));
					dispatch(requestGuideTableBody());
				} else {
					dispatch(
						initNotificationMessage(
							'Ошибка',
							'Произошла системная ошибка. Обратитесь к администратору системы',
							'danger'
						)
					)
					dispatch(showNotificationMessage());
				}
			})
			.catch((e) => {
				console.log(e);
				dispatch(
					initNotificationMessage(
						'Ошибка',
						'Произошла системная ошибка. Обратитесь к администратору системы',
						'danger'
					)
				);
				dispatch(showNotificationMessage());
			});
	}

	function close() {
		if(!changes) {
			setFields(initialState);
			dispatch( requestModelTC(false, null, null));
		} else {
			setActive_modal('modalTC__wrapper-active');
		}
	}

	function closeApprowal(value) {
		setActive_modal(null);
	}

	function onContinueWithoutSave(value) {
		setFields(initialState);
		setActive_modal(null);
		dispatch(requestModelTC(false, null, null));
	}

	return (
		<div className={"model-guide__wrapper model-guide__wrapper_active"}>
			<div className="model-guide-block">
				{/* Header */}
				<div className="model-guide-block__header">
					<div className="model-guide-block__header__name">{props.data.currentGuide}</div>
					<div className="model-guide-block__control model-guide-block__control_elastic">
						<CodeInputField
							id={'code'}
							label={'Код'}
							disabled={store.type === 'edit'}
							error={errors.code}
							value={fields.code}
							onChange={changeInput}
							required={true}
						/>
					</div>
					<button
						className={'button button__small button__primary cursor__pointer'}
						disabled={store?.type === 'edit'}
						onClick={() => createCode()}
					>
						Создать код
					</button>
					<div className="model-guide-block__close" onClick={() => close()}>
						<img src={iconClose} alt="Закрыть" />
					</div>
				</div>
				{/* Body */}
				<div className="model-guide-block__content">
					<div className="model-guide-block__control-column">
						{/* Название */}
						<FormInputField
							id='name'
							label='Название'
							value={fields.name}
							error={errors.name}
							onChange={changeInput}
							required={true}
						/>
						{/* Категория */}
						<DropdownSelect
							id={'category'}
							label={'Категория'}
							defaultValue={getNameFromList(categories, fields.category)}
							error={errors.category}
							items={categories}
							onSelect={changeDropdown}
							required={true}
						/>
						{/* Подкатегория */}
						<DropdownSelect
							id={'modelSubcategoryCode'}
							label={'Подкатегория'}
							defaultValue={getNameFromList(sub, fields.modelSubcategoryCode)}
							error={errors.modelSubcategoryCode}
							items={sub}
							onSelect={changeDropdown}
							required={true}
						/>
						{/* Марка */}
						<DropdownSelect
							id={'modelMarkCode'}
							label={'Марка'}
							items={mark}
							defaultValue={getNameFromList(mark, fields.modelMarkCode)}
							error={errors.modelMarkCode}
							searchable={true}
							onSelect={changeDropdown}
							onSearch={handleDropdownSearch}
							isLoading={markSearchLoading}
							required={true}
							isFlipping={false} 
						/>
						<FormInputField
							id={'model'}
							label='Модель автостата'
							value={fields.model}
							onChange={changeInput}
						/>
						{/* Год */}
						<div className="model-guide-block__content__item">
							<YearPicker
								id={'startYear'}
								label="Год (начало выпуска)"
								value={fields.startYear}
								onChangeData={handleDateChange}
								maxDate={fields.endYear}
							/>
							<YearPicker
								id={'endYear'}
								label="Год (окончание выпуска)"
								value={fields.endYear}
								onChangeData={handleDateChange}
								minDate={fields.startYear}
							/>
						</div>

						{/*  Описание */}
						<div className="model-guide-block__content__item">
							<div className="model-guide-block__control-column">
								<TextAreaDescription
									id={'description'}
									value={fields.description}
									onChange={changeInput}/>
							</div>
						</div>

						<div className="model-guide-block__content__divider" />

						<MultiLanguageForm
							multiLanguageFormData={multiLanguageFormData}
							multiLanguageFormErrors={multiLanguageFormErrors}
							updateLanguage={updateLanguage}
							updateLanguageName={updateLanguageName}
							addNewLanguageName={addNewLanguageName}
							deleteLanguageName={deleteLanguageName}
						/>
					</div>
				</div>
				<div className="model-guide-block__bottom">
					<ToggleSwitch
						id={'hidden'}
						label={'Скрыть'}
						onToggle={changeInput}
						checked={fields.hidden}
					/>
					<div className="buttons__group">
						<div className="button button__secondary button__secondary__ghost" onClick={() => onContinueWithoutSave()} >
							Не сохранять
						</div>
						<button
							disabled={!changes}
							className={'button button__primary '}
							onClick={() => save()} >
							Сохранить изменения
						</button>
					</div>
				</div>
			</div>
			{/* Modal */}
			<ConfirmModal
				active={active_modal}
				onSave={save}
				onContinueWithoutSave={onContinueWithoutSave}
				closeModal={closeApprowal}
			/>
		</div>
	);
}
