import { receiveComponentFuelType } from "../sync/componentFuelType";
export function requestComponentFuelType(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveComponentFuelType([data]))
	};
}
