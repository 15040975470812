import { updateConditionVisco } from "../../reducers/conditionViscos";
import { setViscoSelectors } from "../../reducers/viscoSelectors";
import { fetchFromApi } from "../service";

export function getConditionViscoIntervalSelector() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/condition-visco-interval/filter/page";
    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });
      const data = await response.json();

      dispatch(
        setViscoSelectors({
          conditionViscoIntervalList: data.data,
        })
      );
    } catch (error) {}
  };
}

export function getViscoList(
  viscoCode = null,
  id = null,
  searchString = null,
  callback = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/visco/filter/page";

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
      searchString,
      colListCode: "name",
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });
      const viscoList = await response.json();

      if (id) {
        dispatch(
          updateConditionVisco({
            id: id.toString(),
            field: "viscoList",
            value: viscoList.data,
          })
        );
      } else {
        dispatch(
          setViscoSelectors({
            viscoList: viscoList.data,
          })
        );
      }

      if (callback) {
        callback();
      }
    } catch (error) {}
  };
}
