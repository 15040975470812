import { RECEIVE_MODEL_TC } from "../actions/sync/modelTC";

const initialState = {
	list: [],
};

function modelTC(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_MODEL_TC:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default modelTC;
