import { connect } from 'react-redux';
import { ReportRow } from '../../components/reportRow';
import { switchFullDescription } from '../actions/sync/description';

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.specVisco.by_id[ownProps.data],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchFullDescription: (id) => dispatch(switchFullDescription(id)),
  };
};

const VisibleReportRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportRow);

export default VisibleReportRow;
