const initialState = {
  intervalTypeList: [],
  intervalActionList: [],
};

function intervalSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_INTERVAL_SELECTORS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const SET_INTERVAL_SELECTORS = "SET_INTERVAL_SELECTORS";
export const setIntervalSelectors = (payload) => ({
  type: SET_INTERVAL_SELECTORS,
  payload,
});

export default intervalSelectors;
