export function normalizeConditionIntervalsForServer(intervals, condition) {
  return {
    data: intervals
      .filter((interval) => interval.actionType)
      .map((interval) => ({
        condition,
        interval: interval.intervalCode,
        intervalAction: interval.intervalActionCode,
        id: interval.actionType === "C" ? null : interval.id,
        actionType: interval.actionType,
      })),
  };
}
