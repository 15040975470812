import generateModelComplectLink from "../../utils/generateModelComplectLink";

const initialState = {
  modelComplectName: "",
  modelComplectCode: "",
  modelMarkCode: "",
  modelSubcategoryCode: "",
  active: false,
  root: true,
  rootName: "Данные о технике",
  children: [],
};

// TODO: refactor this with I18N support
const conditionOptions = {
  interval: "Интервалы",
  spec: "Спецификации",
  visco: "Вязкости",
};

function modelComplectMenu(state = initialState, action) {
  switch (action.type) {
    case SET_MODEL_COMPLECT_MENU:
      return { ...state, ...action.payload };
    case SET_ACTIVE_MENU_ITEM:
      return {
        ...state,
        active: state.link === action.payload,
        children: state.children.map((item) => {
          return {
            ...item,
            active: item.link === action.payload,
            children: item.children.map((item) => {
              return {
                ...item,
                active: item.link === action.payload,
                children: item.children.map((item) => {
                  return {
                    ...item,
                    active: item.link === action.payload,
                  };
                }),
              };
            }),
          };
        }),
      };
    case ADD_NEW_COMPONENT:
      if (state.children.some((item) => item.code === action.payload)) {
        return state;
      }
      return {
        ...state,
        children: [
          ...state.children,
          {
            active: true,
            link: generateModelComplectLink(state.modelComplectCode, {
              type: "component",
              code: action.payload,
            }),
            code: action.payload,
            name: "Новый Узел",
            actionType: action.payload,
            children: [],
          },
        ],
      };
    case DELETE_NEW_COMPONENT:
      return {
        ...state,
        children: state.children.filter((item) => {
          return item.code !== "new" && item.code !== "copy";
        }),
      };
    case ADD_NEW_CONDITION:
      const { actionType, componentCode } = action.payload;
      const component = state.children.find(
        (item) => item.code === componentCode
      );

      if (component.children.some((item) => item.code === actionType)) {
        return state;
      }

      return {
        ...state,
        children: state.children.map((item) => {
          if (item.code !== componentCode) {
            return { ...item };
          }

          return {
            ...item,
            children: [
              ...item.children,
              {
                active: true,
                link: generateModelComplectLink(state.modelComplectCode, {
                  type: "condition",
                  code: actionType,
                }),
                code: actionType,
                name: "Новое Условие",
                actionType: actionType,
                children: [],
              },
            ],
          };
        }),
      };
    case DELETE_NEW_CONDITION:
      return {
        ...state,
        children: state.children.map((item) => {
          if (item.code !== action.payload) return item;
          return {
            ...item,
            children: item.children.filter((item) => {
              return item.code !== "new" && item.code !== "copy";
            }),
          };
        }),
      };
    case ADD_CONDITION_OPTION:
      const { conditionCode, componentCode: compCode, option } = action.payload;
      const componentItem = state.children.find(
        (item) => item.code === compCode
      );

      if (!componentItem) return state;

      const condition = componentItem.children.find(
        (item) => item.code === conditionCode
      );

      if (!condition || condition.children.some((item) => item.code === option))
        return state;

      return {
        ...state,
        children: state.children.map((item) => {
          if (item.code !== compCode) {
            return { ...item };
          }

          return {
            ...item,
            children: item.children.map((item) => {
              if (item.code !== conditionCode) {
                return { ...item };
              }

              return {
                ...item,
                children: [
                  ...item.children,
                  {
                    active: true,
                    link: generateModelComplectLink(
                      state.modelComplectCode,
                      { type: "condition", code: item.code },
                      option
                    ),
                    code: option,
                    name: conditionOptions[option],
                  },
                ],
              };
            }),
          };
        }),
      };

    case RESET_MODEL_COMPLECT_MENU:
      return initialState;
    default:
      return state;
  }
}

export default modelComplectMenu;

const SET_MODEL_COMPLECT_MENU = "SET_MODEL_COMPLECT_MENU";
const RESET_MODEL_COMPLECT_MENU = "RESET_MODEL_COMPLECT_MENU";
const SET_ACTIVE_MENU_ITEM = "SET_ACTIVE_MENU_ITEM";
const ADD_NEW_CONDITION = "ADD_NEW_CONDITION";
const DELETE_NEW_CONDITION = "DELETE_NEW_CONDITION";
const DELETE_NEW_COMPONENT = "DELETE_NEW_COMPONENT";
const ADD_CONDITION_OPTION = "ADD_CONDITION_OPTION";
const ADD_NEW_COMPONENT = "ADD_NEW_COMPONENT";

export const setModelComplectMenu = (payload) => ({
  type: SET_MODEL_COMPLECT_MENU,
  payload,
});

export const resetModelComplectMenu = () => ({
  type: RESET_MODEL_COMPLECT_MENU,
});

export const setActiveMenuItem = (payload) => ({
  type: SET_ACTIVE_MENU_ITEM,
  payload,
});

export const addNewComponent = (payload) => ({
  type: ADD_NEW_COMPONENT,
  payload,
});

export const deleteNewComponent = () => ({
  type: DELETE_NEW_COMPONENT,
});

export const addNewCondition = (payload) => ({
  type: ADD_NEW_CONDITION,
  payload,
});

export const deleteNewCondition = (payload) => ({
  type: DELETE_NEW_CONDITION,
  payload,
});

export const addConditionOption = (payload) => ({
  type: ADD_CONDITION_OPTION,
  payload,
});
