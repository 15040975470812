export const RECIVE_MODEL_GENERATION = "RECIVE_MODEL_GENERATION";
export function reciveModelGeneration(json){
    return {
        type: RECIVE_MODEL_GENERATION,
        json
    }
}

export const UPDATE_MODEL_GENERATION = "UPDATE_MODEL_GENERATION";
export function updateModelGeneration(id){
    return {
        type: UPDATE_MODEL_GENERATION,
        id
    }
}

export const CLEAR_MODEL_GENERATION = "CLEAR_MODEL_GENERATION";
export function clearModelGeneration(){
    return {
        type: CLEAR_MODEL_GENERATION
    }
}