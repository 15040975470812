import { SET_COMPONENT_GROUP_LIST, RECEIVE_COMPONENT_GROUP} from "../actions/sync/componentGroup";

const initialState = {
  current: "",
  list: [],
};

function componentGroup(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT_GROUP_LIST:
      return { ...state, list: action.payload };
    case RECEIVE_COMPONENT_GROUP:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}

export default componentGroup;
