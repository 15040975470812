import { useState } from "react";
import Calendar from "react-calendar";
import { getValidDate } from "../../utils/getDate";
import DatePickerContainer from "../datePickerContainer";

const DatePicker = ({
  label,
  id,
  onChangeData,
  placeholder = "Введите",
  minDate = null,
  maxDate = null,
  required = false,
  error = null,
  value = null,
}) => {
  const [isOpen, setOpen] = useState(false);

  function handleOnChangeDate(value, event) {
    onChangeData(value, id);
    setOpen(false);
  }
  return (
    <DatePickerContainer
      label={label}
      id={id}
      placeholder={placeholder}
      isOpen={isOpen}
      setOpen={setOpen}
      required={required}
      error={error}
      value={value}
      onChangeData={onChangeData}
    >
      <Calendar
        value={value ? getValidDate(value) : ""}
        onChange={handleOnChangeDate}
        className="calendar"
        locale="ru-RU" // TODO locale refactoring
        minDate={minDate ? getValidDate(minDate) : undefined}
        maxDate={maxDate ? getValidDate(maxDate) : undefined}
      />
    </DatePickerContainer>
  );
};

export default DatePicker;
