import React from "react";
import ModalLinkWrapper from "../modalLinkWrapper";
import "./index.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setSearchStage,
} from "../../redux/actions/sync/search";

export function Header(props) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const langList = props.langList.map((el, i) => (
    <option className="header__option" key={i} value={el}>
      {el}
    </option>
  ));
  
  function changeLanguage(e) {
    const lang = e.target.value;
    props.onLangChange(lang);
  }

  if (props.authorizationStage !== "login") {
    return (
      <div className="header">
        <div className="header__group header__group_right">
          <div className="header__user-section">
            <div className="header__user-logo header__user-logo_logaut"></div>
          </div>
          <select
            className="header__select"
            onChange={changeLanguage}
            defaultValue={props.lang}
          >
            {langList}
          </select>
        </div>
      </div>
    );
  }

  const wrapper = "header__link-wrapper";
  const wrapperActive = "header__link-wrapper header__link-wrapper_active";


  //при открытии редактирования со страницы поиска, если вместо назад к ТС начать ходить по меню, то чтобы при повторном возрате уже не возвращалось к ТС, а к списку ТС
 const handleMenuItemClick = () => {
  dispatch(setSearchStage("home"));
 }


  const menuItems = props.pages.map((el, i) => (
    <div
      className={el.url === pathname ? wrapperActive : wrapper}
      key={i}
    >
      <div  
        className="header__link"
      >
        <ModalLinkWrapper
          linkProps={{
            to: el.url,
            state: el.pageCode ? { pageCode: el.pageCode } : null,
            onClick: handleMenuItemClick,
          }}
          className="header__a"
        >
          {props.pagesCaption[el.name][props.lang]}
        </ModalLinkWrapper>
      </div>
    </div>
  ));

  return (
    <div className="header">
      <div className="header__group">{menuItems}</div>
      <div className="header__group header__group_right">
        <div className="header__user-section">
          <div
            className="header__user-wrapper"
            onClick={() => props.switchUserInfo()}
          >
            <div className="header__user-info">
              <div className="header__user-name">{props.userFIO}</div>
              <div className="header__user-position">
                <div className="position-name">
                  {props.userPosition[props.lang]}
                </div>
                <div className="position-button">
                  <div className="position-button__point"></div>
                  <div className="position-button__point"></div>
                  <div className="position-button__point"></div>
                </div>
              </div>
            </div>
            <div className="header__user-logo header__user-logo_login"></div>
          </div>
        </div>
        <select
          className="header__select"
          onChange={changeLanguage}
          defaultValue={props.lang}
        >
          {langList}
        </select>
      </div>
      <div
        className={
          "header__user-menu" +
          (props.showUserInfo ? "" : " header__user-menu_unvisable")
        }
      >
        <div className="user-menu__info">
          <div className="user-menu__user-name">{props.userFIO}</div>
          <div className="user-menu__user-position">
            {props.userPosition[props.lang]}
          </div>
        </div>
        <div className="user-menu__submenu">
          <div className="user-menu__contacts">{props.username}</div>
          <div className="user-menu__logaut" onClick={props.logout}>
            <div className="user-menu__logaut-img"></div>
            <div className="user-menu__logaut-caption">Выйти</div>
          </div>
        </div>
      </div>
      <div className={"header__cover" + (props.access ? "" : "_active")}></div>
    </div>
  );
}
