import * as yup from "yup";

const messages = {
  required: "Поле не заполнено",
};

const componentSchema = yup.object().shape({
  code: yup.string()
    .required(messages.required)
    .matches(
      /^[A-Z0-9-_]+$/,
      "Допускаются заглавные латинские символы, цифры, знаки _ -"
    ),
  name: yup.string().nullable(),
  modelComplectCode: yup.string().required(messages.required),
  componentGroupCode: yup.string().nullable().required(messages.required),
  componentTypeCode: yup.string().nullable().required(messages.required),
  componentFuelTypeCode: yup.string().nullable(),
  componentGearTypeCode: yup.string().nullable(),
  componentTurboTypeCode: yup.string().nullable(),
  componentEngineBuildCode: yup.string().nullable(),
  componentCoolTypeCode: yup.string().nullable(),
  producerCode: yup.string().nullable(),
  engineCap: yup.string().nullable(),
  fillVolume: yup.string().nullable(),
  serviceVolume: yup.string().nullable(),
  powerHp: yup.string().nullable(),
  powerRpm: yup.string().nullable(),
  valveCnt: yup.string().nullable(),
  gearFwCnt: yup.string().nullable(),
  gearRevCnt: yup.string().nullable(),
  cilinderCap: yup.string().nullable(),
  cilinderCnt: yup.string().nullable(),
  stroke: yup.string().nullable(),
  startYear: yup.string().nullable().transform(value => (!value ? null : value)),
  endYear: yup.string().nullable().transform(value => (!value ? null : value)),
  description: yup.string().nullable().transform(value => (!value ? null : value)),
  hidden: yup.boolean(),
  verified: yup.boolean(),
});

export default componentSchema;