import {
  TOGGLE_EXPAND_All_PRODUCTS
} from '../actions/sync/expandAllProducts';

const defState = false

function expandAllProductsFromStorage(state = defState, action) {
  switch (action.type) {
    case TOGGLE_EXPAND_All_PRODUCTS:
      return { ...state, expandAllProducts: action.expandAllProducts };
      default:
        return state;
  }
}

export default expandAllProductsFromStorage;

