import { fetchFromApi } from "../service";
import { clearEngineCap } from "../sync/engineCap";
import { clearFuelType } from "../sync/fuelType";
import { clearModelList } from "../sync/model";
import { clearModelClass } from "../sync/modelClass";
import { clearModelGeneration } from "../sync/modelGeneration";
import { clearModelMark } from "../sync/modelMark";
import { dropSearchResult } from "../sync/search";
import {
  clearSubcategory,
  receiveSubcategoryList,
  reciveSubcategories,
  updateSubcategory,
} from "../sync/subcategory";
import { clearYear } from "../sync/years";
import {requestModelMark_o} from "./modelMark";
import { requestSearchByFilterResult } from "./search";

export function requestSubcategories() {
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      lang: state.i18.current,
      modelCategoryCode: state.category.current,
    };

    return fetchFromApi("api/v1/model-subcategory/filter","POST",{body})
      .then((res) => res.json())
      .then((res) => {
        dispatch(reciveSubcategories(res));
          const firstActiveSubcategory = res.find(subcategory => !subcategory.hidden)
          if(firstActiveSubcategory){
            dispatch(selectSubcategory(firstActiveSubcategory.code))
          }
      })
      .catch((e) => console.log("error " + e));
  };
}

export function selectSubcategory(id) {
  return (dispatch) => {
    return new Promise((res, rej) => {
      dispatch(updateSubcategory(id));
      res();
    })
      .then(() => {
        dispatch(clearModelMark());
      })
      .then(() => {
        dispatch(requestModelMark_o());
      })
      .then(() => {
        dispatch(clearModelGeneration());
      }).then(() => {
        dispatch(clearModelList());
      })
      .then(() => {
        dispatch(clearYear());
      })
      .then(() => {
        dispatch(clearEngineCap());
      });
  };
}

export function filterBySubcategory(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateSubcategory(id));
      res();
    }).then(() => {
      dispatch(requestSearchByFilterResult());
    });
  };
}

export function dropSubcategory() {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(clearSubcategory());
      res();
    })
      .then((res) => {
        dispatch(requestSubcategories());
      })
      .then(() => {
        dispatch(clearModelMark());
      })
      .then(() => {
        dispatch(clearModelGeneration());
      })
      .then(() => {
        dispatch(clearModelList());
      })
      .then(() => {
        dispatch(clearYear());
      })
      .then(() => {
        dispatch(clearFuelType());
      })
      .then(() => {
        dispatch(clearEngineCap());
      })
      .then(() => {
        dispatch(clearModelClass());
      })
      .then(() => {
        dispatch(dropSearchResult());
      });
  };
}

export function getSubcategories(modelCategoryCode = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const body = {
      lang,
      modelCategoryCode,
    };

    try {
      const response = await fetchFromApi("api/v1/model-subcategory/filter", "POST", { body });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = await response.json();
      dispatch(receiveSubcategoryList(data));
    } catch (error) {
      console.log(error.message);
    }
  };
}
