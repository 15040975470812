import { object, string, array } from "yup";

const messages = {
  required: "Поле не заполнено",
};

const multiLanguageListSchema = array().of(
  object().shape({
    id: string(),
    languageList: array(),
    lang: string(),
    name: string(),
  })
);

export default multiLanguageListSchema;
