import { updateFuelType } from '../sync/fuelType';
import { requestSearchByFilterResult } from './search';

export function selectFuelType(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateFuelType(id));
      res();
    }).then(() => {
      dispatch(requestSearchByFilterResult());
    }).catch(e=>console.log(e));
  };
}
