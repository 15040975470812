import React from 'react';
import './index.css';

export function SvProductListItem(props) {
  function copyData() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(props.data.name);
    }
  }

  return (
    <div className={'product-list__item' + (props.data.hidden ? ' hidden' : '')}>
      <div className="pl-item__img button_active" onClick={copyData}></div>
      <div className={'pl-item__caption pl-item__caption_' + props.data.id}>
        {props.data.name}
      </div>
    </div>
  );
}
