import React from "react";

const DbTable = ({ data, columns, vocabulary }) => {
  return (
    <table className="guides-table">
      <thead>
        <tr>
          {columns.map((el, i) => (
            <th className="guides-table__th" key={i}>
              {el.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          const tr_content = columns.map((column, index) => {
            const key =
              column.fieldType === "ref"
                ? column.code + "NameDb"
                : column.code + "Db";

            if (column.fieldType === "boolean") {
              return (
                <td key={index} className={`guides-table__td`}>
                  {item[key] ? vocabulary[1].name : vocabulary[0].name}
                </td>
              );
            }

            return (
              <td key={index} className={`guides-table__td`}>
                {item[key]}
              </td>
            );
          });

          return <tr key={item.idDb}>{tr_content}</tr>;
        })}
      </tbody>
    </table>
  );
};

export default DbTable;
