import normalizeProductMenu from "../../../normalizers/productMenu";
import { setProductMenu } from "../../reducers/productMenu";
import {fetchFromApi} from "../service";

export function getProductMenu(productCode, actionType) {
  return async (dispatch, getState) => {
    try {
      const response = await fetchFromApi(`api/v1/menu/product/${productCode}`,"GET",{});
      const data = await response.json();

      if (actionType === "copy") {
        dispatch(
          setProductMenu({
            productCode: productCode,
          })
        );
        return;
      }
      const normalizedData = normalizeProductMenu(data, productCode);
      dispatch(setProductMenu(normalizedData));
    } catch (error) {
      console.log(error);
    }
  };
}
