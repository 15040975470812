import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { setCurrentPath } from "../../redux/reducers/currentPath";
import { getSpecMenu } from "../../redux/actions/async/specMenu";
import {
  resetSpecMenu,
  setActiveSpecMenuItem,
} from "../../redux/reducers/specMenu";
import SideTreeMenu from "../../components/sideTreeMenu";
import {useDocumentTitle} from "../../hooks/useDocumentTitle";

const SpecPageLayout = () => {
  const dispatch = useDispatch();
  const specMenu = useSelector((state) => state.specMenu);
  const { specCode } = useParams();
  const { pathname, state } = useLocation();
  const actionType = state?.actionType ? state.actionType : "new";

  const isSpecPage = pathname === `/spec/${specCode}` || pathname === `/spec/`;

  useDocumentTitle('Спецификации')

  useEffect(() => {
    dispatch(setCurrentPath(pathname));
  }, [pathname]);

  useEffect(() => {
    if (specCode && actionType !== "copy") {
      dispatch(getSpecMenu(specCode));
    }

    return () => {
      dispatch(resetSpecMenu());
    };
  }, [specCode]);

  useEffect(() => {
    if (pathname && specMenu.specCode !== "") {
      dispatch(setActiveSpecMenuItem(pathname));
    }
  }, [pathname, specMenu.specCode]);

  return (
    <>
      <SideTreeMenu
        menu={specMenu}
        title={
          actionType === "edit" || !isSpecPage
            ? `Редактирование спецификации`
            : "Создание спецификации"
        }
      />
      <section className="layout-wrapper">
        <Outlet />
      </section>
    </>
  );
};

export default SpecPageLayout;
