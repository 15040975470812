const initialState = {
  productBrandList: [],
  productPremiumList: [],
};

function productFormSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_FORM_SELECTORS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const SET_PRODUCT_FORM_SELECTORS = "SET_PRODUCT_FORM_SELECTORS";

export const setProductFormSelectors = (payload) => ({
  type: SET_PRODUCT_FORM_SELECTORS,
  payload,
});

export default productFormSelectors;
