import {
  deleteProductModelSubcategoriesFromServer,
  getProductModelSubcategories,
  putProductModelSubcategories,
} from "../../../redux/actions/async/productModelSubcategories";
import { getSubcategories } from "../../../redux/actions/async/subcategory";
import { setProductModelSubcategories } from "../../../redux/reducers/productModelSubcategory";

const modelSubcategory = {
  headerTitle: "Добавление Подкатегорий ТС",
  list: "subcategory",
  productList: "productModelSubcategories",
  option: "model-subcategory",
  optionName: "Подкатегория ТС",
  historyTableName: "product_model_subcategory",
  searchable: false,
  getList: () => getSubcategories(),
  getData: (productCode) => getProductModelSubcategories(productCode),
  setData: (productModelSubcategories) =>
    setProductModelSubcategories(productModelSubcategories),
  deleteData: (productCode, callback) =>
    deleteProductModelSubcategoriesFromServer(productCode, callback),
  putData: () => putProductModelSubcategories(),
};

export default modelSubcategory;
