import './index.css';

export function DoubleDescription(props) {
  const fullDescription = props.data.visible;
  if (!fullDescription) {
    return null;
  }

  const classMod =
    props.data.visible === undefined
      ? ''
      : ' double-description_' + props.data.visible;

  function selectAll(e) {
    if (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      let range = new Range();

      range.setStart(
        document.querySelector(
          '.double-description-modal__span_' +
            props.id +
            '_' +
            props.data.active
        ),
        0
      );
      range.setEnd(
        document.querySelector(
          '.double-description-modal__span_' +
            props.id +
            '_' +
            props.data.active
        ),
        1
      );
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
    }
  }

  function setActiveDescription(id) {
    props.setActiveDescription(props.data.id, id);
  }

  function rollUp() {
    document
      .querySelector('.double-description-modal__text-content')
      .scrollTo(0, 0);
  }

  return (
    <div className={'double-description' + classMod}>
      <div
        className={
          'double-description-modal__wrapper' +
          (fullDescription ? ' double-description-modal__wrapper_visable' : '')
        }
        onKeyDown={selectAll}
        tabIndex={0}
      >
        <div className="double-description-modal">
          <div className="double-description-modal__header">
            <div className="description-modal__header-caption">Описание</div>
            <div
              className="description-modal__header-button"
              onClick={() => {
                document.getSelection().removeAllRanges();
                props.switchFullDescription(props.id);
              }}
            >
              <div className="description-modal__header-button-img"></div>
            </div>
          </div>
          <div
            className="double-description-modal__content"
            onClick={() => setActiveDescription(0)}
          >
            <div className="double-description-modal__content-header">
              Описание 1
            </div>
            <div className="double-description-modal__text-content">
              <span
                className={
                  'double-description-modal__span double-description-modal__span_' +
                  props.id +
                  '_0'
                }
              >
                {props.data.description1}
              </span>
            </div>
            <div
              className="double-description-modal__content-button"
              onClick={rollUp}
            >
              <div className="double-description-modal__content-button-img"></div>
            </div>
          </div>
          <div className="double-description-modal__border"></div>
          <div
            className="double-description-modal__content"
            onClick={() => setActiveDescription(1)}
          >
            <div className="double-description-modal__content-header">
              Описание 2
            </div>
            <div className="double-description-modal__text-content">
              <span
                className={
                  'double-description-modal__span double-description-modal__span_' +
                  props.id +
                  '_1'
                }
              >
                {props.data.description2}
              </span>
            </div>
            <div
              className="double-description-modal__content-button"
              onClick={rollUp}
            >
              <div className="double-description-modal__content-button-img"></div>
            </div>
          </div>
          <div className="double-description-modal__footer">
            <div
              className="double-description-modal__footer-button"
              onClick={() => props.switchFullDescription(props.id)}
            >
              Закрыть
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
