import {
  resetConditionViscos,
  setCVFetchingStatus,
  setConditionCodeForCV,
  setConditionViscos,
} from "../../reducers/conditionViscos";
import { fetchFromApi } from "../service";
import normalizeConditionViscosForServer from "../../../normalizers/conditionViscos";
import { getModelComplectMenu } from "./modelComplectMenu";
import { setActiveMenuItem } from "../../reducers/modelComplectMenu";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";

export function getConditionViscos(conditionCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/condition-visco";

    try {
      dispatch(resetConditionViscos());
      dispatch(setCVFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${conditionCode}/locale/${lang}`
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(setConditionViscos(data));
      dispatch(setConditionCodeForCV(conditionCode));
      dispatch(setCVFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setCVFetchingStatus());
    }
  };
}

export function putConditionViscos() {
  return async (dispatch, getState) => {
    const state = getState();
    const conditionViscos = state.conditionViscos.list;
    const conditionCode = state.conditionViscos.conditionCode;
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setCVFetchingStatus("update"));

      const response = await fetchFromApi("api/v1/condition-visco", "POST", {
        body: normalizeConditionViscosForServer(conditionViscos, conditionCode),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getModelComplectMenu(modelComplectCode));
      dispatch(setActiveMenuItem(pathname));
      dispatch(resetCurrentPageFormState());
      dispatch(getConditionViscos(conditionCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setCVFetchingStatus());

      return false;
    }
  };
}

export function deleteConditionViscosFromServer(
  conditionCode,
  callback = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;
    const conditionViscos = state.conditionViscos.list;

    const body = {
      code: conditionCode,
      data: conditionViscos
        .filter((item) => item.actionType !== "C")
        .map((item) => ({ visco: item.viscoCode })),
    };

    try {
      dispatch(setCVFetchingStatus("loading"));
      const response = await fetchFromApi(
        "api/v1/condition-visco/all",
        "DELETE",
        {body}
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getModelComplectMenu(modelComplectCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setCVFetchingStatus());
    }
  };
}
