import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetNewCode } from "../../hooks/useGetNewCode";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import ConditionForm from "./conditionForm";
import conditionSchema from "../../validations/condition";
import {
  resetCondition,
  updateConditionChangedFields,
  updateConditionField,
} from "../../redux/actions/sync/condition";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import { getMarketList } from "../../redux/actions/async/marketList";
import { getConditionTypeList } from "../../redux/actions/async/conditionType";
import { getConditionAnalogList } from "../../redux/actions/async/conditionAnalog";
import {
  addNewCondition,
  deleteNewCondition,
} from "../../redux/reducers/modelComplectMenu";
import {
  deleteConditionFromServer,
  getCondition,
  putCondition,
} from "../../redux/actions/async/condition";
import generateModelComplectLink from "../../utils/generateModelComplectLink";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";

const ConditionPage = () => {
  const { state } = useLocation();
  const actionType = state ? state.actionType : "new";
  const componentCode = state.componentCode;
  const { modelComplectCode, conditionCode } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { sideMenuIsLoaded, modelMarkCode, modelSubcategoryCode } = useSelector(
    (state) => ({
      sideMenuIsLoaded: state.modelComplectMenu.modelComplectCode !== "",
      modelMarkCode: state.modelComplectMenu.modelMarkCode,
      modelSubcategoryCode: state.modelComplectMenu.modelSubcategoryCode,
    })
  );
  const conditionData = useSelector((state) => state.condition);
  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const getNewCode = useGetNewCode(updateConditionField);

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const [searchLoading, setSearchLoading] = useState({
    conditionTypeCode: false,
  });

  useEffect(() => {
    dispatch(getMarketList());

    dispatch(
      updateConditionField({
        changedFields: ["code", "hidden", "componentCode"],
      })
    );
    return () => {
      dispatch(resetCondition());
    };
  }, []);

  useEffect(() => {
    if (sideMenuIsLoaded) {
      dispatch(
        getConditionTypeList(
          modelMarkCode,
          modelSubcategoryCode,
          conditionData.conditionTypeCode
        )
      );
    }
  }, [sideMenuIsLoaded, conditionData.conditionTypeCode]);

  useEffect(() => {
    if (!conditionData.componentCode) {
      dispatch(
        updateConditionField({
          componentCode,
        })
      );
    }
  }, [conditionData.componentCode]);

  useEffect(() => {
    if (conditionData.conditionTypeCode) {
      dispatch(getConditionAnalogList(conditionData.conditionTypeCode));
    }
  }, [conditionData.conditionTypeCode]);

  useEffect(() => {
    if (actionType === "copy") {
      dispatch(
        updateConditionField({
          id: null,
          changedFields: Object.keys(conditionData).filter(
            (key) =>
              conditionData[key] !== null && !Array.isArray(conditionData[key])
          ),
        })
      );
      dispatch(updateAllChg(true));
      setErrors({});
      return;
    }
  }, [actionType]);

  useEffect(() => {
    if (actionType !== "edit") {
      getNewCode();
    }
  }, [actionType]);

  useEffect(() => {
    if (conditionCode && actionType === "edit") {
      dispatch(getCondition(conditionCode));
      setErrors({});
    }
  }, [conditionCode, actionType]);

  useEffect(() => {
    if (!sideMenuIsLoaded || actionType === "edit") {
      return;
    }

    dispatch(addNewCondition({ actionType, componentCode }));

    return () => {
      dispatch(deleteNewCondition(componentCode));
    };
  }, [actionType, sideMenuIsLoaded]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitCondition));
  }, [conditionData]);

  const handleInputChange = useCallback(
    ({ target: { id, type, value } }) => {
      if (type === "checkbox") {
        dispatch(
          updateConditionField({
            [id]: !conditionData[id],
          })
        );

        dispatch(updateConditionChangedFields(id));

        if (!dataChanged) {
          dispatch(updateAllChg(true));
        }
        return;
      }

      dispatch(
        updateConditionField({
          [id]: value,
        })
      );

      dispatch(updateConditionChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [conditionData, actionType, dataChanged]
  );

  const handleDropdownChange = useCallback(
    ({ code }, id) => {
      dispatch(
        updateConditionField({
          [id]: code,
        })
      );

      dispatch(updateConditionChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [actionType, dataChanged]
  );

  const handleConditionalAnalogDropdownChange = useCallback(
    (item, id) => {
      dispatch(
        updateConditionField({
          marketCode: item.marketCode,
          description: item.description,
        })
      );
    },
    [actionType, dataChanged]
  );

  const handleDropdownSearch = useCallback(
    (id, searchValue) => {
      setSearchLoading({
        [id]: true,
      });

      if (id === "conditionTypeCode") {
        dispatch(
          getConditionTypeList(
            modelMarkCode,
            modelSubcategoryCode,
            conditionData.conditionTypeCode,
            searchValue,
            () =>
              setSearchLoading({
                [id]: false,
              })
          )
        );
      }
    },
    [modelMarkCode, modelSubcategoryCode, conditionData.conditionTypeCode]
  );

  const handleDeleteCondition = useCallback(() => {
    setDeleteModalActive(true);
  }, []);

  const deleteCondition = () => {
    dispatch(
      deleteConditionFromServer(conditionCode, () => {
        navigate(
          generateModelComplectLink(modelComplectCode, {
            type: "component",
            code: conditionData.componentCode,
          }),
          {
            state: {
              actionType: "edit",
            },
          }
        );
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitCondition() {
    const isFormValid = await conditionSchema.isValid(conditionData, {
      abortEarly: false,
    });
    if (isFormValid) {
      return dispatch(putCondition());
    } else {
      if (!isFormValid) {
        conditionSchema
          .validate(conditionData, { abortEarly: false })
          .catch((err) => {
            const errors = err.inner.reduce((acc, error) => {
              return {
                ...acc,
                [error.path]: error.message,
              };
            }, {});
            setErrors(() => {
              return {
                ...errors,
              };
            });
          });
      }
      return false;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const isValid = await submitCondition();
    if (isValid) {
      dispatch(
        updateConditionField({
          changedFields: [],
        })
      );
      navigate(
        generateModelComplectLink(modelComplectCode, {
          type: "condition",
          code: conditionData.code,
        }),
        {
          state: {
            actionType: "edit",
          },
        }
      );
    }
  };

  return (
    <>
      <ConditionForm
        getNewCode={getNewCode}
        formErrors={formErrors}
        searchLoading={searchLoading}
        handleInputChange={handleInputChange}
        handleDropdownChange={handleDropdownChange}
        handleConditionalAnalogDropdownChange={
          handleConditionalAnalogDropdownChange
        }
        handleDropdownSearch={handleDropdownSearch}
        handleDeleteCondition={handleDeleteCondition}
        openHistoryChanges={openHistoryChanges}
        onSubmit={onSubmit}
      />
      <DeleteModal
        active={deleteModalActive}
        title="Удалить запись?"
        subtitle="Будут удалены все зависимые объекты."
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteCondition}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"condition"}
        rowCode={conditionCode}
        listType={"hist"}
      />
    </>
  );
};

export default ConditionPage;
