import { getComponentGroupList } from "../../../redux/actions/async/componentGroup";
import {
  deleteProductComponentGroupFromServer,
  getProductComponentGroup,
  putProductComponentGroup,
} from "../../../redux/actions/async/productComponentGroup";
import { setProductComponentGroup } from "../../../redux/reducers/productComponentGroup";

const componentGroup = {
  headerTitle: "Добавление Групп узлов",
  list: "componentGroup",
  productList: "productComponentGroup",
  option: "component-group",
  optionName: "Группа узла",
  historyTableName: "product_component_group",
  searchable: false,
  getList: () => getComponentGroupList(),
  getData: (productCode) => getProductComponentGroup(productCode),
  setData: (productComponentGroup) =>
    setProductComponentGroup(productComponentGroup),
  deleteData: (productCode, callback) =>
    deleteProductComponentGroupFromServer(productCode, callback),
  putData: () => putProductComponentGroup(),
};

export default componentGroup;
