import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import thunkMiddleware from "redux-thunk";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import rootReducer from "./redux/reducers";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// create an .env file with NODE_ENV=development to use devtools
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App store={store} />);

reportWebVitals();
