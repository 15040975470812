export const SET_EXPORT_DATA = 'SET_EXPORT_DATA';
export function setExportData(data) {
  return {
    type: SET_EXPORT_DATA,
    data,
  };
}

export const DROP_EXPORT_DATA = 'DROP_EXPORT_DATA';
export function dropExportData() {
  return {
    type: DROP_EXPORT_DATA,
  };
}
