import { RESET_APP } from "../actions/sync/init";
import {
  CLEAR_MODEL_MARK,
  CLEAR_MODEL_MARK_LIST,
  RECIVE_MODEL_MARK,
  RECIVE_MODEL_MARK_LIST,
  UPDATE_MODEL_MARK,
} from "../actions/sync/modelMark";
import {
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  CLEAR_SEARCH_RESULT,
} from "../actions/sync/search";

const defState = { current: "def", list: [], modelComplectPageList: [] };

function modelMark(state = defState, action) {
  switch (action.type) {
    case RECIVE_MODEL_MARK:
      if (action.json.length === 1) {
        return Object.assign({}, state, {
          list: action.json,
          current: action.json.code,
        });
      } else {
        return Object.assign({}, state, { list: action.json });
      }
    case RECIVE_SEARCH_BY_STRING_RESULT:
      if (Object.keys(action.json).length === 0) {
        return state;
      }
      return Object.assign({}, state, {
        list: action.json.modelMark,
        current: "def",
      });
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      if (Object.keys(action.json).length === 0) {
        return state;
      }
      if (action.json.modelMark.length === 1) {
        return Object.assign({}, state, {
          list: action.json.modelMark,
          current: action.json.modelMark[0].code,
        });
      }
      return Object.assign({}, state, { list: action.json.modelMark });
    case UPDATE_MODEL_MARK:
      return Object.assign({}, state, { current: action.id });
    case RECIVE_MODEL_MARK_LIST:
      return Object.assign({}, state, {
        modelComplectPageList: action.payload,
      });
    case CLEAR_MODEL_MARK_LIST:
      return Object.assign({}, state, { modelComplectPageList: [] });
    case CLEAR_MODEL_MARK:
      return defState;
    case RESET_APP:
      return defState;
    case CLEAR_SEARCH_RESULT:
      return defState;
    default:
      return state;
  }
}

export default modelMark;
