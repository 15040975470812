import { RESET_APP } from '../actions/sync/init';
import {
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  CLEAR_SEARCH_RESULT,
} from '../actions/sync/search';
import {
  CLEAR_YEAR,
  HENDLE_UPDATE_YEAR,
  UPDATE_YEAR,
  VALIDATE_YEAR,
} from '../actions/sync/years';

const defState = {
  current: 'def',
  list: [],
  value: '',
  errorValue: false,
  verified: false,
};

function years(state = defState, action) {
  switch (action.type) {
    case RECIVE_SEARCH_BY_STRING_RESULT:
      return Object.assign({}, state, {
        list: createYearsArr(action.json.minYearStart, action.json.maxYearEnd),
      });
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      if (Object.keys(action.json).length === 0) {
        return defState;
      }
      if (action.json.minYearStart === action.json.maxYearEnd) {
        return Object.assign({}, state, {
          list: createYearsArr(
            action.json.minYearStart,
            action.json.maxYearEnd
          ),
          current: action.json.minYearStart,
        });
      }
      return Object.assign({}, state, {
        list: createYearsArr(action.json.minYearStart, action.json.maxYearEnd),
      });
    case UPDATE_YEAR:
      return Object.assign({}, state, { current: action.year });
    case HENDLE_UPDATE_YEAR:
      return Object.assign({}, state, { value: action.value });
    case VALIDATE_YEAR:
      if (
        (String(parseInt(action.value)) === action.value &&
          parseInt(action.value) > 1900 &&
          action.value.length === 4) ||
        action.value === ''
      ) {
        return Object.assign({}, state, {
          value: action.value,
          errorValue: false,
          verified: true,
        });
      } else {
        return Object.assign({}, state, {
          value: '',
          errorValue: true,
          verified: false,
        });
      }
    case CLEAR_YEAR:
      return defState;
    case RESET_APP:
      return defState;
    case CLEAR_SEARCH_RESULT:
      return defState;
    default:
      return state;
  }
}

function createYearsArr(start, finish) {
  const res = [];
  for (let i = start; i <= finish; i++) {
    const obj = { code: i, name: i };
    res.push(obj);
  }
  return res;
}

export default years;
