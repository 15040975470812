import {
  initMarkGuideForm,
  setMarkGuideFormId,
  setMarkGuideFormNames,
  showMarkGuideForm,
  updateMarkGuideName,
} from '../sync/markGuideForm';
import {fetchFromApi} from '../service';

export function callMarkGuideForm(mode) {
  return (dispatch, getState) => {
    return new Promise((res) => {
      const state = getState();
      const code = state.guides.currentRow;
      let currGuide = null;
      if (code !== 'def') {
        currGuide = state.guides.body.filter(
          (el) => el.code === String(code)
        )[0];
      }

      const name = code === 'def' ? 'def' : currGuide.name;
      const hidden = code === 'def' ? false : currGuide.hidden;
      const asMark =
        code === 'def'
          ? 'def'
          : currGuide.asMark === undefined
          ? 'def'
          : currGuide.asMark;

      dispatch(initMarkGuideForm(mode, code, name, hidden, asMark));
      res({ mode, code });
    })
      .then(({ mode, code }) => {
        if (mode === 'edit') {
          dispatch(getModelMarkById(code));
        }
      })
      .then(() => {
        dispatch(showMarkGuideForm());
      });
  };
}

function getModelMarkById(code) {
  return (dispatch, getState) => {
    fetchFromApi('api/v1/model-mark/' + code,"GET",{})
      .then((res) => res.json())
      .then((res) => {
        dispatch(setMarkGuideFormId(res.id));
        const names_obj = res.name;
        let names_mass = [];
        let defName = 'def';
        if (names_obj.value !== undefined) {
          names_mass = names_obj.value.filter((el) => el.lang !== 'DEF');
          defName = names_obj.value.filter((el) => el.lang === 'DEF')[0].name;
        }
        dispatch(setMarkGuideFormNames(names_mass));
        dispatch(updateMarkGuideName(defName, false));
      });
  };
}