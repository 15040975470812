import { connect } from 'react-redux';
import { Header } from '../../components/header';
import { changeLanguage } from '../actions/sync/i18';
import { switchUserInfo } from '../actions/sync/personal';
import { logout } from '../actions/async/auth';

const mapStateToProps = (state, ownProps) => {
  const access = ownProps.access !== undefined ? ownProps.access : true;
  return {
    langList: state.i18.list,
    authorizationStage: state.auth.stage,
    lang: state.i18.current,
    username: state.personal.username,
    userFIO: state.personal.fio,
    userPosition: state.i18.phrases.userInfo.role[state.personal.position],
    pages: state.pages.list,
    currPage: state.pages.current,
    pagesCaption: state.i18.phrases.pages,
    showUserInfo: state.personal.showUserInfo,
    access,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLangChange: (lang) => dispatch(changeLanguage(lang)),
    switchUserInfo: () => dispatch(switchUserInfo()),
    logout: () => dispatch(logout()),
  };
};

const VisibleHeader = connect(mapStateToProps, mapDispatchToProps)(Header);

export default VisibleHeader;
