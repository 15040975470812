import React, {useEffect, useState} from 'react';
import './index.scss';

import {useDispatch, useSelector} from 'react-redux'
import iconClose from "../../theme/svg/close_icon.svg";
import {requestViscoClass} from "../../redux/actions/async/viscoClass";
import {fetchFromApi} from "../../redux/actions/service";
import {initNotificationMessage, showNotificationMessage} from "../../redux/actions/sync/notificationMessage";
import {API} from "../viscoClass/api";
import guidsFormViscosityClassSchema from "../../validations/guidsFormViscosityClass";
import CodeInputField from "../../pureComponents/codeInputField";
import FormInputField from "../../pureComponents/formInputField";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import ConfirmModal from "../../pureComponents/modal/confirmModal";
import {waitForGuideData} from "../../redux/actions/sync/guides";
import {requestGuideTableBody} from "../../redux/actions/async/guides";

const initialState = {
    id: null,
    code: null,
    name: null,
    partName: null,
    hidden: false
}

export function ViscoClassGuideForm(props) {
    const store = useSelector(state => state.viscoClass.list[0] );
    const dispatch = useDispatch();

    const [active_modal, setActive_modal] = useState(null);
    const [fields, setFields] = useState(initialState);
    const [errors, setErrors] = useState(null);
    const [changes, setChanges] = useState(false);

    useEffect(() => {
        store.type === 'edit' && startEditCopy();
        store.type === 'copy' && startEditCopy();
        store.type === 'new' && startNew();
    },[]);

    function startEditCopy() {
        Promise.all([
            API.getViscoClass(props.data.currentRow),
            API.getCode()
        ]).then((response) => {
            setFields({ ...fields,
                id: store.type === 'copy' ? null : response[0]?.id,
                code: store.type === 'copy' ? response[1] : response[0]?.code ,
                name: response[0]?.name,
                partName: response[0]?.partName,
                hidden: response[0]?.hidden || false
            });
            if (store.type === 'copy') {
                setChanges(true);
            }
        })
    }

    function startNew() {
        createCode();
    }

    function changeInput(event) {
        setChanges(true);
        if(event.target.id === 'hidden') {
            setFields({ ...fields, [event.target.id]: !fields.hidden });
            return;
        }
        setFields({ ...fields, [event.target.id]: event.target.value });
        setErrors(prev => ({...prev, [event.target.id]:null }));
    }

    function createCode() {
        API.getCode().then((response) => {
            setFields({ ...fields,
                code: response
            })
        });
    }

    async function save() {
        const isFormValid = await guidsFormViscosityClassSchema.isValid( fields,{abortEarly: false} );
        if( isFormValid ) {
            saveChanges( {
                id: fields.id,
                code: fields.code,
                name: fields.name,
                partName: fields.partName,
                hidden: fields.hidden
            }).then();
        } else {
            if (!isFormValid) {
                guidsFormViscosityClassSchema
                    .validate(fields, { abortEarly: false })
                    .catch((err) => {
                        const errors = err.inner.reduce((acc, error) => {
                            return {
                                ...acc,
                                [error.path]: error.message,
                            };
                        }, {});
                        setErrors(() => {
                            return {
                                ...errors,
                            };
                        });
                    });
            }
        }
    }

    async function saveChanges(body) {
        await fetchFromApi("api/v1/visco-classification","PUT",{body})
        .then((res) => {
            if (res.status === 200) {
                dispatch(waitForGuideData(true));
                dispatch(requestViscoClass(false, null, res.id));
                dispatch(requestGuideTableBody());
            } else {
                dispatch(
                    initNotificationMessage(
                        'Ошибка',
                        'Произошла системная ошибка. Обратитесь к администратору системы',
                        'danger'
                    )
                )
                dispatch(showNotificationMessage());
            }
        })
        .catch((e) => {
            console.log(e);
            dispatch(
                initNotificationMessage(
                    'Ошибка',
                    'Произошла системная ошибка. Обратитесь к администратору системы',
                    'danger'
                )
            );
            dispatch(showNotificationMessage());
        });
    }

    function close() {
        if(!changes) {
            setFields(initialState);
            dispatch( requestViscoClass(false, null, null));
        } else {
            setActive_modal('modalTC__wrapper-active');
        }
    }

    function closeApprowal(value) {
        setActive_modal(null);
    }

    function onContinueWithoutSave(value) {
        setFields(initialState);
        setActive_modal(null);
        dispatch(requestViscoClass(false, null, null));
    }

    return (
        <div className={"model-guide__wrapper model-guide__wrapper_active"}>
            <div className="model-guide-block">
                {/* Header */}
                <div className="model-guide-block__header">
                    <div className="model-guide-block__header__name">{props.data.currentGuide}</div>
                    <div className="model-guide-block__control model-guide-block__control_elastic">
                        <CodeInputField
                            id={'code'}
                            label={'Код'}
                            disabled={store.type === 'edit'}
                            error={errors?.code}
                            value={fields.code}
                            onChange={changeInput}
                            required={true}
                        />
                    </div>
                    <button
                        className={'button button__small button__primary cursor__pointer'}
                        disabled={store?.type === 'edit'}
                        onClick={() => createCode()}>
                        Создать код
                    </button>
                    <div className="model-guide-block__close" onClick={() => close()}>
                        <img src={iconClose} alt="Закрыть" />
                    </div>
                </div>
                {/* Body */}
                <div className="model-guide-block__content">
                    {/* Название */}
                    <FormInputField
                        id='name'
                        label='Название'
                        value={fields.name}
                        error={errors?.name}
                        onChange={changeInput}
                        required={true}
                    />
                    <FormInputField
                        id='partName'
                        label='Сокращенное название'
                        value={fields.partName}
                        error={errors?.partName}
                        onChange={changeInput}
                        required={true}
                    />
                </div>

                <div className="model-guide-block__bottom">
                    <ToggleSwitch
                        id={'hidden'}
                        label={'Скрыть'}
                        onToggle={changeInput}
                        checked={fields.hidden}
                    />

                    <div className="buttons__group">
                        <div
                            className="button button__secondary button__secondary__ghost"
                            onClick={() => onContinueWithoutSave()} >
                            Не сохранять
                        </div>
                        <button
                            disabled={!changes}
                            className={'button button__primary '}
                            onClick={() => save()}>
                            Сохранить изменения
                        </button>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <ConfirmModal
                active={active_modal}
                onSave={save}
                onContinueWithoutSave={onContinueWithoutSave}
                closeModal={closeApprowal}
            />
        </div>
    );
}