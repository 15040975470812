import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Waiter } from "../waiter";
import "./index.scss";

const PersistLogin = () => {
  const { isLoggedIn, isLoggedOut } = useAuth();

  if (isLoggedOut) {
    return <Navigate to={'/'} />
  }
  
  return (
    <>
      {isLoggedIn ? (
        <Outlet />
      ) : (
        <div className="full-screen-loading">
          <Waiter size="large" />
        </div>
      )}
    </>
  );
};

export default PersistLogin;
