const initialState = {
  conditionViscoIntervalList: [],
  viscoList: []
};

function viscoSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_VISCO_SELECTORS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const SET_VISCO_SELECTORS = "SET_VISCO_SELECTORS";
export const setViscoSelectors = (payload) => ({
  type: SET_VISCO_SELECTORS,
  payload,
});

export default viscoSelectors;
