import { connect } from 'react-redux';
import { SelectorList } from '../../pages/searchPage';
import {
  dropCategory,
  filterByCategory,
  requestCategories,
  selectCategory,
} from '../actions/async/category';
import {
  dropModelMark,
  filterByModelMark,
  selectModelMark,
} from '../actions/async/modelMark';
import {
  dropSubcategory,
  filterBySubcategory,
  selectSubcategory,
} from '../actions/async/subcategory';
import {
  dropModelGeneration,
  filterByModelGeneration,
  selectModelGeneration,
} from '../actions/async/modelGeneration';
import {
  requestSearchByFilterResult,
} from '../actions/async/search';
import { selectYear } from '../actions/async/years';
import { selectFuelType } from '../actions/async/fuelType';
import { selectEngineCap } from '../actions/async/engineCap';
import { selectModelClass } from '../actions/async/modelClass';
import { setSearchStage } from '../actions/sync/search';

const mapStateToProps = (state) => {
  return {
    phrases: state.i18.phrases.selectorList,
    lang: state.i18.current,
    category: state.category.current,
    categories: state.category.list,
    currentCategory: state.category.current,
    subcategories: state.subcategory.list,
    currentSubcategory: state.subcategory.current,
    modelMark: state.modelMark.list,
    currentModelMark: state.modelMark.current,
    modelGeneration: state.modelGeneration.list,
    currentModelGeneration: state.modelGeneration.current,
    searchMode: state.search.mode,
    years: state.years.list,
    currentYear: state.years.current,
    fuelType: state.fuelType.list,
    currentFuelType: state.fuelType.current,
    engineCap: state.engineCap.list,
    currentEngineCap: state.engineCap.current,
    modelClass: state.modelClass.list,
    currentModelClass: state.modelClass.current,
    fetching: state.search.fetching,
    hasMore: state.search.hasMore,
    columns: state.search.columns,
    result: state.search.result,
    minYearStart: state.search.minYearStart,
    maxYearEnd: state.search.maxYearEnd,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCategories: () => dispatch(requestCategories()),
    updateCategory: (id) => dispatch(selectCategory(id)),
    dropCategory: () => dispatch(dropCategory()),
    selectSubcategory: (id) => dispatch(selectSubcategory(id)),
    dropSubcategory: () => dispatch(dropSubcategory()),
    selectModelMark: (id) => dispatch(selectModelMark(id)),
    dropModelMark: () => dispatch(dropModelMark()),
    selectModelGeneration: (id) => dispatch(selectModelGeneration(id)),
    dropModelGeneration: () => dispatch(dropModelGeneration()),
    selectYear: (year) => dispatch(selectYear(year)),
    selectFuelType: (id) => dispatch(selectFuelType(id)),
    selectEngineCap: (id) => dispatch(selectEngineCap(id)),
    selectModelClass: (id) => dispatch(selectModelClass(id)),
    startSearch: () => dispatch(requestSearchByFilterResult()),
    filterByCategory: (id) => dispatch(filterByCategory(id)),
    filterBySubcategory: (id) => dispatch(filterBySubcategory(id)),
    filterByModelMark: (id) => dispatch(filterByModelMark(id)),
    filterByModelGeneration: (id) => dispatch(filterByModelGeneration(id)),
    setSearchStage: (stage) => dispatch(setSearchStage(stage)),
  };
};

const VisableSelectorList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectorList);

export default VisableSelectorList;
