import React from 'react';
import VisibleConditionTable from '../../redux/containers/visibleConditionTable';
import VisibleDescription from '../../redux/containers/visibleDescription';
// import { ConditionTable } from '../conditionTable';
// import { Description } from '../description';
import { InfoRow } from '../infoRow';
import './index.scss';

export function Component(props) {
  const hidden = props.data.hidden;
  if (props.data.closed) {
    return (
      <div className="product-element">
        <div
          className={
            'product-element__header' +
            (hidden ? ' product-element__header_hidden' : '')
          }
        >
          <div className="product-element__header-info">
            <div
              className={
                'product-element__header-img' +
                (hidden ? ' product-element__header-img_hidden' : '')
              }
            ></div>
            <div
              className={
                'product-element__header-type' +
                (hidden ? ' product-element__header-type_hidden' : '')
              }
            >
              {props.data.componentType}
            </div>
            <div
              className={
                'product-element__header-name' +
                (hidden ? ' product-element__header-name_hidden' : '')
              }
            >
              {props.data.name}
            </div>
          </div>
          <div className="product-element__header-controls">
            <div
              className="product-element__header-control"
              onClick={() => props.changeComponentMode(props.id)}
            >
              <div className="product-element__header-arrow product-element__header-arrow_down"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const motorInfo = [
    {
      name: 'Объем двигателя',
      value: props.data.engineCap,
    },
    {
      name: 'Мощность',
      value:
        props.data.powerHp ? props.data.powerHp +
        ' л.с./' +
        Math.round(props.data.powerHp * 0.735) +
        ' кВт' : '',
    },
    {
      name: 'Тип топлива',
      value: props.data.componentFuelType,
    },
  ];
  const hasMotorInfo = !!motorInfo.filter((el) => el.value || el.value === 0).length;

  return (
    <div className="product-element">
      <div
        className={
          'product-element__header' +
          (hidden ? ' product-element__header_hidden' : '')
        }
      >
        <div className="product-element__header-info">
          <div
            className={
              'product-element__header-img' +
              (hidden
                ? ' product-element__header-img_hidden'
                : ' product-element__header-img_active')
            }
          ></div>
          <div
            className={
              'product-element__header-type' +
              (hidden ? ' product-element__header-type_hidden' : '')
            }
          >
            {props.data.componentType}
          </div>
          <div
            className={
              'product-element__header-name' +
              (hidden ? ' product-element__header-name_hidden' : '')
            }
          >
            {props.data.name}
          </div>
        </div>
        <div className="product-element__header-controls">
          <div
            className="product-element__header-control"
            onClick={() => props.changeComponentMode(props.id)}
          >
            <div className="product-element__header-arrow product-element__header-arrow_up"></div>
          </div>
        </div>
      </div>
      <div className="product-element__body">
        {hasMotorInfo && (<InfoRow data={motorInfo} mode="inner" />)}
        <div className="product-element__description">
          <VisibleDescription id={props.data.description} />
        </div>
        <VisibleConditionTable conditions={props.data.conditions} />
      </div>
    </div>
  );
}

// function generateDescription(id) {
//   let res = '';
//   // for (let i = 0; i < 1000; i++) {
//   res += 'description ' + id;
//   // }
//   return res;
// }
