import React from 'react';

export function AuthController(props) {

  React.useEffect(() => {

    let interval = null;
    switch (props.authStage) {
      case 'init':
        props.initSystem();
        break;
      // case 'login':
        // interval = setInterval(() => {
        //   props.checkJWT();
        // }, 10000);
        // break;
      case 'logout':
        break;
      default:
        break;
    }
    return () => clearInterval(interval);
  });
  return <div></div>;
}
