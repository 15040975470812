import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import MultiSelectBlock from "../../pureComponents/multiSelectBlock";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import FormInputField from "../../pureComponents/formInputField";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import VerticalLine from "../../components/veritcalLine";
import { Waiter } from "../../components/waiter";
import { getSpecList } from "../../redux/actions/async/specList";
import {
  deleteProductSpecsFromServer,
  getProductSpecs,
  putProductSpecs,
} from "../../redux/actions/async/productSpecs";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import { getProductSpecLevelList } from "../../redux/actions/async/productSpecLevel";
import { setProductSpecs } from "../../redux/reducers/productSpecs";
import { addNewProductOption } from "../../redux/reducers/productMenu";

function handleProductSpecs(productSpecs, item) {
  const spec = productSpecs.find((spec) => spec.specCode === item.code);

  if (!spec) {
    return [
      ...productSpecs,
      {
        id: nanoid(3),
        specCode: item.code,
        specName: item.name,
        productSpecLevelName: null,
        productSpecLevelCode: null,
        actionType: "C",
      },
    ];
  }

  if (spec.actionType === "C") {
    return productSpecs.filter((spec) => spec.specCode !== item.code);
  }

  // specs from server
  return productSpecs.map((spec) => {
    if (spec.specCode !== item.code) return spec;

    return {
      ...spec,
      actionType: !spec.actionType ? "D" : null,
    };
  });
}

function deleteAllProductSpecs(productSpecs) {
  return productSpecs
    .filter((spec) => spec.actionType !== "C")
    .map((spec) => ({ ...spec, actionType: "D" }));
}

const ProductSpec = () => {
  const navigate = useNavigate();
  const { productCode } = useParams();

  const dispatch = useDispatch();

  const productMenu = useSelector((state) => state.productMenu);
  const menuIsLoaded = useSelector(
    (state) => state.productMenu.productCode !== ""
  );

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const [specList, isSpecListLoading] = useSelector(
    (state) => [state.specList.list, state.specList.isLoading],
    shallowEqual
  );

  const productSpecLevelList = useSelector(
    (state) => state.productSpecLevel.list
  );

  const [productSpecs, fetchingStatus] = useSelector(
    (state) => [state.productSpecs.data, state.productSpecs.fetchingStatus],
    shallowEqual
  );

  const isDeleteButtonDisabled = productSpecs.every(
    (item) => item.actionType === "C"
  );

  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const filteredProductSpecs = productSpecs.filter(
    (spec) => spec.actionType !== "D"
  );

  const filteredSpecList = specList.map((spec) => {
    return {
      ...spec,
      checked: filteredProductSpecs.some((item) => item.specCode === spec.code),
    };
  });

  useEffect(() => {
    dispatch(getSpecList());
    dispatch(getProductSpecLevelList());
  }, []);

  useEffect(() => {
    if (menuIsLoaded) {
      const option = "spec";
      const optionName = "Спецификации";

      if (productMenu.children.some((item) => item.code === option)) return;

      dispatch(
        addNewProductOption({
          option,
          optionName,
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    if (!productCode) return;

    dispatch(getProductSpecs(productCode));
  }, [productCode]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitSpecs));
  }, [JSON.stringify(productSpecs)]);

  function onSearch(value) {
    dispatch(getSpecList(value));
  }

  function onProductLevelSpecSelect(item, id) {
    const newProductSpecs = productSpecs.map((productSpec) => {
      if (productSpec.id !== id) return productSpec;

      return {
        ...productSpec,
        productSpecLevelName: item.name,
        productSpecLevelCode: item.code,
        actionType: !productSpec.actionType ? "U" : productSpec.actionType,
      };
    });

    dispatch(setProductSpecs(newProductSpecs));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function onSelectSpec(item) {
    const newProductSpecs = handleProductSpecs(productSpecs, item);
    dispatch(setProductSpecs(newProductSpecs));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function onSelectAllSpecs() {
    const allChecked = filteredSpecList.length === filteredProductSpecs.length;
    if (allChecked) {
      clearAllProductSpecs();
      return;
    }
    const nonCheckedSpecList = filteredSpecList.filter((item) => !item.checked);
    let newProductSpecs = productSpecs;
    nonCheckedSpecList.forEach((item) => {
      newProductSpecs = handleProductSpecs(newProductSpecs, item);
    });
    dispatch(setProductSpecs(newProductSpecs));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function deleteProductSpec(id) {
    const productSpec = productSpecs.find((spec) => spec.id === id);
    if (productSpec.actionType === "C") {
      dispatch(setProductSpecs(productSpecs.filter((spec) => spec.id !== id)));
      return;
    }
    dispatch(
      setProductSpecs(
        productSpecs.map((spec) => {
          if (spec.id !== id) return spec;
          return {
            ...spec,
            actionType: "D",
          };
        })
      )
    );
    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function clearAllProductSpecs() {
    const newProductSpecs = deleteAllProductSpecs(productSpecs);
    dispatch(setProductSpecs(newProductSpecs));
    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const handleDeleteProductSpecs = () => {
    setDeleteModalActive(true);
  };

  const deleteProductSpecs = () => {
    dispatch(
      deleteProductSpecsFromServer(productCode,() => {
        navigate(`/product/${productCode}`, {
          state: {
            actionType: "edit",
          },
        });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  function submitSpecs() {
    if (productSpecs.length === 0) {
      navigate(`/product/${productCode}`, {
        state: {
          actionType: "edit",
        },
      });
      return;
    }

    if (productSpecs.every((spec) => spec.actionType === "D")) {
      dispatch(
        deleteProductSpecsFromServer(productCode, () => {
          navigate(`/product/${productCode}`, {
            state: {
              actionType: "edit",
            },
          });
        })
      );
      return;
    }

    dispatch(putProductSpecs());
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    submitSpecs();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="content-general__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="content-general__header">
            <div className="main-header-block__item main-header-block__title">
              Добавление спецификаций
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteProductSpecs}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__specs">
              <div className="main-form-block__product-specs">
                {filteredProductSpecs.map((spec) => {
                  return (
                    <div className="main-form-block__row" key={spec.id}>
                      <FormInputField
                        value={spec.specName}
                        label="Спецификация"
                        id="specCode"
                        disabled={true}
                      />
                      <DropdownSelect
                        label="Уровень соответствия"
                        id={spec.id}
                        items={productSpecLevelList}
                        defaultValue={spec.productSpecLevelName}
                        onSelect={onProductLevelSpecSelect}
                      />
                      <button
                        type="button"
                        className="button button__negative button__negative__outline"
                        onClick={() => deleteProductSpec(spec.id)}
                      >
                        Удалить элемент
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="multiselect-block">
                <MultiSelectBlock
                  items={filteredSpecList}
                  isLoading={isSpecListLoading}
                  onSearch={onSearch}
                  onItemSelect={onSelectSpec}
                  onSelectAll={onSelectAllSpecs}
                />
              </div>
            </div>
          </div>
          <div className="content-general__footer">
            <div className="main-footer-block__buttons">
              <ModalLinkWrapper
                linkProps={{ to: "/products", state: { pageCode: 40, withoutReset: true} }}
                className="button button__secondary button__secondary__ghost"
              >
                Вернуться к списку продуктов
              </ModalLinkWrapper>
              <button type="submit" className="button button__primary" disabled={!dataChanged}>
                Сохранить изменения
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteProductSpecs}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"product_spec"}
        rowCode={productCode}
        listType={"hist"}
      />
    </>
  );
};

export default ProductSpec;
