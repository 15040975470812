import React from "react";

const ClearFieldIcon = ({ className }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="#777B92"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35353 0.93934C5.63483 0.658035 6.01636 0.5 6.41419 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V10C15.5 10.8284 14.8284 11.5 14 11.5H6.41418C6.01636 11.5 5.63483 11.342 5.35352 11.0607L1.35352 7.06066C0.767737 6.47487 0.767739 5.52513 1.35352 4.93934L5.35353 0.93934ZM6.41419 1.5C6.28158 1.5 6.1544 1.55268 6.06063 1.64645L2.06063 5.64645C1.86537 5.84171 1.86537 6.15829 2.06063 6.35355L6.06063 10.3536C6.1544 10.4473 6.28158 10.5 6.41418 10.5H14C14.2761 10.5 14.5 10.2761 14.5 10V2C14.5 1.72386 14.2761 1.5 14 1.5H6.41419ZM12.3535 3.64645C12.5488 3.84171 12.5488 4.15829 12.3535 4.35355L10.7071 6L12.3535 7.64645C12.5488 7.84171 12.5488 8.15829 12.3535 8.35355C12.1583 8.54882 11.8417 8.54882 11.6464 8.35355L9.99997 6.70711L8.35352 8.35355C8.15826 8.54882 7.84168 8.54882 7.64642 8.35355C7.45116 8.15829 7.45116 7.84171 7.64642 7.64645L9.29286 6L7.64642 4.35355C7.45116 4.15829 7.45116 3.84171 7.64642 3.64645C7.84168 3.45118 8.15826 3.45118 8.35352 3.64645L9.99997 5.29289L11.6464 3.64645C11.8417 3.45118 12.1583 3.45118 12.3535 3.64645Z"
      />
    </svg>
  );
};

export default ClearFieldIcon;
