import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import {
  resetProductFormState,
  setProductForm,
  setProductFormFetchingStatus,
  updateProductFormField,
} from "../../reducers/productForm";
import { setActiveProductMenuItem } from "../../reducers/productMenu";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import {
  reciveProductInfo,
  setProductCode,
  waitForProduct,
} from "../sync/product";
import { getProductMenu } from "./productMenu";

export function selectProduct(code) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(setProductCode(code));
      res();
    }).then(() => {
      dispatch(fetchProductInfo());
    });
  };
}

export function fetchProductInfo() {
  return (dispatch, getState) => {
    dispatch(waitForProduct(true));

    const state = getState();
    const lang = state.i18.current;
    const code = state.product.code;
    const url =
      "api/v1/model-complect/all/products/" + code + "/locale/" + lang;
    return fetchFromApi(url, "GET", {})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((json) => {
        dispatch(reciveProductInfo(json));
      })
      .then(() => {
        dispatch(waitForProduct(false));
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export function getProduct(code, actionType, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const product = state.product;
    try {
      dispatch(resetProductFormState());
      dispatch(setProductFormFetchingStatus("loading"));
      const response = await fetchFromApi("api/v1/product", "GET", {
        id: code,
      });

      if (!response.ok) {
        throw new HttpError(response.status, response.statusText);
      }

      const data = await response.json();
      const multiLanguageArray = data.name.value;
      const name = multiLanguageArray.find((item) => item.lang === "DEF").name;

      dispatch(
        setProductForm({
          ...data,
          name,
          names: multiLanguageArray.filter((item) => item.lang !== "DEF"),
          changedFields:
            actionType === "copy"
              ? Object.keys(data).filter(
                  (key) => data[key] !== null && !Array.isArray(data[key])
                )
              : [],
          code: actionType === "edit" ? data.code : product.code,
        })
      );
      if (callback) callback();

      dispatch(setProductFormFetchingStatus());
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }

      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductFormFetchingStatus());
    }
  };
}

export function putProduct(namesList, actionType) {
  return async (dispatch, getState) => {
    const state = getState();
    const product = state.productForm;
    const pathname = state.currentPath.path;

    try {
      dispatch(setProductFormFetchingStatus("update"));
      const response = await fetchFromApi("api/v1/product", "PUT", {
        body: {
          ...product,
          name: namesList,
          id: actionType === "edit" ? product.id : null,
        },
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getProductMenu(product.code));
      dispatch(setActiveProductMenuItem(pathname));
      dispatch(setProductFormFetchingStatus());
      dispatch(resetCurrentPageFormState());

      //reset changedFields
      dispatch(
        updateProductFormField({
          changedFields: [],
        })
      );

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductFormFetchingStatus());

      return false;
    }
  };
}

export function deleteProductFromServer(id, callback = null) {
  return async (dispatch, getState) => {
    const body = {
      code: id,
      tableName: "product",
    };
    try {
      dispatch(setProductFormFetchingStatus("loading"));
      const response = await fetchFromApi("api/v1/dict/record", "DELETE", {
        body,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductFormFetchingStatus());
    }
  };
}
