import { normalizeConditionIntervalsForServer } from "../../../normalizers/conditionIntervals";
import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import {
  resetConditionIntervals,
  setCIFetchingStatus,
  setConditionCode,
  setConditionIntervals,
} from "../../reducers/conditionIntervals";
import { setActiveMenuItem } from "../../reducers/modelComplectMenu";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getModelComplectMenu } from "./modelComplectMenu";

export function getConditionIntervals(conditionCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/condition-interval";

    try {
      dispatch(resetConditionIntervals());
      dispatch(setCIFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${conditionCode}/locale/${lang}`
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(setConditionIntervals(data));
      dispatch(setConditionCode(conditionCode));
      dispatch(setCIFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setCIFetchingStatus());
    }
  };
}

export function putConditionIntervals() {
  return async (dispatch, getState) => {
    const state = getState();
    const conditionIntervals = state.conditionIntervals.list;
    const conditionCode = state.conditionIntervals.conditionCode;
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setCIFetchingStatus("update"));
      const response = await fetchFromApi("api/v1/condition-interval", "POST", {
        body: normalizeConditionIntervalsForServer(
          conditionIntervals,
          conditionCode
        ),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getModelComplectMenu(modelComplectCode));
      dispatch(setActiveMenuItem(pathname));
      dispatch(setCIFetchingStatus());
      dispatch(resetCurrentPageFormState());
      dispatch(getConditionIntervals(conditionCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setCIFetchingStatus());

      return false;
    }
  };
}

export function deleteConditionIntervalsFromServer(
  conditionCode,
  callback = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    const conditionIntervals = state.conditionIntervals.list;
    const modelComplectCode = state.modelComplectMenu.modelComplectCode;

    const body = {
      code: conditionCode,
      data: conditionIntervals
        .filter((item) => item.actionType !== "C")
        .map((item) => ({ interval: item.intervalCode })),
    };

    try {
      dispatch(setCIFetchingStatus("loading"));
      const response = await fetchFromApi(
        "api/v1/condition-interval/all",
        "DELETE",
        {
          body,
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getModelComplectMenu(modelComplectCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setCIFetchingStatus());
    }
  };
}
