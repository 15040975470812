import {Waiter} from "../../components/waiter";
import {Link} from "react-router-dom";
import {InfoRow} from "../../components/infoRow";
import VisibleComponentsList from "../../redux/containers/visibleComponentsList";

const searchViewProductForm = ({ state }) => {

    if (state.fetching) {
        return <Waiter size="large" />;
    }

    const data = state;
    const productName = data.modelMark + ' ' + data.model + ' ' + data.modelGeneration + ' ' + data.modelComplect;
    const infoMass = [
        {
            name: '',
            value: (data.startYear ?? '') + ' - ' + (data.endYear ?? ''),
        },
        {
            name: 'Тип привода',
            value: data.modelDrive,
        },
        { name: 'Тип кузова', value: data.modelBody },
        { name: 'Класс Евро', value: data.modelClass },
    ];

    return (
        <div className="selected-result">
            <div className="selected-result__content">
                <div className={'product-header' + (data.hidden?' hidden':'') }>
                    <div className={'product-header__caption'}>{productName}</div>
                    <Link to={`/model-complect/${data.code}`} state={{ actionType: 'edit' }} className="button button__primary">Редактировать</Link>
                </div>
                <InfoRow data={infoMass} />
                <VisibleComponentsList />
            </div>
        </div>
    );
}

export default searchViewProductForm;