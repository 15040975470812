import {
  RESET_CONDITION_STATE,
  SET_CONDITION,
  SET_CONDITION_FETCHING_STATUS,
  UPDATE_CONDITION_CHANGED_FIELDS,
  UPDATE_CONDITION_FIELD,
} from "../actions/sync/condition";

const initialState = {
  id: null,
  code: "",
  componentCode: null,
  conditionTypeCode: null,
  marketCode: null,
  description: null,
  hidden: false,
  changedFields: [],
  fetchingStatus: "",
};

function condition(state = initialState, action) {
  switch (action.type) {
    case SET_CONDITION:
      return Object.assign({}, state, action.payload);
    case UPDATE_CONDITION_FIELD:
      return Object.assign({}, state, action.payload);
    case UPDATE_CONDITION_CHANGED_FIELDS:
      const isFieldAlreadyMarkedForUpdate = state.changedFields.includes(
        action.payload
      );
      return {
        ...state,
        changedFields: isFieldAlreadyMarkedForUpdate
          ? [...state.changedFields]
          : [...state.changedFields, action.payload],
      };
    case SET_CONDITION_FETCHING_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case RESET_CONDITION_STATE:
      return initialState;
    default:
      return state;
  }
}

export default condition;
