import React from "react";
import "./App.scss";

import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import VisableAuthController from "./redux/containers/visibleAuthController";
import VisibleAuthForm from "./redux/containers/visibleAuthForm";
import VisibleSelectionPage from "./redux/containers/visibleSelectionPage";
import VisibleGuidesPage from "./redux/containers/visibleGuidesPage";
import { UserAgreement } from "./components/userAgreement";
import VisibleHomePage from "./redux/containers/visibleHomePage";
import Layout from "./components/layout";
import PersistLogin from "./components/persistLogin";
import ModelComplectPage from "./pages/modelComplectPage";
import ModelComplectGeneral from "./pages/modelComplectPage/components/modelComplectGeneral";
import ComponentPage from "./pages/componentPage";
import ConditionPage from "./pages/conditionPage";
import ConditionIntervalsPage from "./pages/conditionIntervalsPage";
import ConditionViscosPage from "./pages/conditionViscosPage";
import ConditionSpecPage from "./pages/conditionSpecPage";
import ProductPageLayout from "./pages/product/productPageLayout";
import ProductPage from "./pages/product";
import ProductSpec from "./pages/productSpec";
import ProductViscosPage from "./pages/productViscos";
import ReportPageLayout from "./pages/reports/reportPageLayout";
import StatisticRequestReportPage from "./pages/reports/statisticRequestReportPage";
import AutostatModelReportPage from "./pages/reports/autostatModelReportPage";

import ProductRestrictionPage from "./pages/productRestriction";
import StatisticCoverReportPage from "./pages/reports/statisticCoverReportPage";
import SpecPageLayout from "./pages/specs/SpecPageLayout";
import SpecPage from "./pages/specs/SpecPage";
import SpecOverPage from "./pages/specs/SpecOverPage";
import SpecComponentGroupPage from "./pages/specs/SpecComponentGroupPage";
import LoadFromExcelPage from "./pages/loadFromExcelPage/LoadFromExcelPage";
import {useDocumentTitle} from "./hooks/useDocumentTitle";

function App({ store }) {
  useDocumentTitle(); //set default title
  return (
    <Provider store={store}>
      <VisableAuthController />

      <Router>
        <Routes>
          <Route path="/" element={<VisibleHomePage />} />
          <Route element={<PersistLogin />}>
            <Route path="/search" element={<VisibleSelectionPage />} />
            <Route path="/guides" element={<VisibleGuidesPage />} />
            <Route path="/techniks" element={<VisibleGuidesPage />} />
            <Route path="/components" element={<VisibleGuidesPage />} />
            <Route path="/products" element={<VisibleGuidesPage />} />
            <Route path="/specs" element={<VisibleGuidesPage />} />
            <Route
              path="/guides/load_from_excel"
              element={<LoadFromExcelPage />}
            />
            <Route element={<Layout />}>
              <Route path="/model-complect" element={<ModelComplectPage />}>
                <Route index element={<ModelComplectGeneral />} />
                <Route
                  path=":modelComplectCode"
                  element={<ModelComplectGeneral />}
                />
                <Route path=":modelComplectCode/component">
                  <Route
                    index
                    element={
                      <Navigate to="new" state={{ actionType: "new" }} />
                    }
                  />
                  <Route path=":componentCode" element={<ComponentPage />} />
                </Route>
                <Route path=":modelComplectCode/condition">
                  <Route
                    index
                    element={
                      <Navigate to="new" state={{ actionType: "new" }} />
                    }
                  />
                  <Route path=":conditionCode" element={<ConditionPage />} />
                </Route>
                <Route
                  path=":modelComplectCode/condition/:conditionCode/interval"
                  element={<ConditionIntervalsPage />}
                />
                <Route
                  path=":modelComplectCode/condition/:conditionCode/visco"
                  element={<ConditionViscosPage />}
                />
                <Route
                  path=":modelComplectCode/condition/:conditionCode/spec"
                  element={<ConditionSpecPage />}
                />
              </Route>
              <Route path="product" element={<ProductPageLayout />}>
                <Route index element={<ProductPage />} />
                <Route path=":productCode" element={<ProductPage />} />
                <Route path=":productCode/spec" element={<ProductSpec />} />
                <Route
                  path=":productCode/visco"
                  element={<ProductViscosPage />}
                />
                <Route
                  path=":productCode/segment"
                  element={<ProductRestrictionPage page="segment" />}
                />
                <Route
                  path=":productCode/model-subcategory"
                  element={<ProductRestrictionPage page="model-subcategory" />}
                />
                <Route
                  path=":productCode/model-mark"
                  element={<ProductRestrictionPage page="model-mark" />}
                />
                <Route
                  path=":productCode/model"
                  element={<ProductRestrictionPage page="model" />}
                />
                <Route
                  path=":productCode/component-fuel-type"
                  element={
                    <ProductRestrictionPage page="component-fuel-type" />
                  }
                />
                <Route
                  path=":productCode/component-group"
                  element={<ProductRestrictionPage page="component-group" />}
                />
              </Route>
              <Route path="spec" element={<SpecPageLayout />}>
                <Route index element={<SpecPage />} />
                <Route path=":specCode" element={<SpecPage />} />
                <Route path=":specCode/spec-over" element={<SpecOverPage />} />
                <Route
                  path=":specCode/spec-component-group"
                  element={<SpecComponentGroupPage />}
                />
              </Route>
              <Route path="reports" element={<ReportPageLayout />}>
                <Route
                  path="autostat/missing-models"
                  element={<AutostatModelReportPage />}
                />
                <Route
                  path="statistics/specification-coverage"
                  element={<StatisticCoverReportPage />}
                />
                <Route
                  path="statistics/requests"
                  element={<StatisticRequestReportPage />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="/login" element={<VisibleAuthForm />} />
          <Route path="/userAgreement" element={<UserAgreement />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
