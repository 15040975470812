import React, { useState, useMemo} from "react";
import Modal from "../index";
import DropdownSelect from "../../dropdownSelect";
import {useDispatch} from 'react-redux'
import { getModelComplects } from "../../../redux/actions/async/modelComplect";


export const SelectModificationModal = ({active, closeModal, setSelectedModification, selectedModification, okModal}) => {
  const dispatch = useDispatch();

  const [foundedModificationList, setFoundedModificationList] = useState([]);
	const [searchLoading, setSearchLoading] = useState(false);
  const [searchBy, setSearchBy] = useState({name: 'Название', code:'name'});

  

  const handleDropdownSearch = async (id, searchString) => {
    setSearchLoading(true)
    const props = {
      limit: 30,
      searchString:searchString,
      colListCode: searchBy.code
    }

   const rez =  await dispatch(getModelComplects(props));
   setFoundedModificationList(rez)
   setSearchLoading(false)
  };


const changeDropdown = (item, id) => {
    setSelectedModification(item)
}
  


 
 const searchFields = [{name: 'Название', code:'name'}, {name: 'Код', code:'code'}]
 const changeSearchBy = (item) => {
  setSearchBy(item)
}



  return (
    <Modal active={active} closeModal={closeModal} width={'800px'} >
      <div className="modal__header">
        <h4>
         Выберите модификацию
        </h4>
        <DropdownSelect
							id={'modelComplectCode'}
              items={foundedModificationList}
              defaultValue={selectedModification?.name}
							searchable={true}
							onSelect={changeDropdown}
							onSearch={handleDropdownSearch}
							isLoading={searchLoading}
							required={true}
						/>

        <h4>
         Поиск по:
        </h4>
              <DropdownSelect
							id={'searchByDropdown'}
              items={searchFields}
              defaultValue={searchBy?.name}
							onSelect={changeSearchBy}
              required={true}
          />
      </div>
      <hr className="horizontal-line" />
      <div className="modal__buttons">
        <button className="modal-button button button__primary" onClick={okModal}>
          Копировать
        </button>
        <button className="modal-button button button__negative button__negative__outline" onClick={closeModal}>
          Отмена
        </button>
      </div>
    </Modal>
  );
};

