import React from "react";
import { FieldWrapper } from "../fieldWrapper";
import "./index.scss";

export function Filter(props) {
  return (
    <div className="ordinary-filter">
      <FieldWrapper
        id={props.id}
        type={props.type}
        data={props.data}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onDrop={props.onDrop}
        priority={props.priority}
        mode={props.mode}
        required={props.required}
        blocked={props.blocked}
        searchable={props.searchable}
      />
    </div>
  );
}
