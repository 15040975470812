import { connect } from "react-redux";
import { Interval } from "../../components/interval";

const mapStateToProps = (state, ownProps) => {
    return{
        data: state.intervals.by_id[ownProps.id],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const VisibleInterval = connect(mapStateToProps, mapDispatchToProps)(Interval);

export default VisibleInterval;