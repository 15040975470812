import * as yup from "yup";

const messages = {
  required: "Поле не заполнено",
};

const productFormSchema = yup.object().shape({
  code: yup
    .string()
    .required(messages.required)
    .matches(
      /^[a-z0-9-_]+$/,
      "Допускаются строчные латинские символы, цифры, знаки _ -"
    ),
  name: yup.string().required(messages.required),
  productBrandCode: yup.string().nullable().required(messages.required),
  productPremiumCode: yup.string().nullable().required(messages.required),
  priority: yup.string().nullable().required(messages.required),
  startYear: yup.string().nullable(),
  endYear: yup.string().nullable(),
  hidden: yup.boolean(),
});

export default productFormSchema;
