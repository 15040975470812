import { useCallback, useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownSelect from "../../../pureComponents/dropdownSelect";
import getNameFromList from "../../../utils/getNameFromList";
import {
  deleteConditionInterval,
  updateConditionInterval,
} from "../../../redux/reducers/conditionIntervals";
import { getFitleredIntervalsList } from "../../../redux/actions/async/intervalSelectors";
import { updateAllChg } from "../../../redux/actions/sync/currentPageFormState";

const IntervalsItem = memo(
  ({
    id,
    intervalTypeCode,
    intervalCode,
    intervalVal,
    intervalActionCode,
    intervalList,
    isNewInterval,
    error,
    setErrors,
  }) => {
    const dispatch = useDispatch();
    const { intervalTypeList, intervalActionList } = useSelector(
      (state) => state.intervalSelectors
    );
    const dataChanged = useSelector(
      (state) => state.currentPageFormState.allChg
    );

    const [searchLoading, setSearchLoading] = useState({
      [id]: false,
    });

    useEffect(() => {
      if (!intervalTypeCode) return;

      dispatch(getFitleredIntervalsList(intervalTypeCode, id));
    }, [intervalTypeCode]);

    const handleDropdownChange = useCallback(({ code }, id) => {
      const [fieldName, intervalId] = id.split("-");

      if (fieldName === "intervalCode") {
        dispatch(
          updateConditionInterval({
            id: intervalId,
            field: "intervalVal",
            value: getNameFromList(intervalList, code),
          })
        );
      }

      dispatch(
        updateConditionInterval({
          id: intervalId,
          field: fieldName,
          value: code,
        })
      );

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [intervalId]: {
          ...prevErorrs[intervalId],
          [fieldName]: "",
        },
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    }, [intervalList]);

    const handleDropdownSearch = useCallback(
      (id, searchValue) => {
        if (!intervalTypeCode) return;

        const intervalId = id.split("-")[1];
        setSearchLoading({
          [intervalId]: true,
        });

        dispatch(
          getFitleredIntervalsList(
            intervalTypeCode,
            intervalId,
            searchValue,
            () =>
              setSearchLoading({
                [intervalId]: false,
              })
          )
        );
      },
      [intervalTypeCode]
    );

    const handleDeleteItem = () => {
      dispatch(deleteConditionInterval(id));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    };

    return (
      <>
        <DropdownSelect
          id={`intervalTypeCode-${id}`}
          label="Тип интервала"
          defaultValue={getNameFromList(intervalTypeList, intervalTypeCode)}
          items={intervalTypeList}
          onSelect={handleDropdownChange}
          error={error?.intervalTypeCode}
          disabled={!isNewInterval}
          required
        />
        <DropdownSelect
          id={`intervalCode-${id}`}
          label="Интервал"
          defaultValue={getNameFromList(intervalList, intervalCode) || intervalVal}
          items={intervalList}
          onSelect={handleDropdownChange}
          searchable={true}
          onSearch={handleDropdownSearch}
          isLoading={searchLoading[id]}
          error={error?.intervalCode}
          disabled={!isNewInterval}
          required
        />
        <DropdownSelect
          id={`intervalActionCode-${id}`}
          label="Действие"
          defaultValue={getNameFromList(intervalActionList, intervalActionCode)}
          items={intervalActionList}
          onSelect={handleDropdownChange}
          error={error?.intervalActionCode}
          required
        />
        <button
          type="button"
          className="button button__negative button__negative__ghost"
          onClick={() => handleDeleteItem()}
        >
          Удалить элемент
        </button>
      </>
    );
  }
);

export default IntervalsItem;
