import { fetchFromApi } from "../service";
import { setProducerList, receiveProducer } from "../sync/producer";

export function requestProducer(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveProducer([data]))
	};
}


export function getProducerList(
  producerCode = null,
  searchString = null,
  callback = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/producer/filter/page";

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
      searchString,
      colListCode: "name",
      producerCode,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(setProducerList(data.data));

      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
}
