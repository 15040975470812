import { RECEIVE_COMPONENT_FUEL_TYPE } from "../actions/sync/componentFuelType";


const initialState = {
  current: "",
  list: [],
};

function componentFuelType(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT_FUEL_TYPE_LIST:
      return { ...state, list: action.payload };
    case RECEIVE_COMPONENT_FUEL_TYPE:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}

export const SET_COMPONENT_FUEL_TYPE_LIST = "SET_COMPONENT_FUEL_TYPE_LIST";
export const setComponentFuelTypeList = (payload) => {
  return {
    type: SET_COMPONENT_FUEL_TYPE_LIST,
    payload,
  };
};

export default componentFuelType;
