export const UPDATE_FUEL_TYPE = 'UPDATE_FUEL_TYPE';
export function updateFuelType(id) {
  return {
    type: UPDATE_FUEL_TYPE,
    id,
  };
}

export const CLEAR_FUEL_TYPE = 'CLEAR_FUEL_TYPE';
export function clearFuelType() {
  return {
    type: CLEAR_FUEL_TYPE,
  };
}
