function useDownloadFile() {
  function downloadFile(file, name) {
    const downloadUrl = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return downloadFile;
}

export default useDownloadFile;
