import { nanoid } from "nanoid";

const initialState = {
  conditionCode: null,
  list: [],
  fetchingStatus: null,
};

function conditionViscos(state = initialState, action) {
  switch (action.type) {
    case SET_CONDITION_VISCOS:
      return { ...state, list: [...action.payload] };
    case UPDATE_CONDITION_VISCOS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id.toString() !== action.payload.id) {
            return item;
          }

          return {
            ...item,
            [action.payload.field]: action.payload.value,
            actionType:
              item.actionType !== "C" && action.payload.field !== "intervalList"
                ? "U"
                : item.actionType,
          };
        }),
      };
    case ADD_NEW_CONDITION_VISCOS:
      return {
        ...state,
        list: [
          ...state.list,
          {
            id: nanoid(3),
            actionType: "C",
            viscoCode: null,
            conditionViscoIntervalCode: null,
            isNewVisco: true,
          },
        ],
      };
    case DELETE_CONDITION_VISCOS:
      const item = state.list.find((item) => item.id === action.payload);
      if (item.actionType === "C") {
        return {
          ...state,
          list: state.list.filter((item) => item.id !== action.payload),
        };
      }
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id !== action.payload) return item;
          return {
            ...item,
            actionType: "D",
          };
        }),
      };
    case SET_CV_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    case SET_CONDITION_CODE_FOR_CV:
      return { ...state, conditionCode: action.payload };
    case RESET_CONDITION_VISCOS:
      return initialState;
    default:
      return state;
  }
}

const SET_CONDITION_VISCOS = "SET_CONDITION_VISCOS";
const UPDATE_CONDITION_VISCOS = "UPDATE_CONDITION_VISCOS";
const ADD_NEW_CONDITION_VISCOS = "ADD_NEW_CONDITION_VISCOS";
const DELETE_CONDITION_VISCOS = "DELETE_CONDITION_VISCOS";
const SET_CV_FETCHING_STATUS = "SET_CI_FETCHING_STATUS";
const RESET_CONDITION_VISCOS = "RESET_CONDITION_VISCOS";
const SET_CONDITION_CODE_FOR_CV = "SET_CONDITION_CODE_FOR_CV";

export const setConditionViscos = (payload) => ({
  type: SET_CONDITION_VISCOS,
  payload,
});

export const updateConditionVisco = (payload) => ({
  type: UPDATE_CONDITION_VISCOS,
  payload,
});

export const addNewConditionVisco = (payload) => ({
  type: ADD_NEW_CONDITION_VISCOS,
  payload,
});

export const deleteConditionVisco = (payload) => ({
  type: DELETE_CONDITION_VISCOS,
  payload,
});

export const setCVFetchingStatus = (payload) => ({
  type: SET_CV_FETCHING_STATUS,
  payload,
});

export const resetConditionViscos = () => ({
  type: RESET_CONDITION_VISCOS,
});

export const setConditionCodeForCV = (payload) => ({
  type: SET_CONDITION_CODE_FOR_CV,
  payload,
});

export default conditionViscos;
