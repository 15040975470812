import { RECEIVE_INTERVAL_TYPE } from "../actions/sync/intervalType";

const initialState = {
	list: [],
};

function intervalType(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_INTERVAL_TYPE:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default intervalType;
