import React from 'react';
import './index.css';

import VisibleHeader from '../../redux/containers/visibleHeader';
import VisibleBreadCrumbs from '../../redux/containers/visibleBreadCrumbs';
import VisibleSelectorList from '../../redux/containers/visibleSelectorList';
import VisibleNotificationMessage from '../../redux/containers/visibleNotificationMessage';
import {useDocumentTitle} from "../../hooks/useDocumentTitle";

export function SelectionPage(props) {
  React.useEffect(()=>{
    props.loadCategory();
  }, [])
  useDocumentTitle('Поиск');
  return (
        <div className="selection-page">
          <VisibleHeader />
          <VisibleBreadCrumbs />
          <VisibleSelectorList />
          <VisibleNotificationMessage />
        </div>
  );
}
