import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import VerticalLine from "../../components/veritcalLine";
import ModalLinkWrapper from "../../components/modalLinkWrapper";
import ChipsField from "../../pureComponents/chipsField";
import MultiSelectBlock from "../../pureComponents/multiSelectBlock";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import { Waiter } from "../../components/waiter";
import { setSpecComponentGroup } from "../../redux/reducers/specComponentGroup";
import { addNewSpecOption } from "../../redux/reducers/specMenu";
import {
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import {
  deleteSpecComponentGroupFromServer,
  getSpecComponentGroup,
  putSpecComponentGroup,
} from "../../redux/actions/async/specComponentGroup";
import { getComponentGroupList } from "../../redux/actions/async/componentGroup";

function handleSpecComponentGroup(specComponentGroup, item) {
  const spec = specComponentGroup.find((spec) => spec.spec === item.code);

  if (!spec) {
    return [
      ...specComponentGroup,
      {
        id: nanoid(3),
        spec: item.code,
        name: item.name,
        actionType: "C",
      },
    ];
  }

  if (spec.actionType === "C") {
    return specComponentGroup.filter((spec) => spec.spec !== item.code);
  }

  // specs from server
  return specComponentGroup.map((spec) => {
    if (spec.spec !== item.code) return spec;

    return {
      ...spec,
      actionType: !spec.actionType ? "D" : null,
    };
  });
}

function deleteAllSpecComponentGroup(specComponentGroup) {
  return specComponentGroup
    .filter((spec) => spec.actionType !== "C")
    .map((spec) => ({ ...spec, actionType: "D" }));
}

const SpecComponentGroupPage = () => {
  const navigate = useNavigate();
  const { specCode } = useParams();

  const dispatch = useDispatch();

  const menuIsLoaded = useSelector((state) => state.specMenu.specCode !== "");
  const specMenu = useSelector((state) => state.specMenu);

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const [ComponentGroupList] = useSelector(
    (state) => [state.componentGroup.list],
    shallowEqual
  );
  const [isComponentGroupListLoading, setComponentGroupListLoading] =
    useState(false);

  const [specComponentGroup, fetchingStatus] = useSelector(
    (state) => [
      state.specComponentGroup.data,
      state.specComponentGroup.fetchingStatus,
    ],
    shallowEqual
  );
  const isDeleteButtonDisabled = specComponentGroup.every(
    (item) => item.actionType === "C"
  );

  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const filteredSpecComponentGroup = specComponentGroup.filter(
    (spec) => spec.actionType !== "D"
  );
  const filteredComponentGroupList = ComponentGroupList.map((spec) => {
    return {
      ...spec,
      checked: filteredSpecComponentGroup.some(
        (item) => item.spec === spec.code
      ),
    };
  });

  useEffect(() => {
    dispatch(getComponentGroupList());
  }, []);

  useEffect(() => {
    if (menuIsLoaded) {
      const option = "spec-component-group";
      const optionName = "Группы узла";

      if (specMenu.children.some((item) => item.code === option)) return;

      dispatch(
        addNewSpecOption({
          option,
          optionName,
        })
      );
    }
  }, [menuIsLoaded]);

  useEffect(() => {
    if (!specCode) return;

    dispatch(getSpecComponentGroup(specCode));
  }, [specCode]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitSpecs));
  }, [JSON.stringify(specComponentGroup)]);

  function onSearch(value) {
    setComponentGroupListLoading(true);
    dispatch(
      getComponentGroupList(value, () => setComponentGroupListLoading(false))
    );
  }

  function onSelectSpec(item) {
    const newSpecComponentGroup = handleSpecComponentGroup(
      specComponentGroup,
      item
    );
    dispatch(setSpecComponentGroup(newSpecComponentGroup));

    if (formErrors.specComponentGroup) {
      setErrors({
        specComponentGroup: null,
      });
    }

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function deleteSpecSpec(id) {
    const specSpec = specComponentGroup.find((spec) => spec.id === id);

    if (specSpec.actionType === "C") {
      dispatch(
        setSpecComponentGroup(
          specComponentGroup.filter((spec) => spec.id !== id)
        )
      );
      return;
    }

    dispatch(
      setSpecComponentGroup(
        specComponentGroup.map((spec) => {
          if (spec.id !== id) return spec;

          return {
            ...spec,
            actionType: "D",
          };
        })
      )
    );

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  function clearAllSpecComponentGroup() {
    const newSpecComponentGroup =
      deleteAllSpecComponentGroup(specComponentGroup);
    dispatch(setSpecComponentGroup(newSpecComponentGroup));

    if (!dataChanged) {
      dispatch(updateAllChg(true));
    }
  }

  const handleDeleteSpecComponentGroup = () => {
    setDeleteModalActive(true);
  };

  const deleteSpecComponentGroup = () => {
    dispatch(
      deleteSpecComponentGroupFromServer(specCode, () => {
        navigate(`/spec/${specCode}`, {
          state: {
            actionType: "edit",
          },
        });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  function submitSpecs() {
    const isFormValid = filteredSpecComponentGroup.length;

    if (isFormValid) {
      return dispatch(putSpecComponentGroup());
    } else {
      setErrors({
        specComponentGroup: "Поле не заполнено",
      });
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    submitSpecs();
  };

  if (fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset
          className="content-general__form"
          disabled={fetchingStatus === "update"}
        >
          <div className="content-general__header">
            <div className="main-header-block__item main-header-block__title">
              Группа узла
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={isDeleteButtonDisabled}
              onClick={handleDeleteSpecComponentGroup}
            >
              <div
                className={`control__img control__img_trash${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
            >
              <div
                className={`control__img control__img_history${
                  isDeleteButtonDisabled ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
          </div>
          <div className="main-form-block">
            <div className="main-form-block__specs">
              <div className="chips-field-block">
                <ChipsField
                  id="specComponentGroup"
                  label="Группы Узла"
                  items={filteredSpecComponentGroup}
                  clearField={clearAllSpecComponentGroup}
                  deleteChipsItem={deleteSpecSpec}
                  error={formErrors.specComponentGroup}
                  required
                />
              </div>
              <div className="multiselect-block">
                <MultiSelectBlock
                  items={filteredComponentGroupList}
                  isLoading={isComponentGroupListLoading}
                  onSearch={onSearch}
                  onItemSelect={onSelectSpec}
                  isSelectAllAvailable={false}
                />
              </div>
            </div>
          </div>
          <div className="content-general__footer">
            <div className="main-footer-block__buttons">
              <ModalLinkWrapper
                linkProps={{ to: "/specs", state: { pageCode: 50, withoutReset: true } }}
                className="button button__secondary button__secondary__ghost"
              >
                Вернуться к списку спецификаций
              </ModalLinkWrapper>
              <button type="submit" className="button button__primary" disabled={!dataChanged}>
                Сохранить изменения
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <DeleteModal
        active={deleteModalActive}
        title="Удалить все объекты привязки?"
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteSpecComponentGroup}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"spec_component_group"}
        rowCode={specCode}
        listType={"hist"}
      />
    </>
  );
};

export default SpecComponentGroupPage;
