import {fetchFromApi} from "../redux/actions/service";

export async function getHTTPData(url, method, body) {
  return await fetchFromApi(url, method,{body})
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log(e);
    });
}
