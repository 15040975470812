function normalizeConditionSpecsForServer(specs, condition) {
  return {
    data: specs
      .filter((item) => item.actionType)
      .map((item) => ({
        condition,
        spec: item.spec,
        id: item.actionType === "C" ? null : item.id,
        actionType: item.actionType,
      })),
  };
}

export default normalizeConditionSpecsForServer;
