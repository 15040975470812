const initialState = {
  current: "",
  list: [],
};

function marketList(state = initialState, action) {
  switch (action.type) {
    case SET_MARKET_LIST:
      return { ...state, list: action.payload };
    default:
      return state;
  }
}

const SET_MARKET_LIST = "SET_MARKET_LIST";
export const setMarketList = (payload) => ({
  type: SET_MARKET_LIST,
  payload,
});

export default marketList;
