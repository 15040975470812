import { RECEIVE_VEHICLE_CATEGORY } from "../actions/sync/vehicleCategory";

const initialState = {
	list: [],
};

function vehicleCategory(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_VEHICLE_CATEGORY:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default vehicleCategory;
