import React from "react";
import Modal from "../index";

const ReplaceModelComplectFileModal = ({active, closeModal, onReplace, onCreateNew}) => {
  return (
    <Modal active={active} closeModal={closeModal}>
      <div className="modal__header">
        <h4>
          Файл с таким именем уже существует.
          <br />
          Заменить или создать новый?
        </h4>
        <button className="modal-button button button__primary" onClick={onReplace}>
          Заменить
        </button>
      </div>
      <hr className="horizontal-line" />
      <div className="modal__buttons">
        <button className="modal-button button button__primary" onClick={onCreateNew}>
          Создать новый
        </button>
        <button className="modal-button button button__negative button__negative__outline" onClick={closeModal}>
          Отмена
        </button>
      </div>
    </Modal>
  );
};

export default ReplaceModelComplectFileModal;
