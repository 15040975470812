import React from "react";
import "./index.scss";

const FileUploader = ({
  onFileUpload,
  disabled = false,
}) => {
  const [drag, setDrag] = React.useState(false);

  const handleFileUpload = (e) => {
    onFileUpload(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDrag(false);
      onFileUpload(e.dataTransfer.files);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDrag(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDrag(false);
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={`file-uploader${drag ? " file-uploader--drag" : ""}${
        disabled ? " file-uploader--disabled" : ""
      }`}
    >
      <div className="file-uploader__container">
        <div className="file-uploader__content">
          <div className="file-uploader__title">Загрузка файлов</div>
          <div className="file-uploader__text">
            Выберите файл или перетащите его в эту область
          </div>
        </div>
        <input
          type="file"
          id="file-upload"
          onChange={handleFileUpload}
          disabled={disabled}
        />
        <label
          htmlFor="file-upload"
          className="button button__small button__secondary button__secondary__outline"
        >
          Выбрать
        </label>
      </div>
    </div>
  );
};

export default FileUploader;
