import { SET_COMPONENT_TYPE_LIST, RECEIVE_COMPONENT_TYPE} from "../actions/sync/componentType";

const initialState = {
  current: "",
  list: [],
};

function componentType(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT_TYPE_LIST:
      return { ...state, list: action.payload };
    case RECEIVE_COMPONENT_TYPE:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}

export default componentType;






