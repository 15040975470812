import { setSpecList, setSpecListLoading } from "../../reducers/specList";
import { fetchFromApi } from "../service";

export function getSpecList(searchString = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/spec/filter/page";

    const body = {
      lang,
      hidden: true,
      offset: "0",
      limit: 100,
      colListCode: "name",
      searchString,
    };

    try {
      dispatch(setSpecListLoading(true));

      const response = await fetchFromApi(url, "POST", {
        body,
      });

      const data = await response.json();

      dispatch(setSpecList(data.data));
      dispatch(setSpecListLoading(false));
    } catch (error) {
      console.log(error.message);
      dispatch(setSpecListLoading(false));
    }
  };
}
