import { receiveIntervalType } from "../sync/intervalType";
export function requestIntervalType(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveIntervalType([data]))
	};
}
