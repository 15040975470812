import { RESET_APP } from '../actions/sync/init';
import {
  CLEAR_MODEL_GENERATION,
  RECIVE_MODEL_GENERATION,
  UPDATE_MODEL_GENERATION,
} from '../actions/sync/modelGeneration';
import {
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  CLEAR_SEARCH_RESULT,
} from '../actions/sync/search';

const defState = { current: 'def', list: [] };

function modelGeneration(state = defState, action) {
  switch (action.type) {
    case RECIVE_MODEL_GENERATION:
      if(action.json.length === 1){
        return Object.assign({}, state, { list: action.json, current: action.json.code });
      }else{
        return Object.assign({}, state, { list: action.json });
      }
    case RECIVE_SEARCH_BY_STRING_RESULT:
      if (Object.keys(action.json).length === 0) {
        return state;
        // return defState;
      }
      return Object.assign({}, state, {
        list: action.json.modelGeneration,
        current: 'def',
      });
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      if (Object.keys(action.json).length === 0) {
        return state;
        // return defState;
      }
      if (action.json.modelGeneration.length === 1) {
        // return state;
        return Object.assign({}, state, {
          list: action.json.modelGeneration,
          current: action.json.modelGeneration[0].code,
        });
      }
      return Object.assign({}, state, { list: action.json.modelGeneration });
    case UPDATE_MODEL_GENERATION:
      return Object.assign({}, state, { current: action.id });
    case CLEAR_MODEL_GENERATION:
      return defState;
    case RESET_APP:
      return defState;
    case CLEAR_SEARCH_RESULT:
      return defState;
    default:
      return state;
  }
}

export default modelGeneration;
