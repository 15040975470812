import { object, string, array } from "yup";

const messages = {
  required: "Поле не заполнено",
};

const conditionViscosSchema = array().of(
  object().shape({
    id: string(),
    actionType: string().nullable(),
    viscoCode: string().required(messages.required),
    conditionViscoIntervalCode: string().required(messages.required),
  })
);

export default conditionViscosSchema;
