import { fetchFromApi, isCashedLocale } from "../service";
import {
  reciveCategories,
  setDefaultCategory,
  updateCategory,
} from "../sync/category";
import { clearEngineCap } from "../sync/engineCap";
import { clearFuelType } from "../sync/fuelType";
import { clearModelList } from "../sync/model";
import { clearModelClass } from "../sync/modelClass";
import { clearModelGeneration } from "../sync/modelGeneration";
import { clearModelMark, clearModelMarkList } from "../sync/modelMark";
import { dropSearchResult } from "../sync/search";
import { clearSubcategory, clearSubcategoryList } from "../sync/subcategory";
import {
  selectSubcategory,
} from '../async/subcategory';
import { clearYear } from "../sync/years";
import { requestSearchByFilterResult } from "./search";
import { requestSubcategories } from "./subcategory";

export function getCategoryIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    const category = state.i18.phrases.categories;
    const locale = state.i18.current;
    if (!isCashedLocale(category, locale)) {
      dispatch(requestCategories());
    }
  };
}

export function requestCategories() {
  return (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const body = {
      lang: locale,
    };

    return fetchFromApi("api/v1/model-category/filter", "POST", { body })
      .then((res) => {
        if (res && res.ok && res.status === 200) {
          return res.json();
        }
      })
      .then((res) => {
        dispatch(reciveCategories(res, locale));
      })
      .then(() => {
        dispatch(setDefaultCategory());
      })
      .then(() => {
        dispatch(clearSubcategory());
      })
      .then(() => {
        dispatch(requestSubcategories());
      })
      .then(() => {
        dispatch(clearModelMark());
      })
      .then(() => {
        dispatch(clearModelGeneration());
      })
      .then(() => {
        dispatch(clearModelList());
      })
      .then(() => {
        dispatch(clearEngineCap());
      })
      .then(() => {
        dispatch(clearYear());
      })
  };
}

// TODO: refactoring
export function requestCategoriesV2() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;

    const body = {
      lang: locale,
      hidden: true,
    };

    try {
      const response = await fetchFromApi(
        "api/v1/model-category/filter",
        "POST",
        { body }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(reciveCategories(data));
      dispatch(clearSubcategoryList());
      dispatch(clearModelMarkList());
      dispatch(clearModelGeneration());
    } catch (error) {
      console.log(error.message);
    }
  };
}

export function selectCategory(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateCategory(id));
      res();
    })
      .then(() => {
        dispatch(clearSubcategory());
        dispatch(clearModelMark());
        dispatch(clearModelGeneration());
        dispatch(clearModelList());
        dispatch(clearYear());
        dispatch(clearEngineCap());
      })
      .then(() => {
        dispatch(requestSubcategories());
      });
  };
}

export function filterByCategory(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateCategory(id));
      res();
    }).then(() => {
      dispatch(requestSearchByFilterResult());
    });
  };
}

export function dropCategory() {
  return (dispatch) => {
    return (
      new Promise((res) => {
        dispatch(dropSearchResult());
        res();
      })
        // .then(() => {
        //   dispatch(clearSubcategory());
        // })
        .then(() => {
          dispatch(requestCategories());
        })
        .then(() => {
          dispatch(clearYear());
        })
        .then(() => {
          dispatch(clearFuelType());
        })
        .then(() => {
          dispatch(clearEngineCap());
        })
        .then(() => {
          dispatch(clearModelClass());
        })
    );
  };
}
