import { receiveModelClasses, updateModelClass } from "../sync/modelClass";
import { requestSearchByFilterResult } from "./search";
import { fetchFromApi } from "../service";

export function selectModelClass(id){
    return(dispatch)=>{
        return new Promise(res=>{
            dispatch(updateModelClass(id))
            res();
        }).then(()=>{
            dispatch(requestSearchByFilterResult())
        })
    }
}

export function requestModelClasses() {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        const url = "api/v1/model-class/filter/page";

        const body = {
        lang: locale,
        hidden: true,
        offset: "0",
        limit: 100,
        };

        try {
        const response = await fetchFromApi(url, "POST", { body });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();

        dispatch(receiveModelClasses(data.data));
        } catch (error) {
        console.log(error.message);
        }
    };
}
