import { connect } from 'react-redux';
import { ComponentsList } from '../../components/componentsList';


const mapStateToProps = (state) => {
  return {
    data: state.components.all_ids,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const VisibleComponentsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentsList);

export default VisibleComponentsList;
