import React from "react";
import "./index.scss";

const RadioButton = ({ code, name = null, checked = false, onChange }) => {
  return (
    <div className="radio-button__wrapper">
      <label className="radio-button">
        <input
          type="radio"
          id={code}
          checked={checked}
          onChange={(e) => onChange(e)}
        />
        <span className="custom-radio"></span>
      </label>
      <label htmlFor={code} className="radio-button__label">
        {name}
      </label>
    </div>
  );
};

export default RadioButton;
