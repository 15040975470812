import { useEffect } from "react";
import VisibleHeader from "../../redux/containers/visibleHeader";
import { useLocation } from "react-router-dom";
import VisibleSide from "../../redux/containers/visibleSide";
import VisableGuidesPageControls from "../../redux/containers/visibleGuidesPageControls";
import VisableGuidesPageSubcontrols from "../../redux/containers/visibleGuidesPageSubcontrols";
import VisibleTable from "../../redux/containers/visibleTable";
import "./index.css";
import { requestGuideTableBody, selectGuide } from "../../redux/actions/async/guides";
import { useDispatch, useSelector } from "react-redux";
import VisibleNotificationMessage from "../../redux/containers/visibleNotificationMessage";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { resetGuidesPage, waitForGuideData } from "../../redux/actions/sync/guides";

export function GuidesPage(props) {
  const dispatch = useDispatch();

  const { state } = useLocation();
  const currentGuideCode = state?.pageCode;
  const withoutReset = state?.withoutReset || false;

  const guide = useSelector((state) => state.guides.list)
    .filter((el) => el.id === currentGuideCode)[0];
  const parentGuideCode = guide?.parent;

  // TODO: find and replace all "def" values to null
  const isGuideSelected = useSelector(state => state.guides.current !== "def")

  useDocumentTitle(guide?.pageTitle || 'Справочники');

  useEffect(() => {
    if (!currentGuideCode) {
      dispatch(resetGuidesPage());
      return;
    };

    if (withoutReset && isGuideSelected) {
      dispatch(waitForGuideData(true));
      dispatch(requestGuideTableBody());
      return;
    }

    dispatch(selectGuide(currentGuideCode, parentGuideCode));
  }, [currentGuideCode]);

  return (
    <div className="guides-page">
      <VisibleHeader />
      <div className="guides-page__content">
        <VisibleSide />
        <div className="guides-page__workplace">
          <VisableGuidesPageControls />
          {/* <VisableGuidesPageTable /> */}
          <VisibleTable id="guides" zebra={false} lastColumn="checkbox" />
          <VisableGuidesPageSubcontrols />
          <VisibleNotificationMessage />
        </div>
      </div>
    </div>
  );
}
