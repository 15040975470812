import React from "react";
import "./index.scss";

function InputSearchField(
  { id, value, placeholder = "Введите значение", onChange, disabled = false },
) {
  const handleOnClear = () => {
    onChange(id, "");
  };

  const handleOnChange = (e) => {
    onChange(id, e.target.value);
  };

  return (
    <div className="input-block">
      <label htmlFor={id} className="sr-only"></label>
      <input
        value={value}
        id={id}
        placeholder={placeholder}
        className="input-block__field"
        onChange={(e) => handleOnChange(e)}
        disabled={disabled}
      />
      {disabled || (
        <div className="input-block__icons">
          {value.length !== 0 && (
            <button
              type="button"
              className="button button__secondary button__secondary__ghost input-block__icon"
              tabIndex={0}
              onClick={() => handleOnClear()}
            >
              <div className="clear-field-icon"></div>
            </button>
          )}

          <div className="input-block__icon">
            <div className="search-icon"></div>
          </div>
        </div>
      )}
    </div>
  );
}

InputSearchField = React.memo(InputSearchField);

export default InputSearchField;
