import React, { useMemo, useState } from "react";
import "./index.css";
import getNameFromList from "../../utils/getNameFromList";
import { TextAreaItem } from "../textAreaItem";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import FormInputField from "../../pureComponents/formInputField";

export function FieldWrapper(props) {
  const sizeClass = " field-wrapper_" + props.size;
  const visibleClass = props.role === "help" ? " field-wrapper_hide" : "";
  let child;
  const [searchString, setSearchString] = useState('');

  const handleDropdownSearch = async (id, searchString) => {
         setSearchString(searchString)
  };

  const getItems = () => {
    if(!searchString){
      return props.data
    }
    else {
      return props.data.filter(item => item.name.toLowerCase().includes(searchString))
    }
  }

  const items = useMemo(() => getItems(), [props.data, searchString])

  if (props.type === "selector") {
    const searchable = !!props?.searchable && props.data.length
    child = (
      /*<SelectorItem
            clearButton={props.clearButton}
            data={props.data}
            onChange={props.onChange}
            value={props.value}
            mode={props.mode}
            priority={props.priority}
            onDrop={props.onDrop}
            blocked={props.blocked}
          />*/
      <DropdownSelect
        id={props.id}
        items={items}
        label={props.name}
        defaultValue={getNameFromList(items, props.value)}
        value={props.value}
        onSelect={(val) =>
          val && val.code ? props.onChange(val.code) : props.onDrop()
        }
        required={props.required}
        searchable={searchable}
        onSearch={handleDropdownSearch}
        isLoading={false}
        isClearEnabled={!props.required}
        isErrorsEnabled={false}
      />
    );
  } else if (props.type === "input") {
    child = (
      // <InputItem value={props.value} onChange={props.onChange} onBlur={props.onBlur} />;
      <FormInputField
        id={props.id}
        required={props.required}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
        label={props.name}
        isErrorsEnabled={false}
      />
    );
  } else {
    child = <TextAreaItem value={props.value} onChange={props.onChange} />;
  }
  return (
    <div className={"field-wrapper" + sizeClass + visibleClass}>{child}</div>
  );
}
