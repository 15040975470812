class HttpError extends Error {
  constructor(status, message, body = null) {
    super(message);
    this.status = status;
    this.body = body;
    this.name = "HttpError";
  }
}

export default HttpError;
