import { connect } from "react-redux";
import {SelectionPage} from "../../pages/selection";
import { getCategoryIfNeeded } from "../actions/async/category";
import {
    toggleExpandAllProducts,
  } from '../actions/sync/expandAllProducts';

  
const mapStateToProps = (state, ownProps) =>{
    return{
        
        
    }
}

const mapDispatchToProps = (dispatch) => {
    const initialExpandAllProducts =  JSON.parse(localStorage.getItem("isExpandAllProducts"))

    return {
        loadCategory: ()=>{
        dispatch(getCategoryIfNeeded())
        dispatch(toggleExpandAllProducts(initialExpandAllProducts))
        }
       
    }
}

const VisibleSelectionPage = connect(mapStateToProps, mapDispatchToProps)(SelectionPage);

export default VisibleSelectionPage;