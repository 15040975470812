import React, {useEffect, useState} from 'react';
import './index.scss';

import {useDispatch, useSelector} from 'react-redux'
import {useMultiLanguageList} from "../../hooks/useMultiLanguageList";
import iconClose from "../../theme/svg/close_icon.svg";
import MultiLanguageForm from "../../components/multiLanguageForm";
import {requestVisco} from "../../redux/actions/async/visco";
import {fetchFromApi} from "../../redux/actions/service";
import {initNotificationMessage, showNotificationMessage} from "../../redux/actions/sync/notificationMessage";
import {API} from "../viscosity/api";
import createNameObject from "../../utils/createNameObject";
import ConfirmModal from "../../pureComponents/modal/confirmModal";
import guidsFormViscositySchema from "../../validations/guidsFormViscosity";
import CodeInputField from "../../pureComponents/codeInputField";
import FormInputField from "../../pureComponents/formInputField";
import getNameFromList from "../../utils/getNameFromList";
import updateDefaultLangName from "../../utils/updateDefaultLangName";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import {requestGuideTableBody} from "../../redux/actions/async/guides";
import {waitForGuideData} from "../../redux/actions/sync/guides";

const initialState = {
    id: null,
    code: null,
    name: null,
    viscoClassification: null,
    plasId: null,
    olyaId: null,
    hidden: false
}
const avaliableFields = {
    code: 'code',
    name: 'name',
    viscoClassification: 'viscoClassificationCode',
    plasId: 'plasId',
    olyaId: 'olyaId',
    hidden: 'hidden'
}

export function ViscoGuideForm(props) {
    const state = useSelector(state => state );
    const store = useSelector(state => state.visco.list[0] );
    const dispatch = useDispatch();

    const [active_modal, setActive_modal] = useState(null);
    const [fields, setFields] = useState(initialState);
    const [errors, setErrors] = useState([]);
    const [names, setNames] = useState([]);
    const [changes, setChanges] = useState(false);
    const [viscoClassifications, setViscoClassifications] = useState([]);
    const [changedFields, setChangedFields] = useState([]);

    const {
        multiLanguageFormData,
        multiLanguageFormErrors,
        multiLanguageFormDataChanged,
        updateLanguage,
        updateLanguageName,
        addNewLanguageName,
        deleteLanguageName,
        checkMultiLanguageFormIsValid,
        validateMultiLanguageForm
    } = useMultiLanguageList(names);

    useEffect(() => {
        store.type === 'edit' && startEditCopy();
        store.type === 'copy' && startEditCopy();
        store.type === 'new' && startNew();
    },[]);

    useEffect(() => {
        if(multiLanguageFormDataChanged && !changes) {
            setChanges(true);
            updateChangedFields('name');
        }
    },[multiLanguageFormDataChanged, changes]);

    function updateChangedFields(id) {
        if (avaliableFields[id] && !changedFields.includes(avaliableFields[id])) {
            setChangedFields((prev) => [...prev, avaliableFields[id]]);
        }
    }

    function startEditCopy() {
        Promise.all([
                API.getVisco(props.data.currentRow),
                API.getViscoClassifications({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
                API.getCode()
            ]
        ).then((response) => {
            setViscoClassifications(response[1].data);
            setNames(response[0].name.value.filter(item => item.lang !== 'DEF'));
            setFields({ ...fields,
                id: store.type === 'copy' ? null : response[0]?.id,
                code: store.type === 'copy' ? response[2] : response[0]?.code ,
                name: response[0]?.name.value.filter(item => item.lang === 'DEF')[0]?.name,
                viscoClassification: response[0].viscoClassification,
                plasId: response[0]?.plasId,
                olyaId: response[0]?.olyaId,
                hidden: response[0]?.hidden || false
            })
            if (store.type === 'copy') {
                setChangedFields(Object.values(avaliableFields));
                setChanges(true);
            }
        })
    }

    function startNew() {
        Promise.all([
                API.getViscoClassifications({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
            ]
        ).then(response => {
            setViscoClassifications(response[0].data);
            createCode();
        });
    }

    function changeInput(event) {
        setChanges(true);
        updateChangedFields(event.target.id);
        if(event.target.id === 'hidden') {
            setFields({ ...fields, [event.target.id]: !fields.hidden });
        } else {
            setFields({ ...fields, [event.target.id]: event.target.value });
            setErrors(prev => ({...prev, [event.target.id]:null }));
        }
    }

    function changeDropdown(item, id) {
        setFields(prev => ({...prev, [id]:item.code }));
        setErrors(prev => ({...prev, [id]:null }));
        setChanges(true);
        updateChangedFields(id);
    }

    function createCode() {
        API.getCode().then((response) => {setFields({ ...fields,code: response })});
    }

    async function save() {
        const isFormValid = await guidsFormViscositySchema.isValid( fields,{abortEarly: false} );
        const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();
        if( isFormValid && isMultiLanguageFormValid ) {
            updateDefaultLangName(multiLanguageFormData, fields.name)
            saveChanges({
                id: fields.id,
                code: fields.code,
                name: createNameObject(fields.name, multiLanguageFormData),
                viscoClassificationCode: fields.viscoClassification,
                plasId: fields.plasId,
                olyaId: fields.olyaId,
                hidden: fields.hidden,
                changedFields: changedFields
            }).then();
        } else {
            if (!isFormValid) {
                guidsFormViscositySchema
                    .validate(fields, { abortEarly: false })
                    .catch((err) => {
                        const errors = err.inner.reduce((acc, error) => {
                            return {
                                ...acc,
                                [error.path]: error.message,
                            };
                        }, {});
                        setErrors(() => {
                            return {
                                ...errors,
                            };
                        });
                    });
            }
            if (!isMultiLanguageFormValid) {
                validateMultiLanguageForm();
            }
        }
     }

    async function saveChanges(body) {
        await fetchFromApi("api/v1/visco","PUT",{body})
            .then((res) => {
                switch (res.status) {
                    case 200:
                        dispatch(waitForGuideData(true));
                        dispatch( requestVisco(false, null, res.id));
                        dispatch(requestGuideTableBody());
                        break;
                    case 409:
                        dispatch(
                            initNotificationMessage(
                                'Ошибка',
                                'Введенный код уже существует в системе',
                                'danger'
                            ));
                        dispatch(showNotificationMessage());
                        break;
                    default:
                        dispatch(
                            initNotificationMessage(
                                'Ошибка',
                                'Произошла системная ошибка. Обратитесь к администратору системы',
                                'danger'
                            ));
                        dispatch(showNotificationMessage());
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(
                    initNotificationMessage(
                        'Ошибка',
                        'Произошла системная ошибка. Обратитесь к администратору системы',
                        'danger'
                    )
                );
                dispatch(showNotificationMessage());
            });
    }

    function close() {
        if(!changes) {
            setFields(initialState);
            dispatch( requestVisco(false, null, null));
        } else {
            setActive_modal('modalTC__wrapper-active');
        }
    }

    function closeApprowal(value) {
        setActive_modal(null);
    }

    function onContinueWithoutSave(value) {
        setFields(initialState);
        setActive_modal(null);
        dispatch(requestVisco(false, null, null));
    }

    return (
        <div className={"model-guide__wrapper model-guide__wrapper_active"}>
            <div className="model-guide-block">
                {/* Header */}
                <div className="model-guide-block__header">
                    <div className="model-guide-block__header__name">{props.data.currentGuide}</div>

                    <div className="model-guide-block__control model-guide-block__control_elastic">
                        <CodeInputField
                            id={'code'}
                            label={'Код'}
                            disabled={store.type === 'edit'}
                            error={errors.code}
                            value={fields.code}
                            onChange={changeInput}
                            required={true}
                        />
                    </div>
                    <button
                        className={'button button__primary button__small'}
                        disabled={store?.type === 'edit'}
                        onClick={() => createCode()}>
                        Создать код
                    </button>
                    {/* X button */}
                    <div className="model-guide-block__close" onClick={() => close()}>
                        <img src={iconClose} alt="Закрыть" />
                    </div>
                </div>

                {/* Body */}
                <div className="model-guide-block__content">
                    <FormInputField
                        id='name'
                        label='Название'
                        value={fields.name}
                        error={errors.name}
                        onChange={changeInput}
                        required={true}
                    />
                    <DropdownSelect
                        id={'viscoClassification'}
                        label={'Классификация вязкости'}
                        defaultValue={getNameFromList(viscoClassifications, fields.viscoClassification)}
                        error={errors.viscoClassification}
                        items={viscoClassifications}
                        onSelect={changeDropdown}
                        required={true}
                    />
                    <FormInputField
                        id='plasId'
                        label='Идентификатор в PLAS'
                        value={fields.plasId}
                        error={errors.plasId}
                        onChange={changeInput}
                    />
                    <FormInputField
                        id='olyaId'
                        label='Идентификатор в OLYA'
                        value={fields.olyaId}
                        error={errors.olyaId}
                        onChange={changeInput}
                    />
                    <div className="model-guide-block__content__divider"></div>
                    <MultiLanguageForm
                        multiLanguageFormData={multiLanguageFormData}
                        multiLanguageFormDataChanged={multiLanguageFormDataChanged}
                        multiLanguageFormErrors={multiLanguageFormErrors}
                        updateLanguage={updateLanguage}
                        updateLanguageName={updateLanguageName}
                        addNewLanguageName={addNewLanguageName}
                        deleteLanguageName={deleteLanguageName}
                    />
                </div>

                <div className="model-guide-block__bottom">
                    <ToggleSwitch
                        id={'hidden'}
                        label={'Скрыть'}
                        onToggle={changeInput}
                        checked={fields.hidden}
                    />
                    <div className="buttons__group">
                        <div
                            className="button button__secondary button__secondary__ghost"
                            onClick={() => onContinueWithoutSave()}
                        >
                            Не сохранять
                        </div>

                        <button
                            disabled={!changes}
                            className={'button button__primary ' }
                            onClick={() => save()}
                        >
                            Сохранить изменения
                        </button>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <ConfirmModal
                active={active_modal}
                onSave={save}
                onContinueWithoutSave={onContinueWithoutSave}
                closeModal={closeApprowal}
            />
        </div>
    );
}