import React from 'react';
import './index.css';

export function InfoRow(props) {
  const items = props.data.map((el, i, mass) => {
    let className = 'info-row__item';
    className += i === mass.length - 1 ? ' info-row__item_no-border' : '';
    let name = el.name.length > 0 ? el.name + ': ' : '';
    let value = (el.value !== undefined) && (el.value !== null) ? el.value : '';

    return (
      <div className={className} key={i}>
        {name + value}
      </div>
    );
  });

  const classMod = props.mode === undefined ? '' : ' info-row_' + props.mode;

  return <div className={'info-row' + classMod}>{items}</div>;
}
