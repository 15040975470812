import {fetchFromApi} from '../service';
import { clearEngineCap } from '../sync/engineCap';
import { clearFuelType } from '../sync/fuelType';
import { clearModelClass } from '../sync/modelClass';
import {
  clearModelGeneration,
  reciveModelGeneration,
  updateModelGeneration,
} from '../sync/modelGeneration';
import { dropSearchResult } from '../sync/search';
import { clearYear } from '../sync/years';
import { requestSearchByFilterResult } from './search';

export function requestModelGeneration() {
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      lang: state.i18.current,
      modelSubcategoryCode: state.subcategory.current,
      modelMarkCode: state.modelMark.current,
    };

    return fetchFromApi("api/v1/model-generation/filter","POST",{body})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((json) => {
        dispatch(reciveModelGeneration(json));
      });
  };
}

export function requestModelGenerationV2(
  modelCode,
  modelCategoryCode,
) {
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      lang: state.i18.current,
      hidden: true,
      offset: 0,
      limit: 100,
      modelCode,
      modelCategoryCode,
    };

    return fetchFromApi("api/v1/model-generation/filter/page","POST",{body})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        dispatch(reciveModelGeneration(data.data));
      });
  };
}

export function selectModelGeneration(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateModelGeneration(id));
      res();
    }).then(() => {});
  };
}

export function filterByModelGeneration(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateModelGeneration(id));
      res();
    }).then(() => {
      dispatch(requestSearchByFilterResult());
    });
  };
}

export function dropModelGeneration() {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(clearModelGeneration());
      res();
    })
      .then(() => {
        dispatch(requestModelGeneration());
      })
      .then(() => {
        dispatch(clearYear());
      })
      .then(() => {
        dispatch(clearFuelType());
      })
      .then(() => {
        dispatch(clearEngineCap());
      })
      .then(() => {
        dispatch(clearModelClass());
      })
      .then(() => {
        dispatch(dropSearchResult());
      });
  };
}
