import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {
    getAsFuelType,
    getAsMark, getAsModel,
    getAsType,
    makeAutostatModelReport
} from "../../redux/actions/async/autostatModelReportFormSelectors";
import {
    resetAutostatModelReportFormState,
    updateAutostatModelReportForm
} from "../../redux/reducers/autostatModelReportForm";
import AutostatModelReportForm from "./autostatModelReportForm";
import useDownloadFile from "../../hooks/useDownloadFile";
import {useDocumentTitle} from "../../hooks/useDocumentTitle";


const AutostatModelReportPage = () => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.autostatModelReportForm );
    const [formErrors, setErrors] = useState({});
    const [processedList, setProcessedList] = useState({});
    const downloadFile = useDownloadFile();

    useDocumentTitle('Отчеты - Недостающие модели (парк РФ)');

    useEffect(() => {
        dispatch(getAsType());
        dispatch(getAsMark());
        dispatch(getAsModel());
        dispatch(getAsFuelType());
        return () => {
            dispatch(resetAutostatModelReportFormState());
        };
    }, []);

    const handleInputChange = useCallback(
        ( items, id ) => {
            dispatch( updateAutostatModelReportForm({ [items.target.id]: items.target.value }));
            setErrors(prev => ( {...prev, [items.target.id]: null }) );
        },[formData]
    );

    const handleButtonClick = useCallback(
        ( items, id ) => {
            dispatch(resetAutostatModelReportFormState());
        },[]
    );

    const handleDateChange = useCallback((value, id) => {
        dispatch(updateAutostatModelReportForm({[id]: value}))
    }, [])

    const onSubmit = (event) => {
        event.preventDefault();
        const currentYear = new Date().getFullYear();
        let hasError = false;

        if( !formData.selectedAsType.length > 0 ) {
            setErrors(prev => ( {...prev, selectedAsType: 'Значение обязательное'}) );
            hasError = true;
        }
        if( !formData.enteredAsCntFrom ) {
            setErrors(prev => ( {...prev, enteredAsCntFrom: 'Введите целое число'}) );
            hasError = true;
        }
        if( formData.enteredAsStartYear && (Number(formData.enteredAsStartYear) < 1900 || Number(formData.enteredAsStartYear) > currentYear )) {
            setErrors(prev => ( {...prev, enteredAsStartYear: 'Неверный год'}) );
            hasError = true;
        }
        if( formData.enteredAsEndYear && (Number(formData.enteredAsEndYear) < 1900 || Number(formData.enteredAsEndYear) > currentYear )) {
            setErrors(prev => ( {...prev, enteredAsEndYear: 'Неверный год'}) );
            hasError = true;
        }
        if( !hasError ) {
            dispatch(  makeAutostatModelReport( (file, name) => downloadFile(file, name)));
        }
    };

    const onDelete = (code,id) => {
        const newSelectedItems = formData[id].filter( (selectedItem) => selectedItem.code !== code );
        dispatch(updateAutostatModelReportForm({[id]:newSelectedItems}));
    };

    const onSearch = (searchString, id ) => {
        setProcessedList(prev => ({...prev, [id]:true}));
        switch (id) {
            case 'selectedAsModel':
                dispatch(getAsModel( searchString,() => setProcessedList(prev => ({...prev, [id]:false}))));
                break;
            case 'selectedAsMark':
                dispatch(getAsMark( searchString, () => setProcessedList(prev => ({...prev, [id]:false}))));
                break;
            default:
                return;
        }
    };

    const onSelect = (item, id) => {
        const isExist = formData[id].some((i) => i.code === item.code);
        if (!isExist) {
            const newSelectedItems = [...formData[id], item];
            dispatch(updateAutostatModelReportForm({[id]:newSelectedItems}));
        } else {
            onDelete(item.code, id);
        }
        setErrors(prev => ( {...prev, [id]: null }) );
    };

    const onClearField = (id) => {
        dispatch(updateAutostatModelReportForm({[id]:[]}));
    };

    const onSelectAll = (items, id) => {
        const isAllAdded = formData[id].length === items.length;
        if (!isAllAdded) {
            dispatch(updateAutostatModelReportForm({[id]:items}));
        } else {
            onClearField(id);
        }
    };

    return (
        <>
            <AutostatModelReportForm
                formErrors={formErrors}
                handleInputChange={handleInputChange}
                onSubmit={onSubmit}
                onDelete={onDelete}
                onSearch={onSearch}
                onSelect={onSelect}
                onClearField={onClearField}
                onSelectAll={onSelectAll}
                processedList={processedList}
                handleDateChange={handleDateChange}
                handleButtonClick={handleButtonClick}
            />
        </>
    );
};

export default AutostatModelReportPage;
