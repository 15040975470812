import React from "react";
import closeIcon from "../../../theme/svg/close_icon-small.svg";
import './index.scss'

const ChipsItem = ({ name = "Test", id = null, onDelete }) => {
  function handleOnClick(e) {
    e.stopPropagation();
    onDelete(id);
  }

  return (
    <div className="chips-item">
      <span className="chips-item__value">{name}</span>
      <button
        type="button"
        className="chips-item__icon"
        onClick={handleOnClick}
      >
        <img src={closeIcon} alt="Delete Chips Item" />
      </button>
    </div>
  );
};

export default ChipsItem;
