export const INIT_NOTIFICATION_MESSAGE = 'INIT_NOTIFICATION_MESSAGE';
export function initNotificationMessage(header, message, mode) {
  return {
    type: INIT_NOTIFICATION_MESSAGE,
    header,
    message,
    mode
  };
}

export const SHOW_NOTIFICATION_MESSAGE = 'SHOW_NOTIFICATION_MESSAGE';
export function showNotificationMessage() {
  return {
    type: SHOW_NOTIFICATION_MESSAGE,
  };
}

export const CLOSE_NOTIFICATION_MESSAGE = 'CLOSE_NOTIFICATION_MESSAGE';
export function closeNotificationMessage() {
  return {
    type: CLOSE_NOTIFICATION_MESSAGE,
  };
}
