import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createPortal } from "react-dom";
import FormInputField from "../../../pureComponents/formInputField";
import DropdownSelect from "../../../pureComponents/dropdownSelect";
import YearPicker from "../../../pureComponents/yearPicker";
import getNameFromList from "../../../utils/getNameFromList";
import {
  booleanFields,
  dropdownSelectFields,
  fieldsWithSearch,
  inputFields,
  textAreaFields,
  yearPickerFields,
} from "./fieldsMap";
import { API } from "./api";
import { TextAreaDescription } from "../../../components/textAreaDescription";
import { validateCode } from "../../../validations/code";

const ImportDataCellEditorModal = ({ active, close, field, updateCell }) => {
  const lang = useSelector((state) => state.i18.current);
  const booleanList = useSelector((state) => state.importFile.vocabulary);
  const [changedValue, setChangedValue] = useState(null);
  const [fieldList, setFieldList] = useState([]);
  const [listIsLoading, setListLoading] = useState(false);
  const [error, setError] = useState(null);
  let contentComponent;

  useEffect(() => {
    const getList = async () => {
      const body = {
        lang,
        limit: 100,
        offset: 0,
        hidden: true,
      };

      if (dropdownSelectFields.includes(field.code)) {
        body[field.code + "Code"] = field.value;
      }
      const data = await API.getList(field.code, body);

      if (Array.isArray(data)) {
        setFieldList(data);
      } else {
        setFieldList(data.data);
      }
    };

    if (active && dropdownSelectFields.includes(field.code)) {
      getList();
    }

    if (active) {
      setChangedValue({
        name: null,
        code: field.value,
      });
      setError(null);
    }
  }, [active]);

  const handleDropdownChange = useCallback(
    ({ code }, id) => {
      setChangedValue({ name: getNameFromList(fieldList, code), code });
    },
    [fieldList]
  );

  const handleDateChange = useCallback((value, id) => {
    setChangedValue({ name: null, code: value });
  }, []);

  const handleBooleanChange = useCallback(
    ({ code }, id) => {
      setChangedValue({
        name: getNameFromList(booleanList, code),
        code,
      });
    },
    [booleanList]
  );

  const handleDropdownSearch = useCallback(
    async (id, searchString) => {
      setListLoading(true);
      const body = {
        lang,
        limit: 100,
        offset: 0,
        hidden: true,
        colListCode: "name",
        searchString,
      };
      const data = await API.getList(field.code, body);

      if (Array.isArray(data)) {
        setFieldList(data);
      } else {
        setFieldList(data.data);
      }

      setListLoading(false);
    },
    [field.code]
  );

  const saveChanges = (e) => {
    if (booleanFields.includes(field.code)) {
      updateCell(field.id, field.code, {
        name: null,
        code: changedValue.code === "Y",
      });
      return;
    }

    if (field.code === "code" && !validateCode(changedValue.code)) {
      setError("Допускаются заглавные латинские буквы, цифры, символы - _");
      return;
    }

    updateCell(field.id, field.code, changedValue);
    setError(null);
    setFieldList([]);
  };

  if (inputFields.includes(field.code)) {
    contentComponent = (
      <FormInputField
        label={field.name}
        id={field.id}
        value={changedValue?.code || ""}
        onChange={(e) => setChangedValue({ name: null, code: e.target.value })}
        error={error}
      />
    );
  }

  if (dropdownSelectFields.includes(field.code)) {
    contentComponent = (
      <DropdownSelect
        label={field.name}
        id={field.id}
        items={fieldList}
        defaultValue={
          getNameFromList(fieldList, changedValue?.code) || changedValue?.name
        }
        onSelect={handleDropdownChange}
        searchable={fieldsWithSearch.includes(field.code)}
        onSearch={handleDropdownSearch}
        isLoading={listIsLoading}
      />
    );
  }

  if (booleanFields.includes(field.code)) {
    const fieldValue = field.value ? booleanList[1].code : booleanList[0].code;

    contentComponent = (
      <DropdownSelect
        label={field.name}
        id={field.id}
        items={booleanList}
        defaultValue={
          getNameFromList(booleanList, changedValue?.code) ||
          getNameFromList(booleanList, fieldValue)
        }
        onSelect={handleBooleanChange}
        required
      />
    );
  }

  if (yearPickerFields.includes(field.code)) {
    contentComponent = (
      <YearPicker
        id={field.id}
        label={field.name}
        value={changedValue?.code}
        onChangeData={handleDateChange}
      />
    );
  }

  if (textAreaFields.includes(field.code)) {
    contentComponent = (
      <TextAreaDescription
        label={field.name}
        id={field.id}
        value={changedValue?.code}
        onChange={(e) => setChangedValue({ name: null, code: e.target.value })}
      />
    );
  }

  return createPortal(
    <div
      className={"dialog" + (active ? " dialog__active" : "")}
      onMouseDown={close}
    >
      <div className="dialog__modal" onMouseDown={(e) => e.stopPropagation()}>
        <div className="dialog__modal__content">{contentComponent}</div>
        <div className="dialog__modal__bottom">
          <div className="button button__secondary__ghost" onClick={close}>
            Не сохранять
          </div>
          <button
            className="button button__primary"
            onClick={saveChanges}
            disabled={active && !changedValue}
          >
            Сохранить изменения
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-container")
  );
};

export default ImportDataCellEditorModal;
