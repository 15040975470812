const initialState = {
  productCode: null,
  data: [],
  fetchingStatus: null,
};

function productSpecs(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_CODE_FOR_PS:
      return { ...state, productCode: action.payload };
    case SET_PRODUCT_SPECS:
      return { ...state, data: action.payload };
    case UPDATE_PRODUCT_SPEC:
      return action.payload.actionType === "C"
        ? {
            ...state,
            data: state.data.filter((item) => item.id !== action.payload.id),
          }
        : {
            ...state,
            data: state.data.map((item) => {
              if (item.id !== action.payload.id) return item;

              return {
                ...item,
                actionType: action.payload.actionType,
              };
            }),
          };
    case SET_PRODUCT_SPECS_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };
    default:
      return state;
  }
}

const SET_PRODUCT_CODE_FOR_PS = "SET_PRODUCT_CODE_FOR_PS";
const SET_PRODUCT_SPECS = "SET_PRODUCT_SPECS";
const UPDATE_PRODUCT_SPEC = "UPDATE_PRODUCT_SPEC";
const SET_PRODUCT_SPECS_FETCHING_STATUS =
  "SET_PRODUCT_SPECS_FETCHING_STATUS";

export const setProductCodeForPS = (payload) => ({
  type: SET_PRODUCT_CODE_FOR_PS,
  payload,
});

export const setProductSpecs = (payload) => ({
  type: SET_PRODUCT_SPECS,
  payload,
});

export const addProductSpec = (payload) => ({
  type: SET_PRODUCT_SPECS,
  payload,
});

export const updateProductSpec = (payload) => ({
  type: SET_PRODUCT_SPECS,
  payload,
});

export const setProductSpecsFetchingStatus = (payload) => ({
  type: SET_PRODUCT_SPECS_FETCHING_STATUS,
  payload,
});

export default productSpecs;
