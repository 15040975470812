import React from 'react';
import VisibleSvProductListItem from '../../redux/containers/visibleSvProductListItem';
import VisibleSvProductListItemAlways from '../../redux/containers/visibleSvProductListItemAlways';
import cn from 'classnames'
import {useDispatch} from "react-redux";
import {
  toggleExpandAllProducts,
} from '../../redux/actions/sync/expandAllProducts';

import './index.css';

export function SvProductList(props) {
  const { expandAllProducts } = props
  const dispatch = useDispatch();
  const alwaysVisibleItems = props.products.map((el, i) => { 
    {if(expandAllProducts || i < 3) { 
    return <VisibleSvProductListItemAlways id={el} key={i} />}
  }}).filter(product => product);


 const headerCaption = !!alwaysVisibleItems.length ? alwaysVisibleItems  :  "не найдено"
 const moreItemsCounter = props?.products?.length - alwaysVisibleItems?.length
 const showExpandButton = (!!moreItemsCounter || expandAllProducts) && !!alwaysVisibleItems.length
 const showMoreCounter = !!moreItemsCounter && !expandAllProducts


  const list__body_mod = expandAllProducts ? ' product-list__body_active' : '';

const handleExpandAllProducts = () => {
  dispatch(toggleExpandAllProducts(!expandAllProducts));
}


  return (
    <div className="product-list">
    
      <div className="product-list__header">
        <div className={'pl-header__caption' + list__body_mod}>{headerCaption}
        {showMoreCounter && <span className="pl-header__caption__moreItemsCounter" onClick={handleExpandAllProducts}>+{moreItemsCounter}</span>}
        </div>

        {showExpandButton &&
        <div
          className="button button_24x24 button_active button_no-border"
          onClick={handleExpandAllProducts}>
          <div className={cn('image__arrow-down', {'rotatedArrow': expandAllProducts})}></div>
        </div>}
      </div>
    </div>
  );
}