export const SET_COMPONENT_TYPE_LIST = "SET_COMPONENT_TYPE_LIST";
export const setComponentTypeList = (payload) => {
  return {
    type: SET_COMPONENT_TYPE_LIST,
    payload,
  };
};


export const RECEIVE_COMPONENT_TYPE = 'RECEIVE_COMPONENT_TYPE'
export function receiveComponentType(payload) {
	return {
		type: RECEIVE_COMPONENT_TYPE,
		payload
	}
}

