const initialState = {
  specificationList: [],
  viscoList: [],
  catigoryList: []
};

const SET_STATISTIC_COVER_REPORT_FORM_SELECTORS = "SET_STATISTIC_COVER_REPORT_FORM_SELECTORS";

function statisticCoverReportFormSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_STATISTIC_COVER_REPORT_FORM_SELECTORS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const setStatisticCoverReportFormSelectors = (payload) => ({
  type: SET_STATISTIC_COVER_REPORT_FORM_SELECTORS,
  payload,
});

export default statisticCoverReportFormSelectors;
