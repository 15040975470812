import normalizeConditionAnalogList from "../../../normalizers/conditionAnalogList";
import { setConditionAnalogList } from "../../reducers/conditionAnalog";
import { fetchFromApi } from "../service";

export function getConditionAnalogList(conditionTypeCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;

    const body = {
      lang: locale,
      conditionTypeCode,
    };

    try {
      const response = await fetchFromApi("api/v1/condition/analog/filter", "POST", { body });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      const normalizedData = normalizeConditionAnalogList(data)

      dispatch(setConditionAnalogList(normalizedData));
    } catch (error) {
      console.log(error.message);
    }
  }
}