import { SET_PRODUCER_LIST, RECEIVE_PRODUCER} from "../actions/sync/producer";

const initialState = {
  current: '',
  list: [],
}

function producer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCER_LIST:
      return { ...state, list: action.payload };
    case RECEIVE_PRODUCER:
			return Object.assign({}, state, { list: action.payload }); 
    default:
      return state;
  }
}

export default producer