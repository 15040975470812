import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConditionViscos } from "../redux/actions/async/conditionViscos";

function useConditionViscos(conditionCode) {
  const dispatch = useDispatch();
  const { conditionViscos, fetchingStatus } = useSelector((state) => ({
    conditionViscos: state.conditionViscos.list.filter(
      (item) => item.actionType !== "D"
    ),
    fetchingStatus: state.conditionViscos.fetchingStatus,
  }));

  useEffect(() => {
    if (!conditionCode) return;

    dispatch(getConditionViscos(conditionCode));
  }, [conditionCode]);

  return {
    conditionViscos,
    fetchingStatus,
  };
}

export default useConditionViscos;
