import { useLayoutEffect, useState } from "react";
import { Waiter } from "../../../components/waiter";
import { useSelector } from "react-redux";
import VerticalLine from "../../../components/veritcalLine";
import ModalLinkWrapper from "../../../components/modalLinkWrapper";
import MultiLanguageForm from "../../../components/multiLanguageForm";
import CodeInputField from "../../../pureComponents/codeInputField";
import ToggleSwitch from "../../../pureComponents/toggleSwitch";
import FormInputField from "../../../pureComponents/formInputField";
import DropdownSelect from "../../../pureComponents/dropdownSelect";
import MenuComponent from "../../../pureComponents/menuComponent";
import PlusIcon from "../../../icons/PlusIcon";
import getNameFromList from "../../../utils/getNameFromList";
import YearPicker from "../../../pureComponents/yearPicker";

const ProductForm = ({
  formErrors,
  actionType,
  productCode,
  multiLanguageFormData,
  multiLanguageProps,
  handleDateChange,
  handleInputChange,
  handleDropdownChange,
  handleDeleteProduct,
  openHistoryChanges,
  onSubmit,
}) => {
  const productData = useSelector((state) => state.productForm);
  const { productBrandList, productPremiumList } = useSelector(
    (state) => state.productFormSelectors
  );

  const [defaultValues, setDefaultValues] = useState({});

  useLayoutEffect(() => {
    if (productData) {
      setDefaultValues(() => ({
        productBrand: getNameFromList(
          productBrandList,
          productData.productBrandCode
        ),
        productPremium: getNameFromList(
          productPremiumList,
          productData.productPremiumCode
        ),
      }));
    }
  }, [productData]);

  if (productData.fetchingStatus === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <form onSubmit={onSubmit}>
      <fieldset
        className="content-general__form"
        disabled={productData.fetchingStatus === "update"}
      >
        <div className="content-general__header">
          <div className="main-header-block">
            <div className="main-header-block__item main-header-block__title">
              Данные о продукте
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={actionType !== "edit"}
              onClick={handleDeleteProduct}
            >
              <div
                className={`control__img control__img_trash${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
              disabled={actionType !== "edit"}
            >
              <div
                className={`control__img control__img_history${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <div className="main-header-block__item main-header-block__code input-block input-block--flex">
              <CodeInputField
                id="code"
                label="Код"
                placeholder="Введите"
                value={productData.code ?? ""}
                error={formErrors.code}
                onChange={handleInputChange}
                required={true}
                disabled={actionType === "edit"}
              />
            </div>
            <VerticalLine size="24px" />
          </div>
          <div className="additional-header-block">
            <MenuComponent
              buttonName="Добавить ограничение"
              className="button button__small button__primary"
              disabled={actionType !== "edit"}
            >
              <ModalLinkWrapper
                linkProps={{ to: `/product/${productCode}/spec` }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Спецификации
              </ModalLinkWrapper>
              <ModalLinkWrapper
                linkProps={{ to: `/product/${productCode}/visco` }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Вязкости
              </ModalLinkWrapper>
              <ModalLinkWrapper
                linkProps={{ to: `/product/${productCode}/segment` }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Сегмент
              </ModalLinkWrapper>
              <ModalLinkWrapper
                linkProps={{ to: `/product/${productCode}/model-subcategory` }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Подкатегорию ТС
              </ModalLinkWrapper>
              <ModalLinkWrapper
                linkProps={{ to: `/product/${productCode}/model-mark` }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Марку ТС
              </ModalLinkWrapper>
              <ModalLinkWrapper
                linkProps={{ to: `/product/${productCode}/model` }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Модель ТС
              </ModalLinkWrapper>
              <ModalLinkWrapper
                linkProps={{
                  to: `/product/${productCode}/component-fuel-type`,
                }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Тип топлива
              </ModalLinkWrapper>
              <ModalLinkWrapper
                linkProps={{ to: `/product/${productCode}/component-group` }}
                className="menu-item"
              >
                <PlusIcon />
                Добавить Тип узла
              </ModalLinkWrapper>
            </MenuComponent>
          </div>
        </div>
        <div className="main-form-block">
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <FormInputField
                id="name"
                label="Название"
                placeholder="Введите"
                value={productData.name || ""}
                error={formErrors.name}
                onChange={handleInputChange}
                required={true}
              />
              <DropdownSelect
                label="Бренд"
                id="productBrandCode"
                items={productBrandList}
                defaultValue={defaultValues.productBrand}
                error={formErrors.productBrandCode}
                onSelect={handleDropdownChange}
                required
              />
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <div className="main-form-block__row">
                <YearPicker
                  id="startYear"
                  label="Год (начало)"
                  placeholder={"Выберите"}
                  value={productData.startYear}
                  onChangeData={handleDateChange}
                  maxDate={productData.endYear}
                />
                <YearPicker
                  id="endYear"
                  label="Год (окончание)"
                  placeholder={"Выберите"}
                  value={productData.endYear}
                  onChangeData={handleDateChange}
                  minDate={productData.startYear}
                />
              </div>
            </div>
            <hr className="horizontal-line" />
            <div className="main-form-block__column__block">
              <FormInputField
                id="priority"
                label="Приоритет"
                placeholder="Введите"
                value={productData.priority}
                error={formErrors.priority}
                onChange={handleInputChange}
                required={true}
                inputMask="positive-integer"
              />
              <DropdownSelect
                label="Премиальность продукта"
                id="productPremiumCode"
                items={productPremiumList}
                defaultValue={defaultValues.productPremium}
                error={formErrors.productPremiumCode}
                onSelect={handleDropdownChange}
                required
              />
            </div>
          </div>
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <MultiLanguageForm
                multiLanguageFormData={multiLanguageFormData}
                {...multiLanguageProps}
              />
            </div>
          </div>
        </div>
        <div className="content-general__footer">
          <div className="main-footer-block__toggles">
            <ToggleSwitch
              label="Скрыть"
              id="hidden"
              checked={productData.hidden}
              onToggle={handleInputChange}
            />
          </div>
          <div className="main-footer-block__buttons">
            <ModalLinkWrapper
              linkProps={{
                to: "/products",
                state: { pageCode: 40, withoutReset: true },
              }}
              className="button button__secondary button__secondary__ghost"
            >
              Вернуться к списку продуктов
            </ModalLinkWrapper>
            <button
              type="submit"
              className="button button__primary"
              disabled={productData.changedFields.length === 0}
            >
              Сохранить изменения
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default ProductForm;
