import { connect } from 'react-redux';
import { SvProductListItemAlwaysVisible } from '../../components/svProductListItemAlwaysVisible';


const mapStateToProps = (state, ownProps) => {
  return {
    data: state.svProduct.by_id[ownProps.id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const visibleSvProductListItemAlways = connect(
  mapStateToProps,
  mapDispatchToProps
)(SvProductListItemAlwaysVisible);

export default visibleSvProductListItemAlways;



