import { connect } from 'react-redux';
import { Side } from '../../components/side';
import { selectGuide } from '../actions/async/guides';
import { switchGroupVisable } from '../actions/sync/guides';

const mapStateToProps = (state, ownProps) => {
  const access = ownProps.access !== undefined ? ownProps.access : true;
  return {
    data: state.guides,
    access,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchGroupVisable: (id) => dispatch(switchGroupVisable(id)),
    setCurrentGuide: (id, parent) => dispatch(selectGuide(id, parent)),
  };
};

const VisibleSide = connect(mapStateToProps, mapDispatchToProps)(Side);

export default VisibleSide;
