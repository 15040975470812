import {fetchFromApi} from '../service';
import { reciveUserData } from '../sync/personal';
import { fetchingData } from '../sync/fetchingData';
import { logout } from './auth';

export function requestUserData() {
  return (dispatch, getState) => {
    dispatch(fetchingData('personal'));
    return fetchFromApi("api/v1/auth/me","GET",{})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }else{
          throw new Error("no user data");
        }
      })
      .then((res) => {
        dispatch(reciveUserData(res));
      })
      .catch((e) => dispatch(logout()));
  };
}
