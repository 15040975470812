import { getModels } from "../../../redux/actions/async/model";
import {
  deleteProductModelFromServer,
  getProductModel,
  putProductModel,
} from "../../../redux/actions/async/productModel";
import { setProductModel } from "../../../redux/reducers/productModel";

const model = {
  headerTitle: "Добавление Моделей ТС",
  list: "model",
  productList: "productModel",
  option: "model",
  optionName: "Модель ТС",
  historyTableName: "product_model",
  searchable: true,
  getList: (searchString = null, callback = null) =>
    getModels(searchString, callback),
  getData: (productCode) => getProductModel(productCode),
  setData: (productModel) => setProductModel(productModel),
  deleteData: (productCode, callback) =>
    deleteProductModelFromServer(productCode, callback),
  putData: () => putProductModel(),
};

export default model;
