import { setProductSpecLevelList } from "../../reducers/productSpecLevel";
import { fetchFromApi } from "../service";

export function getProductSpecLevelList() {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/product-spec-level/filter/page";

    const body = {
      lang,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await fetchFromApi(url, "POST", {
        body
      });

      const data = await response.json();

      dispatch(setProductSpecLevelList(data.data));
    } catch (error) {
      console.log(error.message);
    }
  };
}
