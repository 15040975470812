import {
  CLEAR_ENGINE_CAP,
  HENDLE_UPDATE_ENGINE_CAP,
  UPDATE_ENGINE_CAP,
  VALIDATE_ENGINE_CAP,
} from '../actions/sync/engineCap';
import { RESET_APP } from '../actions/sync/init';
import {
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  CLEAR_SEARCH_RESULT,
} from '../actions/sync/search';

const defState = {
  current: 'def',
  list: [],
  value: '',
  errorValue: false,
  verified: false,
};

function engineCap(state = defState, action) {
  switch (action.type) {
    case RECIVE_SEARCH_BY_STRING_RESULT:
      return Object.assign({}, state, getEngineCapList(action.json));
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      return Object.assign({}, state, getEngineCapList(action.json));
    case UPDATE_ENGINE_CAP:
      return Object.assign({}, state, { current: action.id });
    case CLEAR_ENGINE_CAP:
      return defState;
    case RESET_APP:
      return defState;
    case CLEAR_SEARCH_RESULT:
      return defState;
    case HENDLE_UPDATE_ENGINE_CAP:
      return Object.assign({}, state, { value: action.value });
    case VALIDATE_ENGINE_CAP:
      if (
        (String(parseInt(action.value)) === action.value && action.value > 0) ||
        action.value === ''
      ) {
        return Object.assign({}, state, {
          value: action.value,
          errorValue: false,
          verified: true,
        });
      } else {
        return Object.assign({}, state, {
          value: '',
          errorValue: true,
          verified: false,
        });
      }
    default:
      return state;
  }
}

function getEngineCapList(data) {
  if (Object.keys(data).length === 0 || !data.engineCap) {
    return defState;
  }
  const list = data.engineCap
    .filter((el) => el.code !== null)
    .map((el) => {
      return { code: el.code, name: el.name + ' ' + el.dimension };
    });
  if (list.length === 0) {
    return defState;
  }
  if (list.length === 1) {
    return { current: list[0].code, list: list };
  }
  return { list: list };
}

export default engineCap;
