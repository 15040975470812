const initialState = {
  catigoryList: [],
  subcatigoryList: [],
};

const SET_STATISTIC_REQUEST_REPORT_FORM_SELECTORS = "SET_STATISTIC_REQUEST_REPORT_FORM_SELECTORS";


function statisticRequestReportFormSelectors(state = initialState, action) {
  switch (action.type) {
    case SET_STATISTIC_REQUEST_REPORT_FORM_SELECTORS:
      return { ...state, ...action.payload };
    default:
      return state;
  }

}

export const setStatisticRequestReportFormSelectors = (payload) => ({
  type: SET_STATISTIC_REQUEST_REPORT_FORM_SELECTORS,
  payload,
});

export default statisticRequestReportFormSelectors;
