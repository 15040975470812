import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMultiLanguageList } from "../../hooks/useMultiLanguageList";
import { useGetNewCode } from "../../hooks/useGetNewCode";
import updateDefaultLangName from "../../utils/updateDefaultLangName";

import {
  resetSpecState,
  updateSpecChangedFields,
  updateSpecField,
} from "../../redux/reducers/spec";
import {
  resetCurrentPageFormState,
  updateAllChg,
  updateSubmitFunction,
} from "../../redux/actions/sync/currentPageFormState";
import {
  deleteSpecFromServer,
  getSpec,
  putSpec,
} from "../../redux/actions/async/spec";
import createNameObject from "../../utils/createNameObject";
import SpecPageForm from "./SpecPageForm";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import { getProducerList } from "../../redux/actions/async/producer";
import specSchema from "../../validations/spec";

const SpecPage = () => {
  const navigate = useNavigate();
  const { specCode } = useParams();
  const { state } = useLocation();
  const actionType = state ? state.actionType : "new";

  const dispatch = useDispatch();
  const specData = useSelector((state) => state.spec);
  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const {
    multiLanguageFormData,
    multiLanguageFormDataChanged,
    checkMultiLanguageFormIsValid,
    validateMultiLanguageForm,
    setMultiLanguageFormDataChanged,
    ...multiLanguageProps
  } = useMultiLanguageList(specData.names);

  const getNewCode = useGetNewCode(updateSpecField);
  const [formErrors, setErrors] = useState({});
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [historyChangesModalActive, setHistoryChangesModalActive] =
    useState(false);

  const [searchLoading, setSearchLoading] = useState({
    producerCode: false,
  });

  useEffect(() => {
    if (actionType === "new") {
      dispatch(getProducerList());
    }

    return () => {
      dispatch(resetSpecState());
      dispatch(resetCurrentPageFormState());
    };
  }, []);

  useEffect(() => {
    if (specCode && actionType !== "new") {
      dispatch(
        getSpec(specCode, actionType, () => {
          if (actionType === "copy") {
            getNewCode();
          }
        })
      );

      return;
    }

    // for new spec
    getNewCode();
    dispatch(
      updateSpecField({
        changedFields: ["code", "hidden"],
      })
    );
  }, [specCode, actionType]);

  useEffect(() => {
    dispatch(updateSubmitFunction(submitSpec));
  }, [specData, multiLanguageFormData]);

  useEffect(() => {
    if (multiLanguageFormDataChanged) {
      dispatch(updateSpecChangedFields("name"));
      dispatch(updateAllChg(true));
    }
  }, [multiLanguageFormDataChanged]);

  const handleInputChange = useCallback(
    ({ target: { id, type, value } }) => {
      if (type === "checkbox") {
        dispatch(
          updateSpecField({
            [id]: !specData[id],
          })
        );

        dispatch(updateSpecChangedFields(id));

        if (!dataChanged) {
          dispatch(updateAllChg(true));
        }
        return;
      }

      dispatch(
        updateSpecField({
          [id]: value,
        })
      );

      dispatch(updateSpecChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },
    [specData, actionType, dataChanged]
  );

  const handleDropdownChange = useCallback(
    ({ code }, id) => {
      dispatch(
        updateSpecField({
          [id]: code,
        })
      );

      dispatch(updateSpecChangedFields(id));

      setErrors((prevErorrs) => ({
        ...prevErorrs,
        [id]: "",
      }));

      if (!dataChanged) {
        dispatch(updateAllChg(true));
      }
    },

    [actionType, dataChanged]
  );

  const handleDropdownSearch = useCallback(
    (id, searchValue) => {
      setSearchLoading({
        [id]: true,
      });

      if (id === "producerCode") {
        dispatch(
          getProducerList(specData.producerCode, searchValue, () =>
            setSearchLoading({
              [id]: false,
            })
          )
        );
      }
    },
    [specData.producerCode]
  );

  const handleDeleteSpec = useCallback(() => {
    setDeleteModalActive(true);
  }, []);

  const deleteSpec = () => {
    dispatch(
      deleteSpecFromServer(specCode, () => {
        navigate("/specs", { state: { pageCode: 50, withoutReset: true } });
      })
    );
    setDeleteModalActive(false);
  };

  const openHistoryChanges = () => {
    setHistoryChangesModalActive(true);
  };

  async function submitSpec() {
    const isFormValid = await specSchema.isValid(specData, {
      abortEarly: false,
    });

    const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();

    if (isFormValid && isMultiLanguageFormValid) {
      updateDefaultLangName(multiLanguageFormData, specData.name)
      const namesList = createNameObject(specData.name, multiLanguageFormData);
      return dispatch(putSpec(namesList, actionType));
    } else {
      if (!isFormValid) {
        specSchema.validate(specData, { abortEarly: false }).catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          }, {});
          setErrors(() => {
            return {
              ...errors,
            };
          });
        });
      }

      if (!isMultiLanguageFormValid) {
        validateMultiLanguageForm();
      }
      return false;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const isValid = await submitSpec();
    if (isValid) {
      setMultiLanguageFormDataChanged(false);
      navigate(`/spec/${specData.code}`, {
        state: {
          actionType: "edit",
        },
      });
    }
  };

  return (
    <>
      <SpecPageForm
        formErrors={formErrors}
        searchLoading={searchLoading}
        actionType={actionType}
        specCode={specCode}
        getNewCode={getNewCode}
        multiLanguageFormData={multiLanguageFormData}
        multiLanguageProps={multiLanguageProps}
        handleInputChange={handleInputChange}
        handleDropdownChange={handleDropdownChange}
        handleDropdownSearch={handleDropdownSearch}
        handleDeleteSpec={handleDeleteSpec}
        openHistoryChanges={openHistoryChanges}
        onSubmit={onSubmit}
      />
      <DeleteModal
        active={deleteModalActive}
        title={
          <>
            Будут удалены все данные по спецификации.
            <br /> Продолжить?
          </>
        }
        closeModal={() => setDeleteModalActive(false)}
        onDelete={deleteSpec}
      />
      <ModalHistoryChanges
        active={historyChangesModalActive}
        closeModal={() => setHistoryChangesModalActive(false)}
        tableName={"spec"}
        rowCode={specCode}
        listType={"hist"}
      />
    </>
  );
};

export default SpecPage;
