export const serverErrorMesasages = {
  409: {
    1: 'Запись с таким кодом уже существует',
    2: 'В ссылочном справочнике отсутствует запись с таким кодом',
    3: 'Ошибка при внесении изменений в БД',
    4: 'Такая комбинация уже существует'
  },
  403: {
    0: 'Файл по указанному пути уже существует',
    1: 'Недостаточно места на сервере',
    2: 'Файл отсутствует',
    3: 'Ошибка удаления файла',
    4: "Ошибка при сохранении файла. Обратитесь к администратору системы"
  },
  500: "Произошла непредвиденная ошибка. Обратитесь к администратору системы",
  400: "Ошибка запроса к сервису."
}