import {
  CLEAR_MODEL_LIST,
  RECEIVE_MODEL_LIST,
  UPDATE_MODEL_LIST,
} from "../actions/sync/model";

const initialState = {
  list: [],
  modelComplectPageList: [],
  current: "def",
};

function model(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_MODEL_LIST:
      return action.payload.isModelComplectPage
        ? Object.assign({}, state, { modelComplectPageList: action.payload.data })
        : Object.assign({}, state, { list: action.payload.data, modelComplectPageList: action.payload.data });
    case UPDATE_MODEL_LIST:
      return Object.assign({}, state, { current: action.id });
    case CLEAR_MODEL_LIST:
      return initialState;
    default:
      return state;
  }
}

export default model;
