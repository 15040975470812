import React from 'react';
import './index.css';

export function Interval(props) {
  if(props.data === undefined){
    return null;
  }
  return (
    <div className={'interval ' + (props.data.hidden ? 'hidden' : '')}>
      {props.data.val +
        ' ' +
        props.data.intervalType +
        ' (' +
        props.data.intervalAction +
        ')'}
    </div>
  );
}
