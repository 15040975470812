export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export function setSearchType(id) {
  return {
    type: SET_SEARCH_TYPE,
    id,
  };
}

export const SET_SEARCH_MODE = 'SET_SEARCH_MODE';
export function setSearchMode(mode) {
  return {
    type: SET_SEARCH_MODE,
    mode,
  };
}

export const RECIVE_SEARCH_BY_STRING_RESULT = 'RECIVE_SEARCH_BY_STRING_RESULT';
export function reciveSearchByStringResult(json) {
  return {
    type: RECIVE_SEARCH_BY_STRING_RESULT,
    json,
  };
}

export const RECIVE_SEARCH_BY_FILTER_RESULT = 'RECIVE_SEARCH_BY_FILTER_RESULT';
export function reciveSearchByFilterResult(json) {
  return {
    type: RECIVE_SEARCH_BY_FILTER_RESULT,
    json,
  };
}

export const ADD_SEARCH_BY_FILTER_RESULT = 'ADD_SEARCH_BY_FILTER_RESULT';
export function addSearchByFilterResult(json) {
  return {
    type: ADD_SEARCH_BY_FILTER_RESULT,
    json,
  };
}

export const RECIVE_TABLE_COLUMNS = 'RECIVE_TABLE_COLUMNS';
export function reciveTableColumns(json) {
  return {
    type: RECIVE_TABLE_COLUMNS,
    json,
  };
}

export const UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING';
export function updateSearchString(value) {
  return {
    type: UPDATE_SEARCH_STRING,
    value,
  };
}

export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export function clearSearchResult() {
  return {
    type: CLEAR_SEARCH_RESULT,
  };
}

export const DROP_SEARCH_RESULT = 'DROP_SEARCH_RESULT';
export function dropSearchResult() {
  return {
    type: DROP_SEARCH_RESULT,
  };
}

export const SHIFT_SEARCH_OFFSET = 'SHIFT_SEARCH_OFFSET';
export function shiftSearchOffset() {
  return {
    type: SHIFT_SEARCH_OFFSET,
  };
}

export const RESET_SEARCH_OFFSET = 'RESET_SEARCH_OFFSET';
export function resetSearchOffset() {
  return {
    type: RESET_SEARCH_OFFSET,
  };
}

export const WAIT_FOR_SEARCH_DATA = 'WAIT_FOR_SEARCH_DATA';
export function waitForSearchData(mode) {
  return {
    type: WAIT_FOR_SEARCH_DATA,
    mode,
  };
}

export const SET_SEARCH_STAGE = 'SET_SEARCH_STAGE';
export function setSearchStage(stage) {
  return {
    type: SET_SEARCH_STAGE,
    stage,
  };
}

export const STOP_SEARCH = 'STOP_SEARCH';
export function stopSearch() {
  return {
    type: STOP_SEARCH,
  };
}

export const SET_TABLE_OFFSET = "SET_TABLE_OFFSET";
export function setTableOffset(offset){
  return {
    type: SET_TABLE_OFFSET,
    offset,
  }
}
