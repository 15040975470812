import React, {useState} from 'react';
import './index.scss';
import Icon from '../../theme/svg/right_preset_icon.svg';

export function TextAreaDescription(props) {
  const [expand, setExpand] = useState(false);

  const handleChange = (e) => {
    props.onChange(e);
  };

  const focusField = () => {
    if (!expand) {
      setExpand(true);
    }
  };

  return (
    <div className="textArea textAreaDesc">
      <img
        src={Icon}
        alt="Стрелка"
        className={`textAreaDesc__icon${
          expand ? " textareaDesc__icon-rotate" : ""
        }`}
        onClick={() => setExpand(() => !expand)}
      />
      <label className="textareaDesc__field-name" htmlFor={props.id}>
        {props.label || "Описание"}
        <textarea
          className={`input__field textareaDesc__field textareaDesc__field-${
            expand ? "property" : "clamp"
          } scrollbar__custom`}
          id={props.id}
          maxLength="32000"
          rows={2}
          name="textarea"
          value={props.value}
          onChange={(e) => handleChange(e)}
          onFocus={(e) => focusField(e)}
        />
      </label>
    </div>
  );
}
