import { useState, useEffect, useCallback } from "react";
import { nanoid } from "nanoid";
import { useSelector } from "react-redux";
import multiLanguageListSchema from "../validations/multiLanguageList";
import parseInteger from "../utils/parseInteger";

export const useMultiLanguageList = (multiLanguageArray) => {
  const languageList = useSelector((state) => {
    return state.i18.list.map((item) => ({
      code: item,
      name: item,
    }));
  });
  const [multiLanguageFormData, setMultiLanguageFormData] = useState([]);
  const [multiLanguageFormErrors, setMultiLanguageFormErrors] = useState([]);
  const [multiLanguageFormDataChanged, setMultiLanguageFormDataChanged] =
    useState(false);

  useEffect(() => {
    if (!multiLanguageArray) return;
    
    if (multiLanguageArray.length === 0) {
      setMultiLanguageFormData([
        {
          id: nanoid(3),
          languageList,
          lang: languageList[0].name,
          name: "",
        },
      ]);
    } else {
      const usedLanguages = multiLanguageArray.map((item) => item.lang);
      const availableLanguagesList = languageList.filter(
        (item) => !usedLanguages.includes(item.code)
      );
      setMultiLanguageFormData(
        multiLanguageArray.map((item) => ({
          ...item,
          id: nanoid(3),
          languageList: availableLanguagesList,
        }))
      );
    }
  }, [multiLanguageArray]);

  const updateLanguage = ({ code }, elementId) => {
    const id = elementId.split("-")[0];
    // Get the index of the replaced language in the list
    const item = multiLanguageFormData.find((item) => item.id === id);
    // Get an array of the used languages without the replaced language
    const usedLanguages = multiLanguageFormData
      .map((item) => item.lang)
      .filter((lang) => {
        return lang !== item.lang;
      });
    // Get an array of the used languages with the replaced language
    const usedLanguagesWithNewOne = [...usedLanguages, code];
    // Get new available languages list
    const availableLanguagesListWithNewOne = languageList.filter(
      (item) => !usedLanguagesWithNewOne.includes(item.code)
    );
    // Update every item in the list with the new available languages list
    const newI18nArray = multiLanguageFormData.map((item) => {
      if (item.id === id) {
        item.lang = code;
      }
      return {
        ...item,
        languageList: availableLanguagesListWithNewOne,
      };
    });

    setMultiLanguageFormData(newI18nArray);

    if (!multiLanguageFormDataChanged) {
      setMultiLanguageFormDataChanged(true);
    }
  };

  const updateLanguageName = useCallback(({ target: { id, value } }) => {
    const listId = id.split("-")[0];

    setMultiLanguageFormData((prev) =>
      prev.map((item) => {
        if (item.id === listId) {
          item.name = value;
        }
        return item;
      })
    );

    setMultiLanguageFormErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[listId];
      return newErrors;
    });

    if (!multiLanguageFormDataChanged) {
      setMultiLanguageFormDataChanged(true);
    }
  }, []);

  const addNewLanguageName = () => {
    const usedLanguages = multiLanguageFormData.map((item) => item.lang);
    const availableLanguagesList = languageList.filter(
      (item) => !usedLanguages.includes(item.code)
    );
    const usedLanguagesWithNewOne = [
      ...usedLanguages,
      availableLanguagesList[0].name,
    ];

    const availableLanguagesListWithoutNewOne = languageList.filter(
      (item) => !usedLanguagesWithNewOne.includes(item.code)
    );

    const newI18nArray = multiLanguageFormData.map((item) => ({
      ...item,
      languageList: availableLanguagesListWithoutNewOne,
    }));

    setMultiLanguageFormData([
      ...newI18nArray,
      {
        id: nanoid(3),
        languageList: availableLanguagesListWithoutNewOne,
        lang: availableLanguagesList[0].name,
        name: "",
      },
    ]);

    if (!multiLanguageFormDataChanged) {
      setMultiLanguageFormDataChanged(true);
    }
  };

  const deleteLanguageName = (removedItem) => {
    const usedLanguages = multiLanguageFormData
      .map((item) => item.lang)
      .filter((item) => item !== removedItem.lang);

    const availableLanguagesList = languageList.filter(
      (item) => !usedLanguages.includes(item.code)
    );

    const newI18nArray = multiLanguageFormData
      .filter((item) => item.id !== removedItem.id)
      .map((item) => ({
        ...item,
        languageList: availableLanguagesList,
      }));

    setMultiLanguageFormData(newI18nArray);

    if (!multiLanguageFormDataChanged) {
      setMultiLanguageFormDataChanged(true);
    }
  };

  const checkMultiLanguageFormIsValid = async () => {
    return await multiLanguageListSchema.isValid(multiLanguageFormData, {
      abortEarly: false,
    });
  };

  const validateMultiLanguageForm = () => {
    multiLanguageListSchema
      .validate(multiLanguageFormData, { abortEarly: false })
      .catch((err) => {
        const errors = err.inner.reduce((acc, error) => {
          const index = parseInteger(error.path);
          return {
            ...acc,
            [multiLanguageFormData[index].id]: error.message,
          };
        }, {});

        setMultiLanguageFormErrors(errors);
      });
  };

  return {
    multiLanguageFormData,
    multiLanguageFormDataChanged,
    multiLanguageFormErrors,
    updateLanguage,
    updateLanguageName,
    addNewLanguageName,
    deleteLanguageName,
    checkMultiLanguageFormIsValid,
    validateMultiLanguageForm,
    setMultiLanguageFormDataChanged,
  };
};
