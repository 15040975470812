import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import { setActiveProductMenuItem } from "../../reducers/productMenu";
import {
  setProductCodeForPS,
  setProductSpecs,
  setProductSpecsFetchingStatus,
} from "../../reducers/productSpecs";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import {
  initNotificationMessage,
  showNotificationMessage,
} from "../sync/notificationMessage";
import { getProductMenu } from "./productMenu";

export function getProductSpecs(productCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/product-spec";

    try {
      dispatch(setProductSpecsFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${productCode}/locale/${lang}`,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(setProductSpecs(data));
      dispatch(setProductCodeForPS(productCode));
      dispatch(setProductSpecsFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setProductSpecsFetchingStatus());
    }
  };
}

export function putProductSpecs() {
  return async (dispatch, getState) => {
    const state = getState();
    const productSpecs = state.productSpecs.data;
    const productCode = state.productSpecs.productCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setProductSpecsFetchingStatus("update"));

      const body = {
        data: productSpecs
          .filter((item) => item.actionType)
          .map((item) => ({
            id: item.actionType !== "C" ? item.id : null,
            productCode,
            specCode: item.specCode,
            productSpecLevelCode: item.productSpecLevelCode,
            actionType: item.actionType,
          })),
      };

      const response = await fetchFromApi("api/v1/product-spec", "POST", {
        body
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getProductMenu(productCode));
      dispatch(setActiveProductMenuItem(pathname));
      dispatch(resetCurrentPageFormState());
      dispatch(getProductSpecs(productCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductSpecsFetchingStatus());

      return false;
    }
  };
}

export function deleteProductSpecsFromServer(productCode, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const body = {
      code: productCode,
      data: state.productSpecs.data.map( item => ({spec: item.specCode}))
    };

    try {
      dispatch(setProductSpecsFetchingStatus("loading"));
      const response = await fetchFromApi("api/v1/product-spec/all", "DELETE", {
        body,
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getProductMenu(productCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setProductSpecsFetchingStatus());
    }
  };
}
