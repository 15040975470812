import { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import InputSearchField from "../inputSearchField";
import Checkbox from "../checkbox";
import { Waiter } from "../../components/waiter";
import "./index.scss";

const MultiSelectBlock = ({
  items = [],
  isSearchable = true,
  onSearch,
  isLoading = false,
  onItemSelect,
  isSelectAllAvailable = true,
  onSelectAll,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const allItemsChecked = items.every((item) => item.checked);

  function handleOnSearch(id, value) {
    setSearchValue(value);
  }

  useEffect(() => {
    const search = async () => {
      await onSearch(debouncedSearchValue);
    };

    search();
  }, [debouncedSearchValue]);

  function handleCheckboxChange(e) {
    const itemCode = e.target.id;
    const item = items.find((item) => item.code === itemCode);
    onItemSelect(item);
  }

  return (
    <>
      {isSearchable && (
        <InputSearchField
          id="search"
          value={searchValue}
          onChange={handleOnSearch}
        />
      )}
      {isLoading ? (
        <Waiter size="large" />
      ) : (
        <div className="multiselect-block__options">
          {isSelectAllAvailable && (
            <Checkbox
              code="selectAll"
              onChange={onSelectAll}
              checked={allItemsChecked}
              name={<strong>Выбрать все</strong>}
            />
          )}
          {items.map((item) => {
            return (
              <Checkbox
                key={item.code}
                {...item}
                onChange={handleCheckboxChange}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default MultiSelectBlock;
