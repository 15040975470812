import { CLEAR_FUEL_TYPE, UPDATE_FUEL_TYPE } from '../actions/sync/fuelType';
import { RESET_APP } from '../actions/sync/init';
import {
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  CLEAR_SEARCH_RESULT,
} from '../actions/sync/search';

const defState = {
  current: 'def',
  list: [],
};

function getFuelTypeList(data) {
  if (Object.keys(data).length === 0 || !data.fuelType) {
    return defState;
  }
  const list = data.fuelType.filter((el) => el.code !== null);
  if (list.length === 0) {
    return defState;
  }
  if (list.length === 1) {
    return { current: list[0].code, list: list };
  }
  return { list: list };
}

function fuelType(state = defState, action) {
  switch (action.type) {
    case RECIVE_SEARCH_BY_STRING_RESULT:
      return Object.assign({}, state, getFuelTypeList(action.json));
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      return Object.assign({}, state, getFuelTypeList(action.json));
    case UPDATE_FUEL_TYPE:
      return Object.assign({}, state, { current: action.id });
    case CLEAR_FUEL_TYPE:
      return defState;
    case RESET_APP:
      return defState;
    case CLEAR_SEARCH_RESULT:
      return defState;
    default:
      return state;
  }
}

export default fuelType;
