import React from 'react';
import './index.scss';

export function TextAreaItem(props) {
  return (
    <div className="textArea">
      <textarea
        className="input__field field__textarea scrollbar__custom"
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />
    </div>
  );
}
