import { fetchFromApi } from "../service";
import {setStatisticCoverReportFormSelectors} from "../../reducers/statisticCoverReportFormSelectors";

export function getSpecifications(searchString = null, callback = null) {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        const response = await fetchFromApi("api/v1/spec/filter/page", "POST",
            { body:{
                    lang: locale,
                    offset: 0,
                    limit: 100,
                    hidden: true,
                    searchString,
                    colListCode: "name"
                } });
        const data = await response.json();
        dispatch(
            setStatisticCoverReportFormSelectors({
                specificationList: data.data
            })
        );
        if(callback) callback();
    };
}

export function getVisco(searchString = null, callback = null) {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        const response = await fetchFromApi("api/v1/visco/filter/page", "POST",
            { body:{
                    lang: locale,
                    offset: 0,
                    limit: 100,
                    hidden: true,
                    searchString,
                    colListCode: "name"
                } });
        const data = await response.json();
        dispatch(
            setStatisticCoverReportFormSelectors({
                viscoList: data.data
            })
        );
        if(callback) callback();
    };
}

export function getCategiry() {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        const response = await fetchFromApi("api/v1/model-category/filter", "POST",
            { body:{
                    lang: locale,
                    offset: 0,
                    limit: 100,
                    hidden: true,
                    colListCode: "name"
                } });
        const data = await response.json();
        dispatch(
            setStatisticCoverReportFormSelectors({
                catigoryList: data
            })
        );
    };
}

export function makeStatisticCoverReport(callback) {
    return async (dispatch, getState) => {
        const state = getState();
        const locale = state.i18.current;
        try {
            const response = await fetchFromApi("api/v1/xls/report/spec/cover", "POST",
                { body:{
                        lang: locale,
                        spec: state.statisticCoverReportForm.selectedSpecification.map( (item) => (item.code)),
                        visco: state.statisticCoverReportForm.selectedVisco.map( (item) => (item.code)),
                        modelCategory: state.statisticCoverReportForm.selectedCategory.map( (item) => (item.code))
                    } });
            const file = await response.blob();
            const name = response.headers.get('Content-Disposition').split('\'\'')[1];
            if (callback) {
                callback(file, decodeURIComponent(name));
            }
        } catch (error) {
        }
    }
}