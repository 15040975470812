import { connect } from 'react-redux';
import { HomePage } from '../../pages/home';

const mapStateToProps = (state) => {
  return {
    autorizationStage: state.auth.stage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const VisibleHomePage = connect(mapStateToProps, mapDispatchToProps)(HomePage);

export default VisibleHomePage;
