import { setSegmentList } from "../../reducers/segmentList";
import { fetchFromApi } from "../service";

export function getSegmentList() {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/segment/filter/page";

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });

      const data = await response.json();

      dispatch(setSegmentList(data.data));
    } catch (error) {}
  };
}
