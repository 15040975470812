function normalizeSpecOverForServer(specs, specBaseCode) {
  return {
    data: specs
      .filter((item) => item.actionType)
      .map((item) => ({
        specBaseCode,
        specOverCode: item.spec,
        id: item.actionType === "C" ? null : item.id,
        actionType: item.actionType,
      })),
  };
}

export default normalizeSpecOverForServer;
