import normalizeSpecComponentGroupForServer from "../../../normalizers/specComponentGroup";
import { serverErrorMesasages } from "../../../utils/getErrorMessage";
import HttpError from "../../../utils/httpError";
import { setActiveSpecMenuItem } from "../../reducers/specMenu";
import { setSpecCodeForSCG, setSpecComponentGroup, setSpecComponentGroupFetchingStatus } from "../../reducers/specComponentGroup";
import { fetchFromApi } from "../service";
import { resetCurrentPageFormState } from "../sync/currentPageFormState";
import { initNotificationMessage, showNotificationMessage } from "../sync/notificationMessage";
import { getSpecMenu } from "./specMenu";


export function getSpecComponentGroup(specCode) {
  return async (dispatch, getState) => {
    const state = getState();
    const lang = state.i18.current;
    const url = "api/v1/spec-component-group";

    try {
      dispatch(setSpecComponentGroupFetchingStatus("loading"));
      const response = await fetchFromApi(url, "GET", {
        id: `${specCode}/locale/${lang}`
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(
        setSpecComponentGroup(
          data.map((spec) => ({
            id: spec.id,
            spec: spec.componentGroupCode,
            name: spec.componentGroupName,
          }))
        )
      );
      dispatch(setSpecCodeForSCG(specCode));
      dispatch(setSpecComponentGroupFetchingStatus());
    } catch (error) {
      console.log(error.message);
      dispatch(setSpecComponentGroupFetchingStatus());
    }
  };
}

export function putSpecComponentGroup() {
  return async (dispatch, getState) => {
    const state = getState();
    const specComponentGroup = state.specComponentGroup.data;
    const specCode = state.specComponentGroup.specCode;
    const pathname = state.currentPath.path;

    try {
      dispatch(setSpecComponentGroupFetchingStatus("update"));

      const response = await fetchFromApi("api/v1/spec-component-group", "POST", {
        body: normalizeSpecComponentGroupForServer(specComponentGroup, specCode)
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      await dispatch(getSpecMenu(specCode));
      dispatch(setActiveSpecMenuItem(pathname));
      dispatch(resetCurrentPageFormState());
      dispatch(getSpecComponentGroup(specCode));

      return true;
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status];

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setSpecComponentGroupFetchingStatus());

      return false;
    }
  };
}

export function deleteSpecComponentGroupFromServer(specCode, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const specCode = state.specMenu.specCode;
    const body = {
      code: specCode,
      data: state.specComponentGroup.data.map( item => ({componentGroup: item.spec}))
    };

    try {
      dispatch(setSpecComponentGroupFetchingStatus("loading"));
      const response = await fetchFromApi(
        "api/v1/spec-component-group/all",
        "DELETE",
        {
          body
        }
      );

      if (!response.ok) {
        const responseBody = await response.json();
        throw new HttpError(response.status, response.statusText, responseBody);
      }

      dispatch(resetCurrentPageFormState());
      await dispatch(getSpecMenu(specCode));
      if (callback) {
        callback();
      }
    } catch (error) {
      let errorMessage = serverErrorMesasages[error.status] || "";

      if (error.body) {
        errorMessage = errorMessage[error.body.msgCode];
      }
      await dispatch(
        initNotificationMessage(
          `${error.status} ${error.message}`,
          errorMessage,
          "danger"
        )
      );

      dispatch(showNotificationMessage());
      dispatch(setSpecComponentGroupFetchingStatus());
    }
  };
}
