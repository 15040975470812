import { fetchFromApi } from "../service";
import { setComponentGroupList, receiveComponentGroup } from "../sync/componentGroup";

export function getComponentGroupList(searchString = null, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/component-group/filter/page";

    const body = {
      lang: locale,
      hidden: true,
      offset: "0",
      limit: 100,
    };

    if (searchString) {
      body.colListCode = "name";
      body.searchString = searchString;
    }

    try {
      const response = await fetchFromApi(url, "POST", { body });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      dispatch(setComponentGroupList(data.data));

      if (callback) callback();
    } catch (error) {
      console.log(error.message);
    }
  };
}


export function requestComponentGroup(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveComponentGroup([data]))
	};
}