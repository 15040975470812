export const CHANGE_LOGIN = 'CHANGE_LOGIN';
export function changeLogin(login) {
  return {
    type: CHANGE_LOGIN,
    login,
  };
}

export const SET_LOGIN_ACCESS = "SET_LOGIN_ACCESS";
export function setLoginAccess(access){
  return{
    type: SET_LOGIN_ACCESS,
    access
  }
}

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export function changePassword(password) {
  return {
    type: CHANGE_PASSWORD,
    password,
  };
}

export const SWITCH_PASSWORD_VISABLE = "SWITCH_PASSWORD_VISABLE";
export function switchPasswordVisable(){
  return{
    type: SWITCH_PASSWORD_VISABLE
  }
}

export const CLEAR_AUTH_FORM = "CLEAR_AUTH_FORM";
export function clearAuthForm(){
  return{
    type: CLEAR_AUTH_FORM
  }
}

export const RECIVE_USER_DATA = "RECIVE_USER_DATA";
export function reciveUserData(data){
  return{
    type: RECIVE_USER_DATA,
    data
  }
}

export const SWITCH_USER_INFO = "SWITCH_USER_INFO";
export function switchUserInfo(){
    return {
        type: SWITCH_USER_INFO
    }
}

export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export function setLoginError(error){
  return{
    type: SET_LOGIN_ERROR,
    error
  }
}

export const SET_RIGHT_LOGIN = "SET_RIGHT_LOGIN";
export function setRightLogin(){
  return{
    type: SET_RIGHT_LOGIN
  }
}