import { getHTTPData } from "../../utils/getData";
import {fetchFromApi} from "../../redux/actions/service";

export const API = {
	getGeneration: function (str) {
		return getHTTPData("api/v1/model-generation/" + str, 'GET');
	},
	getCategory: function (body) {
		return getHTTPData("api/v1/model-category/filter/page", 'POST', body );
	},
	getSubcategory: function (body) {
		return getHTTPData("api/v1/model-subcategory/filter", 'POST', body );
	},
	getModel: function (body) {
		return getHTTPData("api/v1/model/filter", 'POST', body)
	},
	getCode: function () {
		const token = localStorage.getItem(`access_token`);
		return fetchFromApi("api/v1/dict/code","GET",{token})
		.then((res) => { return res.text() })
	},
	saveChanges: function (body) {
		return getHTTPData("api/v1/visco", 'PUT', body);
	}
}
