export const TOGGLE_EXPAND_All_PRODUCTS = "TOGGLE_EXPAND_All_PRODUCTS";
export const toggleExpandAllProducts = (expandAllProducts) => {
  localStorage.setItem("isExpandAllProducts", expandAllProducts);
  return {
    type: TOGGLE_EXPAND_All_PRODUCTS,
    expandAllProducts,
  };
};


