import React, {useEffect, useState} from 'react';
import '../styles/index.scss';

import {useDispatch, useSelector} from 'react-redux'
import {useMultiLanguageList} from "../../hooks/useMultiLanguageList";
import iconClose from "../../theme/svg/close_icon.svg";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import MultiLanguageForm from "../../components/multiLanguageForm";
import {requestComponentType} from "../../redux/actions/async/componentType";
import {fetchFromApi} from "../../redux/actions/service";
import {initNotificationMessage, showNotificationMessage} from "../../redux/actions/sync/notificationMessage";
import {API} from "../componentType/api";
import createNameObject from "../../utils/createNameObject";
import updateDefaultLangName from "../../utils/updateDefaultLangName";
import CodeInputField from "../../pureComponents/codeInputField";
import FormInputField from "../../pureComponents/formInputField";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import ConfirmModal from "../../pureComponents/modal/confirmModal";
import guidsFormComponentTypeSchema from "../../validations/guidsFormComponentType";
import {requestGuideTableBody} from "../../redux/actions/async/guides";
import {waitForGuideData} from "../../redux/actions/sync/guides";

const initialState = {
    id: null,
    code: null,
    name: null,
    componentGroupCode: null,
    priority: 0,
    hidden: false
}
const avaliableFields = {
    code: 'code',
    name: 'name',
    modelSubcategoryCode: 'componentGroupCode',
    priority: 'priority',
    hidden: 'hidden'
}




export function ComponentTypeGuideForm(props) {
    const state = useSelector(state => state );
    const store = useSelector(state => state.componentType.list[0]);
    const dispatch = useDispatch();
    const [fields, setFields] = useState(initialState);
    const [active_modal, setActive_modal] = useState(null);
    const [names, setNames] = useState([]);
    const [componentGroups, setComponentGroups] = useState([]);
    const [changes, setChanges] = useState(false);
    const [errors, setErrors] = useState([]);
    const [changedFields, setChangedFields] = useState([]);
	const [isInitState, setIsInitState] = useState(true);

    const {
        multiLanguageFormData,
        multiLanguageFormErrors,
        multiLanguageFormDataChanged,
        updateLanguage,
        updateLanguageName,
        addNewLanguageName,
        deleteLanguageName,
        checkMultiLanguageFormIsValid,
        validateMultiLanguageForm
    } = useMultiLanguageList(names);

    useEffect(() => {
        store.type === 'edit' && startEditCopy();
        store.type === 'copy' && startEditCopy();
        store.type === 'new' && startNew();
    },[]);

    useEffect(() => {
        if(multiLanguageFormDataChanged && !changes) {
            setChanges(true);
            updateChangedFields('name');
        }
    },[multiLanguageFormDataChanged, changes]);


    function startEditCopy() {
		Promise.all([
				API.getComponentType(props.data.currentRow),
				API.getComponentGroup({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
			 	API.getCode(),
			]
		).then((response) => {
			setNames(response[0].name.value.filter(item => item.lang !== 'DEF'));
			setComponentGroups(response[1].data);

			setFields({ ...fields,
				id: store.type === 'copy' ? null : response[0]?.id,
				code: store.type === 'copy' ? response[2] : response[0]?.code ,
				name: response[0]?.name.value.filter(item => item.lang === 'DEF')[0]?.name,
				componentGroupCode: response[0]?.componentGroupCode,
                priority: response[0]?.priority,
				hidden: response[0]?.hidden || false
			});
			if (store.type === 'copy') {
				setChangedFields(Object.values(avaliableFields));
				setChanges(true);
			}
		})
	}


    function startNew() {
        Promise.all([
            API.getCode(),
            API.getComponentGroup({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
        ]
    ).then(response => {
        setFields({ ...fields, code: response[0] })
        setComponentGroups(response[1].data);
        setChangedFields(['code','hidden']);
    });
    
    }


    function changeDropdown(item, id) {
		setFields(prev => ({...prev, [id]:item.code }));
		setErrors(prev => ({...prev, [id]:null }));
		if(isInitState) setIsInitState(false);
		setChanges(true);
		updateChangedFields(id);
	}


    function updateChangedFields(id) {
        if (avaliableFields[id] && !changedFields.includes(avaliableFields[id])) {
            setChangedFields((prev) => [...prev, avaliableFields[id]]);
        }
    }

    function changeInput(event) {
        setChanges(true);
        updateChangedFields(event.target.id);
        if(event.target.id === 'hidden') {
            setFields({ ...fields, [event.target.id]: !fields.hidden });
        } else {
            setFields({ ...fields, [event.target.id]: event.target.value });
            setErrors(prev => ({...prev, [event.target.id]:null }));
        }
    }

    function createCode() {
        API.getCode().then((response) => {
            setFields(prev => ({...prev, code: response }));
        });
    }

 

    async function save() {
        const isFormValid = await guidsFormComponentTypeSchema.isValid( fields,{abortEarly: false} );

        const isMultiLanguageFormValid = await checkMultiLanguageFormIsValid();

        if( isFormValid && isMultiLanguageFormValid ) {
            updateDefaultLangName(multiLanguageFormData, fields.name)
            saveChanges({
				id: fields.id,
				code: fields.code,
				name: createNameObject(fields.name, multiLanguageFormData),
				componentGroupCode: fields.componentGroupCode,
                priority: fields.priority || 0,
				hidden: fields.hidden,
				changedFields: changedFields
			}).then();

        } else {
            if (!isFormValid) {
                guidsFormComponentTypeSchema
                    .validate(fields, { abortEarly: false })
                    .catch((err) => {
                        const errors = err.inner.reduce((acc, error) => {
                            return {
                                ...acc,
                                [error.path]: error.message,
                            };
                        }, {});
                        setErrors(() => {
                            return {
                                ...errors,
                            };
                        });
                    });
            }
            if (!isMultiLanguageFormValid) {
                validateMultiLanguageForm();
            }
        }
    }

    async function saveChanges(body) {
        await fetchFromApi("api/v1/component-type","PUT",{body})
        .then((res) => {
            if (res.status === 200) {
                dispatch(waitForGuideData(true));
                dispatch(requestComponentType(false, null, res.id));
                dispatch(requestGuideTableBody());
            } else {
                dispatch(
                    initNotificationMessage(
                        'Ошибка',
                        'Произошла системная ошибка. Обратитесь к администратору системы',
                        'danger'
                    )
                )
                dispatch(showNotificationMessage());
            }
        })
        .catch((e) => {
            console.log(e);
            dispatch(
                initNotificationMessage(
                    'Ошибка',
                    'Произошла системная ошибка. Обратитесь к администратору системы',
                    'danger'
                )
            );
            dispatch(showNotificationMessage());
        });
    }

    function close() {
        if(!changes) {
            onContinueWithoutSave(null);
        } else {
            setActive_modal('modalTC__wrapper-active');
        }
    }

    function closeApprowal(value) {
        setActive_modal(null);
    }

    function onContinueWithoutSave(value) {
        setFields(initialState);
        setActive_modal(null);
        dispatch(requestComponentType(false, null, null));
    }

    return (
        <div className={"guide__wrapper guide__wrapper_active"}>

            <div className="guide-block">
                {/* Header */}
                <div className="guide-block__header">
                    <div className="guide-block__header__name">{props.data.currentGuide}</div>
                    <div className="guide-block__control guide-block__control_elastic">
                        <CodeInputField
                            id={'code'}
                            label={'Код'}
                            disabled={store.type === 'edit'}
                            error={errors.code}
                            value={fields.code}
                            onChange={changeInput}
                            required={true}
                        />
                    </div>
                    <button
                        className={"button button__primary button__small"}
                        disabled={store?.type === 'edit'}
                        onClick={() => createCode()}>
                        Создать код
                    </button>

                    {/* X button */}
                    <div className="guide-block__close" onClick={() => close()}>
                        <img src={iconClose} alt="Закрыть" />
                    </div>
                </div>

                {/* Body */}
                <div className="guide-block__content">
                    <FormInputField
                        id='name'
                        label='Название'
                        value={fields.name}
                        error={errors.name}
                        onChange={changeInput}
                        required={true}
                    />
                    <DropdownSelect
                        id={'componentGroupCode'}
                        label={'Группа узла'}
                        defaultValue={getNameFromList(componentGroups, fields.componentGroupCode)}
                        error={errors.componentGroupCode}
                        items={componentGroups}
                        onSelect={changeDropdown}
                        required={true}
                    />

                    <FormInputField
                        id='priority'
                        label='Приоритет'
                        value={fields.priority}
                        error={errors.priority}
                        onChange={changeInput}
                        inputMask="positive-integer"
                    />

                    <div className="guide-block__content__divider"></div>
                    <MultiLanguageForm
                        multiLanguageFormData={multiLanguageFormData}
                        multiLanguageFormDataChanged={multiLanguageFormDataChanged}
                        multiLanguageFormErrors={multiLanguageFormErrors}
                        updateLanguage={updateLanguage}
                        updateLanguageName={updateLanguageName}
                        addNewLanguageName={addNewLanguageName}
                        deleteLanguageName={deleteLanguageName}
                    />
                </div>

                <div className="guide-block__bottom">
                    <ToggleSwitch
                        id={'hidden'}
                        label={'Скрыть'}
                        onToggle={changeInput}
                        checked={fields.hidden}
                    />

                    <div className="buttons__group">
                        <div
                            className="button button__secondary button__secondary__ghost"
                            onClick={() => onContinueWithoutSave()}
                        >
                            Не сохранять
                        </div>

                        <button
                            disabled={!changes}
                            className={"button button__primary"}
                            onClick={() => save()}
                        >
                            Сохранить изменения
                        </button>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div className={"modalTC__wrapper "}>
                <ConfirmModal
                    active={active_modal}
                    onSave={save}
                    onContinueWithoutSave={onContinueWithoutSave}
                    closeModal={closeApprowal}
                />
            </div>
        </div>
    );
}