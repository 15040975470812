import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import VerticalLine from "../../../components/veritcalLine";
import getNameFromList from "../../../utils/getNameFromList";
import DropdownSelect from "../../../pureComponents/dropdownSelect";
import CodeInputField from "../../../pureComponents/codeInputField";
import { Waiter } from "../../../components/waiter";
import ModalLinkWrapper from "../../../components/modalLinkWrapper";
import { TextAreaDescription } from "../../../components/textAreaDescription";
import ToggleSwitch from "../../../pureComponents/toggleSwitch";
import "./index.scss";
import generateModelComplectLink from "../../../utils/generateModelComplectLink";

const ConditionForm = ({
  getNewCode,
  formErrors,
  searchLoading,
  handleInputChange,
  handleDropdownChange,
  handleConditionalAnalogDropdownChange,
  handleDropdownSearch,
  handleDeleteCondition,
  openHistoryChanges,
  onSubmit,
}) => {
  const { state } = useLocation();
  const actionType = state ? state.actionType : "new";
  const { modelComplectCode, conditionCode } = useParams();

  const searchStage = useSelector(state => state.search.stage);
  const isBackToListLinkType = searchStage !== "product"
  const backToProductLink = `/search?code=${modelComplectCode}`

  const dataChanged = useSelector((state) => state.currentPageFormState.allChg);

  const conditionData = useSelector((state) => state.condition);
  const { conditionTypeList, conditionAnalogList, marketList } = useSelector(
    (state) => ({
      marketList: state.marketList.list,
      conditionTypeList: state.conditionType.list,
      conditionAnalogList: state.conditionAnalog.list,
    })
  );
  const [defaultValues, setDefaultValues] = useState({});

  useLayoutEffect(() => {
    if (conditionData && marketList && conditionTypeList) {
      setDefaultValues(() => ({
        market: getNameFromList(marketList, conditionData.marketCode),
        conditionType: getNameFromList(
          conditionTypeList,
          conditionData.conditionTypeCode
        ),
      }));
    }
  }, [conditionData, marketList, conditionTypeList]);

  if (conditionData.status === "loading") {
    return <Waiter size="large" />;
  }

  return (
    <form onSubmit={onSubmit}>
      <fieldset
        className="condition-general__form"
        disabled={conditionData.status === "update"}
      >
        <div className="condition-general__header">
          <div className="main-header-block">
            <div className="main-header-block__item main-header-block__title">
              Добавление условия
            </div>
            <VerticalLine size="24px" />
            <button
              type="button"
              className="main-header-block__item"
              disabled={actionType !== "edit"}
              onClick={handleDeleteCondition}
            >
              <div
                className={`control__img control__img_trash${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <button
              className="main-header-block__item"
              type="button"
              onClick={openHistoryChanges}
              disabled={actionType !== "edit"}
            >
              <div
                className={`control__img control__img_history${
                  actionType !== "edit" ? "" : "-active"
                }`}
              ></div>
            </button>
            <VerticalLine size="24px" />
            <div className="main-header-block__item main-header-block__code input-block input-block--flex">
              <CodeInputField
                id="code"
                label="Код"
                placeholder="Введите"
                value={conditionData.code}
                error={formErrors.code}
                onChange={handleInputChange}
                required={true}
                disabled={actionType === "edit"}
              />
              <button
                type="button"
                className="button button__small button__primary"
                onClick={() => getNewCode()}
                disabled={actionType === "edit"}
              >
                Создать код
              </button>
            </div>
            <VerticalLine size="24px" />
          </div>
          <div className="additional-header-block">
            <ModalLinkWrapper
              linkProps={{
                to: generateModelComplectLink(
                  modelComplectCode,
                  {
                    type: "condition",
                    code: conditionCode,
                  },
                  "interval"
                ),
                state: { componentCode: conditionData.componentCode },
              }}
              className="main-header-block__item button button__small button__primary"
              disabled={actionType !== "edit"}
            >
              Добавить интервал
            </ModalLinkWrapper>
            <ModalLinkWrapper
              linkProps={{
                to: generateModelComplectLink(
                  modelComplectCode,
                  {
                    type: "condition",
                    code: conditionCode,
                  },
                  "spec"
                ),
                state: { componentCode: conditionData.componentCode },
              }}
              className="main-header-block__item button button__small button__primary"
              disabled={actionType !== "edit"}
            >
              Добавить спецификацию
            </ModalLinkWrapper>
            <ModalLinkWrapper
              linkProps={{
                to: generateModelComplectLink(
                  modelComplectCode,
                  {
                    type: "condition",
                    code: conditionCode,
                  },
                  "visco"
                ),
                state: { componentCode: conditionData.componentCode },
              }}
              className="main-header-block__item button button__small button__primary"
              disabled={actionType !== "edit"}
            >
              Добавить вязкость
            </ModalLinkWrapper>
            <ModalLinkWrapper
              linkProps={{
                to: generateModelComplectLink(modelComplectCode, {
                  type: "condition",
                  code: "copy",
                }),
                state: {
                  actionType: "copy",
                  componentCode: conditionData.componentCode,
                },
              }}
              className="main-header-block__item button button__primary button__small"
              disabled={actionType !== "edit"}
            >
              <div className="control__img copy-icon"></div>
            </ModalLinkWrapper>
          </div>
        </div>
        <div className="main-form-block">
          <div className="main-form-block__column">
            <div className="main-form-block__column__block">
              <DropdownSelect
                label="Тип условия"
                id="conditionTypeCode"
                items={conditionTypeList}
                defaultValue={defaultValues.conditionType}
                error={formErrors.conditionTypeCode}
                onSelect={handleDropdownChange}
                searchable={true}
                onSearch={handleDropdownSearch}
                isLoading={searchLoading.conditionTypeCode}
                required
              />
              <DropdownSelect
                label="Условие"
                id="conditionAnalogCode"
                items={conditionAnalogList}
                defaultValue={
                  defaultValues.market
                    ? `${defaultValues.market} ${conditionData.description || ""}`
                    : null
                }
                onSelect={handleConditionalAnalogDropdownChange}
              />
              <DropdownSelect
                label="Рынок"
                id="marketCode"
                items={marketList}
                defaultValue={defaultValues.market}
                error={formErrors.marketCode}
                onSelect={handleDropdownChange}
                required
              />
              <TextAreaDescription
                label="Описание"
                id="description"
                value={conditionData.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="component-general__footer">
          <div className="main-footer-block__toggles">
            <ToggleSwitch
              label="Скрыть"
              id="hidden"
              checked={conditionData.hidden}
              onToggle={handleInputChange}
            />
          </div>
          <div className="main-footer-block__buttons">
          {isBackToListLinkType ?
          <ModalLinkWrapper
          linkProps={{ to: "/guides", state: { pageCode: 28, withoutReset: true } }}
              className="button button__secondary button__secondary__ghost"
            >
              Вернуться к списку ТС
            </ModalLinkWrapper>
            : 
            <ModalLinkWrapper
              linkProps={{ to: backToProductLink}}
              className="button button__secondary button__secondary__ghost"
            >
              Вернуться к ТС
            </ModalLinkWrapper>}

            <button
              type="submit"
              className="button button__primary"
              disabled={!dataChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default ConditionForm;
