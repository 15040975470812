const recomendationMap = {
  interval: "Интервалы",
  spec: "Спецификации",
  visco: "Вязкости",
};

function normalizeModelComplectMenu(menu) {
  const modelComplectLink = `/model-complect/${menu.modelComplectCode}`;

  return {
    ...menu,
    active: false,
    link: modelComplectLink,
    actionType: "edit",
    children: menu.component
      ? menu.component.map((comp) => {
          const componentLink = `${modelComplectLink}/component/${comp.code}`;
          return {
            ...comp,
            actionType: "edit",
            active: false,
            link: componentLink,
            children: comp.condition
              ? comp.condition.map((cond) => {
                  const activeRecomendations = Object.keys(
                    cond.recomendation
                  ).filter(
                    (recomendation) => cond.recomendation[recomendation]
                  );
                  return {
                    ...cond,
                    componentCode: comp.code,
                    actionType: "edit",
                    active: false,
                    link: `${modelComplectLink}/condition/${cond.code}`,
                    children: activeRecomendations.map((recomendation) => {
                      return {
                        link: `${modelComplectLink}/condition/${cond.code}/${recomendation}`,
                        active: false,
                        name: recomendationMap[recomendation],
                        code: recomendation,
                      };
                    }),
                  };
                })
              : [],
          };
        })
      : [],
  };
}

export default normalizeModelComplectMenu;
