import React, { useRef } from "react";
import "./index.css";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import VisibleFilter from "../../redux/containers/visibleFilter";
import copyIcon from "../../theme/svg/copy_icon_button.svg";
import copyIconDisabled from "../../theme/svg/copy_icon_button_disabled.svg";
import DeleteModal from "../../pureComponents/modal/deleteModal";
import { useDispatch, useSelector } from "react-redux";
import { requestModelTC } from "../../redux/actions/async/modelTC";
import { ModelGuideForm } from "../../guidesForms/modelTC";
import { requestBrend } from "../../redux/actions/async/brend";
import { requestModelDrive } from "../../redux/actions/async/modelDrive";
import { requestVehicleCategory } from "../../redux/actions/async/vehicleCategory";
import { requestVehicleSubCategory } from "../../redux/actions/async/vehicleSubCategory";
import { requestVehicleBodyType } from "../../redux/actions/async/vehicleBodyType";
import { requestMarket } from "../../redux/actions/async/market";
import { requestProducer } from "../../redux/actions/async/producer";
import { requestConditionType } from "../../redux/actions/async/conditionType";
import { requestComponentFuelType } from "../../redux/actions/async/componentFuelType";
import { requestComponentType } from "../../redux/actions/async/componentType";
import { requestComponentGroup } from "../../redux/actions/async/componentGroup";
import { requestIntervalType } from "../../redux/actions/async/intervalType";
import { requestInterval } from "../../redux/actions/async/interval";
import { requestConditionViscoInterval } from "../../redux/actions/async/conditionViscoInterval";
import { BrendGuideForm } from "../../guidesForms/brend";
import { MarketGuideForm } from "../../guidesForms/market";
import { ModelDriveGuideForm } from "../../guidesForms/modelDrive";
import { ProducerGuideForm } from "../../guidesForms/producer";
import { ComponentFuelTypeGuideForm } from "../../guidesForms/componentFuelType";
import { IntervalTypeGuideForm } from "../../guidesForms/intervalType";
import { IntervalGuideForm } from "../../guidesForms/interval";
import { ConditionViscoIntervalGuideForm } from "../../guidesForms/conditionViscoInterval";
import { requestVisco } from "../../redux/actions/async/visco";
import { ViscoGuideForm } from "../../guidesForms/viscosity";
import { requestViscoClass } from "../../redux/actions/async/viscoClass";
import { ViscoClassGuideForm } from "../../guidesForms/viscoClass";
import { GenerationGuideForm } from "../../guidesForms/generation";
import { VehicleCategoryGuideForm } from "../../guidesForms/vehicleCategory";
import { VehicleSubCategoryGuideForm } from "../../guidesForms/vehicleSubCategory";
import { VehicleBodyTypeGuideForm } from "../../guidesForms/vehicleBodyType";
import { ComponentTypeGuideForm } from "../../guidesForms/componentType";
import { ComponentGroupGuideForm } from "../../guidesForms/componentGroup";
import { ConditionTypeGuideForm } from "../../guidesForms/conditionType";
import { requestGeneration } from "../../redux/actions/async/generation";
import { ModalHistoryChanges } from "../../pureComponents/modal/modalHistoryChanges";
import { MarkGuideForm } from "../../guidesForms/mark";
import { requestModelMark } from "../../redux/actions/async/modelMark";
import { copyModelComplect } from "../../redux/actions/async/modelComplect";

import useDownloadFile from "../../hooks/useDownloadFile";
export function GuidesPageControls(props) {
  const fileRef = useRef(null);
  const navigate = useNavigate();

  const [deleteModalActive, setDeleteModalActive] = React.useState(false);
  const [deleteWaiting, setDeleteWaiting] = React.useState(false);
  const [historyModalActive, setHistoryModalActive] = React.useState(false);

  const downloadFile = useDownloadFile();

  React.useEffect(() => {
    if (
      props.modelComplectCode !== null &&
      props.currentTable === "component"
    ) {
      const url =
        "/model-complect/" +
        props.modelComplectCode +
        "/component/" +
        props.currentRow;
      props.dropModelComplectCode();
      navigate(url, { state: { actionType: "edit" } });
    }
  }, [props.modelComplectCode]);


  const navigateToComponent = (componentCode) => {
    if (componentCode) {
      const url =
        "/model-complect/" + componentCode
      navigate(url, {
        state: { actionType: "edit", componentCode: componentCode },
        
      });
    }
  }

  React.useEffect(() => {
    if (props.componentCode) {
      const url =
        "/model-complect/" +
        props.modelComplectCode +
        "/condition/" +
        props.currentRow;
      props.dropModelComplectCode();
      navigate(url, {
        state: { actionType: "edit", componentCode: props.componentCode },
      });
    }
  }, [props.componentCode]);

  const dispatch = useDispatch();
  const modelTC = useSelector((state) => state.modelTC.list[0]);
  const modelMark = useSelector((state) => state.modelMark.list[0]);
  const brend = useSelector((state) => state.brend.list[0]);
  const visco = useSelector((state) => state.visco.list[0]);
  const generation = useSelector((state) => state.generation.list[0]);
  const viscoClass = useSelector((state) => state.viscoClass.list[0]);
  const vehicleCategory = useSelector((state) => state.vehicleCategory.list[0]);
  const vehicleSubCategory = useSelector((state) => state.vehicleSubCategory.list[0]);
  const vehicleBodyType = useSelector((state) => state.vehicleBodyType.list[0]);
  const market = useSelector((state) => state.market.list[0]);
  const producer = useSelector((state) => state.producer.list[0]);
  const interval = useSelector((state) => state.interval.list[0]);
  const intervalType = useSelector((state) => state.intervalType.list[0]);
  const conditionViscoInterval = useSelector((state) => state.conditionViscoInterval.list[0]);
  const modelDrive = useSelector((state) => state.modelDrive.list[0]);
  const componentType = useSelector((state) => state.componentType.list[0]);
  const componentGroup = useSelector((state) => state.componentGroup.list[0]);
  const componentFuelType = useSelector((state) => state.componentFuelType.list[0]);
  const conditionType = useSelector((state) => state.conditionType.list[0]); 
  
  const eye_type = props.currentRowMode ? "-crossed" : "";

  const menuButtonMod = anyRowSelected() ? " button__ghost_active" : "";
  const pointMod = anyRowSelected() ? " menu-img__point_active" : "";
  const eye_mode =
    getControlMode("hidden") && anyRowSelected() ? "-active" : "";
  const pen_mode = getControlMode("edit") && anyRowSelected() ? "-active" : "";
  const history_mode =
    props.currentTable && getControlMode("history") && anyRowSelected()
      ? "-active"
      : "";
  const trash_mode =
    getControlMode("delete") && anyRowSelected() ? "-active" : "";
  const import_data_mod = getControlMode("importData");
  const export_template_mod = getControlMode("exportTemplate");
  const export_data_mod = getControlMode("exportData");
  const copyButtonAvailable = getControlMode("copy") && anyRowSelected();
  const addButtonAvailable = getControlMode("add") && anyGuideSelected();

  const controls_secondary_mod =
    props.currentGuideFilters.length > 0
      ? " guides-page__controls__secondary__active"
      : "";

  const filters = props.currentGuideFilters.map((filter, i) => {
    return <VisibleFilter filter={filter} key={i} />;
  });

  const mainMenuMod = props.showMainMenu
    ? " guides-page__main-menu_active"
    : "";
  const mainMenuWrapperMod = props.showMainMenu
    ? " guides-page__main-menu-wrapper_active"
    : "";

  let searchEnabled = false;

  if (props.allFiltersFilled) {
    if (props.currentSearchValue !== "def") {
      if (props.currentSearchValueType !== "boolean") {
        const enableSearchWithEmptyNameButWithFilters =  !!props.currentGuideFilters.length
        if (props.searchString.length >= 1 || enableSearchWithEmptyNameButWithFilters) {
          searchEnabled = true;
        }
      } else {
        searchEnabled = true;
      }
    } else {
      searchEnabled = props.currentGuideFilters.length;
    }
  }

  function getControlMode(control) {
    return props.currentGuideControls[control];
  }

  function anyRowSelected() {
    return props.currentRow !== "def";
  }

  function anyGuideSelected() {
    return props.currentGuide !== "def";
  }

  function startSearch(e) {
    e.preventDefault();
    if (searchEnabled) {
      props.startSearch();
    }
  }

  function mainMenuClicked() {
    props.switchMainMenuVisible(!props.showMainMenu);
  }

  function eyeClicked() {
    if (anyRowSelected()) {
      props.changeRowMode();
    }
  }

  function startDataImport() {
    props.switchMainMenuVisible(false);
    fileRef.current.click();
  }

  function onImportFile(e) {
    navigate("/guides/load_from_excel", {
      state: {
        file: fileRef.current.files[0],
        tableName: props.currentTable,
        guideName: props.currentGuide,
        guideId: props.currentPageId,
      },
    });
  }

  function startPatternExport() {
    props.switchMainMenuVisible(false);
    props.startTemplateExport((file) =>
      downloadFile(file, `${props.currentTable}.xlsx`)
    );
  }

  function startExportData() {
    props.switchMainMenuVisible(false);
    props.startDataExport((file, fileName) => {
      downloadFile(file, fileName);
    });
  }



  async function handleCopy() {
    if (props.currentGuide === "Модификация ТС") {
      const copiedModelCode =  await copyModelComplect(props.currentRow)
      navigateToComponent(copiedModelCode)
      props.setCurrentRow(copiedModelCode)
    }
    else {
      newGuideElement('copy')
    }
  }



  function newGuideElement(mode) {
    if (
      (anyRowSelected() && (mode === "edit" || mode === "copy")) ||
      (anyGuideSelected() && mode === "new")
    ) {
      const actionType = mode;
      const code = actionType !== "new" ? props.currentRow : "";

      if (props.currentGuide === "Модификация ТС") {
        navigate("/model-complect/" + code, { state: { actionType } });
        return;
      }

      if (props.currentGuide === "Продукт") {
        navigate("/product/" + code, { state: { actionType } });
        return;
      }

      if (props.currentGuide === "Спецификация") {
        navigate("/spec/" + code, { state: { actionType } });
        return;
      }

      if (props.currentGuide === "Узел ТС") {
        const code = props.currentRow;
        props.getComponentInfo(code);
      }

      if (props.currentGuide === "Условие") {
        if (mode === "copy") return;
        const code = props.currentRow;
        props.getConditionInfo(code);
      }

      if (props.currentGuide === "Бренд") {
        dispatch(requestBrend(true, mode, code));
      }

      if (props.currentGuide === "Марка ТС") {
        dispatch(requestModelMark(true, mode, code));
      }

      if (props.currentGuide === "Вязкость") {
        dispatch(requestVisco(true, mode, code));
      }

      if (props.currentGuide === "Классификация вязкостей") {
        dispatch(requestViscoClass(true, mode, code));
      }

      if (props.currentGuide === "Поколение ТС") {
        dispatch(requestGeneration(true, mode, code));
      }

      if (props.currentGuide === "Категория ТС") {
        dispatch(requestVehicleCategory(true, mode, code));
      }

      if (props.currentGuide === "Подкатегория ТС") {
        dispatch(requestVehicleSubCategory(true, mode, code));
      }

      if (props.currentGuide === "Тип кузова") {
        dispatch(requestVehicleBodyType(true, mode, code));
      }

      if (props.currentGuide === "Тип привода") {
        dispatch(requestModelDrive(true, mode, code));
      }

      if (props.currentGuide === "Группа узла") {
        dispatch(requestComponentGroup(true, mode, code));
      }

      if (props.currentGuide === "Тип топлива") {
        dispatch(requestComponentFuelType(true, mode, code));
      }

      if (props.currentGuide === "Тип узла") {
        dispatch(requestComponentType(true, mode, code));
      }

      if (props.currentGuide === "Справочник интервалов") {
        dispatch(requestInterval(true, mode, code));
      }

      if (props.currentGuide === "Температурные интервалы для вязкостей условия") {
        dispatch(requestConditionViscoInterval(true, mode, code));
      }

      if (props.currentGuide === "Тип интервала") {
        dispatch(requestIntervalType(true, mode, code));
      }

      if (props.currentGuide === "Тип условия") {
        dispatch(requestConditionType(true, mode, code));
      }

      if (props.currentGuide === "Производитель") {
        dispatch(requestProducer(true, mode, code));
      }

     if (props.currentGuide === "Рынок") {
        dispatch(requestMarket(true, mode, code));
      }
      
      if (props.currentGuide === "Модель ТС") {
        dispatch(requestModelTC(true, mode));
      } else {
        dispatch(requestModelTC(false, mode));
      }

      props.newGuideElement(mode);
    }
  }

  function delCurrentRow() {
    setDeleteWaiting(true);
    props.delCurrentRow().finally(() => {
      setDeleteModalActive(false);
      setDeleteWaiting(false);
    });
  }

  if (props.currentGuide === "def") {
    return (
      <div className="guides-page__controls">
        <div className="guides-page__controls__standart">
          {/* Имя страницы */}
          <div className="guides-page__control guides-page__control-name guides-page__control_last">
            Все справочники
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="guides-page__controls">
      <div className="guides-page__controls__standart">
        {/* Имя страницы */}
        <div className="guides-page__control guides-page__control-name">
          {props.currentGuide}
        </div>

        {/* Иконки быстрых действий */}
        <div
          className="guides-page__control"
          onClick={() => newGuideElement("edit")}
        >
          <div className={"control__img control__img_pen" + pen_mode}></div>
        </div>
        <div className="guides-page__control">
          <div
            className={"control__img control__img_history" + history_mode}
            onClick={() =>
              getControlMode("history") &&
              anyRowSelected() &&
              setHistoryModalActive(true)
            }
          ></div>
        </div>
        <div className="guides-page__control">
          <div
            className={"control__img control__img_eye" + eye_type + eye_mode}
            onClick={eyeClicked}
          ></div>
        </div>
        <div className="guides-page__control guides-page__control_last">
          <div
            className={"control__img control__img_trash" + trash_mode}
            onClick={() =>
              getControlMode("delete") &&
              anyRowSelected() &&
              setDeleteModalActive(true)
            }
          ></div>
        </div>
        {/* Отступ */}
        <div className="guides-page__control guides-page__control_elastic">
          <div className="guides-page__control-patch"></div>

          <button
            className="button button__small button__primary"
            onClick={() => newGuideElement("new")}
            disabled={!addButtonAvailable}
          >
            Добавить запись
          </button>
          <button
            className="button button__small button__primary"
            onClick={handleCopy}
            disabled={!copyButtonAvailable}
          >
            <img
              src={copyButtonAvailable ? copyIcon : copyIconDisabled}
              alt="Копировать запись"
            />
          </button>
        </div>
        <div
          className={"button button__primary__ghost" + menuButtonMod}
          onClick={mainMenuClicked}
        >
          <div className="guides-page__menu-img">
            <div className={"menu-img__point" + pointMod}></div>
            <div className={"menu-img__point" + pointMod}></div>
            <div className={"menu-img__point" + pointMod}></div>
          </div>
          <div
            className={"guides-page__main-menu-wrapper" + mainMenuWrapperMod}
            onClick={mainMenuClicked}
          >
            <div className={"guides-page__main-menu" + mainMenuMod}>
              <div className="guides-page__main-menu-section guides-page__main-menu-section_small">
                <div
                  className="guides-page__main-menu-button-active"
                  onClick={() => props.changeHiddenMode(props.dataLoaded)}
                >
                  <div
                    className={
                      "main-menu-button__img" +
                      (props.hiddenMode
                        ? " main-menu-button__img_eye-crossed"
                        : " main-menu-button__img_eye")
                    }
                  ></div>
                  <div className="main-menu-button__caption">
                    {props.hiddenModeCaption}
                  </div>
                </div>
              </div>
              {(import_data_mod || export_template_mod || export_data_mod) && (
                <div className="guides-page__main-menu-section guides-page__main-menu-section_large">
                  {import_data_mod && (
                    <div
                      className="guides-page__main-menu-button-active"
                      onClick={startDataImport}
                    >
                      <div className="main-menu-button__img main-menu-button__img_download-active"></div>
                      <div className="main-menu-button__caption">
                        Импортировать
                      </div>
                      <input
                        type="file"
                        className="file-input"
                        ref={fileRef}
                        hidden
                        onChange={onImportFile}
                      />
                    </div>
                  )}
                  {export_template_mod && (
                    <div
                      className="guides-page__main-menu-button-active"
                      onClick={startPatternExport}
                    >
                      <div className="main-menu-button__img main-menu-button__img_upload-active"></div>
                      <div className="main-menu-button__caption">
                        Экспортировать шаблон
                      </div>
                    </div>
                  )}
                  {export_data_mod && (
                    <div
                      className="guides-page__main-menu-button-active"
                      onClick={startExportData}
                    >
                      <div className="main-menu-button__img main-menu-button__img_upload-active"></div>
                      <div className="main-menu-button__caption">
                        Экспортировать данные
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={"guides-page__controls__secondary" + controls_secondary_mod}
      >
        <div className="guides-page__controls__filtres">{filters}</div>

        <div className="guides-page__controls__search">
          <VisibleFilter
            filter={{ id: "guides" }}
            blocked={!props.allFiltersFilled}
          />

          <form className="guides-page__form" onSubmit={startSearch}>
            <input
              className="guides-page__input"
              value={props.searchString}
              type="text"
              disabled={
                props.currentSearchValue === "def" ||
                props.currentSearchValueType === "boolean" ||
                props.currentSearchValueType === ""
              }
              onChange={(e) => props.setSearchString(e.target.value)}
            />
            <div className="guides-page__input-img"></div>
          </form>

          <div className="guides-page__control-item switch__container">
            <label className="switch-block">
              <input
                className="switch-block__input"
                type="checkbox"
                checked={props.searchCheckbox}
                onChange={(e) => props.switchSearchCheckbox(e.target.checked)}
                disabled={
                  props.currentSearchValue === "def" ||
                  props.currentSearchValueType !== "boolean" ||
                  props.currentSearchValueType === ""
                }
              ></input>
              <span className="switch-block__slider switch-block__slider__round"></span>
            </label>
          </div>
          <button
            className="button button__primary"
            onClick={startSearch}
            disabled={!searchEnabled}
          >
            Поиск
          </button>
        </div>
      </div>
      {/*<VisibleMarkGuideForm/>*/}
      {/* <VisibleFileDownloader /> */}
      <DeleteModal
        active={deleteModalActive}
        title="Удалить запись?"
        subtitle={
          props.deleteCascade ? "Будут удалены все зависимые объекты." : ""
        }
        onDelete={delCurrentRow}
        closeModal={() => setDeleteModalActive(false)}
        waiting={deleteWaiting}
      />
      <ModalHistoryChanges
        active={historyModalActive}
        data={props}
        tableName={props.currentTable}
        rowCode={props.currentRow}
        listType="hist"
        closeModal={() => setHistoryModalActive(false)}
      />
      {brend?.active && <BrendGuideForm data={props} />}
      {generation?.active && <GenerationGuideForm data={props} />}
      {modelMark?.active && <MarkGuideForm data={props} />}
      {modelTC?.active && <ModelGuideForm data={props} />}
      {visco?.active && <ViscoGuideForm data={props} />}
      {viscoClass?.active && <ViscoClassGuideForm data={props} />}
      {vehicleCategory?.active && <VehicleCategoryGuideForm data={props} />}
      {vehicleSubCategory?.active && <VehicleSubCategoryGuideForm data={props} />} 
      {vehicleBodyType?.active && <VehicleBodyTypeGuideForm data={props} />} 
      {market?.active && <MarketGuideForm data={props} />} 
      {producer?.active && <ProducerGuideForm data={props} />} 
      {intervalType?.active && <IntervalTypeGuideForm data={props} />} 
      {interval?.active && <IntervalGuideForm data={props} />}      
      {conditionViscoInterval?.active && <ConditionViscoIntervalGuideForm data={props} />} 
      {modelDrive?.active && <ModelDriveGuideForm data={props} />} 
      {componentType?.active && <ComponentTypeGuideForm data={props} />} 
      {componentGroup?.active && <ComponentGroupGuideForm data={props} />} 
      {componentFuelType?.active && <ComponentFuelTypeGuideForm data={props} />} 
      {conditionType?.active && <ConditionTypeGuideForm data={props} />}       
    </div>
  );
}
