import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestHistoryChanges } from "../../../redux/actions/async/historyChanges";
import iconClose from "../../../theme/svg/close_icon.svg";
import { Waiter } from "../../../components/waiter";
import "./index.scss";

export function ModalHistoryChanges(props) {
  const columnsNamesConstTable = [
    { code: "login", name: "Автор изменения" },
    { code: "changeDate", name: "Дата изменения" },
    { code: "actionType", name: "Действие" },
  ];
  const columnsNamesConstTableDecoupleColumns = [
    ...columnsNamesConstTable,
  ];


  const locale = useSelector((state) => state.i18.current);
  const dispatch = useDispatch();
  const wrapper_mod = props.active ? " modal-history__wrapper_active" : "";
  const data = useSelector((state) => state.historyChanges);

  const hasDecoupledColumns = !!data.columns.some(col=>col.decouplingTable)
  let columns = null;
  let decoupleColumns = null;
  let rows = null;
  let screenWidth = null;
  let empty;


  
  useEffect(() => {
    if (props.active) {
      dispatch(requestHistoryChanges(props.tableName, props.rowCode));
    }
  }, [props.active]);

  function generateTableHeader(col) {

    const cl = "container-table__th";
    col.forEach((item) => {
      columnsNamesConstTable.push(item);
    });
    
    

    const simpleTableColumns = columnsNamesConstTable.map((el, i) => (
      <th className={cl} key={`simpleTableColumn_${i}`}>
        {el.name}
      </th>))

    const secondRowForDecoupledTable = columnsNamesConstTable.filter(col=>!!col.decouplingTable).map((el, i) => (
      <th className={cl} key={`secondRow_${i}`}>
            {el.name}
      </th> 
      ));

    return !hasDecoupledColumns ? simpleTableColumns : secondRowForDecoupledTable
  }


  function generateDecoupleTableHeader(col) {
    const cl = "container-table__th";
    col.forEach((item) => {
      if(!item.decouplingTable || !columnsNamesConstTableDecoupleColumns.find(column=>column.decouplingTable === item.decouplingTable)){
          columnsNamesConstTableDecoupleColumns.push(item);
      }
 
    });
      
    return columnsNamesConstTableDecoupleColumns.map((el, i) => !!el?.decouplingTable ? 
          <th className={cl} colSpan={el?.colspan} key={`decouplingTableColumn_${i}`}>
            {el?.decouplingTable}
          </th> :
          <th className={cl} key={`decouplingTableColumn_${i}`} rowSpan={2}>
            {el.name}
          </th>
    );
  }

  

  function generateTableRows(data) {
    return data.map((str, i) => {
      const tr_content = columnsNamesConstTable.map((column, index) => {
      const cellValue = str[`${column.code}Name`]?.value || str[column.code]

        if (
          i === 0 ||
          index < 3 ||
          str[column.code] === data[i - 1][column.code] ||
          str.actionType === "Создание" // TODO do refactoring
        ) {
          return (
            <td
              key={index}
              className={`container-table__td container-table__td`}
            >
              {cellValue}
            </td>
          );
        }

        return (
          <td
            key={index}
            className={`container-table__td container-table__td-color`}
          >
               {cellValue}
          </td>
        );
      });
      return <tr key={i}>{tr_content}</tr>;
    });
  }

  if (data.list.length > 0) {
    empty = false;
    screenWidth =
      data.columns.length + columnsNamesConstTable.length > 4
        ? document.documentElement.clientWidth - 360
        : (document.documentElement.clientWidth - 360) / 2;
    columns = generateTableHeader(data.columns, columnsNamesConstTable);
    decoupleColumns = hasDecoupledColumns && generateDecoupleTableHeader(data.columns, columnsNamesConstTableDecoupleColumns);
    
    rows = generateTableRows(data.list);
  } else {
    empty = true;
  }

  return (
    <div className={"modal-history__wrapper" + wrapper_mod}>
      <div className="container-block" style={{ width: screenWidth }}>
        <div className="container-block__header">
          <div className="container-block__header-names">
            <div className="container-block__header-name">
              История изменений
            </div>
            <div className="container-block__header-name">
              Код {props.rowCode}
            </div>
          </div>
          <div className="container-block__close" onClick={props.closeModal}>
            <img src={iconClose} alt="Закрыть" />
          </div>
        </div>
        <div className="container-block__content">
          {data.isLoading ? (
            <Waiter size="large" />
          ) : (
            <>
              <table
                className={empty ? "container-table-none" : "container-table"}
              >
                <thead>
                  {!!decoupleColumns && <tr key={'decoupleColumns'}>{decoupleColumns}</tr>}         
                  <tr key={'columns'}>{columns}</tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
              {data?.list?.length === 0 && (
                <div className="container-table-empty">
                  {locale === "RU" ? "Нет данных" : "No data"}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="modal-history__glass" onClick={props.closeModal}></div>
    </div>
  );
}
