function getNameFromList(list, code = null) {
  if (!Array.isArray(list) || !code) {
    return null;
  }
  const item = list.find((item) => {
    return item.code === code;
  });

  return item ? item.name : null;
}

export default getNameFromList;
