import { RECEIVE_GENERATION } from "../actions/sync/generation";

const initialState = {
	list: [],
};

function generation(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_GENERATION:
			return Object.assign({}, state, { list: action.payload });
		default:
			return state;
	}
}

export default generation;
