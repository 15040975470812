import React, {useEffect, useState} from 'react';
import '../styles/index.scss';

import {useDispatch, useSelector} from 'react-redux'
import iconClose from "../../theme/svg/close_icon.svg";
import DropdownSelect from "../../pureComponents/dropdownSelect";
import getNameFromList from "../../utils/getNameFromList";
import {requestInterval} from "../../redux/actions/async/interval";
import {fetchFromApi} from "../../redux/actions/service";
import {initNotificationMessage, showNotificationMessage} from "../../redux/actions/sync/notificationMessage";
import {API} from "../interval/api";
import CodeInputField from "../../pureComponents/codeInputField";
import FormInputField from "../../pureComponents/formInputField";
import ToggleSwitch from "../../pureComponents/toggleSwitch";
import ConfirmModal from "../../pureComponents/modal/confirmModal";
import guidsFormIntervalSchema from "../../validations/guidsFormInterval";
import {requestGuideTableBody} from "../../redux/actions/async/guides";
import {waitForGuideData} from "../../redux/actions/sync/guides";

const initialState = {
    id: null,
    code: null,
    intervalTypeCode: null,
    val: null,
    hidden: false
}
const avaliableFields = {
    code: 'code',
    val:'val',
    intervalTypeCode: 'intervalTypeCode',
    hidden: 'hidden'
}




export function IntervalGuideForm(props) {
    const state = useSelector(state => state );
    const store = useSelector(state => state.interval.list[0]);
    const dispatch = useDispatch();
    const [fields, setFields] = useState(initialState);
    const [active_modal, setActive_modal] = useState(null);
    const [intervals, setIntervals] = useState([]);
    const [changes, setChanges] = useState(false);
    const [errors, setErrors] = useState([]);
    const [changedFields, setChangedFields] = useState([]);
	const [isInitState, setIsInitState] = useState(true);

    useEffect(() => {
        store.type === 'edit' && startEditCopy();
        store.type === 'copy' && startEditCopy();
        store.type === 'new' && startNew();
    },[]);

    useEffect(() => {
        if(!changes) {
            setChanges(true);
            updateChangedFields('name');
        }
    },[changes]);


    function startEditCopy() {
		Promise.all([
				API.getInterval(props.data.currentRow),
				API.getIntervalTypes({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
			 	API.getCode(),
			]
		).then((response) => {
			setIntervals(response[1].data);

			setFields({ ...fields,
				id: store.type === 'copy' ? null : response[0]?.id,
				code: store.type === 'copy' ? response[2] : response[0]?.code ,
				intervalTypeCode: response[0]?.intervalTypeCode,
                val: response[0]?.val,
				hidden: response[0]?.hidden || false
			});
			if (store.type === 'copy') {
				setChangedFields(Object.values(avaliableFields));
				setChanges(true);
			}
		})
	}


    function startNew() {
        Promise.all([
            API.getCode(),
            API.getIntervalTypes({ lang: state.i18.current, hidden: true, offset: 0, limit: 100, }),
        ]
    ).then(response => {
        setFields({ ...fields, code: response[0] })
        setIntervals(response[1].data);
        setChangedFields(['code','hidden']);
    });
    
    }


    function changeDropdown(item, id) {
		setFields(prev => ({...prev, [id]:item.code }));
		setErrors(prev => ({...prev, [id]:null }));
		if(isInitState) setIsInitState(false);
		setChanges(true);
		updateChangedFields(id);
	}


    function updateChangedFields(id) {
        if (avaliableFields[id] && !changedFields.includes(avaliableFields[id])) {
            setChangedFields((prev) => [...prev, avaliableFields[id]]);
        }
    }

    function changeInput(event) {
        setChanges(true);
        updateChangedFields(event.target.id);
        if(event.target.id === 'hidden') {
            setFields({ ...fields, [event.target.id]: !fields.hidden });
        } else {
            setFields({ ...fields, [event.target.id]: event.target.value });
            setErrors(prev => ({...prev, [event.target.id]:null }));
        }
    }

    function createCode() {
        API.getCode().then((response) => {
            setFields(prev => ({...prev, code: response }));
        });
    }

 

    async function save() {
        const isFormValid = await guidsFormIntervalSchema.isValid( fields,{abortEarly: false} );
        if(isFormValid) {
            saveChanges({
				id: fields.id,
				code: fields.code,
				intervalTypeCode: fields.intervalTypeCode,
                val: +fields.val,
				hidden: fields.hidden,
			}).then();

        } else {
            if (!isFormValid) {
                guidsFormIntervalSchema
                    .validate(fields, { abortEarly: false })
                    .catch((err) => {
                        const errors = err.inner.reduce((acc, error) => {
                            return {
                                ...acc,
                                [error.path]: error.message,
                            };
                        }, {});
                        setErrors(() => {
                            return {
                                ...errors,
                            };
                        });
                    });
            }
        }
    }

    async function saveChanges(body) {
        await fetchFromApi("api/v1/interval","PUT",{body})
        .then((res) => {
            if (res.status === 200) {
                dispatch(waitForGuideData(true));
                dispatch(requestInterval(false, null, res.id));
                dispatch(requestGuideTableBody());
            } else {
                dispatch(
                    initNotificationMessage(
                        'Ошибка',
                        'Произошла системная ошибка. Обратитесь к администратору системы',
                        'danger'
                    )
                )
                dispatch(showNotificationMessage());
            }
        })
        .catch((e) => {
            console.log(e);
            dispatch(
                initNotificationMessage(
                    'Ошибка',
                    'Произошла системная ошибка. Обратитесь к администратору системы',
                    'danger'
                )
            );
            dispatch(showNotificationMessage());
        });
    }

    function close() {
        if(!changes) {
            onContinueWithoutSave(null);
        } else {
            setActive_modal('modalTC__wrapper-active');
        }
    }

    function closeApprowal(value) {
        setActive_modal(null);
    }

    function onContinueWithoutSave(value) {
        setFields(initialState);
        setActive_modal(null);
        dispatch(requestInterval(false, null, null));
    }

    return (
        <div className={"guide__wrapper guide__wrapper_active"}>
            <div className="guide-block">
                {/* Header */}
                <div className="guide-block__header">
                    <div className="guide-block__header__name">{props.data.currentGuide}</div>
                    <div className="guide-block__control guide-block__control_elastic">
                        <CodeInputField
                            id={'code'}
                            label={'Код'}
                            disabled={store.type === 'edit'}
                            error={errors.code}
                            value={fields.code}
                            onChange={changeInput}
                            required={true}
                        />
                    </div>
                    <button
                        className={"button button__primary button__small"}
                        disabled={store?.type === 'edit'}
                        onClick={() => createCode()}>
                        Создать код
                    </button>

                    {/* X button */}
                    <div className="guide-block__close" onClick={() => close()}>
                        <img src={iconClose} alt="Закрыть" />
                    </div>
                </div>

                {/* Body */}
                <div className="guide-block__content">
                <DropdownSelect
                        id={'intervalTypeCode'}
                        label={'Тип интервала'}
                        defaultValue={getNameFromList(intervals, fields.intervalTypeCode)}
                        error={errors.intervalTypeCode}
                        items={intervals}
                        onSelect={changeDropdown}
                        required={true}
                    />
     
                    <FormInputField
                        id='val'
                        label='Значение'
                        value={fields.val}
                        error={errors.val}
                        onChange={changeInput}
                        required={true}
                        inputMask="positive-integer"
                    />
                    <div className="guide-block__content__divider"></div>
                </div>

                <div className="guide-block__bottom">
                    <ToggleSwitch
                        id={'hidden'}
                        label={'Скрыть'}
                        onToggle={changeInput}
                        checked={fields.hidden}
                    />

                    <div className="buttons__group">
                        <div
                            className="button button__secondary button__secondary__ghost"
                            onClick={() => onContinueWithoutSave()}
                        >
                            Не сохранять
                        </div>

                        <button
                            disabled={!changes}
                            className={"button button__primary"}
                            onClick={() => save()}
                        >
                            Сохранить изменения
                        </button>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div className={"modalTC__wrapper "}>
                <ConfirmModal
                    active={active_modal}
                    onSave={save}
                    onContinueWithoutSave={onContinueWithoutSave}
                    closeModal={closeApprowal}
                />
            </div>
        </div>
    );
}