import { useCallback, useRef } from "react";
import ClearFieldIcon from "../../icons/ClearFieldIcon";
import DatePickerIcon from "../../icons/DatePickerIcon";
import useDateFormat from "../../hooks/useDateFormat";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import "./index.scss";
import "./_calendar.scss";

const DatePickerContainer = ({
  label,
  id,
  onChangeData,
  placeholder = "Выберите",
  required = false,
  error = null,
  value = null,
  children,
  isOpen,
  setOpen,
  disabled,
}) => {
  const blockRef = useRef(null);
  const localeDate = useDateFormat();

  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  useOnClickOutside(blockRef, onClickOutside);

  function handleOnClear() {
    onChangeData(null, id);
  }

  return (
    <>
      <div className="date-picker" ref={blockRef}>
        <label htmlFor={id} className="date-picker__label">
          <span>{label}</span>
          {required && (
            <>
              &nbsp;
              <span className="required">*</span>
            </>
          )}
        </label>
        <div
          className={`date-picker__field${
            error ? " date-picker__field--error" : ""
          }${disabled ? " disabled" : ""}`}
        >
          {value ? (
            <span onClick={() => setOpen((prev) => !prev)}>{value}</span>
          ) : (
            <span
              className="date-picker__placeholder"
              onClick={() => setOpen((prev) => !prev)}
            >
              {placeholder}
            </span>
          )}
          <div className="date-picker__icons">
            {value && (
              <button
                type="button"
                className="date-picker__icon"
                tabIndex={0}
                onClick={() => handleOnClear()}
              >
                <ClearFieldIcon />
              </button>
            )}
            <button
              type="button"
              className="date-picker__icon"
              onClick={() => setOpen((prev) => !prev)}
            >
              <DatePickerIcon />
            </button>
          </div>
        </div>
        <div className="date-picker__error">{error}</div>
        {isOpen && <div className="date-picker__calendar">{children}</div>}
      </div>
    </>
  );
};

export default DatePickerContainer;
