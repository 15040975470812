import React from "react";
import formatInputValue from "../../validations/formInputField";
import "./index.scss";

const FormInputField = React.memo(
  ({
    label,
    id,
    value = "",
    type = "text",
    placeholder = "Введите значение",
    onChange,
    onEnterDown,
    required = false,
    disabled = false,
    error = null,
    inputMask = null,
    isErrorsEnabled = true
  }) => {
    const handleOnClear = () => {
      onChange({ target: { id, type, value: "" } });
    };

    const handleEnterKey = (event) => {
        onEnterDown(event);
    }

    const handleOnChange = (e) => {
      if (inputMask) {
        const formattedValue = formatInputValue(e.target.value, inputMask);
        e.target.value = formattedValue;
      }

      onChange(e);
    };

    const showClearButton = value && value.length > 0 || value === 0 

    return (
      <div className="form-input">
        <label htmlFor={id} className="form-input__label">
          <span>{label}</span>
          {required && (
            <>
              &nbsp;
              <span className="required">*</span>
            </>
          )}
        </label>
        <div className="form-input__element">
          <input
            value={value}
            id={id}
            type={type}
            placeholder={placeholder}
            className={`form-input__field${
              error ? " form-input__field--error" : ""
            }`}
            onChange={(e) => handleOnChange(e)}
            onKeyDown={event => event.key === 'Enter' && handleEnterKey(event)}
            disabled={disabled}
          />
          {disabled || (
            <div className="form-input__icons">
              {showClearButton && (
                <button
                  type="button"
                  className="button button__secondary button__secondary__ghost form-input__icon"
                  tabIndex={0}
                  onClick={() => handleOnClear()}
                >
                  <div className="clear-field-icon"></div>
                </button>
              )}
              {error && (
                <div className="form-input__icon">
                  <div className="alert-icon"></div>
                </div>
              )}
            </div>
          )}
        </div>

        {isErrorsEnabled && <div className="form-input__error">{error}</div>}
      </div>
    );
  }
);

export default FormInputField;
