import { connect } from 'react-redux';
import { BreadCrumbs } from '../../components/breadCrumbs';
import { resetSearch } from '../actions/async/search';
import { setSearchStage } from '../actions/sync/search';

const mapStateToProps = (state) => {
  return {
    searchMode: state.search.mode,
    searchStage: state.search.stage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSearch: () => dispatch(resetSearch()),
    setSearchStage: (stage) => dispatch(setSearchStage(stage))
  };
};

const VisibleBreadCrumbs = connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadCrumbs);

export default VisibleBreadCrumbs;
