import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFileDataWithDiff,
  saveImportData,
} from "../../redux/actions/async/importFile";
import { Waiter } from "../../components/waiter";
import {
  resetImportFileData,
  setImportFileData,
} from "../../redux/reducers/importFile";
import DbTable from "./DbTable";
import XlsTable from "./XlsTable";
import VisibleHeader from "../../redux/containers/visibleHeader";
import VisibleSide from "../../redux/containers/visibleSide";
import VisibleNotificationMessage from "../../redux/containers/visibleNotificationMessage";
import "./index.scss";

const LoadFromExcelPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const file = state?.file;
  const tableName = state?.tableName;
  const guideName = state?.guideName;
  const pageCode = state?.guideId;

  const dispatch = useDispatch();
  const importFileData = useSelector(
    ({ importFile }) => importFile,
    shallowEqual
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const allRowsSelected =
    selectedRows.length === importFileData.dataFromFile.length;

  const dbTable = useRef(null);
  const xlsTable = useRef(null);

  useEffect(() => {
    if (!file || !tableName) {
      navigate("/");
    }

    dispatch(getFileDataWithDiff(file, tableName, () => {
      navigate("/guides", { state: { pageCode } });
    }));

    return () => dispatch(resetImportFileData());
  }, []);

  function setTableOffset(e) {
    const offset = e.target.scrollLeft;

    dbTable.current.scrollLeft = offset;
    xlsTable.current.scrollLeft = offset;
  }

  function selectRow(id) {
    const row = selectedRows.find((rowId) => rowId === id);

    if (row) {
      setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
      return;
    }

    setSelectedRows((prev) => [...prev, id]);
  }

  function selectAllRows() {
    if (allRowsSelected) {
      setSelectedRows([]);
      return;
    }

    const dataIds = importFileData.dataFromFile
      .filter((item) => !selectedRows.includes(item.idXls))
      .map((item) => item.idXls);

    setSelectedRows((prev) => [...prev, ...dataIds]);
  }

  function deleteSelectedRows() {
    const dataFromFile = importFileData.dataFromFile.filter(
      (item) => !selectedRows.includes(item.idXls)
    );

    if (dataFromFile.length === 0) {
      navigate("/guides", { state: { pageCode } });
    }

    const dataFromDB = dataFromFile.filter((item) => item.idDb);

    dispatch(
      setImportFileData({
        ...importFileData,
        dataFromFile,
        dataFromDB,
      })
    );
    setSelectedRows([]);
  }

  function save() {
    const selectedFileData = importFileData.dataFromFile.filter((item) =>
      selectedRows.includes(item.idXls)
    );
    dispatch(
      saveImportData(tableName, selectedFileData, () => {
        deleteSelectedRows();
      })
    );
  }

  return (
    <div className="import-page">
      <VisibleHeader access={false} />
      <div className="import-page__content">
        <VisibleSide access={false} />
        <div className="import-page__work-area">
          <div className="work-area__head">
            <div className="work-area__title import-page__guide-name">
              {guideName}
            </div>
            <div className="work-area__title import-page__file-name">
              {importFileData.fileName}
            </div>
          </div>
          <div className="work-area__content">
            <div
              ref={dbTable}
              className="work-area__item import-page__guide-grid"
              onScroll={setTableOffset}
            >
              {!importFileData.fetching &&
                importFileData.dataFromDB.length !== 0 && (
                  <DbTable
                    data={importFileData.dataFromDB}
                    columns={importFileData.columns}
                    vocabulary={importFileData.vocabulary}
                  />
                )}
            </div>
            <div
              ref={xlsTable}
              className="work-area__item import-page__file-grid"
              onScroll={setTableOffset}
            >
              {importFileData.fetching ? (
                <Waiter size="large" />
              ) : (
                <XlsTable
                  data={importFileData.dataFromFile}
                  columns={importFileData.columns}
                  vocabulary={importFileData.vocabulary}
                  selectAllRows={selectAllRows}
                  allRowsSelected={allRowsSelected}
                  selectedRows={selectedRows}
                  selectRow={selectRow}
                />
              )}
            </div>
          </div>

          <div className="import-page__buttons">
            <div className="buttons__group import-page__actions">
              <button
                className="button button__negative"
                onClick={deleteSelectedRows}
                disabled={selectedRows.length === 0}
              >
                Удалить выбранное
              </button>
              <div
                className="button button__secondary__ghost"
                onClick={() => navigate("/guides", { state: { pageCode } })}
              >
                Вернуться в справочник
              </div>
              <button
                className="button button__primary"
                onClick={save}
                disabled={selectedRows.length === 0}
              >
                Применить
              </button>
            </div>
          </div>
        </div>
      </div>
      <VisibleNotificationMessage />
    </div>
  );
};

export default LoadFromExcelPage;
