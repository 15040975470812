import { receiveVehicleCategory } from "../sync/vehicleCategory";
export function requestVehicleCategory(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveVehicleCategory([data]))
	};
}
