import { fetchFromApi } from "../service";
import { receiveModelList, updateModelList } from "../sync/model";

export function requestModelList(data, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;
    const url = "api/v1/model/filter-by-mark/page";

    const body = {
      offset: 0,
      limit: 100,
      lang: locale,
      name: data.searchStr,
    };

    if (data?.modelMarkCode) {
      body.markCode = data.modelMarkCode;
    }


    try {
      const response = await fetchFromApi(url, "POST", { body });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const resp = await response.json();

      dispatch(receiveModelList({ data:resp.data, isModelComplectPage:false }));

      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
}

export function getModels(searchStr = null, callback = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = "api/v1/model/filter";
    const body = {
      lang: state.i18.current,
      hidden: true,
      searchStr,
    };

    try {
      const response = await fetchFromApi(url, "POST", { body });
      const data = await response.json();

      if (callback) {
        callback(data);
        return;
      }

      dispatch(receiveModelList(data));
    } catch (error) {}
  };
}

export function selectModelList(id) {
  return (dispatch) => {
    return new Promise((res) => {
      dispatch(updateModelList(id));
    });
  };
}
