export const SET_PRODUCER_LIST = "SET_PRODUCER_LIST";
export const setProducerList = (payload) => {
  return {
    type: SET_PRODUCER_LIST,
    payload,
  };
};


export const RECEIVE_PRODUCER = 'RECEIVE_PRODUCER'
export function receiveProducer(payload) {
	return {
		type: RECEIVE_PRODUCER,
		payload
	}
}
