import { fetchFromApi } from "../service";
import { setComponentTypeList, receiveComponentType} from "../sync/componentType";

export function getComponentTypeList(
  componentGroupCode = null,
  componentTypeCode = null,
  searchString = null,
  callback = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.i18.current;

    const body = {
      lang: locale,
      hidden: true,
      limit: 100,
      offset: 0,
      componentTypeCode,
      componentGroupCode
    };

    if (searchString) {
      body.searchString = searchString;
    }

    try {
      const response = await fetchFromApi("api/v1/component-type/wide-filter", "POST", { body });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = (await response.json()).data;

      dispatch(setComponentTypeList(data));

      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
}

export function requestComponentType(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveComponentType([data]))
	};
}