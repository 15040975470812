import { RESET_APP } from "../actions/sync/init";
import {
  CLEAR_SEARCH_RESULT,
  RECIVE_SEARCH_BY_STRING_RESULT,
  RECIVE_SEARCH_BY_FILTER_RESULT,
  RECIVE_TABLE_COLUMNS,
  SET_SEARCH_MODE,
  SET_SEARCH_TYPE,
  UPDATE_SEARCH_STRING,
  SHIFT_SEARCH_OFFSET,
  RESET_SEARCH_OFFSET,
  ADD_SEARCH_BY_FILTER_RESULT,
  WAIT_FOR_SEARCH_DATA,
  SET_SEARCH_STAGE,
  DROP_SEARCH_RESULT,
} from "../actions/sync/search";

const defState = {
  type: "technic",
  string: "",
  result: [],
  columns: [],
  mode: "",
  offset: 0,
  limit: 25,
  fetching: false,
  stage: "home",
  hasMore: false,
};

function search(state = defState, action) {
  switch (action.type) {
    case SET_SEARCH_TYPE:
      return Object.assign({}, state, { type: action.id });
    case SET_SEARCH_MODE:
      return Object.assign({}, state, { mode: action.mode });
    case RECIVE_SEARCH_BY_STRING_RESULT:
      if (Object.keys(action.json).length === 0) {
        return Object.assign({}, state, { result: [] });
      }

      const stringPage = action.json.data ?? [];
      return Object.assign({}, state, {
        result: stringPage,
        hasMore: stringPage.length >= state.limit,
        minYearStart: action.json.minYearStart,
        maxYearEnd: action.json.maxYearEnd,
      });
    case RECIVE_SEARCH_BY_FILTER_RESULT:
      if (Object.keys(action.json).length === 0) {
        return Object.assign({}, state, { result: [], hasMore: false });
      }
      const filterPage = action.json.data ?? [];
      return Object.assign({}, state, {
        result: filterPage,
        hasMore: filterPage.length >= state.limit,
      });
    case ADD_SEARCH_BY_FILTER_RESULT:
      const addingPage = action.json.data ?? [];
      return Object.assign({}, state, {
        result: [...state.result, ...addingPage],
        hasMore: addingPage.length >= state.limit,
      });
    case RECIVE_TABLE_COLUMNS:
      return Object.assign({}, state, { columns: action.json });
    case UPDATE_SEARCH_STRING:
      return Object.assign({}, state, { string: action.value });
    case SHIFT_SEARCH_OFFSET:
      return Object.assign({}, state, { offset: state.offset + state.limit });
    case RESET_SEARCH_OFFSET:
      return Object.assign({}, state, { offset: 0 });
    case WAIT_FOR_SEARCH_DATA:
      return Object.assign({}, state, {
        fetching: action.mode,
      });
    case SET_SEARCH_STAGE:
      return Object.assign({}, state, { stage: action.stage });
    case DROP_SEARCH_RESULT:
      return Object.assign({}, state, {
        columns: [],
        result: [],
        offset: defState.offset,
        hasMore: false,
        minYearStart: null,
        maxYearEnd: null,
      });
    case CLEAR_SEARCH_RESULT:
      return defState;
    case RESET_APP:
      return defState;
    default:
      return state;
  }
}

export default search;
