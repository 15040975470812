import { RECEIVE_MODEL_BODY_LIST } from "../actions/sync/modelBody";

const initialState = {
  list: [],
};

function modelBody(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_MODEL_BODY_LIST:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}

export default modelBody;
