import { connect } from "react-redux";
import { ConditionTable } from "../../components/conditionTable";

const mapStateToProps = (state, ownProps) =>{
    return{
        
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {}
}

const VisibleConditionTable = connect(mapStateToProps, mapDispatchToProps)(ConditionTable);

export default VisibleConditionTable;