import React from "react";
import "./index.scss";

const Checkbox = ({ code, name = null, checked = false, hidden =false, onChange }) => {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={code}
        checked={checked}
        onChange={(e) => onChange(e)}
      />
      <label
        htmlFor={code}
        className={hidden ? "hidden" : ""}
      >
        {name}
      </label>
    </div>
  );
};

export default Checkbox;
