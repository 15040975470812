import { connect } from 'react-redux';
import { DoubleDescription } from '../../components/doubleDescription';
import {
  setActiveDescription,
  switchFullDescription,
} from '../actions/sync/description';

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.descriptions.by_id[ownProps.id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchFullDescription: (id) => dispatch(switchFullDescription(id)),
    setActiveDescription: (id, count) =>
      dispatch(setActiveDescription(id, count)),
  };
};

const VisibleDoubleDescription = connect(
  mapStateToProps,
  mapDispatchToProps
)(DoubleDescription);

export default VisibleDoubleDescription;
