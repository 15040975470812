import {
  fetchFromApiAuth, fetchFromApi, encodeFormData, getRootUrl
} from '../service';
import {
  changeAuthStage,
  setAuthError,
  setRefresh,
} from '../sync/auth';
import { resetApp } from '../sync/init';
import { requestUserData } from './personal';
import { getApplicationSettings } from './settings';

export function getAccessToken() {
  return (dispatch) => {
    return new Promise((res, rej) => {
      const accessToken = localStorage.getItem(`access_token`);
      if ( accessToken ) {
        res(accessToken);
      } else {
        rej();
      }
    })
      .then(() => {
        dispatch(requestUserData());
      }).then(()=>{
        dispatch(getApplicationSettings());
      })
  };
}

export function getRefreshToken() {
  return (dispatch) => {
    return new Promise((res, rej) => {
      const refreshToken = localStorage.getItem(`refresh_token`);
      if ( refreshToken ) {
        res(refreshToken);
      }
      rej();
    })
      .then((refresh) => {
        dispatch(setRefresh(refresh));
      })
      .then(() => {
        dispatch(getAccessToken());
      })
      .catch(() => {
        dispatch(changeAuthStage('logout'));
      });
  };
}

export function refreshTokens(refreshTokensParams) {
  const token = localStorage.getItem(`refresh_token`);
  if( !token ) {
    throw new Error('no refresh token');
  }
  return fetch(getRootUrl() + 'realms/gpsm/protocol/openid-connect/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: encodeFormData({
      client_id: 'gpsm_app',
      grant_type: 'refresh_token',
      refresh_token: token,
    })
  })
    .then((res) => {
    if (res.status === 200) {
     return  res.json();
    } else {
      localStorage.removeItem(`access_token`);
      localStorage.removeItem(`refresh_token`);
      window.location.reload();
    }})
    .then((res) => {
        localStorage.setItem(`access_token`,  res[`access_token`]);
        localStorage.setItem(`refresh_token`, res[`refresh_token`]);
        return fetchFromApi(...refreshTokensParams);
    });
}

export function requestTokensFromServer() {
  return (dispatch, getState) => {
    const state = getState();
    const body = {
      client_id: 'gpsm_app',
      grant_type: 'password',
      username: state.personal.login,
      password: state.personal.password,
    };

    return fetchFromApiAuth('auth/token', 'POST',{body})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error(res.status.toString());
        }
      })
      .then((res) => {
        localStorage.setItem(`access_token`,  res[`access_token`]);
        localStorage.setItem(`refresh_token`, res[`refresh_token`]);
      })
      .then(() => {
        dispatch(requestUserData());
      })
      .catch((code) => {
        dispatch(setAuthError(code.message));
      });
  };
}

export function logout() {
  return (dispatch, getState) => {
    const body = {
      client_id: 'gpsm_app',
      refresh_token: localStorage.getItem(`refresh_token`),
    };
    dispatch(removeTokens());
    return fetchFromApiAuth('realms/gpsm/protocol/openid-connect/logout', 'POST',{body})
      .then((res) => {
        dispatch(changeAuthStage('logout'));
      })
      .then(() => {
        dispatch(resetApp());
      });
  };
}

export function removeTokens() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(`access_token`);
      localStorage.removeItem(`refresh_token`);
      resolve('ok');
    })
      .then(() => {
        dispatch(changeAuthStage('logout'));
      })
      .catch();
  };
}
