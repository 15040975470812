import React from 'react';
import VisibleDoubleDescription from '../../redux/containers/visibleDoubleDescription';
import VisibleSvProductList from '../../redux/containers/visibleSvProductList';
import './index.css';

export function ReportRow(props) {
  function switchFullDescription() {
    props.switchFullDescription(props.data.description);
  }

  return (
    <div className={'report-row' + (props.last ? ' report-row_last' : '')}>
      <div
        className={'report-cell report-cell_interactive' + (props.data.specHidden ? ' hidden' : '')}
        onDoubleClick={switchFullDescription}
      >
        {props.data.spec}
      </div>
      <div className={'report-cell' + (props.data.viscoHidden ? ' hidden' : '')}>{props.data.visco}</div>
      <div className={'report-cell' + (props.data.intervalHidden ? ' hidden' : '')}>{props.data.interval}</div>
      <div className="report-cell report-cell_last">
        <VisibleSvProductList id={props.data.id} products={props.data.products} />
      </div>
      <VisibleDoubleDescription id={props.data.description} />
    </div>
  );
}
