import React from 'react';
import './index.css';
import { useNavigate } from "react-router-dom";
import { tableUrls } from '../../consts'
export function SideElement(props) {
  const navigate = useNavigate();
  let sideMod = '';
  let buttonImgMod = '';
  let captionMod = '';

  if (props.data.show === false) {
    return null;
  }
  if (props.data.parent !== null) {
    sideMod = ' side-element_subcategory';
    if (props.current === props.data.id) {
      sideMod += ' side-element_current';
    }
  } else {
    buttonImgMod = props.data.collapsed
      ? ' collapse-button__img_open'
      : ' collapse-button__img_close';
    if (props.data.id === props.currentGroup) {
      sideMod += ' side-group-element_current';
    }
  }

  if (props.data.dataType === 'business') {
    captionMod = ' side-element__caption_bold';
  }

  function clickElement() {
    const pageData =  tableUrls.find(tableItem => tableItem.name === props.data.table)

    if(pageData){
      const linkProps =  {
        to: pageData.url,
        state: pageData.pageCode ? { pageCode: pageData.pageCode, withoutReset: true } : null}

    

        const options = {};
        if (linkProps.state) {
          options.state = linkProps.state;
        }

       navigate(linkProps.to, options);
    }
  
    if (props.data.parent === null) {
      props.switchGroupVisable(props.data.id);
    } else {
      props.setCurrentGuide(props.data.id, props.data.parent);
    }
  }

  return (
    <div className={'side-element' + sideMod} onClick={clickElement}>
      <div className="side-element__img"></div>
      <div className={'side-element__caption' + captionMod}>
        {props.data.name}
      </div>
      <div className="side-element__collapse-button">
        <div className={'collapse-button__img' + buttonImgMod}></div>
      </div>
    </div>
  );
}
