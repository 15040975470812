import {receiveGeneration} from "../sync/generation";

export function requestGeneration(active, type, code) {
	return async (dispatch) => {
		let data = {}
		data.active = active;
		data.type = type;
		data.code = code;
		dispatch(receiveGeneration([data]))
	};
}

